import authApi from './auth/api'
import organizationApi from './organization/api'
import clustersApi from './clusters/api'
import optimizationsApi from './optimizations/api'
import trackingApi from './tracking/api'
import usersApi from './users/api'
import notificationsApi from './notifications/api'
import { setAccessToken, setRefreshToken, setClientId } from './common'

const api = {
  setAccessToken,
  setRefreshToken,
  setClientId,
  auth: authApi,
  organization: organizationApi,
  clusters: clustersApi,
  optimizations: optimizationsApi,
  tracking: trackingApi,
  users: usersApi,
  notifications: notificationsApi,
}

export default api
