export function floatInForm(item) {
  return Number.parseFloat(item.replace(',', '.'))
}

export const booleanInForm = value => {
  return value === 1
}

export const nameParts = fullName => {
  const [firstName, ...rest] = fullName?.split(' ') || []
  const lastName = rest.join(' ')

  return {
    firstName,
    lastName,
  }
}
