import store, { actions } from 'store'

import { subscribePureListener, createPersister } from 'utils/store'
import { localStorageKeys } from 'utils/storageKeys'
import api from 'services/api'

const listeners = []

listeners.push(subscribePureListener(store,
  state => state.auth.apiAccessToken,
  (previous, current) => {
    api.setAccessToken(current)

    // if user has logged out, clear all user specific data from the state
    if (!current && !!previous) {
      vacate()
    }
  }))

listeners.push(subscribePureListener(store,
  state => state.auth.apiAccessToken,
  createPersister(localStorageKeys.apiAccessToken),
  false))

listeners.push(subscribePureListener(store,
  state => state.auth.apiRefreshToken,
  (previous, current) => {
    api.setRefreshToken(current)

    // if user has logged out, clear all user specific data from the state
    if (!current && !!previous) {
      vacate()
    }
  }))

listeners.push(subscribePureListener(store,
  state => state.auth.apiRefreshToken,
  createPersister(localStorageKeys.apiRefreshToken),
  false))

listeners.push(subscribePureListener(store,
  state => state.auth.clientId,
  (previous, current) => {
    api.setClientId(current)

    // if user has logged out, clear all user specific data from the state
    if (!current && !!previous) {
      vacate()
    }
  }))

listeners.push(subscribePureListener(store,
  state => state.auth.clientId,
  createPersister(localStorageKeys.clientId),
  false))

/* eslint-disable no-unused-vars */
function vacate() {
  Object.entries(actions)
    .filter(([, value]) => !!value.vacate)
    .forEach(([, value]) => {
      store.dispatch(value.vacate())
    })
}

export default listeners
