import PropTypes from 'prop-types'

import { Role, UserStatus } from 'services/enums'

const customPropTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),

  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),

  deferred: PropTypes.shape({
    resolve: PropTypes.func.isRequired,
    reject: PropTypes.func.isRequired,
    promise: PropTypes.instanceOf(Promise),
  }),

  category: PropTypes.shape({
    name: PropTypes.string,
    deviation: PropTypes.number,
    id: PropTypes.number,
  }),

  profile: PropTypes.shape({
    id: PropTypes.number.isRequired,
    role: PropTypes.oneOf([Role.admin, Role.categoryManager]).isRequired,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string.isRequired,
    profilePhotoUrl: PropTypes.string,
    status: PropTypes.oneOf([UserStatus.active, UserStatus.pendingAccess, UserStatus.suspended, UserStatus.waitingConfirmation]),
    categories: PropTypes.array,
  }),

  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    location: PropTypes.shape({
      lat: PropTypes.number,
      long: PropTypes.number,
    }),
    address: PropTypes.shape({
      line1: PropTypes.string,
      line2: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
      state: PropTypes.string,
    }),
  })),
}

export default customPropTypes
