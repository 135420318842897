import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './SuspendUserModal.module.scss'

const SuspendUserModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      title="Suspend account"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6 className={styles.suspendText}>Do you want to susspend account for this user? This action will allow you to add categories to other user. Later you can revoke access and add new categories to this user.</H6>
        <Button
          className={styles.okButton}
          onClick={onConfirm}
          variant="contained"
        >Ok</Button>
        <Button
          className={styles.cancelButton}
          onClick={onClose}
          variant="primary"
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

SuspendUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default SuspendUserModal
