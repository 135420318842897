export const vacate = 'vacate'

export const loginSuccess = 'login_success'

export const logoutSuccess = 'logout_success'

export const registerUserSuccess = 'register_user_success'

export const restoreApiAccessToken = 'restore_api_access_token'

export const restoreApiRefreshToken = 'restore_api_refresh_token'

export const restoreClientId = 'restore_client_id'
