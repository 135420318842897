import { createTheme } from '@mui/material'

const theme = createTheme({
  breakpoints: {
    values: {
      mobile: 0,
      tablet: 640,
      laptop: 1024,
      desktop: 1200,
      wideScreen: 1600,
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#a6ce39',
      contrastText: '#333333',
    },
    secondary: {
      main: '#6b6b6b',
    },
    background: {
      default: '#fff',
      paper: '#fff',
    },
    text: {
      primary: '#333333',
      secondary: '#292e31',
      disabled: 'rgba(51,51,51,0.5)',
      hint: 'rgba(51,51,51,0.5)',
    },
    success: {
      main: '#8dc640',
    },
    error: {
      main: '#e61458',
    },
    caption: '#b2b2b2',
  },
  typography: {
    button: {
      fontSize: '1rem',
      fontWeight: 600,
    },
    h1: {
      fontSize: '1.5625rem',
      fontWeight: 800,
    },
    h2: {
      fontSize: '1.375rem',
      fontWeight: 800,
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 700,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 800,
    },
    h5: {
      fontSize: '1.125rem',
      fontWeight: 500,
    },
    h6: {
      fontSize: '1.125rem',
      fontWeight: 400,
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing: 8,
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#a6ce39',
        color: '#fff',
      },
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          display: 'inline-block',
          height: 'fit-content',
        },
      },
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
  },
})

export default theme
