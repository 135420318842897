import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import { Button } from '@mui/material'
import Link from '@mui/material/Link'

import Dialog from 'components/ui/Dialog'
import { P } from 'components/ui/Typography'
import { CloseIcon } from 'assets/icons'

import styles from './EditClusters.module.scss'

const EditClusters = ({ openModal, onClose, onUpload, onExport }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    accept: '.csv, application/vnd.ms-excel, text/csv',
  })
  const [chosenFile, setChosenFile] = useState(null)

  useEffect(() => {
    setChosenFile(acceptedFiles?.[0])
  }, [acceptedFiles])

  const handleDeleteItem = useCallback(() => {
    setChosenFile(null)
  }, [])

  const handleCancel = useCallback(() => {
    setChosenFile(null)
    onClose()
  }, [onClose])

  const handleUploadFile = useCallback(() => {
    onUpload(chosenFile)
    setChosenFile(null)
  }, [chosenFile, onUpload])

  return (
    <Dialog
      className={styles.dialog}
      title="Edit clusters"
      open={openModal}
      onClose={onClose}
    >
      <div className={styles.body}>
        <P className={styles.description}>
          Edit your current clusters by uploading an excel file with your desired cluster setup. Or you can
          <Link className={styles.link} component="button" variant="body2" onClick={onExport}> export the current state of clusters to edit.</Link>
        </P>

        <div {...getRootProps({ className: 'dropzone' })} className={`flex-col-center ${styles.fileContainer}`}>
          <input {...getInputProps()} />
          {
            !chosenFile ?
              <>
                <Button className={styles.chooseFileButton} onClick={open} variant="contained" size="small">
                  Choose file
                </Button>
                <div className="flex-col-center">
                  <P>or</P>
                  <P>drag-and-drop a file to the area</P>
                </div>
              </> :
              <div className={`flex-col-center ${styles.uploadedFilesContainer}`}>
                <div className={`flex-row-center ${styles.fileNameContainer}`}>
                  <div className={styles.chosenFile}>{chosenFile.name}</div>
                  <CloseIcon className={styles.closeIcon} onClick={handleDeleteItem} />
                </div>
                <Button className={styles.uploadButton} onClick={handleUploadFile} variant="contained" size="small">
                  Upload
                </Button>
                <Button className={styles.cancelButton} onClick={handleCancel} variant="primary" size="small">
                  Cancel
                </Button>
              </div>
          }
        </div>
      </div>
    </Dialog>
  )
}

EditClusters.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
  onExport: PropTypes.func.isRequired,
}

export default EditClusters
