import { clearDiacritics } from 'utils/language'

export const searchByWord = (searchValue, searchItem) => {
  if (searchValue !== '') {
    // allow each word to be searched separately
    const searchParams = clearDiacritics(searchValue).toLowerCase().split(' ')
    const itemSearchValue = clearDiacritics(searchItem.toLowerCase())
    return searchParams.reduce((prev, curr) => {
      return prev && itemSearchValue.includes(curr)
    }, true)
  } else {
    return true
  }
}
