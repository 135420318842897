import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import Input from 'components/ui/Input'
import { useForm, useRevalidate } from 'hooks/useForm'
import { Errors } from 'components/ui/Typography'
import { validateResetPassword } from 'services/validation/resetPassword'

import styles from './PasswordResetStart.module.scss'

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

const PasswordResetStart = ({ open, onClose, onPasswordReset }) => {
  const submit = useCallback(values => onPasswordReset(values.password), [onPasswordReset])

  const onChange = useRevalidate()
  const { errors, submitForm, processing, values, setValue, clear } =
    useForm('passwordReset', validateResetPassword, submit, initialValues, onChange)

  const handleClose = useCallback(() => {
    onClose()
    clear()
  }, [clear, onClose])

  useEffect(() => {
    if (open) {
      clear()
    }
  }, [clear, open])

  return (
    <Dialog
      title="Reset password"
      open={open}
      onClose={handleClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <Errors errors={errors?._} />
        <Input
          name="password"
          label="New password"
          type="password"
          value={values.password || ''}
          onChange={setValue}
          error={!!errors?.password}
          helperText={errors?.password?.[0]}
          fullWidth
        />
        <Input
          name="passwordConfirmation"
          label="New password again"
          type="password"
          value={values.passwordConfirmation || ''}
          onChange={setValue}
          error={!!errors?.passwordConfirmation}
          helperText={errors?.passwordConfirmation?.[0]}
          fullWidth
          className={styles.passwordConfirmationInput}
        />

        <Button
          className={styles.resetPasswordButton}
          onClick={submitForm}
          variant="contained" size="medium"
          disabled={processing}
        >Reset Password</Button>
        <Button
          className={styles.button}
          onClick={handleClose}
          variant="primary"
          disabled={processing}
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

PasswordResetStart.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPasswordReset: PropTypes.func.isRequired,
}

export default PasswordResetStart
