import LRU from 'lru-cache'

import { hour } from 'services/api/common'

const cache = {
  // todo should be cleared on any notification about assortment optimization
  optimizations: new LRU({
    max: 5,
    maxAge: 1 * hour,
    updateAgeOnGet: false,
  }),

  // todo should be cleared on any notification about assortment optimization
  optimization: new LRU({
    max: 10,
    maxAge: 1 * hour,
    updateAgeOnGet: false,
  }),

  // todo should be cleared on any notification about assortment optimization
  keyIndicators: new LRU({
    max: 30,
    maxAge: 1 * hour,
    updateAgeOnGet: false,
  }),

  // todo should be cleared on any notification about assortment optimization
  goals: new LRU({
    max: 50,
    maxAge: 1 * hour,
    updateAgeOnGet: false,
  }),

  categories: new LRU({
    max: 20,
    maxAge: 12 * hour,
    updateAgeOnGet: false,
  }),

  subcategories: new LRU({
    max: 20,
    maxAge: 12 * hour,
    updateAgeOnGet: false,
  }),

  producers: new LRU({
    max: 20,
    maxAge: 12 * hour,
    updateAgeOnGet: false,
  }),

  brands: new LRU({
    max: 20,
    maxAge: 12 * hour,
    updateAgeOnGet: false,
  }),
}

export default cache
