import React from 'react'
import PropTypes from 'prop-types'

import { assortmentLengthNumberFormat } from 'utils/numberFormat'
import { H6 } from 'components/ui/Typography'
import BaseAssortmentCard from 'components/ui/AssortmentCards/BaseAssortmentCard'

import styles from '../AssortmentCards.module.scss'

const AssortmentLengthStabilityCard = ({ data, className }) => {
  const { code, title, info, percentChange, absoluteChange } = data

  return (
    <BaseAssortmentCard title={title} info={info} className={`${className || ''} ${styles.card}`}>
      <div className={`flex-row-center ${styles.smallNumberContainer}`}>
        <div className={`${styles.smallNumber} ${code}`}>{percentChange}%</div>
        <H6>SKUs vs previous period</H6>
      </div>

      <div className={`flex-row-center ${styles.row}`}>
        <div className={`${styles.smallNumber} ${code}`}>{assortmentLengthNumberFormat(absoluteChange)}</div>
        <H6>SKUs vs previous period</H6>
      </div>
    </BaseAssortmentCard>
  )
}

AssortmentLengthStabilityCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    percentChange: PropTypes.number,
    absoluteChange: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
}

export default AssortmentLengthStabilityCard
