import { useCallback, useState } from 'react'

/**
 * Hook that provides utility callbacks for boolean state and helps in minimizing use of arrow functions.
 *
 * @example
 * const [showPopup, setShowPopup, unsetShowPopup, toggleShowPopup] = useBooleanState(false)
 *
 * <Button onClick={setShowPopup} title="Show popup" />
 *
 * <Button onClick={toggleShowPopup} title="Toggle popup" />
 *
 * <Popup
 *   open={showPopup}
 *   onClose={unsetShowPopup}
 * />
 */
const useBooleanState = initialState => {
  const [value, setValue] = useState(initialState)

  const set = useCallback(() => {
    setValue(true)
  }, [])

  const unset = useCallback(() => {
    setValue(false)
  }, [])

  const toggle = useCallback(() => {
    setValue(current => !current)
  }, [])

  return [value, set, unset, toggle]
}

export default useBooleanState
