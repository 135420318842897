export const getPageTitle = (url, id) => {
  switch (url) {
    case '/':
      return ''
    case '/clusters':
      return 'Clusters'
    case '/assortment-optimization/all':
      return 'Assortment optimization'
    case `/assortment-optimization/details/${id}`:
      return 'Assortment details'
    case '/execution-tracking':
      return 'Execution tracking'
    case '/users':
      return 'Users'
    case '/settings':
      return 'Settings'
    case '/logout':
      return 'Log out'
    default:
      return ''
  }
}
