import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'
import { useParams } from 'react-router'
import { useNavigate, useLocation } from 'react-router-dom'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

import { Role } from 'services/enums'
import useData from 'hooks/useData'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import { H3 } from 'components/ui/Typography'
import ConfigureProperties from 'pages/ConfigureProperties'
import Suspended from 'components/ui/Suspended'
import useAuth from 'hooks/useAuth'
import { PreviousIcon } from 'assets/icons'
import useBooleanState from 'hooks/useBooleanState'
import api from 'services/api'
import ReadyForCategoryManagerModal from 'components/ui/ReadyForCategoryManagerModal'
import RunOptimizationModal from 'components/ui/RunOptimizationModal'
import ExportStartModal from 'components/ui/ExportStartModal'
import useToast from 'components/ui/Toast/useToast'
import ConfirmChoicesDialog from 'components/ui/ConfirmChoicesDialog'

import OptimizationTitle from './OptimizationTitle'
import EditTitleModal from './EditTitleModal'
import FinalizeAssortment from './FinalizeAssortment'
import RecommendedAssortment from './RecommendedAssortment'
import AssortmentOptimizationKPICards from './AssortmentOptimizationKPICards'
import AssortmentOptimizationFilters from './AssortmentOptimizationFilters'
import styles from './AssortmentOptimizationDetails.module.scss'

const AssortmentOptimizationDetails = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const isAdmin = useAuth({ is: Role.admin })
  const [readyForCategoryManagerOpen, openReadyForCategoryManager, closeReadyForCategoryManager] = useBooleanState(false)
  const [runOptimizationOpen, openRunOptimization, closeRunOptimization] = useBooleanState(false)
  const [confirmChoicesOpen, openConfirmChoices, closeConfirmChoices] = useBooleanState(false)
  const [exportStartOpen, openExportStart, closeExportStart] = useBooleanState(false)

  const { successToast, errorToast } = useToast()
  const [finalizeAssortmentDialogOpen, openFinalizeAssortmentDialog, closeFinalizeAssortmentDialog] = useBooleanState(false)

  const optimization = useSelector(state => state.optimizations.optimization)
  const quotas = useSelector(state => state.organizations.quotas)
  const approvalErrors = useSelector(state => state.optimizations.optimization?.approvalErrors)
  const user = useSelector(state => state.users.profile)

  const { id: optimizationId } = useParams()
  const [filter, setFilter] = useState({ cluster: null, store: null })
  const [configurePropertiesOpen, openConfigureProperties, closeConfigureProperties] = useBooleanState(false)
  const [editTitleOpen, openEditTitle, closeEditTitle] = useBooleanState(false)

  const { loaded, error, data: clusterConfigId } = useData(() => {
    return Promise.all([
      dispatch(actions.optimizations.fetchOptimization(optimizationId, filter?.cluster?.id, filter?.store?.id)),
      dispatch(actions.optimizations.fetchOptimizationKeyIndicators(optimizationId, filter?.cluster?.id, filter?.store?.id)),
    ])
      .then(([optimization]) => optimization.clusterConfigId)
  }, [dispatch, optimizationId, filter])

  const { loaded: clustersLoaded, error: clustersError } = useData(() => {
    if (!clusterConfigId) {
      return Promise.reject()
    }
    return dispatch(actions.clusters.fetchClustersWithStores(clusterConfigId))
  }, [clusterConfigId, dispatch])

  const handleExport = useCallback(() => {
    return api.optimizations.downloadOptimization(optimizationId)
      .then(() => openExportStart())
  }, [optimizationId, openExportStart])

  const handleReadyConfirmation = useCallback(() => {
    return dispatch(actions.optimizations.setReadyForCategoryManager(optimizationId))
      .then(() => {
        successToast('Assortment is ready for review')
        return closeReadyForCategoryManager()
      })
  }, [closeReadyForCategoryManager, dispatch, optimizationId, successToast])

  const handleRun = useCallback(() => {
    return dispatch(actions.organizations.fetchQuotas())
      .then(() => openRunOptimization())
  }, [openRunOptimization, dispatch])

  const handleRunConfirmation = useCallback(() => {
    return dispatch(actions.optimizations.runOptimization(optimizationId))
      .then(() => {
        return successToast('Assortment is queued for optimization processing.')
      })
      .catch(e => {
        if (e.statusCode === 402) {
          errorToast('All optimizations have been used.')
        } else if (e.statusCode === 409) {
          errorToast('Optimization can be ran on DRAFT assortments only.')
        } else {
          errorToast('Something went wrong. Please try again.')
        }
      })
      .finally(() => {
        closeRunOptimization()
      })
  }, [optimizationId, successToast, errorToast, closeRunOptimization, dispatch])

  const handleConfirm = useCallback(() => {
    dispatch(actions.optimizations.setApproveOptimization(optimizationId, user?.id, user?.firstName, user?.lastName))
      .then(closeConfirmChoices)
      .catch(e => {
        if (e.statusCode === 409) {
          dispatch(actions.optimizations.fetchApprovalErrorsSuccess(approvalErrors))
        } else {
          errorToast('Something went wrong. Please try again.')
        }
      })
  }, [dispatch, optimizationId, user, closeConfirmChoices, approvalErrors, errorToast])

  const handleConfirmChoices = useCallback(() => {
    dispatch(actions.optimizations.fetchApprovalErrors(optimizationId))
      .then(() => openConfirmChoices())
      .catch(() => {
        errorToast('Something went wrong. Please try again.')
      })
  }, [dispatch, errorToast, openConfirmChoices, optimizationId])

  return (
    <>
      <div className={styles.container}>
        <Button
          onClick={() => navigate('/assortment-optimization/all', { state: location.state })}
          className={styles.backButton}
          variant="primary"
        >
          <PreviousIcon className="icon small" />
          <H3>Back to All optimizations</H3>
        </Button>

        {
          clustersLoaded ?
            <AssortmentOptimizationFilters
              onExport={handleExport}
              onReady={openReadyForCategoryManager}
              onRun={handleRun}
              onFinalize={openFinalizeAssortmentDialog}
              onConfirm={handleConfirmChoices}
              onSelectionChange={setFilter}
              className={styles.headerFilter}
              isAdmin={isAdmin}
            /> :
            <SectionLoadingIndicator className={styles.headerFilter} error={!!clustersError} />
        }

        {
          loaded ?
            <>
              <OptimizationTitle optimization={optimization} onEdit={openEditTitle} readOnly={!isAdmin || !optimization.status.isEditable} />
              <AssortmentOptimizationKPICards
                onConfigure={openConfigureProperties}
                className={styles.kpiCardsSize}
                isAdmin={isAdmin}
                isEditable={optimization.status.isEditable}
              />
            </> :
            <SectionLoadingIndicator className={styles.kpiCardsSize} error={!!error} />
        }

        {
          loaded ?
            <RecommendedAssortment
              clusterId={filter.cluster?.id}
              storeId={filter.store?.id}
              isAdmin={isAdmin}
            /> :
            <SectionLoadingIndicator className={styles.kpiCardsSize} error={!!error} />
        }
      </div>

      <Suspended open={readyForCategoryManagerOpen}>
        <ReadyForCategoryManagerModal
          open={readyForCategoryManagerOpen}
          onClose={closeReadyForCategoryManager}
          onConfirm={handleReadyConfirmation}
        />
      </Suspended>

      <Suspended open={configurePropertiesOpen}>
        <ConfigureProperties
          open={configurePropertiesOpen}
          onClose={closeConfigureProperties}
          clusterId={filter.cluster?.id}
        />
      </Suspended>

      <Suspended open={finalizeAssortmentDialogOpen}>
        <FinalizeAssortment
          open={finalizeAssortmentDialogOpen}
          onClose={closeFinalizeAssortmentDialog}
        />
      </Suspended>

      <Suspended open={runOptimizationOpen}>
        <RunOptimizationModal
          open={runOptimizationOpen}
          onClose={closeRunOptimization}
          onConfirm={handleRunConfirmation}
          quotas={quotas}
        />
      </Suspended>

      <Suspended open={confirmChoicesOpen}>
        <ConfirmChoicesDialog
          open={confirmChoicesOpen}
          onClose={closeConfirmChoices}
          onConfirm={handleConfirm}
          approvalErrors={approvalErrors}
        />
      </Suspended>

      <Suspended open={editTitleOpen}>
        <EditTitleModal
          open={editTitleOpen}
          onClose={closeEditTitle}
          title={optimization?.title}
          optimization={optimization}
        />
      </Suspended>

      <Suspended open={exportStartOpen}>
        <ExportStartModal
          open={exportStartOpen}
          onClose={closeExportStart}
        />
      </Suspended>
    </>
  )
}
AssortmentOptimizationDetails.propTypes = {
  pager: PropTypes.object,
}
export default AssortmentOptimizationDetails
