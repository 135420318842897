import React, { useState } from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import SplashScreen from 'components/ui/SplashScreen'

import styles from './NoMatchFound.module.scss'

function NoMatchFound({ image, titleText, descriptionText, className }) {
  const navigate = useNavigate()
  const [loaded, setLoaded] = useState(false)

  return (
    <div className={styles.NoMatchFound}>
      {
        loaded ?
          null :
          <SplashScreen />
      }
      <div className={loaded ? styles.textButtonWrap : styles.hidden}>
        <img
          className={styles.noMatchPhoto}
          src={image}
          onLoad={() => setLoaded(true)}
          alt='background'
        />
        <div className={styles.titleText}>{titleText}</div>
        <div className={`${styles.descriptionText} ${className || ''}`}>{descriptionText}</div>
        <Button
          variant="contained"
          className={styles.backButton}
          onClick={() => navigate('/')}
        >Back to Spectra</Button>
      </div>
    </div>
  )
}

NoMatchFound.propTypes = {
  image: PropTypes.string.isRequired,
  titleText: PropTypes.string.isRequired,
  descriptionText: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default NoMatchFound
