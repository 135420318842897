import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, Select, Button } from '@mui/material'
import PropTypes from 'prop-types'
import { selectors } from 'store'

import { ClustersIcon, ExportIcon } from 'assets/icons'
import useAuth from 'hooks/useAuth'
import { Role } from 'services/enums'

import styles from './ClusterFilters.module.scss'

const ALL_CLUSTERS_ID = 'ALL_CLUSTERS'

const ClusterFilters = ({ onExport, onEdit, onConfirm, onSelectionChange, className }) => {
  const clustersConfig = useSelector(state => state.clusters.clustersConfig?.clusters)
  const isReadOnly = useSelector(state => state.clusters.clustersConfig?.confirmed)
  const allStores = useSelector(selectors.clusters.allStores)
  const [clusterData, setClusterData] = useState([])
  const [cluster, setCluster] = useState('')
  const [store, setStore] = useState('')
  const isAdmin = useAuth({ is: Role.admin })

  useEffect(() => {
    const allClusters = { id: ALL_CLUSTERS_ID, name: 'All Clusters', stores: allStores }
    setClusterData([allClusters, ...clustersConfig])
    setCluster(allClusters)
  }, [allStores, clustersConfig])

  const handleClusterSelect = useCallback(({ target: { value } }) => {
    setCluster(value)
    setStore('')
    const selectedCluster = value.id === ALL_CLUSTERS_ID ? null : value
    onSelectionChange({ cluster: selectedCluster, store: null })
  }, [onSelectionChange])

  const handleStoreSelect = useCallback(({ target: { value } }) => {
    setStore(value)
    const selectedCluster = cluster.id === ALL_CLUSTERS_ID ? null : cluster
    onSelectionChange({ cluster: selectedCluster, store: value })
  }, [cluster, onSelectionChange])

  return (
    <div className={`flex-row-center ${styles.clusterFilters} ${className || ''}`}>
      <ClustersIcon className="icon large" />

      <Select
        className={styles.select}
        value={cluster}
        size="small"
        onChange={handleClusterSelect}
      >
        {
          clusterData?.map(cluster => (
            <MenuItem key={cluster.id} value={cluster}>
              {cluster.name}
            </MenuItem>
          ))
        }
      </Select>

      <Select
        className={styles.select}
        value={store}
        size="small"
        onChange={handleStoreSelect}
        displayEmpty={true}
        renderValue={
          store !== '' ? undefined : () => <span>Choose store</span>
        }
      >
        {
          cluster?.stores?.map(store => (
            <MenuItem key={store.id} value={store}>
              {store.name}
            </MenuItem>
          ))
        }
      </Select>

      <div className={styles.buttons}>
        <Button
          className={styles.exportButton}
          variant="contained"
          size="medium"
          startIcon={<ExportIcon className="icon xsmall" />}
          onClick={onExport}
        >
          Export
        </Button>
        {
          (isAdmin && !isReadOnly) && <Button
            className={styles.editButton}
            variant="contained"
            size="medium"
            onClick={onEdit}
          >
            Edit Clusters
          </Button>
        }
        {
          (isAdmin && !isReadOnly) && <Button
            className={styles.confirmButton}
            variant="contained"
            size="medium"
            onClick={onConfirm}
          >
            Confirm Clusters
          </Button>
        }
      </div>
    </div>
  )
}

ClusterFilters.propTypes = {
  className: PropTypes.string,
  onExport: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
}

export default ClusterFilters
