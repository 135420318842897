import store, { actions } from 'store'

import { ApiError } from 'services/errors'

import axios from './axios.instance'

export const config = {}
export const setAccessToken = token => {
  config.accessToken = token
  axios.defaults.headers.common.Authorization = token ? `Bearer ${token}` : null
}

export const setRefreshToken = token => {
  config.refreshToken = token
}

export const setClientId = clientId => {
  config.clientId = clientId
}

export const commonErrorHandler = error => {
  if (error instanceof ApiError) {
    if (error.statusCode === 403) {
      return store.dispatch(actions.router.navigate('/unauthorized'))
    }
    if (error.statusCode === 404) {
      return store.dispatch(actions.router.navigate('/not-found'))
    }
  }

  throw error
}

/**
 * Applies transformation to the data and returns transformed data.
 * Transformed data keeps the metadata of the original data.
 */
export const withMeta = (data, transformation) => {
  const transformedData = transformation(data)
  if (transformedData) {
    transformedData._meta = data?._meta
  }
  return transformedData
}

export const second = 1000
export const minute = second * 60
export const hour = minute * 60
