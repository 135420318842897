import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary, TableBody } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TableCell from '@mui/material/TableCell'

import NoResults from 'components/ui/NoResults'
import { H2, Strong } from 'components/ui/Typography'
import SearchInput from 'components/ui/SearchInput'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { ExpandIcon, SortIcon, SortAscIcon, SortDescIcon } from 'assets/icons'
import useHeatmap from 'hooks/useHeatmap'
import { heatmapClasses } from 'components/ui/VerticalHeatmapLegend'
import CustomTooltip from 'components/ui/CustomTooltip'

import CustomTableCell from '../ExecutionTrackingTableCells/TableCell'

import styles from './ExecutionTrackingTable.module.scss'

const headCategoryCells = [
  {
    id: 'name',
    sortId: 'name',
    label: 'Category',
    info: 'Group of similar products within retail department that are inter-related or substitutable. (I.e., meet a similar consumer need)',
  },
  {
    id: 'recommendedLength',
    sortId: 'recommended_length',
    label: 'RAL',
    info: 'Recommended assortment length.',
  },
  {
    id: 'deviation',
    sortId: 'deviation',
    label: 'Deviation',
    suffix: '%',
    info: 'A difference between actual performance and planned performance.',
  },
  {
    id: 'revenue',
    sortId: 'revenue',
    label: 'Revenue',
    info: 'Sales generated from the sale of goods in the specified period.',
  },
  {
    id: 'revenueShare',
    sortId: 'revenue_share',
    label: 'Revenue share',
    suffix: '%',
    className: 'revenueShareCell',
    info: 'Share of category or subcategory in the total revenue.',
  },
  {
    id: 'unitsSold',
    sortId: 'units_sold',
    label: 'Quantity',
    info: 'Number of pieces (items) sold in the specified period.',
  },
  {
    id: 'salesShare',
    sortId: 'sales_share',
    label: 'Quantity share',
    suffix: '%',
    info: 'Share of category or subcategory in the total quantity.',
  },
  {
    id: 'baskets',
    sortId: 'baskets',
    label: 'No.baskets',
    info: 'Number of baskets (transactions) with specific category or subcategory sold in the specified period.',
  },
  {
    id: 'basketShare',
    sortId: 'basket_share',
    label: 'Basket share',
    suffix: '%',
    info: 'Share of baskets (transactions) which contain specific category or subcategory in total number of baskets (transactions). (Note: can be higher then 100% because one basket can contain different products)',
  },
]

function CategoriesTable({
  tableData,
  total,
  compare,
  sort,
  onSortChange,
}) {
  const max = useMemo(() => Math.abs(Math.max(...tableData.map(s => s.deviation))), [tableData])
  const getHeatmapClass = useHeatmap(0, max, heatmapClasses)
  const handleRequestSort = useCallback((property) => {
    const isAsc = sort.sortBy === property && sort.sortOrder === 'asc'
    onSortChange({ sortBy: property, sortOrder: isAsc ? 'desc' : 'asc' })
  }, [sort, onSortChange])

  const handleSearchUpdate = useDebounceCallback(({ target: { value } }) => {
    onSortChange(prevSort => ({ ...prevSort, search: value }))
  }, [onSortChange])

  return (
    <div className={`${styles.executionTrackingTable}`}>
      <Accordion className="accordion" defaultExpanded={true}>
        <AccordionSummary className="summary" expandIcon={<ExpandIcon className="icon small" />}>
          <H2 className={styles.legend}> Execution tracking categories </H2>
          <SearchInput placeholder="Search" onChange={handleSearchUpdate} onClick={(e) => e.stopPropagation()} className={styles.searchInput} />
        </AccordionSummary>
        <AccordionDetails>
          {
            !tableData?.length ?
              <NoResults title="No results found" /> :
              <TableContainer className={styles.tableContainer}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {
                        headCategoryCells.map(headCell => (
                          <TableCell className={`${styles.headCell} ${styles[headCell.className]}`} key={headCell.id}>
                            <div className={styles.headCellsLayout}>
                              <CustomTooltip title={headCell.info} >
                                <span>{headCell.label}</span>
                              </CustomTooltip>
                              {sort.sortBy !== headCell.sortId && <SortIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                              {sort.sortBy === headCell.sortId && sort.sortOrder === 'asc' && <SortAscIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                              {sort.sortBy === headCell.sortId && sort.sortOrder === 'desc' && <SortDescIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                            </div>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      tableData.map(row => (
                        <TableRow key={row.id}>
                          <TableCell className={styles.skuCell}><Strong>{row.name}</Strong></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={+row.recommendedLength} /></TableCell>
                          <TableCell className={`${styles.cell} ${getHeatmapClass(row.deviation)}`}>
                            {Math.sign(row.deviation) === 1 ? '+' : ''}{row.deviation.toFixed(2)}%
                          </TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.revenue} name='revenue' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.revenueShare} suffix='%' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.unitsSold} /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.salesShare} suffix='%' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.baskets} /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.basketShare} suffix='%' /></TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow className={styles.stickyRow}>
                      {
                        headCategoryCells.map(col => (
                          col.id === 'name' ?
                            <TableCell key={col.id}><Strong>Total</Strong></TableCell> :
                            <TableCell key={col.id} align="right">
                              <Strong>
                                {
                                  col.suffix === '%' || col.id === 'revenue' ?
                                    total[col.id]?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) :
                                    total[col.id]?.toLocaleString('en-US')
                                }
                                {col.suffix}
                              </Strong>
                            </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableFooter>
                </Table>
              </TableContainer>
          }
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
CategoriesTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    recommendedLength: PropTypes.number,
    deviation: PropTypes.number,
    revenue: PropTypes.number,
    revenueShare: PropTypes.number,
    unitsSold: PropTypes.number,
    salesShare: PropTypes.number,
    baskets: PropTypes.number,
    basketShare: PropTypes.number,
  })),
  total: PropTypes.shape({
    recommendedLength: PropTypes.number,
    deviation: PropTypes.number,
    revenue: PropTypes.number,
    revenueShare: PropTypes.number,
    unitsSold: PropTypes.number,
    salesShare: PropTypes.number,
    baskets: PropTypes.number,
    basketShare: PropTypes.number,
  }),
  compare: PropTypes.bool,
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
  }),
  onSortChange: PropTypes.func,
}
export default CategoriesTable
