import React from 'react'
import PropTypes from 'prop-types'

import { H6, H4 } from 'components/ui/Typography'
import BaseAssortmentCard from 'components/ui/AssortmentCards/BaseAssortmentCard'

import styles from '../AssortmentCards.module.scss'

const SpecialSKUsAssortmentCard = ({ data, className }) => {
  const { code, title, info, specialSkuShare, specialSkuNumber } = data

  return (
    <BaseAssortmentCard title={title} info={info} className={`${className || ''} ${styles.card}`}>
      <div className={`flex-row-center ${styles.row}`}>
        <div className={`${styles.bigNumber} ${code}`}>{specialSkuShare}% </div>
        <H6>share in assortment</H6>
      </div>

      <div className={`flex-row-center ${styles.row}`}>
        <H6 className={styles.text}>{title} No:</H6>
        <H4 className={code}>{specialSkuNumber.toLocaleString()}</H4>
      </div>
    </BaseAssortmentCard>
  )
}

SpecialSKUsAssortmentCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    specialSkuShare: PropTypes.number,
    specialSkuNumber: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
}

export default SpecialSKUsAssortmentCard
