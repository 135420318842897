import React from 'react'

import { LockIcon } from 'assets/icons'
import { H4, H6 } from 'components/ui/Typography'

import styles from './LoginPending.module.scss'

const LoginPending = () => {
  return (
    <div className={`auth-page ${styles.LoginPending}`}>
      <LockIcon className='icon large' />
      <H4> Access declined! </H4>
      <H6 className={styles.text} > Looks like your account is still not ready for access - we’re working on it!
        If you have any questions do not hesitate to contact us: <br />
        <a href="mailto: support@spectra.com" className={styles.mailLink}>support@spectra.com</a></H6>
    </div>
  )
}

export default LoginPending
