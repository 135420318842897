// noinspection CommaExpressionJS

import { useCallback, useEffect, useState } from 'react'

/**
 * This hook is used to load the async data. It provides loading flag and some other convenience methods.
 *
 * @param load A function that loads the data and returns a promise ofr the load operation outcome
 * @param dependencies Dependencies array for the load functin
 *
 * @return loading A flag indicating that loading of data is in progress or has finished
 * @return loading A flag indicating that that has loaded successfully.
 * @return error An error returned by the load function
 * @return data Data returned by the load function
 * @return reload A function tat can be used to reload the data
 * @return reloading A flag indicating that reloading of data is in progress or has finished
 *                   During data reload, both loading and reloading flags are true.
 *
 * @example <caption>Load data from API</caption>
 * <pre><code>
 *   const { loading, error, data } = useData(() => api.loadSomeData(), [])
 * </code></pre>
 *
 * @example <caption>Load multiple data from API</caption>
 * <pre><code>
 *   const { loading: fooLoading, error: fooError, data: fooData } = useData(() => api.loadFooData(), [])
 *   const { loading: barLoading, error: barError, data: barData } = useData(() => api.loadBarData(), [])
 * </code></pre>
 *
 * @example <caption>Load data with redux</caption>
 * <pre><code>
 *   const { loading: fooLoading, error: fooError } = useData(() => api.loadFooData(), [])
 *   const foo = useSelector(state => state.foo)
 * </code></pre>
 *
 */
const useData = (load, dependencies) => {
  const [loading, setLoading] = useState(true)
  const [reloading, setReloading] = useState(false)
  const [data, setData] = useState(undefined)
  const [error, setError] = useState(undefined)

  const loadData = useCallback(() => {
    setLoading(true)
    return Promise.resolve(load())
      .then(res => ((setData(res), setError())))
      .catch(err => ((setData(), setError(err || true))))
      .finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)

  useEffect(() => loadData(), [loadData])

  const reload = useCallback(() => {
    setReloading(true)
    return loadData(false)
      .finally(() => setReloading(false))
  }, [loadData])

  return {
    loading,
    loaded: !loading && !error,
    error,
    data,
    reload,
    reloading,
  }
}

export default useData
