export const vacate = 'vacate'

export const fetchOptimizationsSuccess = 'fetch_optimizations_success'
export const fetchOptimizationSuccess = 'fetch_optimization_success'
export const deleteOptimizationSuccess = 'delete_optimization_success'
export const fetchOptimizationKeyIndicatorsSuccess = 'fetch_optimization_key_indicators_success'
export const fetchGoalsSuccess = 'fetch_goals_success'
export const fetchLimitsSuccess = 'fetch_limits_success'
export const setGoalsSuccess = 'set_goals_success'
export const setLimitsSuccess = 'set_limits_success'
export const createOptimizationSuccess = 'create_optimization_success'
export const fetchOneOptimizationSuccess = 'fetch_one_optimization_success'
export const fetchProducersSuccess = 'fetch_producers_success'
export const fetchBrandsSuccess = 'fetch_brands_success'
export const resetBrands = 'reset_brands'
export const fetchCategoriesSuccess = 'fetch_categories_success'
export const fetchSubcategoriesSuccess = 'fetch_subcategories_success'
export const fetchSkusSuccess = 'fetch_skus_success'
export const setSkuLockFlagSuccess = 'set_sku_lock_flag_success'
export const setSkuPresenceSuccess = 'set_sku_presence_success'
export const fetchApprovalsSuccess = 'fetch_approvals_success'
export const setReadyForCategoryManagerSuccess = 'set_ready_for_category_manager_success'
export const fetchCategorySettingsSuccess = 'fetch_category_settings_success'
export const setCategorySettingsSuccess = 'set_category_settings_success'
export const fetchSubcategorySettingsSuccess = 'fetch_subcategory_settings_success'
export const setSubcategorySettingsSuccess = 'set_subcategory_settings_success'
export const finalizeAssortmentSuccess = 'finalize_assortment_success'
export const runOptimizationSuccess = 'run_optimization_success'
export const fetchApprovalErrorsSuccess = 'fetch_approval_errors_success'
export const setApproveOptimizationSuccess = 'set_approve_optimization_success'
export const updateOptimizationSuccess = 'update_optimization_success'
