import { camelCase } from 'lodash'

const prefix = 'REACT_APP_'

const config = Object.entries(process.env)
  .filter(([key]) => key.toUpperCase().startsWith(prefix))
  .map(([key, value]) => [key.replace(prefix, ''), value])
  .map(([key, value]) => [camelCase(key), value])
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})

export default config
