import React from 'react'
import { Routes, Route } from 'react-router-dom'

import appInitializer from 'hoc/appInitializer'
import ResetPassword from 'pages/ResetPassword'
import Login from 'pages/login'
import NoMatch from 'pages/NoMatch'
import Home from 'pages/main/Home'
import Clusters from 'pages/clusters/Clusters'
import ExecutionTracking from 'pages/tracking/ExecutionTracking'
import Users from 'pages/users/Users'
import Profile from 'pages/users/Profile'
import Settings from 'pages/users/Settings'
import MainNavigation from 'app/navigation/MainNavigation'
import AuthContainer from 'app/navigation/AuthContainer'
import AppProtectedContent from 'components/router/AppProtectedContent'
import AssortmentOptimizationContext from 'pages/optimizations/AssortmentOptimizationContext'
import AssortmentOptimizationDetails from 'pages/optimizations/AssortmentOptimizationDetails'
import AllAssortmentOptimizations from 'pages/optimizations/AllAssortmentOptimizations'
import { Role } from 'services/enums'
import Toast from 'components/ui/Toast'
import Unauthorized from 'pages/Unauthorized'
import NotFound from 'pages/NotFound'
import LoginPending from 'pages/login/LoginPending'
import NotVerified from 'pages/login/NotVerified'
import RegisterFlow from 'pages/Register/RegisterFlow'
import RegistrationVerificationSuccess from 'pages/Register/RegistrationVerificationSuccess'
import Logout from 'pages/Logout'

function App() {
  return (
    <>
      <Toast />

      <Routes>
        <Route path="/" element={<AuthContainer />}>
          <Route element={<AppProtectedContent anonymous redirectPath="/" />}>
            <Route path="/register" element={<RegisterFlow />} />
            <Route path="/registration/verification/:token" element={<RegistrationVerificationSuccess />} />
            <Route path="/login" element={<Login />} />
            <Route path="/password-reset/:email/:token" element={<ResetPassword />} />
            <Route path="/password-reset" element={<ResetPassword />} />
            <Route path="/login-pending" element={<LoginPending />} />
            <Route path="/not-verified" element={<NotVerified />} />
          </Route>
        </Route>
        <Route path="/" element={<MainNavigation />}>
          <Route element={<AppProtectedContent authenticated redirectPath="/login" />}>
            <Route index element={<Home />} />
            <Route path="/clusters" element={<Clusters />} />
            <Route path="/assortment-optimization" element={<AssortmentOptimizationContext />}>
              <Route path="all" element={<AllAssortmentOptimizations />} />
              <Route path="details/:id" element={<AssortmentOptimizationDetails />} />
            </Route>
            <Route path="/execution-tracking" element={<ExecutionTracking />} />
            <Route element={<AppProtectedContent is={Role.admin} />}>
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<Profile />} />
            </Route>
            <Route path="/settings" element={<Settings />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/logout" element={<Logout />} />
          </Route>
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/not-found" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </>
  )
}

export default appInitializer(App)
