const thousand = 1000
const million = thousand * thousand

export const numberFormat = num => {
  return num.toLocaleString()
}

export const sixDigitsNumberFormat = num => {
  if (Math.round(num) <= 9999) {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 })
  }
  if (Math.round(num) <= 999999) {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
  if (Math.round(num / thousand) <= 999999) {
    return (num / thousand).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'K'
  }
  if (Math.round(num / million) <= 999999) {
    return (num / million).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'M'
  }
  return (num / (million * thousand)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'B'
}

export const assortmentLengthNumberFormat = num => {
  if (Math.round(num) <= 99999) {
    return num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 })
  }
  if (Math.round(num / thousand) <= 9999) {
    return (num / thousand).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'K'
  }
  if (Math.round(num / million) <= 9999) {
    return (num / (thousand * thousand)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'M'
  }
  return (num / (million * thousand)).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + 'B'
}
