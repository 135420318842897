import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, TableBody } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
import TableCell from '@mui/material/TableCell'

import NoResults from 'components/ui/NoResults'
import { Strong } from 'components/ui/Typography'
import TablePaginationButtons from 'components/ui/TablePaginationButtons'
import { heatmapClasses } from 'components/ui/VerticalHeatmapLegend'
import CustomTooltip from 'components/ui/CustomTooltip'
import useHeatmap from 'hooks/useHeatmap'
import { SortIcon, SortAscIcon, SortDescIcon } from 'assets/icons'
import customPropTypes from 'utils/customPropTypes'

import SkuDeviationTableCell from '../ExecutionTrackingTableCells/SkuDeviationTableCell'
import CustomTableCell from '../ExecutionTrackingTableCells/TableCell'

import styles from './ExecutionTrackingTable.module.scss'

const headSubcategoryCells = [
  {
    id: 'name',
    sortId: 'name',
    label: 'name',
    infoSubcategory: 'Subgroup of products within larger category.',
    infoSKU: 'Stock-keeping unit, unique code assigned to every item / product.',
  },
  {
    id: 'deviation',
    sortId: 'deviation',
    label: 'Deviation',
    suffix: '%',
    info: 'A difference between actual performance and planned performance.',
  },
  {
    id: 'revenue',
    sortId: 'revenue',
    label: 'Revenue',
    info: 'Sales generated from the sale of goods in the specified period.',
  },
  {
    id: 'revenueShare',
    sortId: 'revenue_share',
    label: 'Revenue share',
    suffix: '%',
    className: 'revenueShareCell',
    info: 'Share of SKU / subcategory / category in the total revenue.',
  },
  {
    id: 'unitsSold',
    sortId: 'units_sold',
    label: 'Quantity',
    info: 'Number of pieces (items) sold within category/subcategory/SKU.',
  },
  {
    id: 'salesShare',
    sortId: 'sales_share',
    label: 'Quantity share',
    suffix: '%',
    info: 'Quantity share in total quantity sold within category/subcategory/SKU.',
  },
  {
    id: 'baskets',
    sortId: 'baskets',
    label: 'No.baskets',
    info: 'Number of baskets (transactions) with specific category / subcategory / SKU sold in the specified period.',
  },
  {
    id: 'basketShare',
    sortId: 'basket_share',
    label: 'Basket share',
    suffix: '%',
    info: 'Share of baskets (transactions) which contain specific category / subcategory / SKU in total number of baskets (transactions).',
  },
]

function SubcategoriesTable({
  tableData,
  total,
  compare,
  tableFilter,
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  pageLoaded,
  sort,
  onSortChange,
  loadedData,
}) {
  const max = useMemo(() => {
    return Math.abs(Math.max(...tableData.map(s => s.deviation)))
  }, [tableData])
  const getHeatmapClass = useHeatmap(0, max, heatmapClasses)

  const handleRequestSort = useCallback((property) => {
    const isAsc = sort.sortBy === property && sort.sortOrder === 'asc'
    onSortChange({ sortBy: property, sortOrder: isAsc ? 'desc' : 'asc' })
  }, [sort, onSortChange])

  return (
    <div className={`${styles.executionTrackingTable}`}>
      <Accordion className="accordion" defaultExpanded={true}>
        <AccordionDetails>
          {
            !tableData?.length ?
              <NoResults title="No results found" /> :
              <TableContainer className={loadedData && pageLoaded ? styles.tableContainer : styles.loading}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      {
                        headSubcategoryCells.map(headCell => (
                          <TableCell className={`${styles.headCell} ${styles[headCell.className]}`} key={headCell.id}>
                            <div className={styles.headCellsLayout}>
                              <CustomTooltip title={headCell.id === 'name' ? (tableFilter?.subcategory ? headCell.infoSKU : headCell.infoSubcategory) : headCell.info} >
                                <span>{headCell.id === 'name' ? (tableFilter?.subcategory ? 'SKU' : 'Subcategory') : headCell.label}</span>
                              </CustomTooltip>
                              {sort.sortBy !== headCell.sortId && <SortIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                              {sort.sortBy === headCell.sortId && sort.sortOrder === 'asc' && <SortAscIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                              {sort.sortBy === headCell.sortId && sort.sortOrder === 'desc' && <SortDescIcon className={styles.sortIcon} onClick={() => handleRequestSort(headCell.sortId)} />}
                            </div>
                          </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      tableData.map(row => (
                        <TableRow key={row.id}>
                          <TableCell className={styles.skuCell}><Strong>{row.name}</Strong></TableCell>
                          {
                            tableFilter?.subcategory ?
                              <TableCell className={styles.cell}>
                                <SkuDeviationTableCell deviation={row.deviation !== 0} />
                              </TableCell> :
                              <TableCell className={`${styles.cell} ${getHeatmapClass(row.deviation)}`}>
                                {Math.sign(row.deviation) === 1 ? '+' : ''}{row.deviation.toFixed(2)}%
                              </TableCell>
                          }
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.revenue} name='revenue' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.revenueShare} suffix='%' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.unitsSold} /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.salesShare} suffix='%' /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.baskets} /></TableCell>
                          <TableCell className={styles.cell}><CustomTableCell compare={compare} value={row.basketShare} suffix='%' /></TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                  <TableFooter>
                    <TableRow className={styles.stickyRow}>
                      {
                        headSubcategoryCells.map(col => (
                          col.id === 'name' ?
                            <TableCell key={col.id}><Strong>Total</Strong></TableCell> :
                            <TableCell key={col.id} align="right">
                              <Strong>
                                {
                                  col.suffix === '%' || col.id === 'revenue' ?
                                    total[col.id]?.toLocaleString('en-US', {
                                      minimumFractionDigits: 2,
                                      maximumFractionDigits: 2,
                                    }) :
                                    total[col.id]?.toLocaleString('en-US')
                                }
                                {col.suffix}
                              </Strong>
                            </TableCell>
                        ))
                      }
                    </TableRow>
                  </TableFooter>
                </Table>
                <TablePaginationButtons
                  nextPage={onNextPage}
                  previousPage={onPreviousPage}
                  hasNextPage={hasNextPage}
                  hasPreviousPage={hasPreviousPage}
                  pageLoaded={true}
                />
              </TableContainer>
          }
        </AccordionDetails>
      </Accordion>
    </div>
  )
}
SubcategoriesTable.propTypes = {
  tableData: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    recommendedLength: PropTypes.number,
    deviation: PropTypes.number,
    revenue: PropTypes.number,
    revenueShare: PropTypes.number,
    unitsSold: PropTypes.number,
    salesShare: PropTypes.number,
    baskets: PropTypes.number,
    basketShare: PropTypes.number,
  })),
  total: PropTypes.shape({
    recommendedLength: PropTypes.number,
    deviation: PropTypes.number,
    revenue: PropTypes.number,
    revenueShare: PropTypes.number,
    unitsSold: PropTypes.number,
    salesShare: PropTypes.number,
    baskets: PropTypes.number,
    basketShare: PropTypes.number,
  }),
  compare: PropTypes.bool,
  tableFilter: PropTypes.shape({
    search: PropTypes.string,
    category: customPropTypes.category,
    subcategory: PropTypes.oneOfType([PropTypes.arrayOf(customPropTypes.category), customPropTypes.category]),
  }),
  loadedData: PropTypes.bool,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  hasNextPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasPreviousPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageLoaded: PropTypes.bool,
  sort: PropTypes.shape({
    sortBy: PropTypes.string,
    sortOrder: PropTypes.string,
  }),
  onSortChange: PropTypes.func,
}
export default SubcategoriesTable
