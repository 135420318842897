import { isPasswordValid } from 'utils/validators'

export function validateResetPassword(values) {
  const errors = {}
  if (!values.password?.trim()) {
    errors.password = { required: true }
  } else if (!isPasswordValid(values.password, 8, 50)) {
    errors.password = { policy: true }
  }

  if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = { match: true }
  }
  return errors
}
