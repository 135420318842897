import { gql } from '@apollo/client'

const NOTIFICATIONS_SUBSCRIPTION = gql`
  subscription OnCreate($userId: String) {
    onCreate(user_id: $userId) {
      id
      type
      payload
      published_at
    }
  }
`

export class GraphqlAPI {
  static NOTIFICATIONS = {
    subscription: () => NOTIFICATIONS_SUBSCRIPTION,
    subscriptionVariables: (userId) => {
      const vars = {}
      if (userId) {
        vars.userId = userId
      }
      return vars
    },
  }
}
