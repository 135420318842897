import { UserStatus } from 'services/enums'
import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'
import { nvl } from 'utils/object'

import * as types from './types'

const initialState = frozen({
  users: null,
  user: null,
  profile: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.loadUserProfileSuccess]: loadUserProfileSuccess,
  [types.fetchUsersSuccess]: fetchUsersSuccess,
  [types.fetchUserSuccess]: fetchUserSuccess,
  [types.deleteUserSuccess]: deleteUserSuccess,
  [types.suspendUserSuccess]: suspendUserSuccess,
  [types.restoreUserSuccess]: restoreUserSuccess,
  [types.resendUserConfirmationLinkSuccess]: resendUserConfirmationLinkSuccess,
  [types.updateUserSuccess]: updateUserSuccess,
  [types.updateProfilePhotoSuccess]: updateProfilePhotoSuccess,
  [types.fetchUserSuccess]: fetchUserSuccess,
  [types.approveCategoryManagerSuccess]: approveCategoryManagerSuccess,
  [types.enableEmailNotificationsSuccess]: enableEmailNotificationsSuccess,
}

function vacate(state) {
  return {
    ...state,
    users: null,
    user: null,
    profile: null,
  }
}

function loadUserProfileSuccess(state, action) {
  return {
    ...state,
    profile: { ...action.profile },
  }
}

function fetchUsersSuccess(state, action) {
  return {
    ...state,
    users: action.data,
  }
}

function fetchUserSuccess(state, action) {
  return {
    ...state,
    user: action.data,
  }
}

function deleteUserSuccess(state, action) {
  return {
    ...state,
    users: state.users.filter(user => user.id !== action.userId),
  }
}

function suspendUserSuccess(state, action) {
  return {
    ...state,
    users: state.users.map((user) => {
      if (user.id === action.userId) {
        return {
          ...user,
          status: UserStatus.suspended,
        }
      }
      return user
    }),
  }
}

function restoreUserSuccess(state, action) {
  return {
    ...state,
    users: state.users.map((user) => {
      if (user.id === action.userId) {
        return {
          ...user,
          status: UserStatus.active,
        }
      }
      return user
    }),
  }
}

function resendUserConfirmationLinkSuccess(state, action) {
  return {
    ...state,
    user: action.userId,
  }
}

function updateUserSuccess(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      firstName: nvl(action.firstName, state.user.firstName),
      lastName: nvl(action.lastName, state.user.lastName),
      categories: nvl(action.categories, state.user.categories),
    },
    users: state.users?.map(user => {
      if (user.id === action.id) {
        return {
          ...user,
          categories: nvl(action.categories, user.categories),
        }
      }
      return user
    }),
  }
}

function approveCategoryManagerSuccess(state, action) {
  return {
    ...state,
    users: state.users.map(user => {
      if (user.id === action.id) {
        return {
          ...user,
          status: UserStatus.active,
          categories: action.categories,
        }
      }
      return user
    }),
  }
}

function updateProfilePhotoSuccess(state, action) {
  return {
    ...state,
    user: {
      ...state.user,
      profilePhotoUrl: action.data ?? '',
    },
  }
}

function enableEmailNotificationsSuccess(state, action) {
  return {
    ...state,
    profile: {
      ...state.profile,
      enableEmailNotification: action.data,
    },
  }
}

export default objectReducer(initialState, actionHandlers)
