import React, { useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'
import PropTypes from 'prop-types'
import Button from '@mui/material/Button'

import useData from 'hooks/useData'
import Dialog from 'components/ui/Dialog'
import { P, H6, Strong } from 'components/ui/Typography'
import MultipleChipSelect from 'components/ui/MultipleChipSelect'

import styles from './UserApproval.module.scss'

const UserApproval = ({ user, isOpen, onClose, onConfirm }) => {
  const dispatch = useDispatch()
  const [selectedValues, setSelectedValues] = useState([])
  const categories = useSelector(state => state.organizations?.unassignedCategories)

  const { loaded: loadedCategories } = useData(() => {
    return dispatch(actions.organizations.fetchUnassignedCategories())
  }, [dispatch])

  const updateUser = useCallback(() => {
    onConfirm(user.id, selectedValues)
    onClose()
  }, [onClose, selectedValues, onConfirm, user])

  return (
    <>
      {
        loadedCategories &&
          <Dialog
            title='Access confirmation'
            open={isOpen}
            onClose={onClose}
            className={`flex-col-center ${styles.approvalDialog}`}
          >
            <H6 className={styles.text}> Before you confirm access for <b>{user.firstName || ''} {user.lastName || ''}</b> please add categories: </H6>
            <Strong className={styles.categoriesText}> Categories: </Strong>
            <MultipleChipSelect itemsList={categories} onSelection={setSelectedValues} onContinue={updateUser} selectedValues={selectedValues} />
            {
              selectedValues.length > 0 ?
                <Button variant='contained' size='small' className={styles.confirmButton} onClick={updateUser}> Confirm Access </Button> :
                <P className={styles.helperText}> You have to add at least one category to each user! </P>
            }
            <Button className={styles.cancelButton} onClick={onClose}> Cancel </Button>
          </Dialog>
      }
    </>
  )
}
UserApproval.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.number,
    categories: PropTypes.array,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default UserApproval
