import React, { useCallback, useMemo, useState } from 'react'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { actions } from 'store'
import { useNavigate } from 'react-router'

import AssortmentOptimizationDrawerContent from 'pages/optimizations/AssortmentOptimizationDrawerContent'
import useContextDrawer from 'hooks/useContextDrawer'
import useData from 'hooks/useData'
import { FilterIcon } from 'assets/icons'
import { Strong } from 'components/ui/Typography'
import SearchInput from 'components/ui/SearchInput'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import OptimizationsTable from 'components/ui/OptimizationsTable/OptimizationsTable'
import usePager from 'hooks/usePager'
import useDebounceCallback from 'hooks/useDebounceCallback'
import { OptimizationStatus, Role } from 'services/enums'
import NoResults from 'components/ui/NoResults'

import styles from './AllAssortmentOptimizations.module.scss'

const serializePagerState = state => {
  if (!state) return state

  return {
    ...state,
    args: state.args?.map(arg => {
      return {
        ...arg,
        status: arg.status?.value,
      }
    }),
  }
}

const deserializePagerState = state => {
  if (!state) return state

  return {
    ...state,
    args: state.args?.map(arg => {
      return {
        ...arg,
        status: OptimizationStatus.from(arg.status),
      }
    }),
  }
}

const AllAssortmentOptimizations = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const profile = useSelector(state => state.users.profile)
  const { monthsInPeriod } = useSelector(state => state.organizations.config)
  const allOptimizations = useSelector(state => state.optimizations.allOptimizations)

  const initialState = useMemo(() => {
    const args = deserializePagerState(location?.state)?.args?.[0]
    return {
      search: args?.search,
      filter: {
        status: args?.status,
        dateRange: {
          from: args?.dateFrom,
          to: args?.dateTo,
        },
      },
    }
  }, [location?.state])

  const [filter, setFilter] = useState(initialState.filter)
  const [search, setSearch] = useState(initialState.search)

  const { data: optimizationsData, loaded, error } = useData(() => {
    return dispatch(actions.optimizations.fetchOptimizations({
      search,
      dateFrom: filter?.dateRange?.from,
      dateTo: filter?.dateRange?.to,
      status: filter?.status,
      includeApprovals: profile.role === Role.categoryManager,
      pagerState: deserializePagerState(location?.state),
    }))
  }, [profile, dispatch, filter, search, location?.state])

  const {
    previous: previousOptimizations,
    next: nextOptimizations,
    hasNext: hasNextOptimizations,
    hasPrevious: hasPreviousOptimizations,
    loaded: optimizationsPageLoaded,
    state,
  } = usePager(optimizationsData, actions.optimizations.fetchOptimizationsSuccess)

  const applyFilter = useCallback((filterValues) => {
    setFilter(filterValues)
    location.state = null
    navigate(location.pathname, { replace: true })
  }, [location, navigate])

  const openContextDrawer = useContextDrawer(() =>
    <AssortmentOptimizationDrawerContent onApply={applyFilter} defaultValues={filter} />, [applyFilter, filter])

  const handleSearchUpdate = useDebounceCallback(({ target: { value } }) => {
    setSearch(value)
    location.state = null
    navigate(location.pathname, { replace: true })
  }, [location, navigate])

  return (
    <div className={styles.AllAssortmentOptimizations}>
      <div className={`flex-row-center ${styles.filterContainer}`}>
        <SearchInput placeholder="Search" onChange={handleSearchUpdate} defaultValue={search} />
        <Button onClick={openContextDrawer} variant="primary" className={styles.filterButton} >
          <FilterIcon className={`icon normal ${styles.icon}`} />
          <Strong>Filter</Strong>
        </Button>
      </div>

      {
        allOptimizations ?
            !allOptimizations.length ?
              <NoResults
                title={'No results found'}
              /> :
              <div className={loaded ? '' : styles.tableContainer}>
                <OptimizationsTable
                  optimizationRecords={allOptimizations}
                  profile={profile}
                  onNextPage={nextOptimizations}
                  onPreviousPage={previousOptimizations}
                  numberOfMonths={monthsInPeriod}
                  hasNextPage={hasNextOptimizations}
                  hasPreviousPage={hasPreviousOptimizations}
                  pageLoaded={optimizationsPageLoaded}
                  pagerState={serializePagerState(state)}
                />
              </div> :
              <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }
    </div>
  )
}

export default AllAssortmentOptimizations
