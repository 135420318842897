import React, { useState, useCallback, useContext, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { actions } from 'store'
import { MenuItem, Select, Button } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormGroup from '@mui/material/FormGroup'
import Checkbox from '@mui/material/Checkbox'

import { P } from 'components/ui/Typography'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import SearchInput from 'components/ui/SearchInput'
import useData from 'hooks/useData'
import { useOnUpdate } from 'hooks/useOnUpdate'
import { AppLayoutContext } from 'app/navigation/AppLayoutProvider'
import { searchByWord } from 'utils/filter'

import styles from './RecommendedAssortmentFilter.module.scss'

const ALL_BRANDS_ID = 'ALL_BRANDS'
const chooseAllBrands = { id: ALL_BRANDS_ID, name: 'Izaberi sve' }

const RecommendedAssortmentFilter = ({ onApply, categoryId, subcategoryId, optimizationId, initialValues }) => {
  const isFirst = useRef(true)
  const dispatch = useDispatch()

  const { producers, brands } = useSelector(state => state.optimizations?.optimization)

  const [producer, setProducer] = useState(initialValues.producer || '')
  const [search, setSearch] = useState('')
  const [filteredBrands, setFilteredBrands] = useState(initialValues.brands || [])

  const { closeContextDrawer } = useContext(AppLayoutContext)

  useOnUpdate(() => {
    let searchedBrands = brands?.filter(brand => searchByWord(search, brand.name))
    if (isFirst.current) {
      // set checked initial brands
      searchedBrands = searchedBrands?.map(brand => {
        const fb = initialValues.brands.find(b => b.id === brand.id)
        return fb || brand
      })
      isFirst.current = false
    }
    setFilteredBrands(prevFilteredBrands => {
      if (!searchedBrands) {
        return [chooseAllBrands]
      }
      searchedBrands = searchedBrands?.map(brand => {
        const fb = prevFilteredBrands.find(b => b.id === brand.id) || {}
        return {
          ...fb,
          ...brand,
        }
      })
      return [chooseAllBrands, ...searchedBrands]
    })
  }, [brands, search, initialValues])

  const { loaded, error } = useData(() => {
    return dispatch(actions.optimizations.fetchProducers(optimizationId, categoryId, subcategoryId))
  }, [dispatch, optimizationId, categoryId, subcategoryId])

  const { loaded: brandsLoaded } = useData(() => {
    if (!producer) {
      return Promise.never()
    }
    return dispatch(actions.optimizations.fetchBrands(optimizationId, categoryId, subcategoryId, producer.id, search))
  }, [dispatch, optimizationId, categoryId, subcategoryId, producer, search])

  const handleProducerChange = useCallback(({ target: { value } }) => {
    setProducer(value)
  }, [])

  const handleSearchChange = useCallback(({ target: { value } }) => {
    setSearch(value)
  }, [])

  const handleChecked = useCallback((checked, value) => {
    if (value.id === ALL_BRANDS_ID) {
      setFilteredBrands(filteredBrands.map(b => ({ ...b, checked })))
    } else {
      setFilteredBrands(filteredBrands => filteredBrands.map((b, index) => {
        if (b.id !== value.id) {
          return filteredBrands[index]
        }
        return {
          ...b,
          checked,
        }
      }))
    }
  }, [filteredBrands])

  const handleApplyFilter = useCallback(() => {
    onApply({ producer: producer, brands: filteredBrands })
    setProducer('')
    setSearch('')
    setFilteredBrands([])
    closeContextDrawer()
  }, [closeContextDrawer, filteredBrands, onApply, producer])

  const handleReset = useCallback(() => {
    setProducer('')
    setSearch('')
    setFilteredBrands([])
    dispatch(actions.optimizations.resetBrands())
  }, [dispatch])

  return (
    <div className={styles.drawerContainer}>
      {
        loaded ?
          <div className={styles.selectContainer}>
            <P>Producer</P>
            <Select
              value={producer}
              size="small"
              onChange={handleProducerChange}
              className={styles.select}
              displayEmpty={true}
              renderValue={
                producer !== '' ? undefined : () => <span>Choose producer</span>
              }
            >
              {
                producers.map(producer => (
                  <MenuItem key={producer.id} value={producer}>
                    {producer.name}
                  </MenuItem>
                ))
              }
            </Select>

            <SearchInput placeholder="Search brands" onChange={handleSearchChange} className={styles.input} />
          </div> :
          <SectionLoadingIndicator className={styles.producerFilter} error={!!error} />
      }

      {
        brandsLoaded &&
          <div>
            <div className={styles.formGroup}>
              <FormGroup
                className={styles.checkboxContainer}
              >
                {
                  filteredBrands.map(searchedBrand => (
                    <FormControlLabel
                      key={searchedBrand.id}
                      control={
                        <Checkbox
                          checked={!!searchedBrand.checked}
                          onChange={e => handleChecked(e.target.checked, searchedBrand)}
                        />
                      }
                      label={searchedBrand.name}
                    />
                  ))
                }
              </FormGroup>
            </div>

            <div className={styles.footerContainer}>
              <div className={styles.buttonsContainer}>
                <Button
                  onClick={handleReset}
                  variant='outlined'
                  className={styles.button}
                >
                  Reset
                </Button>
                <Button
                  onClick={handleApplyFilter}
                  variant='outlined'
                  className={styles.button}
                >
                  Apply
                </Button>
              </div>
            </div>
          </div>
      }
    </div>
  )
}

RecommendedAssortmentFilter.propTypes = {
  onApply: PropTypes.func.isRequired,
  categoryId: PropTypes.number,
  subcategoryId: PropTypes.number,
  optimizationId: PropTypes.number,
  initialValues: PropTypes.shape({
    producer: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    brands: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
      checked: PropTypes.bool,
    })),
  }),
}

export default RecommendedAssortmentFilter
