import React, { useState, useCallback, useContext } from 'react'
import { MenuItem, Select, Button } from '@mui/material'
import PropTypes from 'prop-types'
import moment from 'moment'

import { Role, OptimizationStatus } from 'services/enums'
import useAuth from 'hooks/useAuth'
import { P } from 'components/ui/Typography'
import { enumValue, from, values } from 'utils/enum'
import { AppLayoutContext } from 'app/navigation/AppLayoutProvider'

import styles from './AssortmentOptimizationDrawerContent.module.scss'

const DateFilter = {
  thisYear: enumValue('this', { label: 'This Year', order: 1, offset: 0 }),
  previousYear: enumValue('previous', { label: 'Previous Year', order: 2, offset: -1 }),
  from,
  values,
}

function dateRange(dateFilter) {
  const m = moment().add(dateFilter.offset, 'year')
  const from = m.startOf('year').toDate()
  const to = m.endOf('year').toDate()
  return { from, to, namedRange: dateFilter }
}

const AssortmentOptimizationDrawerContent = ({ onApply, defaultValues }) => {
  const isCategoryManager = useAuth({ is: Role.categoryManager })
  const [date, setDate] = useState(defaultValues?.dateRange?.namedRange?.value || '')
  const [status, setStatus] = useState(defaultValues?.status?.value || '')

  const { closeContextDrawer } = useContext(AppLayoutContext)

  const handleDateSelect = useCallback(({ target: { value } }) => {
    setDate(value)
  }, [])

  const handleStatusSelect = useCallback(({ target: { value } }) => {
    setStatus(value)
  }, [])

  const handleReset = useCallback(() => {
    setDate('')
    setStatus('')
  }, [])

  const handleApplyFilter = useCallback(() => {
    const selectedDate = date === '' ? null : dateRange(DateFilter.from(date))
    const selectedStatus = status === '' ? null : OptimizationStatus.from(status)
    onApply({ dateRange: selectedDate, status: selectedStatus })
    setDate('')
    setStatus('')
    closeContextDrawer()
  }, [closeContextDrawer, date, onApply, status])

  return (
    <div className={styles.drawerContainer}>
      <div className={styles.selectContainer}>
        <P>Date</P>
        <Select
          value={date}
          size="small"
          onChange={handleDateSelect}
          displayEmpty={true}
          renderValue={
            date !== '' ? undefined : () => <span>All</span>
          }
        >
          {
            DateFilter.values().map(sym => (
              <MenuItem key={sym.value} value={sym.value}>
                {sym.label}
              </MenuItem>
            ))
          }
        </Select>
      </div>

      <div className={styles.selectContainer}>
        <P>Status</P>
        <Select
          value={status}
          size="small"
          onChange={handleStatusSelect}
          displayEmpty={true}
          renderValue={
            status !== '' ? undefined : () => <span>All</span>
          }
        >
          {
            OptimizationStatus.values(isCategoryManager && (sym => sym.isCmAccessible)).map(sym => (
              <MenuItem key={sym.value} value={sym.value}>
                {sym.label}
              </MenuItem>
            ),
            )
          }
        </Select>
      </div>

      <div className={styles.buttonsContainer}>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleReset}
        >
          Reset
        </Button>
        <Button
          className={styles.button}
          variant="outlined"
          onClick={handleApplyFilter}
        >
          Apply
        </Button>
      </div>
    </div>
  )
}

AssortmentOptimizationDrawerContent.propTypes = {
  onApply: PropTypes.func.isRequired,
  defaultValues: PropTypes.shape({
    status: PropTypes.symbol,
    dateRange: PropTypes.shape({
      from: PropTypes.object,
      to: PropTypes.object,
      namedRange: PropTypes.symbol,
    }),
  }),
}

export default AssortmentOptimizationDrawerContent
