/**
 * Create enum objects.
 *
 * const Gender = {
 *   male: enumValue('1', { pronoun: 'he' }),
 *   female: enumValue('2', { pronoun: 'she' }),
 *   other: enumValue('3', { pronoun: 'zie' }),
 *   from,
 * }
 * Gender.male.value // '1'
 * Gender.from('2') // Gender.female
 * Gender.other.pronoun // zie
 * Gender.male === Gender.from('1') // true
 */
export const enumValue = (value, props) => {
  return Object.freeze(Object.assign(Symbol(value), { value, is }, props))
}

function is(value) {
  return this.value === value
}

export function from(value) {
  if (value === null || value === undefined) {
    return value
  }

  const enumEntry = Object.entries(this)
    .find(([, sym]) => sym.value === value)
  return enumEntry ? enumEntry[1] : undefined
}

/**
 * Returns array of enum values, ordered by order property (if it is defined).
 *
 * Optionally a filter function can be passed to filter returned records.
 *   This function receives each enum value as an argument
 *   and should return true or false, indicating if values should be returned or not, respectively.
 */
export function values(filter) {
  filter = filter || (() => true)
  return Object.values(this)
    .filter(sym => sym.constructor === Symbol)
    .filter(filter)
    .sort((s1, s2) => (s1.order || 0) - (s2.order || 0))
}
