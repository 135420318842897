import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import store, { actions } from 'store'

import { restoreValue } from 'utils/store'
import { localStorageKeys } from 'utils/storageKeys'
import SplashScreen from 'components/ui/SplashScreen/SplashScreen'
import listeners from 'store/listeners'
import log from 'utils/log'
import { passGuard } from 'utils/flow'
import { postAuthData } from 'store/auth/actions'

// eslint-disable-next-line react/display-name
const appInitializer = WrappedComponent => props => {
  const dispatch = useDispatch()

  const [dataFetched, setDataFetched] = useState(false)

  useEffect(() => {
    const fetchData = () => Promise.all([
      restoreValue(store, localStorageKeys.apiAccessToken, value => actions.auth.restoreApiAccessToken(value)),
      restoreValue(store, localStorageKeys.apiRefreshToken, value => actions.auth.restoreApiRefreshToken(value)),
      restoreValue(store, localStorageKeys.clientId, value => actions.auth.restoreClientId(value)),
    ])
      .then(() => listeners.forEach(listener => listener.enable()))
      .then(() => !!store.getState().auth.apiAccessToken)
      .then(authenticated => passGuard(authenticated, () => postAuthData(dispatch)))
      .catch(err => {
        log.error(() => ['Error during app initialization.', err])
      })
      .finally(() => {
        setDataFetched(true)
      })

    fetchData()
  }, [dispatch])

  useEffect(() => {
    if (dataFetched) {
      log.debug(() => ['Data fetched. Store snapshot:', store.getState()])
    }
  }, [dataFetched])

  return dataFetched ? <WrappedComponent {...props} /> : <SplashScreen />
}

export default appInitializer
