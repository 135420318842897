import { useDispatch } from 'react-redux'
import { useCallback } from 'react'
import { actions } from 'store'

import { ToastSeverity } from 'store/toast/actions'

const useToast = () => {
  const dispatch = useDispatch()

  const errorToast = useCallback((message, position) => {
    dispatch(actions.toast.queue(message, ToastSeverity.error, position))
  }, [dispatch])

  const warningToast = useCallback((message, position) => {
    dispatch(actions.toast.queue(message, ToastSeverity.warning, position))
  }, [dispatch])

  const infoToast = useCallback((message, position) => {
    dispatch(actions.toast.queue(message, ToastSeverity.info, position))
  }, [dispatch])

  const successToast = useCallback((message, position) => {
    dispatch(actions.toast.queue(message, ToastSeverity.success, position))
  }, [dispatch])

  return {
    errorToast, warningToast, infoToast, successToast,
  }
}

export default useToast
