import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { MenuItem, Select, Button } from '@mui/material'
import PropTypes from 'prop-types'
import Switch from '@mui/material/Switch'
import { selectors } from 'store'

import { H6 } from 'components/ui/Typography'
import { ClustersIcon, ExportIcon } from 'assets/icons'

import styles from './ExecutionTrackingFilters.module.scss'

const ALL_CLUSTERS_ID = 'TOTAL'

const ExecutionTrackingFilters = ({ onExport, onSelectionChange, className, onPeriodChange, executionPeriods, period, comparePeriods }) => {
  const executionClusters = useSelector(state => state.tracking.executionClusters)
  const [clusterData, setClusterData] = useState([])
  const allStores = useSelector(selectors.tracking.allStores)
  const [cluster, setCluster] = useState('')
  const [store, setStore] = useState('')
  const [compare, setCompare] = useState(comparePeriods)

  useEffect(() => {
    const allClusters = { id: ALL_CLUSTERS_ID, name: 'Total', stores: allStores }
    setClusterData([allClusters, ...executionClusters])
    setCluster(allClusters)
  }, [allStores, executionClusters])

  useEffect(() => {
    if (!compare) {
      onPeriodChange(null)
    }
  }, [compare, onPeriodChange])

  const handleClusterSelect = useCallback(({ target: { value } }) => {
    setCluster(value)
    setStore('')
    const selectedCluster = value.id === ALL_CLUSTERS_ID ? null : value
    onSelectionChange({ cluster: selectedCluster, store: null })
  }, [onSelectionChange])

  const handleStoreSelect = useCallback(({ target: { value } }) => {
    setStore(value)
    const selectedCluster = cluster.id === ALL_CLUSTERS_ID ? null : cluster
    onSelectionChange({ cluster: selectedCluster, store: value })
  }, [cluster, onSelectionChange])

  const toggleCompare = useCallback(() => {
    setCompare(value => !value)
  }, [])

  const handlePeriodSelect = useCallback(({ target: { value } }) => {
    onPeriodChange(value)
  }, [onPeriodChange])

  return (
    <div className={`flex-col-center ${styles.executionTrackingFilters} ${className || ''}`}>
      <div className={styles.selectExportWrap}>
        <ClustersIcon className="icon large" />
        <Select
          className={styles.select}
          value={cluster}
          size="small"
          onChange={handleClusterSelect}
        >
          {
            clusterData?.map(cluster => (
              <MenuItem key={cluster.id} value={cluster}>
                {cluster.name}
              </MenuItem>
            ))
          }
        </Select>
        <Select
          className={styles.select}
          value={store}
          size="small"
          onChange={handleStoreSelect}
          displayEmpty={true}
          renderValue={
            store !== '' ? undefined : () => <span>Choose store</span>
          }
        >
          {
            cluster?.stores?.map(store => (
              <MenuItem key={store.id} value={store}>
                {store.name}
              </MenuItem>
            ))
          }
        </Select>
        <Button
          className={styles.exportButton}
          variant="contained"
          size="medium"
          startIcon={<ExportIcon className="icon small" />}
          onClick={onExport}
        >
          Export
        </Button>
      </div>

      <div className={`flex-row-center ${styles.comparePeriodWrap}`}>
        <H6>Compare</H6>
        <Switch
          checked={compare}
          className={styles.switch}
          onClick={toggleCompare}
        />
        <Select
          className={styles.select}
          size="small"
          disabled={!compare}
          value={period || ''}
          onChange={handlePeriodSelect}
          displayEmpty={true}
          renderValue={
            (period !== '' && period !== null) ? undefined : () => <span>Select period to compare with</span>
          }
        >
          {
            executionPeriods?.map(period => (
              <MenuItem key={period.id} value={period}>
                {period.name}
              </MenuItem>
            ))
          }
        </Select>
      </div>
    </div>
  )
}

ExecutionTrackingFilters.propTypes = {
  className: PropTypes.string,
  onExport: PropTypes.func.isRequired,
  onPeriodChange: PropTypes.func.isRequired,
  onSelectionChange: PropTypes.func.isRequired,
  executionPeriods: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    from: PropTypes.object,
    to: PropTypes.object,
  })),
  period: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    from: PropTypes.object.isRequired,
    to: PropTypes.object.isRequired,
  }),
  comparePeriods: PropTypes.bool,
}

export default ExecutionTrackingFilters
