import { useContext, useEffect } from 'react'

import { AppLayoutContext } from 'app/navigation/AppLayoutProvider'

const useContextDrawer = (drawerContent, dependencies) => {
  const { setContextDrawerContent, openContextDrawer } = useContext(AppLayoutContext)

  useEffect(() => {
    setContextDrawerContent(drawerContent)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setContextDrawerContent, ...(dependencies || [])])

  return openContextDrawer
}

export default useContextDrawer
