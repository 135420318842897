import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { MenuItem, Select } from '@mui/material'

import { H1, P, Strong } from 'components/ui/Typography'
import useDebounceCallback from 'hooks/useDebounceCallback'
import customPropTypes from 'utils/customPropTypes'
import SearchInput from 'components/ui/SearchInput'

import styles from './ExecutionTrackingInSubcategories.module.scss'

const ALL_SUBCATEGORIES_ID = 'SUBCATEGORIES'
const allSubcategories = { id: ALL_SUBCATEGORIES_ID, name: 'All subcategories' }

const ExecutionTrackingInSubcategories = ({ setFilters, categories, subcategories }) => {
  const [category, setCategory] = useState(null)
  const [subcategory, setSubcategory] = useState(null)
  const [search, setSearch] = useState('')

  const subcategoryData = useMemo(() => {
    return subcategories ? [allSubcategories, ...subcategories] : [allSubcategories]
  }, [subcategories])

  const categoryValue = useMemo(() => categories?.find(c => c.id === category?.id) || null, [categories, category])
  const subcategoryValue = useMemo(() => subcategories?.find(c => c.id === subcategory?.id) || allSubcategories, [subcategories, subcategory])

  useEffect(() => {
    setCategory(categories?.[0])
  }, [categories])

  useEffect(() => {
    setSubcategory(allSubcategories)
  }, [subcategories])

  useEffect(() => {
    setFilters({ search, category, subcategory: subcategory?.id === ALL_SUBCATEGORIES_ID ? null : subcategory })
  }, [search, category, subcategory, setFilters])

  const updateSearch = useDebounceCallback(({ target: { value } }) => {
    setSearch(value)
  }, [])

  const handleCategorySelect = useCallback(({ target: { value } }) => {
    setCategory(value)
    setSubcategory(allSubcategories)
  }, [setCategory])

  const handleSubcategorySelect = useCallback(({ target: { value } }) => {
    setSubcategory(value)
  }, [])

  return (
    <div className={`flex-col-center ${styles.ExecutionTrackingInSubcategories}`}>
      <H1>Execution tracking in subcategories</H1>
      <div className={styles.filters}>
        <div className={styles.filtersWrap}>
          <div className={styles.dropdown}>
            <P>SKUs grouped by categories:</P>
            <Select
              className={styles.select}
              value={categoryValue || ''}
              size="small"
              onChange={handleCategorySelect}
            >
              {
                categories?.map(category => (
                  <MenuItem key={category.id} value={category}>
                    <Strong>{category.name}</Strong>
                  </MenuItem>
                ))
              }
            </Select>
          </div>
          <div className={styles.dropdown}>
            <P>Subcategory:</P>
            <Select
              className={styles.select}
              value={subcategoryValue || ''}
              size="small"
              onChange={handleSubcategorySelect}
            >
              {
                subcategoryData?.map(subcategory => (
                  <MenuItem key={subcategory.id} value={subcategory}>
                    <Strong>{subcategory.name}</Strong>
                  </MenuItem>
                ))
              }
            </Select>
          </div>
          <SearchInput onChange={updateSearch} placeholder="Search for SKU" />
        </div>
      </div>
    </div>
  )
}

ExecutionTrackingInSubcategories.propTypes = {
  categories: PropTypes.arrayOf(customPropTypes.category),
  subcategories: PropTypes.arrayOf(customPropTypes.category),
  setFilters: PropTypes.func,
}

export default ExecutionTrackingInSubcategories
