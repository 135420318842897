import React, { useEffect, useState } from 'react'

import { heatmapClasses } from 'components/ui/VerticalHeatmapLegend'
import Tooltip from 'components/Map/Tooltip'
import Map from 'components/Map'
import useHeatmap from 'hooks/useHeatmap'
import customPropTypes from 'utils/customPropTypes'

import styles from './TrackingMap.module.scss'

const TrackingMap = ({ stores, allStores }) => {
  const [points, setPoints] = useState([])

  const max = Math.max(...allStores.map(s => s.deviation))
  const getHeatmapClass = useHeatmap(0, max, heatmapClasses)

  useEffect(() => {
    if (stores) {
      const mapPoints = []

      stores.forEach(store => {
        const address = `${store.address.line1} ${store.address.line2}, ${store.address.city}`
        mapPoints.push({
          id: store.id,
          lat: store.location.lat,
          lng: store.location.long,
          tooltip: <Tooltip title={store.name} body={address} />,
          className: getHeatmapClass(store.deviation),
        })
      })
      setPoints(mapPoints)
    }
  }, [getHeatmapClass, stores])

  return (
    <div className={styles.TrackingMap}>
      <Map
        stores={points}
      />
    </div>
  )
}

TrackingMap.propTypes = {
  stores: customPropTypes.stores,
  allStores: customPropTypes.stores,
}

export default TrackingMap
