import { createSelector } from 'reselect'

import clusterColour from 'pages/clusters/clusterColours'

export const pieChartData = createSelector(
  state => state.clusters.clustersConfig,
  state => state.clusters.dimensions,
  (clustersConfig, dimensions) => {
    return dimensions?.shares?.map(share => {
      const chartData = {
        title: share.name,
        values: [],
      }
      Object.entries(share.values).forEach(([clusterId, clusterValue], index) => {
        const cluster = clustersConfig.clusters.find(cluster => cluster.id === +clusterId)
        if (cluster) {
          chartData.values.push({
            clusterName: cluster.name,
            clusterSize: cluster.stores?.length || 0,
            clusterValue: clusterValue,
            clusterColor: clusterColour(index),
          })
        }
      })
      return chartData
    })
  },
)

export const allStores = createSelector(
  state => state.clusters?.clustersConfig?.clusters,
  clusters => clusters?.filter(cluster => cluster.stores).flatMap(cluster => cluster.stores),
)
