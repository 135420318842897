import React, { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { MenuItem, Select, Button } from '@mui/material'
import { selectors } from 'store'

import { ClustersIcon, ExportIcon } from 'assets/icons'
import { OptimizationStatus } from 'services/enums'

import styles from './AssortmentOptimizationFilters.module.scss'

const ALL_CLUSTERS_ID = 'TOTAL'

const AssortmentOptimizationFilters = ({ onExport, onReady, onRun, onFinalize, onSelectionChange, onConfirm, className, isAdmin }) => {
  const clusters = useSelector(state => state.clusters.clustersConfig?.clusters)
  const optimization = useSelector(state => state.optimizations.optimization)
  const allStores = useSelector(selectors.clusters.allStores)
  const [clusterData, setClusterData] = useState([])
  const [cluster, setCluster] = useState('')
  const [store, setStore] = useState('')

  useEffect(() => {
    const allClusters = { id: ALL_CLUSTERS_ID, name: 'Total', stores: allStores }
    setClusterData([allClusters, ...clusters])
    setCluster(allClusters)
  }, [allStores, clusters])

  const handleClusterSelect = useCallback(({ target: { value } }) => {
    setCluster(value)
    setStore('')
    const selectedCluster = value.id === ALL_CLUSTERS_ID ? null : value
    onSelectionChange({ cluster: selectedCluster, store: null })
  }, [onSelectionChange])

  const handleStoreSelect = useCallback(({ target: { value } }) => {
    setStore(value)
    const selectedCluster = cluster.id === ALL_CLUSTERS_ID ? null : cluster
    onSelectionChange({ cluster: selectedCluster, store: value })
  }, [cluster, onSelectionChange])

  return (
    <div className={`flex-row-center ${styles.filtersContainer} ${className || ''}`}>
      <div className={styles.selectsContainer}>
        <ClustersIcon className="icon large" />

        <Select
          className={styles.select}
          value={cluster}
          size="small"
          onChange={handleClusterSelect}
        >
          {
            clusterData?.map(cluster => (
              <MenuItem key={cluster.id} value={cluster}>
                {cluster.name}
              </MenuItem>
            ))
          }
        </Select>
        {
          isAdmin && !optimization.status.isEditable &&
            <Select
              className={styles.select}
              value={store}
              size="small"
              onChange={handleStoreSelect}
              displayEmpty={true}
              renderValue={
                store !== '' ? undefined : () => <span>All stores</span>
              }
            >
              {
                cluster?.stores?.map(store => (
                  <MenuItem key={store.id} value={store}>
                    {store.name}
                  </MenuItem>
                ))
              }
            </Select>
        }
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          className={styles.exportButton}
          variant="contained"
          size="medium"
          startIcon={<ExportIcon className="icon normal" />}
          onClick={onExport}
        >
          Export
        </Button>
        {
          isAdmin && optimization?.status.isEditable &&
            <>
              <Button
                className={styles.readyButton}
                variant="contained"
                size="medium"
                onClick={onReady}
                disabled={optimization?.status !== OptimizationStatus.optimized || optimization?.readyForCm}
              >
                Ready for CM review
              </Button>
              <Button
                className={styles.runButton}
                variant="contained"
                size="medium"
                onClick={onRun}
                disabled={optimization?.status !== OptimizationStatus.draft}
              >
                Run Optimization
              </Button>
              <Button
                className={styles.greenButton}
                variant="contained"
                size="medium"
                onClick={onFinalize}
                disabled={optimization?.status !== OptimizationStatus.optimized}
              >
                Finalize Assortment
              </Button>
            </>
        }
        {
          !isAdmin && optimization?.status.isEditable &&
            <Button
              className={styles.greenButton}
              variant="contained"
              size="medium"
              onClick={onConfirm}
            >
              Confirm Choices
            </Button>
        }
      </div>
    </div>
  )
}

AssortmentOptimizationFilters.propTypes = {
  className: PropTypes.string,
  onExport: PropTypes.func,
  onReady: PropTypes.func,
  onRun: PropTypes.func,
  onFinalize: PropTypes.func,
  onSelectionChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  isAdmin: PropTypes.bool,
}

export default AssortmentOptimizationFilters
