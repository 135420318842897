import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'

import HorizontalHeatmapLegend, { heatmapClasses } from 'components/ui/HorizontalHeatmapLegend'
import { H2 } from 'components/ui/Typography'
import { ExpandIcon } from 'assets/icons'
import useHeatmap from 'hooks/useHeatmap'
import { marginMax } from 'utils/math'

import styles from './ClusterDimension.module.scss'

function DimensionMetricValues({ columns, dimensionMetric }) {
  const max = useMemo(() => {
    const values = columns.map(column => dimensionMetric.values[column.id])
    const mid = dimensionMetric.values.total || 0
    const margin = marginMax(values, mid)
    return mid + margin
  }, [columns, dimensionMetric])

  const getHeatmapClass = useHeatmap(dimensionMetric.values.total, max, heatmapClasses)

  return (
    columns.map(column => (
      <TableCell
        key={column.id + dimensionMetric.name}
        className={`${styles.cell} ${getHeatmapClass(dimensionMetric.values[column.id])}`}
      >
        {dimensionMetric.values[column.id]}
      </TableCell>
    ),
    )
  )
}

DimensionMetricValues.propTypes = {
  columns: PropTypes.array,
  dimensionMetric: PropTypes.object,
}

function ClusterDimension({ className, dimension, index, columns }) {
  return (
    <div className={`${styles.ClusterDimension} ${className || ''}`}>
      <Accordion className="accordion" defaultExpanded={index === 0}>
        <AccordionSummary className="summary" expandIcon={<ExpandIcon className="icon small" />}>
          <H2> {dimension.name} </H2>
          <div className="legend">
            <HorizontalHeatmapLegend />
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className={`${styles.sticky} ${styles.name}`}> {dimension.name} </TableCell>
                  <TableCell className={`${styles.sticky} ${styles.total}`}> Total </TableCell>
                  {
                    columns.map(column => (
                      <TableCell key={column.id} className={styles.cell}>
                        {column.label}
                      </TableCell>
                    ),
                    )
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  dimension.values.map(dimensionMetric => (
                    <TableRow key={dimensionMetric.name}>
                      <TableCell className={`${styles.sticky} ${styles.name}`}>{dimensionMetric.name}</TableCell>
                      <TableCell className={`${styles.sticky} ${styles.total}`}>{dimensionMetric.values.total}</TableCell>
                      <DimensionMetricValues dimensionMetric={dimensionMetric} columns={columns} />
                    </TableRow>
                  ),
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

ClusterDimension.propTypes = {
  className: PropTypes.string,
  dimension: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
}

export default ClusterDimension
