import React from 'react'
import PropTypes from 'prop-types'
import { TableCell, TableRow, IconButton, Tooltip } from '@mui/material'

import { Strong } from 'components/ui/Typography'
import { LockIcon, UnlockIcon, DeleteIcon, RestoreIcon } from 'assets/icons'
import { SkuStatus } from 'services/enums'
import { cls } from 'utils/react'

import styles from './OptimizationSkuTableRow.module.scss'

const spacer = <div className={styles.spacer} />

/**
 * @example <caption>Example of state updates for onRestore/onRemove</caption>
 * <TableContainer>
 *   <Table>
 *     <TableBody>
 *       <OptimizationSkuTableRow
 *         id={1}
 *         name={'Coca Cola can 0.3-10033412'}
 *         locked={test.locked}
 *         optimizationStatus={test.optStatus}
 *         statusOverride={test.usrStatus}
 *         readOnly={test.finalized}
 *         readOnlyLock={test.cm}
 *         onLock={() => setTest(st => ({ ...st, locked: true }))}
 *         onUnlock={() => setTest(st => ({ ...st, locked: false }))}
 *         onRestore={() => setTest(st => ({
 *           ...st,
 *           usrOvrd: st.optStatus === SkuStatus.removed,
 *           usrStatus: st.optStatus !== SkuStatus.removed ? null : SkuStatus.included,
 *         }))}
 *         onRemove={() => setTest(st => ({
 *           ...st,
 *           usrOvrd: st.optStatus !== SkuStatus.removed,
 *           usrStatus: st.optStatus === SkuStatus.removed ? null : SkuStatus.removed,
 *         }))}
 *         applyToAllWarning={true}
 *       />
 *     </TableBody>
 *   </Table>
 * </TableContainer>
 */
function OptimizationSkuTableRow({
  className,
  id,
  name,
  locked,
  optimizationStatus,
  statusOverride,
  readOnly,
  readOnlyLock,
  allClusters,
  onLock,
  onUnlock,
  onRemove,
  onRestore,
}) {
  const removedByUser = statusOverride === SkuStatus.removed
  const restoredByUser = statusOverride === SkuStatus.included
  const removedByAutomatization = optimizationStatus === SkuStatus.removed

  function statusClasses() {
    let statusClass = ''

    if (removedByUser) {
      statusClass = styles.removedByUser
    } else if (removedByAutomatization) {
      statusClass = styles.removed
    } else if (locked) {
      statusClass = styles.locked
    }

    return statusClass
  }

  function renderName() {
    return <Strong className={statusClasses()}>{name}</Strong>
  }

  function renderStatusIcon() {
    const classes = statusClasses()

    let Icon
    if (readOnly) {
      Icon = (removedByUser && DeleteIcon) ||
        (restoredByUser && RestoreIcon) ||
        (removedByAutomatization && DeleteIcon) ||
        (locked && LockIcon)
    } else if (readOnlyLock) {
      Icon = locked && LockIcon
    }

    return Icon ? <Icon className={`icon small ${classes}`} /> : spacer
  }

  function renderLockAction() {
    const disabled = removedByUser

    const Icon = locked ? LockIcon : UnlockIcon

    const classes = [
      locked && styles.locked,
      disabled && styles.disabled,
    ]

    const actionHandler = (!disabled && (locked ? onUnlock : onLock)) || undefined

    const actionButton = (
      <IconButton onClick={actionHandler} disabled={!actionHandler}>
        <Icon className={`icon small ${cls(classes)}`} />
      </IconButton>
    )

    if (!disabled) {
      const lockString = locked ? 'unlock' : 'lock'
      const hint = allClusters ?
        `${lockString}ing this SKU will ${lockString} it through all clusters` :
        `${lockString}ing this SKU will ${lockString} it just in this cluster`

      return (
        <Tooltip placement="bottom" className={styles.tooltip} title={hint}>
          { actionButton }
        </Tooltip>
      )
    } else {
      return actionButton
    }
  }

  function renderRemoveRestoreAction() {
    const removed = (removedByAutomatization && !restoredByUser) || removedByUser
    const Icon = removed ? RestoreIcon : DeleteIcon

    const actionHandler = (removed ? onRestore : onRemove) || undefined

    return (
      <IconButton onClick={actionHandler}>
        <Icon className="icon small" />
      </IconButton>
    )
  }

  return (
    <TableRow className={className || ''}>
      <TableCell className={`name-col ${styles.nameCol}`}>
        <div className={styles.nameContainer}>
          { renderStatusIcon() }
          { renderName() }
        </div>
      </TableCell>

      {
        (!readOnly && !readOnlyLock) &&
          <TableCell align="center" className={`lock-col ${styles.lockCol}`}>
            {renderLockAction()}
          </TableCell>
      }

      {
        !readOnly &&
          <TableCell align="center" className={`remove-col ${styles.removeCol}`}>
            {renderRemoveRestoreAction()}
          </TableCell>
      }
    </TableRow>
  )
}

OptimizationSkuTableRow.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  locked: PropTypes.bool,
  optimizationStatus: PropTypes.oneOf(SkuStatus.values()),
  statusOverride: PropTypes.oneOf(SkuStatus.values()),
  readOnly: PropTypes.bool,
  readOnlyLock: PropTypes.bool,
  allClusters: PropTypes.bool,
  onLock: PropTypes.func,
  onUnlock: PropTypes.func,
  onRemove: PropTypes.func,
  onRestore: PropTypes.func,
}

export default OptimizationSkuTableRow
