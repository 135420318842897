import React, { useContext } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'

import { H1 } from 'components/ui/Typography'
import { CloseIcon } from 'assets/icons'
import { AppLayoutContext } from 'app/navigation/AppLayoutProvider'

import styles from './DrawerFilter.module.scss'

const DrawerFilter = () => {
  const {
    isContextDrawerOpen,
    contextDrawerContent,
    closeContextDrawer,
  } = useContext(AppLayoutContext)

  return (
    <Drawer
      anchor="right"
      open={isContextDrawerOpen}
      onClose={closeContextDrawer}
    >
      <Box
        role="presentation"
        className={styles.DrawerFilter}
      >
        <CloseIcon onClick={closeContextDrawer} className={`icon small ${styles.closeIcon}`} />
        <div className="flex-col-center">
          <H1>Filter</H1>
        </div>
        { contextDrawerContent }
      </Box>
    </Drawer>
  )
}

export default DrawerFilter
