import moment from 'moment'

import { OptimizationStatus } from 'services/enums'
import { booleanInForm, nameParts } from 'services/api/commonAdapters'

export function optimizationsInForm(item) {
  return item && {
    ...item,
    status: booleanInForm(item.trackExecution) ? OptimizationStatus.tracking : OptimizationStatus.from(item.status),
    createdAt: moment(item.dateCreated),
    title: item.name,
    readyForCm: booleanInForm(item.readyForCm),
    createdBy: item.userId ?
        {
          id: item.userId,
          firstName: nameParts(item.userName).firstName,
          lastName: nameParts(item.userName).lastName,
        } :
      null,
  }
}

export function optimizationInForm(item) {
  return item && {
    ...item,
    status: booleanInForm(item.trackExecution) ? OptimizationStatus.tracking : OptimizationStatus.from(item.status),
    createdAt: moment(item.dateCreated),
    title: item.name,
    readyForCm: booleanInForm(item.readyForCm),
    createdBy: item.userId ?
        {
          id: item.userId,
          firstName: nameParts(item.userName).firstName,
          lastName: nameParts(item.userName).lastName,
        } :
      null,
  }
}

export function goalsInForm(item) {
  return item && {
    ...item,
    margin: item.grossMargin,
    baskets: item.numberOfSales,
  }
}

export function limitsInForm(item) {
  return item && {
    ...item,
    skuNumber: {
      ...item.skuNumber,
      automatic: booleanInForm(item.skuNumber.automatic),
      editable: booleanInForm(item.skuNumber.editable),
    },
    innovationSkuNumber: {
      ...item.innovationSkuNumber,
      automatic: booleanInForm(item.innovationSkuNumber.automatic),
      editable: booleanInForm(item.innovationSkuNumber.editable),
    },
  }
}

export function keyIndicatorsInForm(item) {
  switch (item.indicatorName) {
    case ('assortment_length'):
      return {
        ...item,
        code: item.indicatorName,
      }
    case ('assortment_length_stability'):
      return {
        code: item.indicatorName,
        absoluteChange: item.absoluteChange,
        percentChange: Math.floor(item.percentChange),
      }
    case ('special_sku_share'):
      return {
        code: item.indicatorName,
        specialSkuShare: Math.floor(item.specialSkuShare) || 0,
        specialSkuNumber: item.specialSkuNumber || 0,
      }
    case ('revenue'):
      return {
        code: 'revenue',
        expectedGrowth: Math.floor(item.revenueExpectedGrowth) || 0,
        expectedValue: item.expectedRevenue || 0,
        previousPeriodValue: item.previousPeriodRevenue || 0,
      }
    case ('margin'):
      return {
        code: 'margin',
        expectedGrowth: Math.floor(item.marginExpectedGrowth) || 0,
        expectedValue: item.expectedMargin || 0,
        previousPeriodValue: item.previousPeriodMargin || 0,
      }
    case ('quantity'):
      return {
        code: 'quantity',
        expectedGrowth: Math.floor(item.quantityExpectedGrowth) || 0,
        expectedValue: item.expectedQuantity || 0,
        previousPeriodValue: item.previousPeriodQuantity || 0,
      }
    default:
      return item
  }
}

export function approvalsInForm(item) {
  return item && {
    userId: item.userId,
    categories: item.categories,
    action: item.action,
    approvalDate: item.dateCreated,
    firstName: nameParts(item.userName).firstName,
    lastName: nameParts(item.userName).lastName,
  }
}

export function subcategorySettingsInForm(item) {
  const res = {}
  Object.keys(item).forEach(key => {
    res[key] = {
      ...item[key],
      value: item[key].value || null,
      automatic: booleanInForm(item[key].automatic),
      defaultValue: item[key].defaultValue,
    }
  })
  return res
}
