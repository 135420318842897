import { Buffer } from 'buffer'

import axiosBase from 'axios'

import { paged } from 'services/api/pager'
import { withMeta } from 'services/api/common'

import axios from '../axios.instance'

import { userInForm } from './adapters/userAdapter'

const updateUser = ({ id, firstName, lastName, password, categories }) => {
  return axios.patch(`/users/${id}`, {
    firstName: firstName,
    lastName: lastName,
    password: password,
    categories: categories,
  })
}

const approveCategoryManager = async(id, categories) => {
  return axios.patch(`/users/${id}`, {
    categories: categories,
  })
}

const updateProfilePhoto = async(id, photo) => {
  const type = photo.split(';')[0].split('/')[1]
  const buffer = Buffer.from(photo.replace(/^data:image\/\w+;base64,/, ''), 'base64')
  return axios.post(`/users/${id}/profile-photo-upload-policy`,
    {
      name: 'photo.jpg',
    })
    .then(res => {
      axiosBase.create({
        baseURL: res.url,
      }).put('', buffer, {
        headers: {
          'Content-Type': type,
        },
      })
      return axios.post(`https://api.spectraopt.com/users/${id}/profile-photo-settings`, { key: res.key })
    })
}

const getUsers = async(page, search) => {
  return axios.get('/users', { params: { ...page, search } })
    .then(data => withMeta(data, data => data.map(userInForm)))
}

const getUser = async(userId) => {
  return axios.get(`/users/${userId}`)
    .then(userInForm)
}

const deleteUser = async(userId) => {
  return axios.delete(`/users/${userId}`)
}

const suspendUser = async(userId) => {
  return axios.post(`/users/${userId}/suspend-access-commands`, {
    suspend: true,
  })
}

const restoreUser = async(userId) => {
  return axios.post(`/users/${userId}/suspend-access-commands`, {
    suspend: false,
  })
}

const resendUserConfirmationLink = async(userId) => {
  return axios.post(`/users/${userId}/resend-confirmation-link-commands`)
}

const updateUserSettings = async(userId, enableEmailNotifications) => {
  return axios.put(`/users/${userId}/settings`, { enableEmailNotifications })
}

const getProfile = async() => {
  return axios.get('/me', {})
    .then(profile => userInForm(profile))
}

const api = {
  getUsers: paged(getUsers, 10),
  getUser,
  updateProfilePhoto,
  deleteUser,
  suspendUser,
  restoreUser,
  resendUserConfirmationLink,
  updateUser,
  approveCategoryManager,
  updateUserSettings,
  getProfile,
}

export default api
