import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'

import useData from 'hooks/useData'
import pager, { isEol, isInitial } from 'services/api/pager'

/**
 * Provides next and previous methods to fetch next/previous page of data when API supports pagination.
 *
 * API method is supposed to accept page object as the first argument. Paging supported by this hook is
 * based on last element returned by the API endpoint, by using the "pageAfter" query parameter. This hook
 * builds and keeps the list of all termination records for each page iterated through using this hook,
 * and thus enables fetching of the "previous" page of data.
 *
 * @example <caption>Load data and use usePager hook in the page</caption>
 * const orbs = useSelector(state => state.orbs)
 * const { data: orbsData, loaded: orbsLoaded } = useData(() => {
 *   return dispatch(actions.orbs.fetchOrbs(2, 5))
 * }, [dispatch])
 *
 * const { previous: previousOrbs, next: nextOrbs, hasNext: hasNextOrbs, hasPrevious: hasPreviousOrbs, loaded: orbsPageLoaded } =
 *     usePager(orbsData, actions.orbs.fetchOrbsSuccess)
 *
 * @example <caption>Use hook data in the page to render Previous/Next buttons</caption>
 * <H1>{orbs?.[0]?.id} - {orbs?.[0]?.name}</H1>
 * <Button
 *   disabled={!hasPreviousOrbs || !orbsPageLoaded}
 *   onClick={previousOrbs}>
 *   Previous
 * </Button>
 *
 * <Button
 *   disabled={!hasNextOrbs || !orbsPageLoaded}
 *   onClick={nextOrbs}>
 *   Next
 * </Button>
 *
 * @example <caption>Make API method pageable</caption>
 * const getOrbs = async(page, clusterId, spaceId) => {
 *   return ... all api here
 * }
 *
 * const api = {
 *   getOrbs: paged(getOrbs, 10),
 * }
 *
 * export default api
 */
const usePager = (pageableData, successAction) => {
  const dispatch = useDispatch()

  const [page, setPage] = useState(pageableData)
  const [pageLoader, setPageLoader] = useState()

  useEffect(() => {
    setPage(pageableData)
    setPageLoader(undefined)
  }, [pageableData])

  const { loading, loaded, error, data } = useData(() => pageLoader?.(), [pageLoader])

  useEffect(() => {
    if (data) {
      setPage(data)
      if ((isInitial(data) || !isEol(data)) && successAction) {
        dispatch(successAction(data))
      }
    }
  }, [data, dispatch, successAction])

  const hasNext = useMemo(() => pager.hasNext(page), [page])
  const hasPrevious = useMemo(() => pager.hasPrevious(page), [page])
  const state = useMemo(() => page?._meta?.pager?.state, [page])

  const next = useCallback(() => {
    setPageLoader(() => page?._meta?.pager?.next)
  }, [page?._meta?.pager?.next])

  const previous = useCallback(() => {
    setPageLoader(() => page?._meta?.pager?.previous)
  }, [page?._meta?.pager?.previous])

  return {
    previous,
    next,
    loading,
    loaded,
    error,
    hasNext,
    hasPrevious,
    state,
  }
}

export default usePager
