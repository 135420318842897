import React from 'react'
import TextField from '@mui/material/TextField'

const Input = ({ ...props }) => {
  return (
    <TextField
      variant="standard"
      size="small"
      {...props}
    />
  )
}

export default Input
