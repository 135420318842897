import React from 'react'
import Typography from '@mui/material/Typography'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'

/*
  fontSize: 25px
  fontWeight: bold
*/
export const H1 = (props) => {
  return (
    <Typography variant="h1" {...props} />
  )
}

/*
  fontSize: 22px
  fontWeight: bold
*/
export const H2 = (props) => {
  return (
    <Typography variant="h2" {...props} />
  )
}

/*
  fontSize: 20px
  fontWeight: bold
*/
export const H3 = (props) => {
  return (
    <Typography variant="h3" {...props} />
  )
}

/*
  fontSize: 18px
  fontWeight: bold
*/
export const H4 = (props) => {
  return (
    <Typography variant="h4" {...props} />
  )
}

/*
  fontSize: 18px
  fontWeight: 500
*/
export const H5 = (props) => {
  return (
    <Typography variant="h5" {...props} />
  )
}

/*
  fontSize: 18px
  fontWeight: 400
*/
export const H6 = (props) => {
  return (
    <Typography variant="h6" {...props} />
  )
}

/*
  fontSize: 16px
  fontWeight: normal
*/
export const P = (props) => {
  return (
    <Typography variant="body1" {...props} />
  )
}

/*
  fontSize: 16px
  fontWeight: bold
*/
export const Strong = (props) => {
  return (
    <Typography variant="body1" sx={{ fontWeight: 'bold' }} {...props} />
  )
}

/*
  fontSize: 14px
  fontWeight: normal
*/
export const P2 = (props) => {
  return (
    <Typography variant="body2" {...props} />
  )
}

/*
  fontSize: 12px
  fontWeight: normal
  color: caption
*/
export const Caption = (props) => {
  return (
    <Typography variant="caption" sx={{ color: 'caption' }} {...props} />
  )
}

/*
  fontSize: 12px
  fontWeight: bold
*/
export const Label = (props) => {
  return (
    <Typography variant="caption" {...props} />
  )
}

/*
  fontSize: 12px
  fontWeight: normal
  color: error
*/
export const Error = (props) => {
  return (
    <Typography variant="body2" color="error.main" align="center" {...props} />
  )
}

/*
  fontSize: 12px
  fontWeight: normal
  color: error
*/
export const Errors = ({ errors, ...rest }) => {
  return (
    <Box>
      {
        errors
          ?.filter(error => error)
          ?.map(error => <Error key={error} {...rest}>{error}</Error>)
      }
    </Box>
  )
}
Errors.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string),
}
