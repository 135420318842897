import 'utils/string'

/**
 * Looks up message in a optional hierarchy as shown below.
 * If message is not found, defaultMessage is returned.
 * Messages are looked up by more strict placement fist (as presented in the below order).
 * Any placeholders of format {0}, {1}, {N} in the message will be replaced with corresponding constraint.
 *
 * context:
 *   area:
 *    code: message
 *
 * area:
 *   code: message
 *
 * code: message
 */
const message = (messages, context, area, code, defaultMessage, constraints = {}) => {
  function contextMessages() {
    let sub = messages
    if (context) {
      sub = sub[context] || sub
    }
    if (area) {
      sub = sub[area] || sub
    }
    return sub
  }

  function areaMessages() {
    let sub = messages
    if (area) {
      sub = sub[area] || sub
    }
    return sub
  }

  const msg =
    contextMessages()[code] ||
    areaMessages()[code] ||
    messages[code] ||
    defaultMessage ||
    `Unknown error ${code}`

  return msg.formatVars(constraints)
}

/**
 * Returns messages for specified form errors with the following mappings for the {@link message} function:
 *  - context - formName
 *  - area - form field or "_" for global errors
 *  - code: error code
 *  - defaultMessage - message specified as the value of code property
 *
 * @param messages Messages object. See messages in {@link message} function.
 * @param formName Name of the form (context in {@link message} function)
 * @param errors Object of form errors. See example below.
 * @return errorMessages Object of from error messages. See example below.
 *
 * @example <caption>Errors argument example</caption>
 * <pre><code>
 * "email": {
 *   "format": {
 *     "message": "Email format is not correct.",
 *   },
 *   "required": true,
 *   "minLength": "Email is too short",
 * },
 * "password": {
 *   "min-length": {
 *       "constraints": {
 *         "min": 8,
 *       },
 *   },
 * }, ...
 * "_": {
 *   "global-err-1-code": {
 *     "message": "Some global error...",
 *   }, ...
 * }
 * </code></pre>
 *
 * @example <caption>Example of errors messages returned by this function</caption>
 * <pre><code>
 * {
 *   "email": [
 *     "Email format is not correct.",
 *     "Email is too long."
 *   ],
 *   "password": [
 *     "Minimal password length is 8 characters."
 *   ],
 *   "_": [
 *     "Some global error...",
 *     "Some other global error..."
 *   ]
 * }
 * </code></pre>
 */
const formErrors = (messages, formName, errors) => {
  return Object.entries(errors)
    .reduce((acc, [fieldName, err]) => ({
      ...acc,
      [fieldName]: fieldErrors(messages, formName, fieldName, err),
    }), {})
}

const fieldErrors = (messages, formName, fieldName, error) => {
  return Object.entries(error)
    .map(([code, err]) => {
      if (err === true) {
        return message(messages, formName, fieldName, code)
      }
      if (typeof err === 'string') {
        return message(messages, formName, fieldName, code, err)
      }
      return message(messages, formName, fieldName, code, err.message, err.constraints)
    })
}

const messageProvider = {
  message,
  formErrors,
}

export default messageProvider
