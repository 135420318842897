import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'
import styles from 'components/ui/ExportStartModal/ExportStartModal.module.scss'

const ExportStartModal = ({ open, onClose }) => {
  return (
    <Dialog
      title="Export generation"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6>Export generation had been initialized. You will be notified when it is ready for download.</H6>
        <div className={`flex-col-center ${styles.buttonContainer}`}>
          <Button
            className={styles.confirmButton}
            onClick={onClose}
            variant="contained"
          >OK</Button>
        </div>
      </div>
    </Dialog>
  )
}

ExportStartModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ExportStartModal
