import React from 'react'
import PropTypes from 'prop-types'

import { PhotoIcon } from 'assets/icons'

import styles from './ProfilePhoto.module.scss'

const ProfilePhoto = ({ profilePhotoUrl, onClick }) => {
  return (
    <div className={`flex-col-center ${styles.profilePhoto}`} onClick={onClick}>
      {
        profilePhotoUrl === '' ?
          <PhotoIcon className={styles.icon} /> :
          <img src={profilePhotoUrl} className={styles.photo} alt='Profile' />
      }
    </div>
  )
}

ProfilePhoto.propTypes = {
  profilePhotoUrl: PropTypes.string.isRequired,
  onClick: PropTypes.func,
}

export default ProfilePhoto
