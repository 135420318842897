import React from 'react'
import PropTypes from 'prop-types'
import { Navigate, Outlet } from 'react-router-dom'

import customPropTypes from 'utils/customPropTypes'

/**
 * Use this as a wrapper around the component you wish to protect.
 *
 * Example 1:
 * <Route path="admin" element={
 *   <ProtectedContent
 *     redirectPath="/home"
 *     authorized={user.permissions.includes('admin')}
 *   >
 *     <AdminPanel />
 *   </ProtectedContent>
 * }/>
 *
 * Example 2:
 * <Route element={<ProtectedContent authorized={!!user} />}>
 *   <Route path="home" element={<Home />} />
 *   <Route path="dashboard" element={<Dashboard />} />
 * </Route>
 *
 * @param children Child elements protected by this component. If not specified, router Outlet will be rendered.
 * @param authorized Boolean flag indicating if children or Outlet is accessible or not.
 * @param redirectPath Path to redirect to if access is not authorized.
 */
function ProtectedContent({ children, authorized, redirectPath = '/unauthorized' }) {
  if (!authorized) {
    return <Navigate to={redirectPath} replace />
  }

  return children || <Outlet />
}

ProtectedContent.propTypes = {
  children: customPropTypes.children,
  authorized: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
}

export default ProtectedContent
