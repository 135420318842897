import api from 'services/api'
import { pass } from 'utils/flow'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const fetchExecutionPeriods = () => dispatch => {
  return api.tracking.getExecutionPeriods()
    .then(data => pass(data, () => dispatch(fetchExecutionPeriodsSuccess(data))))
}
const fetchExecutionPeriodsSuccess = (data) => ({ type: types.fetchExecutionPeriodsSuccess, data })

export const fetchExecutionClusters = (executionId) => dispatch => {
  return api.tracking.getExecutionClusters(executionId)
    .then(data => pass(data, () => dispatch(fetchExecutionClustersSuccess(data))))
}
const fetchExecutionClustersSuccess = (data) => ({ type: types.fetchExecutionClustersSuccess, data })

export const fetchExecutionClusterStores = (executionId, clusterId) => dispatch => {
  return api.tracking.getExecutionClusterStores(executionId, clusterId)
    .then(data => pass(data, () => dispatch(fetchClusterStoresSuccess(clusterId, data))))
}
const fetchClusterStoresSuccess = (clusterId, data) => ({ type: types.fetchExecutionClusterStoresSuccess, clusterId, data })

export const fetchExecutionClustersWithStores = (executionId) => dispatch => {
  return dispatch(fetchExecutionClusters(executionId))
    .then(data => Promise.all(data.map(cluster => dispatch(fetchExecutionClusterStores(executionId, cluster.id)))))
}

export const fetchExecutionKeyIndicators = (executionId, clusterId, storeId, compareTo) => dispatch => {
  return api.tracking.getExecutionKeyIndicators(executionId, clusterId, storeId, compareTo)
    .then(data => pass(data, () => dispatch(fetchExecutionKeyIndicatorsSuccess(data))))
}
const fetchExecutionKeyIndicatorsSuccess = (data) => ({ type: types.fetchExecutionKeyIndicatorsSuccess, data })

export const fetchExecutionTrackingCategoriesTotal = (executionId, clusterId, storeId) => dispatch => {
  return api.tracking.getExecutionTrackingCategoriesTotal(executionId, clusterId, storeId)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingCategoriesTotalSuccess(data))))
}
const fetchExecutionTrackingCategoriesTotalSuccess = (data) => ({ type: types.fetchExecutionTrackingCategoriesTotalSuccess, data })

export const fetchExecutionTrackingCategories = (executionId, clusterId, storeId, queryParams) => dispatch => {
  return api.tracking.getExecutionTrackingCategories(executionId, clusterId, storeId, queryParams)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingCategoriesSuccess(data))))
}
export const fetchExecutionTrackingCategoriesSuccess = (data) => ({ type: types.fetchExecutionTrackingCategoriesSuccess, data })

export const fetchExecutionTrackingSubcategoriesTotal = (executionId, clusterId, storeId, categoryId) => dispatch => {
  return api.tracking.getExecutionTrackingSubcategoriesTotal(executionId, clusterId, storeId, categoryId)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingSubcategoriesTotalSuccess(data))))
}
const fetchExecutionTrackingSubcategoriesTotalSuccess = (data) => ({ type: types.fetchExecutionTrackingSubcategoriesTotalSuccess, data })

export const fetchExecutionTrackingSubcategories = (executionId, clusterId, storeId, categoryId, queryParams) => dispatch => {
  return api.tracking.getExecutionTrackingSubcategories(executionId, clusterId, storeId, categoryId, queryParams)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingSubcategoriesSuccess(data))))
}
export const fetchExecutionTrackingSubcategoriesSuccess = (data) => ({ type: types.fetchExecutionTrackingSubcategoriesSuccess, data })

export const fetchExecutionTrackingSKUsTotal = (executionId, clusterId, storeId, categoryId, subcategoryId) => dispatch => {
  return api.tracking.getExecutionTrackingSKUTotal(executionId, clusterId, storeId, categoryId, subcategoryId)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingSKUTotalSuccess(data))))
}
const fetchExecutionTrackingSKUTotalSuccess = (data) => ({ type: types.fetchExecutionTrackingSKUTotalSuccess, data })

export const fetchExecutionTrackingSKUs = (executionId, clusterId, storeId, categoryId, subcategoryId, queryParams) => dispatch => {
  return api.tracking.getExecutionTrackingSKUs(executionId, clusterId, storeId, categoryId, subcategoryId, queryParams)
    .then(data => pass(data, () => dispatch(fetchExecutionTrackingSKUsSuccess(data))))
}
export const fetchExecutionTrackingSKUsSuccess = (data) => ({ type: types.fetchExecutionTrackingSKUsSuccess, data })
