import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import { Caption, P2 } from 'components/ui/Typography'

import styles from './NotificationCard.module.scss'

function NotificationCard({ id, type, time, message, onClick, onDismiss, className, handleDownload, downloadURL }) {
  return (
    <div className={`${styles.notificationContainer} ${className || ''}`} onClick={onClick}>
      <Caption className={`${styles.date} caption`}>{time}</Caption>
      <P2 className={`${styles.message} message`}>{message}</P2>
      {
        (type === 'assortment_optimization_export_available' || type === 'execution_tracking_export_available') &&
          <div className={styles.downloadButtonWrapper}>
            <Button variant='contained' className={styles.downloadButton} onClick={() => handleDownload(downloadURL)}>Download</Button>
          </div>
      }
      <Button className={styles.dismissButton} onClick={() => onDismiss(id)} varient="text">Dismiss</Button>
    </div>
  )
}

NotificationCard.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  onDismiss: PropTypes.func.isRequired,
  className: PropTypes.string,
  handleDownload: PropTypes.func,
  downloadURL: PropTypes.string,
}

export default NotificationCard
