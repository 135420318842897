import { startCase, camelCase } from 'lodash'
import mapKeysDeep from 'map-keys-deep-lodash'

/**
 * Returns true if specified value is null, empty string (trimmed) or an empty object or array.
 */
export const isEmpty = o => {
  return (o === null || o === undefined) ||
    (typeof o === 'string' && o.trim().length === 0) ||
    (Array.isArray(o) && o.length === 0) ||
    (Object.getPrototypeOf(o) === Object.prototype && Object.keys(o).length === 0)
}

/**
 * If value is null, blank string or an empty object, this method returns undefined.
 * Otherwise, it returns the value itself.
 */
export const undefIfBlank = o => isEmpty(o) ? undefined : o

/**
 * If value is null or undefined it returns defaultValue, otherwise it returns value it self.
 */
export const nvl = (value, defaultValue) => {
  return value === undefined || value === null ? defaultValue : value
}

/**
 * Returns true if value is not undefined and value is not null
 */
export const isDefined = (value) => {
  return value !== undefined && value !== null
}

/**
 * Pascal case all props of passed object deeply.
 */
export const pascalCaseKeysDeep = data => {
  return mapKeysDeep(data, (value, key) => {
    return startCase(key).replace(/ /g, '')
  }, true)
}

/**
 * Camel case all props of passed object deeply.
 */
export const camelCaseKeysDeep = data => {
  const res = mapKeysDeep(data, (value, key) => {
    return key === '_' ? '_' : camelCase(key)
  }, true)

  return res
}
