import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { actions } from 'store'
import { Button } from '@mui/material'

import { isEmailValid } from 'utils/validators'
import Input from 'components/ui/Input'
import { useForm, useRevalidate } from 'hooks/useForm'
import { Errors, H4, P } from 'components/ui/Typography'
import useToast from 'components/ui/Toast/useToast'

import styles from './Login.module.scss'

const initialValues = {
  email: '',
  password: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.email?.trim()) {
    errors.email = { required: true }
  } else if (!isEmailValid(values.email, 8, 50)) {
    errors.email = { policy: true }
  }
  if (!values.password?.trim()) {
    errors.password = { required: true }
  }
  return errors
}

const Login = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errorToast } = useToast()

  const submit = useCallback(values => {
    return dispatch(actions.auth.login(values))
      .catch(e => {
        if (e.statusCode === 400) {
          errorToast('Authentication failed.')
        }
        if (e.statusCode === 403) {
          navigate('/not-verified')
        }
        if (e.statusCode === 409) {
          navigate('/login-pending')
        }
        if (e.statusCode === 500) {
          errorToast('Something went wrong.')
        }
      })
  }, [dispatch, errorToast, navigate])

  const revalidate = useRevalidate()
  const { errors, submitForm, processing, values, setValue } =
    useForm('login', validate, submit, initialValues, revalidate)

  return (
    <div className={`flex-col-center ${styles.loginContainer}`}>
      <Errors errors={errors?._} />
      <H4 className={styles.text}> Log in </H4>
      <Input
        name="email"
        label="Email"
        type="email"
        value={values.email || ''}
        onChange={setValue}
        error={!!errors?.email}
        helperText={errors?.email?.[0]}
        fullWidth
      />
      <Input
        name="password"
        label="Password"
        type="password"
        value={values.password || ''}
        onChange={setValue}
        error={!!errors?.password}
        helperText={errors?.password?.[0]}
        fullWidth
      />
      <Button variant='text' size='medium' disabled={processing} className={styles.forgotPassButton} onClick={() => navigate('/password-reset')}> I forgot my password </Button>
      <Button variant='contained' size='small' disabled={processing} className={styles.loginButton} onClick={submitForm}> Log in </Button>
      <P>Not a member?</P>
      <Button variant='text' size='medium' disabled={processing} className={styles.registerButton} onClick={() => navigate('/register')}> Register </Button>
    </div>
  )
}

export default Login
