import api from 'services/api'
import { pass } from 'utils/flow'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const loadNotifications = (search) => dispatch => {
  return api.notifications.getUserNotifications(search)
    .then(data => pass(data, () => dispatch(loadNotificationsSuccess(data))))
}

export const loadNotificationsSuccess = (data) =>
  ({ type: types.loadNotificationsSuccess, data })

export const dismissNotification = (id) => () => {
  return api.notifications.dismissNotification(id)
}

export const dismissAllNotifications = () => dispatch => {
  return api.notifications.dismissAllNotifications()
    .then(dispatch(loadNotifications()))
}

export const newNotificationSuccess = (data) =>
  ({ type: types.newNotificationSuccess, data })
