import React, { useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'

import { Strong, P, Errors } from 'components/ui/Typography'
import AutoTextField from 'components/ui/AutoTextField'
import Dialog from 'components/ui/Dialog'
import customPropTypes from 'utils/customPropTypes'
import { useForm, useRevalidate } from 'hooks/useForm'
import useData from 'hooks/useData'
import { isEmpty } from 'utils/object'
import { noop } from 'utils/language'

import styles from './ConfigureSubcategoryProperties.module.scss'

function ConfigureSubcategoryProperties({ open, onClose, optimizationId, clusterId, category, readOnly }) {
  const dispatch = useDispatch()
  const subCategoriesWithMeta = useSelector(selectors.optimizations.subCategoryDetails)

  const initialValues = useMemo(() => {
    return subCategoriesWithMeta
  }, [subCategoriesWithMeta])

  const submit = useCallback(values => {
    if (category) {
      return dispatch(actions.optimizations.setSubcategorySettings(optimizationId, clusterId, category.id, values))
        .then(() => onClose())
    }
  }, [dispatch, optimizationId, clusterId, category, onClose])

  const { loaded } = useData(() => {
    if (category) {
      dispatch(actions.optimizations.fetchSubcategorySettings(optimizationId, clusterId, category.id))
    }
  }, [category, clusterId, dispatch, optimizationId])

  const revalidate = useRevalidate()
  const { errors, submitForm, values, setValue, clear, markSubmitted } =
    useForm('configureSubcategoryProperties', null, submit, initialValues, revalidate)

  const handleConfirm = useCallback(() => {
    submitForm({ optimizationId }).catch(noop)
    markSubmitted()
  }, [optimizationId, markSubmitted, submitForm])

  const handleClose = useCallback(() => {
    onClose()
    clear()
  }, [onClose, clear])

  return (
    <Dialog open={open} title='Category settings' onClose={handleClose} className={styles.dialog}>
      {
        loaded && !isEmpty(values) && subCategoriesWithMeta &&
          <>
            <Strong className={styles.subtitle}> {category.name} </Strong>
            <P className={styles.text}> Define the number of SKUs allowed per category: </P>
            {
              readOnly ?
                <div className={styles.readOnlyLayout}>
                  {
                    Object.keys(subCategoriesWithMeta).map(subId => {
                      return (
                        <ol key={subId} className={styles.readOnlyDataContanier}>
                          <li><P className={styles.readOnlySKUName}> {subCategoriesWithMeta[subId].name} </P></li>
                          <li><P className={styles.readOnlySKUValue}> {subCategoriesWithMeta[subId].value} </P></li>
                        </ol>
                      )
                    })
                  }
                </div> :
                <>
                  <Paper variant='outlined' className={styles.categorySettings}>
                    {
                      Object.keys(subCategoriesWithMeta).map(subId => {
                        const subcategory = subCategoriesWithMeta[subId]
                        return (
                          <AutoTextField
                            key={subId}
                            title={subcategory.name}
                            className={styles.subCategory}
                            value={values[subId].value}
                            automatic={values[subId].automatic}
                            automaticValue={subcategory.defaultValue || 0}
                            error={!!errors?.subCategory}
                            onChange={(checked, value) => setValue(subId, { ...subcategory, automatic: checked, value })}
                          />
                        )
                      },
                      )
                    }
                  </Paper>
                  <Errors errors={errors?._} />
                  <Button variant='contained' className={styles.confirmButton} onClick={handleConfirm}> Confirm </Button>
                  <Button className={styles.cancelButton} onClick={handleClose}> Cancel </Button>
                </>
            }
          </>
      }
    </Dialog>
  )
}
ConfigureSubcategoryProperties.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  clusterId: PropTypes.number,
  optimizationId: PropTypes.number,
  category: customPropTypes.category,
  readOnly: PropTypes.bool,
}
export default ConfigureSubcategoryProperties
