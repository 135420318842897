const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

/**
 * Checks is email is in the valid format.
 */
export const isEmailValid = (email) => {
  return emailRegEx.test(email)
}

/**
 *
 */
export const isPasswordValid = (password, min, max, require = isPasswordValid.ALL) => {
  return (min ? password?.length >= min : true) &&
    (max ? password?.length <= max : true) &&
    (require.includes(isPasswordValid.UPPERCASE) ? /[A-Z]+/.test(password) : true) &&
    (require.includes(isPasswordValid.LOWERCASE) ? /[a-z]+/.test(password) : true) &&
    (require.includes(isPasswordValid.DIGITS) ? /[0-9]+/.test(password) : true) &&
    (require.includes(isPasswordValid.SPECIAL) ? /[ !"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]+/.test(password) : true)
}
isPasswordValid.UPPERCASE = 'UPPERCASE'
isPasswordValid.LOWERCASE = 'LOWERCASE'
isPasswordValid.DIGITS = 'DIGITS'
isPasswordValid.SPECIAL = 'SPECIAL'
isPasswordValid.ALL = [
  isPasswordValid.UPPERCASE,
  isPasswordValid.LOWERCASE,
  isPasswordValid.DIGITS,
  isPasswordValid.SPECIAL,
]
