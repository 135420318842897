import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from '@mui/material'

import { H4, P } from 'components/ui/Typography'

import styles from './ResetPasswordSuccess.module.scss'

const ResetPasswordSuccess = () => {
  const navigate = useNavigate()

  return (
    <div className={'flex-col-center'}>
      <H4 className={styles.title}>Success!</H4>
      <P className={styles.text}>Your password is now reset, you can log in with your new password!</P>
      <Button
        className={styles.loginButton}
        onClick={() => navigate('/login')}
        variant='contained'
      > Log in </Button>
    </div>
  )
}
export default ResetPasswordSuccess
