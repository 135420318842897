import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  messages: [],
  activeMessage: null,
  open: false,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.queue]: queue,
  [types.showNext]: showNext,
  [types.dismiss]: dismiss,
  [types.open]: open,
  [types.close]: close,
}

function vacate(state) {
  return initialState
}

function queue(state, { message, severity, position, key }) {
  return {
    ...state,
    messages: [
      ...state.messages,
      { message, severity, position, key },
    ],
  }
}

function showNext(state) {
  return {
    ...state,
    activeMessage: state.messages?.[0],
    open: true,
    messages: state.messages.slice(1),
  }
}

function dismiss(state) {
  return {
    ...state,
    activeMessage: null,
  }
}

function open(state) {
  return {
    ...state,
    open: true,
  }
}

function close(state) {
  return {
    ...state,
    open: false,
  }
}

export default objectReducer(initialState, actionHandlers)
