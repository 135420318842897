import { enumValue, from, values } from 'utils/enum'

export const Role = {
  admin: enumValue('admin', { label: 'Admin' }),
  categoryManager: enumValue('category_manager', { label: 'Category manager' }),
  from,
  values,
}

export const OptimizationStatus = {
  draft: enumValue('draft', { label: 'Draft', order: 1, isEditable: true, isCmAccessible: false }),
  processing: enumValue('processing', { label: 'Processing', order: 2, isEditable: true, isCmAccessible: false }),
  optimized: enumValue('optimized', { label: 'Optimized', order: 3, isEditable: true, isCmAccessible: true }),
  finalized: enumValue('finalized', { label: 'Finalized', order: 4, isEditable: false, isCmAccessible: true }),
  tracking: enumValue('tracking', { label: 'Tracking', order: 5, isEditable: false, isCmAccessible: true }),
  from,
  values,
}

export const ClustersConfigStatus = {
  pending: enumValue('PENDING'),
  active: enumValue('ACTIVE'),
  from,
  values,
}

export const SkuStatus = {
  included: enumValue(1, { label: 'included' }),
  removed: enumValue(2, { label: 'removed' }),
  from,
  values,
}

export const ReviewStatus = {
  reviewed: enumValue('reviewed', { label: 'Reviewed' }),
  pending: enumValue('pending', { label: 'Pending' }),
  from,
  values,
}

export const UserStatus = {
  active: enumValue('active', { label: 'Active', order: 1 }),
  pendingAccess: enumValue('pending_access', { label: 'Pending Access', order: 2 }),
  waitingConfirmation: enumValue('waiting_confirmation', { label: 'Waiting confirmation', order: 3 }),
  suspended: enumValue('suspended', { label: 'Suspended', order: 4 }),
  from,
  values,
}

export const ExportExecution = {
  all: enumValue('all', { label: 'All', order: 1 }),
  kpiAndSubcategories: enumValue('kpi_and_subcategories', { label: 'Kpi and Subcategories', order: 2 }),
  from,
  values,
}
