import React, { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import ResetPasswordEmailInput from './ResetPasswordEmailInput'
import ResetPasswordNewPassInput from './ResetPasswordNewPassInput'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import styles from './ResetPassword.module.scss'

export const EMAIL_INPUT = 'EMAIL_INPUT'
export const PASSWORD_INPUT = 'PASSWORD_INPUT'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'

const ResetPassword = () => {
  const { email, token } = useParams()
  const [activeStep, setActiveStep] = useState(email && token ? PASSWORD_INPUT : EMAIL_INPUT)

  const handleResetPasswordEmailInputContinue = useCallback(() => setActiveStep(PASSWORD_INPUT), [])
  const handleResetPasswordNewPassInputContinue = useCallback(() => setActiveStep(RESET_PASSWORD_SUCCESS), [])

  return (
    <div className={`flex-col-center ${styles.resetPassword}`}>
      {(activeStep === EMAIL_INPUT) && <ResetPasswordEmailInput onContinue={handleResetPasswordEmailInputContinue} />}
      {(activeStep === PASSWORD_INPUT) && <ResetPasswordNewPassInput onContinue={handleResetPasswordNewPassInputContinue} />}
      {(activeStep === RESET_PASSWORD_SUCCESS) && <ResetPasswordSuccess />}
    </div>
  )
}
export default ResetPassword
