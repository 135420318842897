import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store'
import Switch from '@mui/material/Switch'

import PasswordResetSuccess from 'components/ui/PasswordReset/PasswordResetSuccess'
import PasswordResetStart from 'components/ui/PasswordReset/PasswordResetStart'
import { P } from 'components/ui/Typography'
import useBooleanState from 'hooks/useBooleanState'

import styles from './Settings.module.scss'

const Settings = () => {
  const dispatch = useDispatch()
  const [passwordResetModalOpen, openPasswordResetModal, closePasswordResetModal] = useBooleanState(false)
  const [passwordResetSuccessModalOpen, openPasswordResetSuccessModal, closePasswordResetSuccessModal] = useBooleanState(false)
  const profile = useSelector(state => state.users.profile)

  const handlePasswordReset = useCallback(password => {
    closePasswordResetModal()
    return dispatch(actions.users.resetPassword({
      id: profile.id,
      password,
    }))
      .then(openPasswordResetSuccessModal())
  }, [dispatch, profile, closePasswordResetModal, openPasswordResetSuccessModal])

  const handleEmailNotificationChange = useCallback(() => {
    dispatch(actions.users.enableEmailNotifications({
      id: profile.id,
      enableNotifications: !profile.enableEmailNotification,
    }))
  }, [dispatch, profile.id, profile.enableEmailNotification])

  return (
    <div className={`flex-col-center ${styles.settings}`}>
      <div className={styles.columnContainer}>
        <div className={`flex-row-center ${styles.row}`}>
          <P className={styles.boldText}>Notifications</P>
          <hr className={styles.hr} />
        </div>
        <div className={`flex-row-center ${styles.row}`}>
          <P className={styles.greenText}>Email notifications</P>
          <Switch
            checked={profile.enableEmailNotification}
            onClick={handleEmailNotificationChange}
            className={styles.switch}
          />
        </div>
      </div>

      <div className={styles.columnContainer}>
        <div className={`flex-row-center ${styles.row}`}>
          <P className={styles.boldText}>Account</P>
          <hr className={styles.hr} />
        </div>
        <P className={styles.password} onClick={openPasswordResetModal}>Change password</P>
      </div>

      <div className={styles.columnContainer}>
        <div className={`flex-row-center ${styles.row}`}>
          <P className={styles.boldText}>Support</P>
          <hr className={styles.hr} />
        </div>
        <P>If you have any troubles with Spectra application, do not hesitate to contact us:</P>
        <P className={styles.greenText}>support@spectra.com</P>
      </div>

      <PasswordResetSuccess open={passwordResetSuccessModalOpen} onClose={closePasswordResetSuccessModal} />
      <PasswordResetStart open={passwordResetModalOpen} onClose={closePasswordResetModal} onPasswordReset={handlePasswordReset} />
    </div>
  )
}

export default Settings
