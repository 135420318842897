import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import IconButton from '@mui/material/IconButton'

import SpecialSKUsAssortmentCard from 'components/ui/AssortmentCards/SpecialSKUsAssortmentCard'
import AssortmentLengthStabilityCard from 'components/ui/AssortmentCards/AssortmentLengthStabilityCard'
import AssortmentLengthCard from 'components/ui/AssortmentCards/AssortmentLengthCard'
import RevenueMarginQuantityAssortmentCard from 'components/ui/AssortmentCards/RevenueMarginQuantityAssortmentCard'
import { arrayToMap } from 'utils/array'
import { H4, Strong } from 'components/ui/Typography'
import { ConfigureIcon } from 'assets/icons'
import { cls } from 'utils/react'

import styles from './AssortmentOptimizationKPICards.module.scss'

const cardsText = {
  assortment_length: {
    title: 'Assortment length',
    info: 'Total number of different products (SKUs) offered.',
  },
  assortment_length_stability: {
    title: 'Assortment length stability',
    info: 'Consistency in the number of products (SKUs) a retailer offers over a certain period.',
  },
  special_sku_share: {
    title: 'Special SKUs',
    info: 'A unique, seasonal, limited edition or high-demand items (SKUs) in an assortment that require special attention. Not included in the assortment optimization.',
  },
  revenue: {
    title: 'Revenue',
    info: 'The total sales generated from the sale of goods in the specified period.',
  },
  margin: {
    title: 'Margin',
    info: 'The difference between the cost and selling price of a product in the specified period.',
  },
  quantity: {
    title: 'Quantity',
    info: 'Amount of goods (items) sold in the specified period.',
  },
}

function AssortmentOptimizationKPICards({ className, onConfigure, isAdmin, isEditable }) {
  const keyIndicators = useSelector(state => state.optimizations.optimization.keyIndicators)
  const [cardsData, setCardsData] = useState()

  useEffect(() => {
    if (keyIndicators.length > 0) {
      setCardsData(arrayToMap(keyIndicators,
        indicator => indicator.code,
        indicator => cardsText[indicator.code],
      ))
    }
  }, [keyIndicators])

  return (
    !!cardsData &&
      <div className={cls([styles.container, className])}>
        <div className={styles.header}>
          <H4>Key assortment indicators</H4>
          {
            isAdmin && isEditable &&
              <div className={styles.config}>
                <IconButton onClick={onConfigure}>
                  <ConfigureIcon className="icon normal" />
                </IconButton>
                <Strong>Configure properties</Strong>
              </div>
          }
        </div>
        <div className={styles.cardsContainer}>
          <AssortmentLengthCard data={cardsData.assortment_length} className={styles.kpiCard} />
          <AssortmentLengthStabilityCard data={cardsData.assortment_length_stability} className={styles.kpiCard} />
          <SpecialSKUsAssortmentCard data={cardsData.special_sku_share} className={styles.kpiCard} />
          <RevenueMarginQuantityAssortmentCard data={cardsData.revenue} className={styles.kpiCard} />
          <RevenueMarginQuantityAssortmentCard data={cardsData.margin} className={styles.kpiCard} />
          <RevenueMarginQuantityAssortmentCard data={cardsData.quantity} className={styles.kpiCard} />
        </div>
      </div>
  )
}

AssortmentOptimizationKPICards.propTypes = {
  className: PropTypes.string,
  onConfigure: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  isEditable: PropTypes.bool,
}

export default AssortmentOptimizationKPICards
