import React from 'react'
import PropTypes from 'prop-types'

import { Role } from 'services/enums'

import styles from './UserCategories.module.scss'

const UserCategories = ({ user }) => {
  const categories = user.categories?.map(category => category.name)?.join(', ')

  if (user.role === Role.admin) {
    return 'All'
  }

  if (!user.categories?.length || user.status.value === 'suspended') {
    return '—'
  }

  return (
    <div>
      <div className={styles.categoriesList}>{categories}</div>
    </div>
  )
}

UserCategories.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserCategories
