import React from 'react'
import PropTypes from 'prop-types'

import { InfoIcon } from 'assets/icons'
import { H4, H6 } from 'components/ui/Typography'
import { sixDigitsNumberFormat } from 'utils/numberFormat'

import CustomTooltip from '../CustomTooltip'

import styles from './KPICard.module.scss'

const KPICard = ({ data, className, compare }) => {
  const { title, icon, values, info } = data

  return (
    <div>
      <div className={`flex-col-center ${styles.cardContainer} ${className || ''} ${title === 'Margin' && values.some(value => value.value === 0) ? styles.empty : ''}`}>
        <div className={styles.tooltip}>
          <CustomTooltip title={info}>
            <InfoIcon className="icon small" />
          </CustomTooltip>
        </div>
        <div className="flex-col-center" >
          <div className={styles.title}>
            {title}
          </div>
          <img className={styles.icon} src={icon} alt='kpi-card' />
        </div>
        <div>
          {
            values.map(value => {
              let prefix = ''
              if (compare) {
                prefix = value.value < 0 ? styles.minus : styles.plus
              }

              return (
                <div key={`${value.value}-${value.label}`} className={`flex-row-center ${styles.footer}`}>
                  <H4 className={`${prefix}`}>{sixDigitsNumberFormat(value.value)}{value.suffix}</H4>
                  <H6>{value.label}</H6>
                </div>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

KPICard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    icon: PropTypes.any.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      value: PropTypes.number.isRequired,
      label: PropTypes.string,
    })),
    info: PropTypes.string.isRequired,
  }).isRequired,
  className: PropTypes.string,
  compare: PropTypes.bool,
}

export default KPICard
