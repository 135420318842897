import { all, paged } from 'services/api/pager'
import { cached, withCleanCache } from 'utils/cache'
import axios from 'services/api/axios.instance'
import { withMeta, config } from 'services/api/common'

import { skuInForm } from './adapters/skuAdapter'
import { categoryInForm } from './adapters/categoryAdapter'
import { subcategoryInForm } from './adapters/subcategoryAdapter'
import {
  optimizationInForm,
  optimizationsInForm,
  goalsInForm,
  limitsInForm,
  approvalsInForm,
  subcategorySettingsInForm,
  keyIndicatorsInForm,
} from './adapters/optimizationsAdapter'
import cache from './api.cache'

const getOptimizations = async(page, { search, dateFrom, dateTo, status, approvedBy, includeApprovals }) => {
  page = page || {}
  return axios.get('/optimizations', {
    params: {
      search,
      dateFrom,
      dateTo,
      status: status?.value,
      approvedBy,
      ...page,
    },
  })
    .then(data => withMeta(data, data => data.map(item => optimizationsInForm(item))))
}

const getOptimization = async(optimizationId) => {
  return axios.get(`/optimizations/${optimizationId}`, {})
    .then(data => optimizationInForm(data))
}

const deleteOptimization = async(optimizationId) => {
  return axios.delete(`/optimizations/${optimizationId}`, {})
}

const downloadOptimization = async(optimizationId) => {
  return axios.get(`/optimizations/${optimizationId}`, {
    params: {
      format: 'csv',
    },
  })
}

const createOptimization = async(name, parentOptimizationId) => {
  return axios.post('/optimizations', {
    parentOptimizationId,
    name,
  })
    .then(data => data.insertedAssortmentOptimizationId)
}

const updateOptimization = async(optimizationId, { title }) => {
  return axios.put(`/optimizations/${optimizationId}`, {
    title,
  })
}

const getKeyIndicators = async(optimizationId, clusterId, storeId) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`
    if (storeId) {
      url = url + `/stores/${storeId}`
    }
  }
  url = url + '/key-indicators'

  return axios.get(url, {})
    .then(data => withMeta(data, data => data.map(item => keyIndicatorsInForm(item))))
}

const getGoals = async(optimizationId, clusterId) => {
  const url = clusterId ?
    `/optimizations/${optimizationId}/clusters/${clusterId}/goals` :
    `/optimizations/${optimizationId}/goals`

  return axios.get(url, {})
    .then(data => goalsInForm(data))
}

const getLimits = async(optimizationId, clusterId) => {
  const url = clusterId ?
    `/optimizations/${optimizationId}/clusters/${clusterId}/limits` :
    `/optimizations/${optimizationId}/limits`

  return axios.get(url, {})
    .then(data => limitsInForm(data))
}

const setGoals = async(optimizationId, clusterId, { revenue, margin, baskets }, applyToAllClusters) => {
  const url = clusterId ?
    `/optimizations/${optimizationId}/clusters/${clusterId}/goals` :
    `/optimizations/${optimizationId}/goals`
  const requestBody = {
    revenue,
    baskets,
    applyToAllClusters,
  }
  if (margin !== null) {
    requestBody.margin = margin
  }
  return axios.put(url, requestBody)
}

const setLimits = async(optimizationId, clusterId, { skuNumber, innovationSkuNumber }) => {
  const putParams = {}
  if (skuNumber) {
    putParams.skuNumber = skuNumber
  }
  if (innovationSkuNumber) {
    putParams.innovationSkuNumber = innovationSkuNumber
  }
  return axios.put(`/optimizations/${optimizationId}/${clusterId ? `clusters/${clusterId}/` : ''}limits`, putParams)
}

const getCategories = async(page, optimizationId, clusterId, storeId, search) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`

    if (storeId) {
      url = url + `/stores/${storeId}`
    }
  }
  url = url + '/categories'

  page = page || {}
  return axios.get(url, {
    params: {
      ...page,
      search,
    },
  })
    .then(data => withMeta(data, data => data.map(categoryInForm)))
}

const getSubcategories = async(page, optimizationId, clusterId, storeId, categoryId, search) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`

    if (storeId) {
      url = url + `/stores/${storeId}`
    }
  }
  url = url + `/categories/${categoryId}/subcategories`

  page = page || {}
  return axios.get(url, {
    params: {
      ...page,
      search,
    },
  })
    .then(data => withMeta(data, data => data.map(subcategoryInForm)))
}

const getProducers = async(page, optimizationId, categoryId, subcategoryId, search) => {
  page = page || {}
  return axios.get(`/optimizations/${optimizationId}/producers`, {
    params: {
      ...page,
      categoryId,
      subcategoryId,
      search,
    },
  })
}

const getBrands = async(page, optimizationId, categoryId, subcategoryId, producerId, search) => {
  let url = `/optimizations/${optimizationId}`
  if (producerId) {
    url = url + `/producers/${producerId}`
  }
  url = url + '/brands'

  page = page || {}
  return axios.get(url, {
    params: {
      ...page,
      categoryId,
      subcategoryId,
      search,
    },
  })
}

const getSkus = async(page, optimizationId, clusterId, storeId, categoryId, subcategoryId, includeRemovedByOptimization, producerId, brandIds, search) => {
  if (brandIds[0] === 'ALL_BRANDS') {
    brandIds.shift()
  }
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`

    if (storeId) {
      url = url + `/stores/${storeId}`
    }
  }
  url = url + '/skus'

  page = page || {}
  return axios.get(url, {
    params: {
      ...page,
      categoryId,
      subcategoryId,
      includeRemovedByOptimization,
      producerId,
      brandId: brandIds,
      search,
    },
  })
    .then(data => withMeta(data, data => data.map(skuInForm)))
}

const getCategorySettings = async(optimizationId, clusterId, categoryId) => {
  return axios.get(`/optimizations/${optimizationId}/clusters/${clusterId}/categories/${categoryId}/settings`, {})
    .then(data => limitsInForm(data))
}

const setCategorySettings = async(optimizationId, clusterId, categoryId, { maxSKUs, maxInnovationSKUs }) => {
  const data = {
    maxSKUs: {
      auto: maxSKUs.automatic,
      value: maxSKUs.value,
    },
    maxInnovationSKUs: {
      auto: maxInnovationSKUs.automatic,
      value: maxInnovationSKUs.value,
    },
  }

  return axios.put(`/optimizations/${optimizationId}/clusters/${clusterId}/categories/${categoryId}/settings`, data)
}

const getSubcategorySettings = async(optimizationId, clusterId, categoryId) => {
  return axios.get(`/optimizations/${optimizationId}/clusters/${clusterId}/categories/${categoryId}/subcategory-settings`, {})
    .then(data => subcategorySettingsInForm(data))
}

const setSubcategorySettings = async(optimizationId, clusterId, categoryId, settings) => {
  const data = {}
  Object.keys(settings).forEach(key => {
    data[key] = {
      auto: settings[key].automatic,
      value: settings[key].value,
    }
  })
  return axios.put(`/optimizations/${optimizationId}/clusters/${clusterId}/categories/${categoryId}/subcategory-settings`,
    data,
  )
}

const setSkuLockFlag = async(optimizationId, clusterId, skuId, locked) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`
  }
  url = url + `/skus/${skuId}/lock-unlock-commands`

  return axios.post(url, {
    value: !!locked,
  })
}

const setSkuPresence = async(optimizationId, clusterId, skuId, present) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`
  }
  url = url + `/skus/${skuId}/remove-restore-commands`

  return axios.post(url, {
    value: !present,
  })
}

const approveOptimization = async(optimizationId, userId) => {
  return axios.put(`/optimizations/${optimizationId}/approvals/${userId}`, {})
}

const getApprovalErrors = async(optimizationId, clusterId, categoryId, subcategoryId) => {
  let url = `/optimizations/${optimizationId}`
  if (clusterId) {
    url = url + `/clusters/${clusterId}`

    if (categoryId) {
      url = url + `/categories/${categoryId}`

      if (subcategoryId) {
        url = url + `/subcategories/${subcategoryId}`
      }
    }
  }
  url = url + '/approval-errors'

  return axios.get(url, {})
}

const getApprovals = async(optimizationId) => {
  return axios.get(`/optimizations/${optimizationId}/approvals`, {})
    .then(data => withMeta(data, data => data.map(item => approvalsInForm(item))))
}

const readyForCategoryManagers = async(optimizationId) => {
  return axios.post(`/optimizations/${optimizationId}/ready-for-cm-commands`, {})
}

const runOptimization = async(optimizationId) => {
  return axios.post(`/optimizations/${optimizationId}/run-commands`, {})
}

const finalize = async(optimizationId) => {
  return axios.post(`/optimizations/${optimizationId}/finalize-commands`, {})
}

const api = {
  getOptimizations: cached(paged(getOptimizations, 10), 'getOptimizations', cache.optimizations, () => config.accessToken),
  getOneOptimization: cached(paged(getOptimizations, 1), 'getOneOptimization', cache.optimizations, () => config.accessToken),
  getOptimization: cached(getOptimization, 'getOptimization', cache.optimization, () => config.accessToken),
  deleteOptimization: withCleanCache(deleteOptimization, cache.optimizations, cache.optimization),
  downloadOptimization,
  createOptimization: withCleanCache(createOptimization, cache.optimizations),
  updateOptimization: withCleanCache(updateOptimization, cache.optimizations, cache.optimization),
  getKeyIndicators: cached(getKeyIndicators, 'getKeyIndicators', cache.keyIndicators, () => config.accessToken),
  getGoals: cached(getGoals, 'getGoals', cache.goals, () => config.accessToken),
  getLimits: cached(getLimits, 'getLimits', cache.goals, () => config.accessToken),
  setGoals: withCleanCache(setGoals, cache.goals),
  setLimits: withCleanCache(setLimits, cache.goals),
  getCategories: cached(all(getCategories), 'getCategories', cache.categories, () => config.accessToken),
  getSubcategories: cached(all(getSubcategories), 'getSubcategories', cache.subcategories, () => config.accessToken),
  getProducers: cached(all(getProducers), 'getProducers', cache.producers, () => config.accessToken),
  getBrands: cached(all(getBrands), 'getBrands', cache.brands, () => config.accessToken),
  getSkus: paged(getSkus, 10),
  getCategorySettings,
  setCategorySettings,
  getSubcategorySettings,
  setSubcategorySettings,
  setSkuLockFlag,
  setSkuPresence,
  approveOptimization,
  getApprovalErrors,
  getApprovals,
  readyForCategoryManagers: withCleanCache(readyForCategoryManagers, cache.optimizations, cache.optimization),
  finalize: withCleanCache(finalize, cache.optimizations, cache.optimization),
  runOptimization: withCleanCache(runOptimization, cache.optimizations, cache.optimization),
}

export default api
