export const vacate = 'vacate'
export const loadUserProfileSuccess = 'load_user_profile_success'
export const fetchUsersSuccess = 'fetch_users_success'
export const fetchUserSuccess = 'fetch_user_success'
export const updateProfilePhotoSuccess = 'update_profile_photo_success'
export const deleteUserSuccess = 'delete_user_success'
export const suspendUserSuccess = 'suspend_user_success'
export const restoreUserSuccess = 'restore_user_success'
export const resendUserConfirmationLinkSuccess = 'resend_user_confirmation_link_success'
export const updateUserSuccess = 'update_user_success'
export const approveCategoryManagerSuccess = 'approve_category_manager_success'
export const enableEmailNotificationsSuccess = 'enable_email_notifications_success'
