import { Role, UserStatus } from 'services/enums'
import { nameParts } from 'services/api/commonAdapters'

export function registerInForm(item) {
  const { firstName, lastName } = nameParts(item?.name)

  return item && {
    ...item,
    status: UserStatus.from(item.status),
    role: Role.from(item.role),
    firstName,
    lastName,
  }
}

export const profileInForm = registerInForm
