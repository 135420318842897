import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './RemoveUserModal.module.scss'

const RemoveUserModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      title="Remove user"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6 className={styles.removeText}>Are you sure you want to remove this user?</H6>
        <Button
          className={styles.removeButton}
          onClick={onConfirm}
          variant="contained"
        >Remove</Button>
        <Button
          className={styles.button}
          onClick={onClose}
          variant="primary"
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

RemoveUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default RemoveUserModal
