import React from 'react'
import PropTypes from 'prop-types'

import { P } from 'components/ui/Typography'

import styles from './VerticalHeatmapLegend.module.scss'

export const heatmapClasses = [
  styles.colorMin4, styles.colorMin3, styles.colorMin2, styles.colorMin1,
  styles.colorMid, styles.colorMid, styles.colorMid,
  styles.colorMax1, styles.colorMax2, styles.colorMax3, styles.colorMax4,
]

const minScaleClasses = heatmapClasses.slice(0, 4).reverse()
const maxScaleClasses = heatmapClasses.slice(7, 12).reverse()

function VerticalHeatmapLegend({ className }) {
  function renderLegendItems(classes) {
    return (
      classes.map(squareClass => <div key={squareClass} className={`${styles.square} ${squareClass}`} />)
    )
  }

  return (
    <div className={`flex-col-center ${styles.VerticalHeatmapLegend} ${className || ''}`}>
      <P className={styles.label}>Max</P>
      { renderLegendItems(maxScaleClasses) }
      <div className={`${styles.square} ${styles.colorMid}`} />
      <div className={`${styles.square} ${styles.divider}`} />
      <div className={`${styles.square} ${styles.colorMid}`} />
      { renderLegendItems(minScaleClasses) }
      <P className={styles.label}>Min</P>
    </div>
  )
}

VerticalHeatmapLegend.propTypes = {
  className: PropTypes.string,
}

export default VerticalHeatmapLegend
