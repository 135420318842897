import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { actions } from 'store'
import { useNavigate } from 'react-router'

import Dialog from 'components/ui/Dialog'
import { Errors, H4, P } from 'components/ui/Typography'
import AutoTextField from 'components/ui/AutoTextField'
import NewDraft from 'pages/NewDraft'
import { nvl } from 'utils/object'
import { useForm, useRevalidate } from 'hooks/useForm'
import customPropTypes from 'utils/customPropTypes'
import useBooleanState from 'hooks/useBooleanState'
import { noop } from 'utils/language'
import { OptimizationStatus } from 'services/enums'
import Suspended from 'components/ui/Suspended'
import useData from 'hooks/useData'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'

import styles from './ConfigureCategoryProperties.module.scss'

const ConfigureCategoryProperties = ({ open, onClose, optimizationId, clusterId, category, readOnly }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { categorySettings, status } = useSelector(state => state.optimizations.optimization || {})
  const [isNewDraftModalOpen, openNewDraftModal, closeNewDraftModal] = useBooleanState(false)
  const isDraft = status === OptimizationStatus.draft
  const { skuNumber: maxSKUs, innovationSkuNumber: maxInnovationSKUs } = categorySettings || {}

  const initialValues = useMemo(() => {
    return {
      maxSKUs: {
        automatic: nvl(maxSKUs?.automatic, false),
        value: nvl(maxSKUs?.value, 0),
      },
      maxInnovationSKUs: {
        automatic: nvl(maxInnovationSKUs?.automatic, false),
        value: nvl(maxInnovationSKUs?.value, 0),
      },
    }
  }, [maxInnovationSKUs, maxSKUs])

  const validate = useCallback((values) => {
    const errors = {}
    if (
      values.maxSKUs.automatic ?
        values.maxInnovationSKUs.value > maxSKUs?.defaultValue :
        values.maxInnovationSKUs.value > values.maxSKUs.value
    ) {
      errors.category = { total: true }
    }

    return errors
  }, [maxSKUs])

  const submit = useCallback(values => {
    if (category) {
      const data = {
        maxSKUs: {
          automatic: values.maxSKUs.automatic,
          value: values.maxSKUs.value,
          defaultValue: maxSKUs?.defaultValue,
        },
        maxInnovationSKUs: {
          automatic: values.maxInnovationSKUs.automatic,
          value: values.maxInnovationSKUs.value,
          defaultValue: maxInnovationSKUs?.defaultValue,
        },
      }
      return dispatch(actions.optimizations.setCategorySettings(optimizationId, clusterId, category.id, data))
        .then(onClose)
    }
  }, [maxSKUs, maxInnovationSKUs, dispatch, optimizationId, clusterId, category, onClose])

  const revalidate = useRevalidate()
  const { errors, submitForm, validateForm, processing, values, setValue, clear, markSubmitted } =
    useForm('configureCategoryProperties', validate, submit, initialValues, revalidate)

  const { loaded, error } = useData(() => {
    if (category) {
      dispatch(actions.optimizations.fetchCategorySettings(optimizationId, clusterId, category.id))
    }
  }, [category, clusterId, dispatch, optimizationId])

  const handleConfirm = useCallback(() => {
    if (isDraft) {
      submitForm({ optimizationId })
    } else {
      markSubmitted()
      validateForm({ optimizationId })
        .then(() => openNewDraftModal())
        .catch(noop)
    }
  }, [isDraft, submitForm, optimizationId, markSubmitted, validateForm, openNewDraftModal])

  const handleNewDraftCreated = useCallback((newOptimizationId) => {
    return submitForm({ optimizationId: newOptimizationId })
      .then(() => navigate(`/assortment-optimization/details/${newOptimizationId}`))
  }, [navigate, submitForm])

  const handleClose = useCallback(() => {
    onClose()
    clear()
  }, [onClose, clear])

  return (
    <Dialog
      title="Configure properties"
      open={open}
      onClose={handleClose}
      className={styles.dialog}
    >
      {
        loaded && category ?
          <div className={styles.content}>
            <H4>{category.name}</H4>
            {
              readOnly ?
                <div className={styles.readOnlyLayout}>
                  <ol className={styles.readOnlyDataContanier}>
                    <li> <P> Maximum number of SKUs allowed in assortment: </P> </li>
                    <li> <P className={styles.readOnlyValue}> {values.maxSKUs.value} </P> </li>
                  </ol>
                  <ol className={styles.readOnlyDataContanier}>
                    <li> <P> Number of innovation and seasonal of SKUs allowed in assortment: </P> </li>
                    <li> <P className={styles.readOnlyValue}> {values.maxInnovationSKUs.value} </P> </li>
                  </ol>
                </div> :
                <>
                  {
                    categorySettings &&
                      <>
                        <Errors errors={errors?._} />
                        <AutoTextField
                          title='Maximum number of SKUs allowed in assortment:'
                          automatic={values.maxSKUs.automatic}
                          value={values.maxSKUs.value}
                          automaticValue={maxSKUs?.defaultValue || 0}
                          onChange={(checked, value) => setValue('maxSKUs', { automatic: checked, value })}
                          error={false}
                        />
                        <AutoTextField
                          title='Number of innovation and seasonal of SKUs allowed in assortment:'
                          automatic={values.maxInnovationSKUs.automatic}
                          value={values.maxInnovationSKUs.value}
                          automaticValue={maxInnovationSKUs?.defaultValue || 0}
                          onChange={(checked, value) => setValue('maxInnovationSKUs', { automatic: checked, value })}
                          error={!!errors?.category}
                          helperText={errors?.category?.[0]}
                        />
                      </>
                  }
                  <div className={`flex-col-center ${styles.buttonsContainer}`}>
                    <Button
                      className={styles.containedButton}
                      onClick={handleConfirm}
                      variant="contained"
                      size="medium"
                      disabled={processing}
                    >
                      Confirm
                    </Button>
                    <Button
                      className={styles.button}
                      onClick={handleClose}
                      variant="primary"
                    >
                      Cancel
                    </Button>
                  </div>
                </>
            }
          </div> :
          <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }

      <Suspended open={isNewDraftModalOpen}>
        <NewDraft
          open={isNewDraftModalOpen}
          onCreate={handleNewDraftCreated}
          onClose={closeNewDraftModal}
        />
      </Suspended>
    </Dialog>
  )
}
ConfigureCategoryProperties.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clusterId: PropTypes.number,
  optimizationId: PropTypes.number,
  category: customPropTypes.category,
  readOnly: PropTypes.bool,
}

export default ConfigureCategoryProperties
