import React from 'react'
import PropTypes from 'prop-types'
import { Badge, Button, Stack } from '@mui/material'

import { NotificationsIcon } from 'assets/icons'
import { P } from 'components/ui/Typography'

import styles from './NotificationHeadCard.module.scss'

function NotificationHeadCard({ notificationsNumber, onDismiss, className }) {
  return (
    <Stack direction='row' className={styles.headCardNotification}>
      <Badge className='notifications' badgeContent={notificationsNumber} showZero color='primary'>
        <NotificationsIcon className='icon small' />
      </Badge>
      <P sx={{ paddingLeft: '16px' }}>Notifications</P>
      <div className={`${styles.notificationContainer} ${className || ''}`}>
        <Button className={styles.dismissButton} onClick={onDismiss} varient="text">Dismiss all</Button>
      </div>
    </Stack>
  )
}

NotificationHeadCard.propTypes = {
  notificationsNumber: PropTypes.number.isRequired,
  onDismiss: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default NotificationHeadCard
