export const pluralize = (number, singular, plural, plural5) => {
  const lastDigit = number % 10
  return (lastDigit === 1 && number % 100 !== 11) ?
    singular :
      ((lastDigit >= 5 || lastDigit === 0) ? (plural5 || plural) : plural)
}

export const clearDiacritics = text => {
  return text
    .replace(/č/gi, 'c')
    .replace(/ć/gi, 'c')
    .replace(/š/gi, 's')
    .replace(/đ/gi, 'dj')
    .replace(/ž/gi, 'z')
}

export const noop = () => {}
