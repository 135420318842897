import React from 'react'
import PropTypes from 'prop-types'

import { noop } from 'utils/language'

import styles from './Marker.module.scss'

const Marker = ({
  tooltip,
  className,
  $hover,
}) => {
  return (
    <>
      <div
        className={`${styles.circle} ${$hover ? styles.hover : ''} ${className}`}
        onClick={noop}
      />
      {
        $hover &&
        tooltip
      }
    </>
  )
}
Marker.propTypes = {
  tooltip: PropTypes.node,
  $hover: PropTypes.bool,
  className: PropTypes.string,
}

export default Marker
