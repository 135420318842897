import moment from 'moment'

export function periodsInForm(item) {
  return item && {
    id: item.id,
    name: item.name,
    from: moment(item.fromDate),
    to: moment(item.toDate),
  }
}

export function clustersInForm(item) {
  return item && {
    id: item.clusterId,
    name: item.clusterName,
  }
}

export function KPIsInForm(item, compare) {
  if (compare) {
    return item && {
      assortment: {
        length: item.assortmentLength,
        recommendedLength: item.assortmentRecommendedLength,
        deviation: item.assortmentDeviation * 100,
      },
      revenue: {
        amount: item.revenueAmount,
        share: item.revenueShare * 100,
      },
      margin: {
        amount: item.marginAmount,
        shareInSales: item.marginShareInSales * 100,
        shareInMargin: item.marginShareInMargin * 100,
      },
      quantity: {
        units: item.quantityUnits,
        share: item.quantityShare * 100,
      },
      baskets: {
        basketNumber: item.basketsBasketNumber,
        averageRevenue: item.basketsAverageRevenue,
        averageUnits: item.basketsAverageUnits * 100,
      },
      specialSkus: {
        share: 100 * item.specialSkusShare,
        newSkus: item.specialSkusNewSkus,
        delistedSkus: item.specialSkusDelistedSkus,
      },
    }
  }
  return item && {
    assortment: {
      length: item.assortmentLength,
      recommendedLength: item.assortmentRecommendedLength,
      deviation: item.assortmentDeviation,
    },
    revenue: {
      amount: item.revenueAmount,
      share: item.revenueShare,
    },
    margin: {
      amount: item.marginAmount,
      shareInSales: item.marginShareInSales,
      shareInMargin: item.marginShareInMargin,
    },
    quantity: {
      units: item.quantityUnits,
      share: item.quantityShare,
    },
    baskets: {
      basketNumber: item.basketsBasketNumber,
      averageRevenue: item.basketsAverageRevenue,
      averageUnits: item.basketsAverageUnits,
    },
    specialSkus: {
      share: 0,
      newSkus: 0,
      delistedSkus: 0,
    },
  }
}

export function categoriesInForm(item) {
  return item && {
    id: item.id,
    name: item.name,
    recommendedLength: item.assortmentRecommendedLength ?? 0,
    deviation: item.assortmentDeviation ?? 0,
    revenue: item.revenueAmount ?? 0,
    revenueShare: item.revenueShare ?? 0,
    unitsSold: item.quantityUnits ?? 0,
    salesShare: item.salesShare ?? 0,
    baskets: item.basketsBasketNumber ?? 0,
    basketShare: item.basketsShare ?? 0,
  }
}

export function subcategoriesTotalInForm(item) {
  return item && {
    deviation: item.assortmentDeviation ?? 0,
    revenue: item.revenueAmount ?? 0,
    revenueShare: item.revenueShare ?? 0,
    unitsSold: item.quantityUnits ?? 0,
    salesShare: item.salesShare ?? 0,
    baskets: item.basketsBasketNumber ?? 0,
    basketShare: item.basketsShare ?? 0,
  }
}

export function skusInForm(item) {
  return item && {
    id: item.id,
    name: item.name,
    recommendedLength: item.recommendedLength ?? 0,
    deviation: item.deviation ?? 0,
    revenue: item.revenue ?? 0,
    revenueShare: item.revenueShare ?? 0,
    unitsSold: item.unitsSold ?? 0,
    salesShare: item.salesShare ?? 0,
    baskets: item.baskets ?? 0,
    basketShare: item.basketShare ?? 0,
  }
}

export function skusTotalInForm(item) {
  return item && {
    deviation: item.deviation ?? 0,
    revenue: item.revenue ?? 0,
    revenueShare: item.revenueShare ?? 0,
    unitsSold: item.unitsSold ?? 0,
    salesShare: item.salesShare ?? 0,
    baskets: item.baskets ?? 0,
    basketShare: item.basketShare ?? 0,
  }
}
