import React from 'react'
import PropTypes from 'prop-types'
import Slider from '@mui/material/Slider'
import TextField from '@mui/material/TextField'
import InputAdornment from '@mui/material/InputAdornment'

import { P } from 'components/ui/Typography'

import styles from './SliderEditor.module.scss'

const percentRegex = /^$|^0$|^[1-9][0-9]?$|^100$/

/**
 * To set color of a slider use mixins:
 *  - sliderDisabledColor($color)
 *  - sliderDisabledColor($color)
 */
function SliderEditor({ disabled, label, value, onChange, className }) {
  return (
    <div className={`flex-row-center ${styles.container} ${className || ''}`}>
      <P className={`${styles.label} ${disabled ? styles.disabled : ''}`}>{label}</P>
      <Slider
        className={`${styles.slider} slider ${disabled ? styles.disabled : ''} ${disabled ? 'disabled' : ''}`}
        valueLabelDisplay="on"
        disabled={disabled}
        value={value}
        min={0}
        max={100}
        step={1}
        onChange={
          ({ target: { value } }) => {
            onChange(+value)
          }
        }
      />
      <TextField
        className={`${styles.textField} textField ${disabled ? styles.disabled : ''} ${disabled ? 'disabled' : ''}`}
        value={value}
        size="small"
        autoComplete="off"
        onChange={
          ({ target: { value } }) => {
            if (percentRegex.test(value)) {
              onChange(+value)
            }
          }
        }
        disabled={disabled}
        InputProps={
          {
            inputProps: { min: 0, max: 100 },
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }
        }
      />
    </div>
  )
}

SliderEditor.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default SliderEditor
