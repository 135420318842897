import api from 'services/api'
import { pass } from 'utils/flow'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const fetchCategories = (search) => dispatch => {
  return api.organization.getCategories(false, search)
    .then(data => pass(data, () => dispatch(fetchCategoriesSuccess(data))))
}
const fetchCategoriesSuccess = (data) => ({ type: types.fetchCategoriesSuccess, data })

export const fetchUnassignedCategories = (search) => dispatch => {
  return api.organization.getCategories(true, search)
    .then(data => pass(data, () => dispatch(fetchUnassignedCategoriesSuccess(data))))
}
const fetchUnassignedCategoriesSuccess = (data) => ({ type: types.fetchUnassignedCategoriesSuccess, data })

export const fetchConfig = () => dispatch => {
  return api.organization.getConfigs()
    .then(data => pass(data, () => dispatch(fetchConfigSuccess(data))))
}
const fetchConfigSuccess = (data) => ({ type: types.fetchConfigSuccess, data })

export const fetchQuotas = () => dispatch => {
  return api.organization.getQuotas()
    .then(data => pass(data, () => dispatch(fetchQuotasSuccess(data))))
}
const fetchQuotasSuccess = (data) =>
  ({ type: types.fetchQuotasSuccess, data })
