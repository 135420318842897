import React from 'react'
import PropTypes from 'prop-types'

import { H6 } from 'components/ui/Typography'
import BaseAssortmentCard from 'components/ui/AssortmentCards/BaseAssortmentCard'
import { sixDigitsNumberFormat } from 'utils/numberFormat'

import styles from '../AssortmentCards.module.scss'

const RevenueMarginQuantityAssortmentCard = ({ data, className }) => {
  const { code, title, info, expectedGrowth, previousPeriodValue, expectedValue } = data

  return (
    <BaseAssortmentCard title={title} info={info} className={`${className || ''} ${styles.card} ${expectedValue === 0 ? styles.emptyMargin : ''}`}>
      <div className={`flex-row-center ${styles.row}`}>
        <div className={`${styles.bigNumber} ${code}`}>{expectedGrowth}%</div>
        <H6>expected growth</H6>
      </div>

      <div className={styles.footer}>
        <H6>{title} previous period: {sixDigitsNumberFormat(previousPeriodValue)}</H6>
        <H6>Expected {title}: {sixDigitsNumberFormat(expectedValue)}</H6>
      </div>
    </BaseAssortmentCard>
  )
}

RevenueMarginQuantityAssortmentCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    expectedGrowth: PropTypes.number,
    previousPeriodValue: PropTypes.number,
    expectedValue: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
}

export default RevenueMarginQuantityAssortmentCard
