import { useCallback, useEffect, useState } from 'react'

const useSettledState = (initialState, settleTime = 500) => {
  const [value, setValue] = useState({
    value: initialState,
    settled: true,
  })

  useEffect(() => {
    if (!value.settled) {
      const id = setTimeout(() => {
        setValue(state => ({
          ...state,
          settled: true,
        }))
      }, settleTime)

      return () => clearTimeout(id)
    }
  }, [settleTime, value])

  const setSettledValue = useCallback((v) => {
    if (typeof v === 'function') {
      setValue(state => {
        const newState = v(state.value)
        return {
          value: newState,
          settled: false,
        }
      })
    } else {
      setValue({
        value: v,
        settled: false,
      })
    }
  }, [])

  return [value, setSettledValue]
}

export default useSettledState
