import React from 'react'
import TextField from '@mui/material/TextField'
import PropTypes from 'prop-types'

import { SearchIcon } from 'assets/icons'

import styles from './SearchInput.module.scss'

const SearchInput = ({ className, onChange, ...props }) => {
  return (
    <TextField
      size="small"
      className={`${styles.input} ${className || ''}`}
      placeholder="Search"
      InputProps={
        {
          startAdornment: (
            <SearchIcon className={`icon small ${styles.icon}`} />
          ),
        }
      }
      onChange={onChange}
      {...props}
    />
  )
}
SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
}

export default SearchInput
