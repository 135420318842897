import React, { createContext, useMemo, useState } from 'react'

import customPropTypes from 'utils/customPropTypes'
import useBooleanState from 'hooks/useBooleanState'

export const AppLayoutContext = createContext(null)

function AppLayoutProvider({ children }) {
  const [isContextDrawerOpen, openContextDrawer, closeContextDrawer, toggleContextDrawer] = useBooleanState(false)
  const [contextDrawerContent, setContextDrawerContent] = useState(null)

  const providerValue = useMemo(() => ({
    isContextDrawerOpen,
    contextDrawerContent,
    setContextDrawerContent,
    openContextDrawer,
    closeContextDrawer,
    toggleContextDrawer,
  }), [closeContextDrawer, contextDrawerContent, isContextDrawerOpen, openContextDrawer, toggleContextDrawer])

  return (
    <AppLayoutContext.Provider value={providerValue}>
      {children}
    </AppLayoutContext.Provider>
  )
}

AppLayoutProvider.propTypes = {
  children: customPropTypes.children,
}

export default AppLayoutProvider
