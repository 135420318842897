import LRU from 'lru-cache'

import { hour } from 'services/api/common'

const cache = {
  default: new LRU({
    max: 20,
    maxAge: 12 * hour,
    updateAgeOnGet: true,
  }),
}

export default cache
