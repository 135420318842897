import React, { useCallback, useEffect, useState } from 'react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router'

import NoResults from 'components/ui/NoResults'
import { OptimizationStatus, ReviewStatus, Role } from 'services/enums'
import { ConfigureIcon } from 'assets/icons'
import useData from 'hooks/useData'
import usePager from 'hooks/usePager'
import usePrevious from 'hooks/usePrevious'
import useContextDrawer from 'hooks/useContextDrawer'
import CategoryReviewStatus from 'pages/optimizations/CategoryReviewStatus'
import RecommendedAssortmentFilter from 'pages/optimizations/RecommendedAssortmentFilter'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import TablePaginationButtons from 'components/ui/TablePaginationButtons'
import { H1, H2 } from 'components/ui/Typography'
import useBooleanState from 'hooks/useBooleanState'
import useDebounceCallback from 'hooks/useDebounceCallback'
import Suspended from 'components/ui/Suspended'
import NewDraft from 'pages/NewDraft'

import ConfigureCategoryProperties from './ConfigureCategoryProperties'
import ConfigureSubcategoryProperties from './ConfigureSubcategoryProperties'
import RecommendedAssortmentFilters from './RecommendedAssortmentFilters'
import RecommendedAssortmentTable from './RecommendedAssortmentTable'
import styles from './RecommendedAssortment.module.scss'

const RecommendedAssortment = ({ clusterId, storeId, isAdmin }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [category, setCategory] = useState(null)
  const [subcategory, setSubcategory] = useState(null)
  const [search, setSearch] = useState('')
  const [producer, setProducer] = useState(null)
  const [brands, setBrands] = useState([])
  const [showRemovedSkusChecked, setShowRemovedSkusChecked] = useState(true)
  const [configureDialogOpen, openConfigureDialog, closeConfigureDialog] = useBooleanState(false)
  const [isNewDraftModalOpen, openNewDraftModal, closeNewDraftModal] = useBooleanState(false)
  const [skuRowDisabled, setSkuRowDisabled] = useState(null)
  const [skuRowConfig, setSkuRowConfig] = useState(null)
  const optimization = useSelector(state => state.optimizations.optimization)
  const categoryManagers = useSelector(selectors.optimizations.categoryManagers)
  const role = useSelector(state => state.users?.profile?.role)

  const optimizationId = optimization?.id
  const readOnly = optimization?.status === OptimizationStatus.finalized
  const isDraft = optimization?.status === OptimizationStatus.draft

  const openContextDrawer = useContextDrawer(() => (
    <RecommendedAssortmentFilter
      onApply={(filter) => { setProducer(filter.producer); setBrands(filter.brands) }}
      categoryId={category?.id}
      subcategoryId={subcategory?.id}
      optimizationId={optimization?.id}
      initialValues={{ producer, brands }}
    />
  ), [category, optimization, subcategory, producer, brands])

  useEffect(() => {
    if (role === Role.admin) {
      dispatch(actions.optimizations.fetchApprovals(optimizationId))
    }
  }, [dispatch, optimizationId, role])

  useEffect(() => {
    // reset drawer filters
    setProducer(null)

    // avoid +1 rerender (when it is already empty do not create new empty array object as that triggers rerender)
    setBrands(val => val?.length ? [] : val)
  }, [category, subcategory, optimization?.id])

  const { loaded, error } = useData(() => {
    return dispatch(actions.optimizations.fetchCategories(optimizationId, clusterId, storeId))
      .then(categories => {
        return setCategory(categories?.[0])
      })
  }, [clusterId, dispatch, optimizationId, storeId])

  const prevValues = usePrevious({ category, subcategory, optimization })
  const { data: skuData, loaded: loadedSkus, error: errorSkus } = useData(() => {
    if (!category || !subcategory) {
      return Promise.reject()
    }

    if (((!prevValues.category || !prevValues.subcategory) || (category.id === prevValues.category?.id && subcategory.id !== prevValues.subcategory?.id))) {
      return dispatch(actions.optimizations.fetchSkus(
        optimizationId,
        clusterId,
        storeId,
        category.id,
        subcategory.id,
        showRemovedSkusChecked,
        producer?.id,
        brands?.filter(b => b.checked).map(b => b.id),
        search,
      ))
    } else {
      return Promise.resolve(prevValues.optimization?.skus)
    }
  }, [
    prevValues,
    category,
    clusterId,
    dispatch,
    optimizationId,
    producer,
    brands,
    search,
    showRemovedSkusChecked,
    storeId,
    subcategory,
  ])

  const {
    previous: previousSkus,
    next: nextSkus,
    hasNext: hasNextSkus,
    hasPrevious: hasPreviousSkus,
    loaded: skusPageLoaded,
  } = usePager(skuData, actions.optimizations.fetchSkusSuccess)

  useEffect(() => {
    if (category) {
      return dispatch(actions.optimizations.fetchSubcategories(optimizationId, clusterId, storeId, category.id))
        .then(subcategories => {
          return setSubcategory(subcategories?.[0])
        })
    }
  }, [category, clusterId, dispatch, optimizationId, storeId])

  const updateSearch = useDebounceCallback(({ target: { value } }) => {
    setSearch(value)
  }, [])

  const handleLock = useCallback((skuId, locked) => {
    if (isDraft) {
      setSkuRowDisabled(skuId)
      return dispatch(actions.optimizations.setSkuLockFlag(optimizationId, clusterId, skuId, locked))
        .finally(() => setSkuRowDisabled(null))
    } else {
      setSkuRowConfig({ skuId, locked })
      openNewDraftModal()
    }
  }, [clusterId, dispatch, isDraft, openNewDraftModal, optimizationId])

  const handleRestore = useCallback((skuId, present) => {
    setSkuRowDisabled(skuId)
    return dispatch(actions.optimizations.setSkuPresence(optimizationId, clusterId, skuId, present))
      .finally(() => setSkuRowDisabled(null))
  }, [clusterId, dispatch, optimizationId])

  const handleNewDraftCreated = useCallback((newOptimizationId) => {
    const { skuId, locked } = skuRowConfig
    setSkuRowDisabled(skuId)
    return dispatch(actions.optimizations.setSkuLockFlag(newOptimizationId, clusterId, skuId, locked))
      .then(() => {
        setSkuRowDisabled(null)
        return navigate(`/assortment-optimization/details/${newOptimizationId}`)
      })
  }, [clusterId, dispatch, navigate, skuRowConfig])

  return (
    <div className={`flex-col-center ${styles.RecommendedAssortment}`}>
      <H1>Recommended Assortment</H1>
      {
        loaded ?
          <RecommendedAssortmentFilters
            showRemovedSkusChecked={showRemovedSkusChecked}
            setShowRemovedSkusChecked={setShowRemovedSkusChecked}
            openContextDrawer={openContextDrawer}
            category={category}
            setCategory={setCategory}
            categories={optimization?.categories}
            subcategory={subcategory}
            setSubcategory={setSubcategory}
            subcategories={optimization?.subcategories}
            updateSearch={updateSearch}
          /> :
          <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }

      <div className={`flex-col-center ${styles.assortmentTableContainer}`}>
        <div className={styles.tableTopContainer}>
          <div className="flex-row-center">
            <Tooltip title={clusterId ? '' : 'Category settings only available on cluster level'}>
              <span>
                <IconButton
                  onClick={openConfigureDialog}
                  className={styles.configButton}
                  disabled={!(clusterId)}
                >
                  {optimization.status.isEditable ? <ConfigureIcon className="icon normal" /> : <VisibilityOutlinedIcon fontSize='large' />}
                </IconButton>
              </span>
            </Tooltip>
            <H2>{category?.name} - {subcategory?.name}</H2>
          </div>
          {
            category && categoryManagers && categoryManagers[category.id] && isAdmin &&
              <CategoryReviewStatus
                manager={categoryManagers[category.id]?.manager || ''}
                status={categoryManagers[category.id]?.status || ReviewStatus.pending}
              />
          }
        </div>

        {
          skuData ?
              !skuData.length ?
                <NoResults
                  title={'No results found'}
                /> :
                <TableContainer component={Paper} className={loadedSkus ? styles.tableContainer : styles.opacityTable}>
                  <RecommendedAssortmentTable
                    subcategory={subcategory}
                    skus={optimization?.skus}
                    readOnly={readOnly}
                    readOnlyLock={!isAdmin}
                    onLock={handleLock}
                    onRestore={handleRestore}
                    skuRowDisabled={skuRowDisabled}
                    allClusters={!clusterId}
                    isAdmin={isAdmin}
                  />
                  <TablePaginationButtons
                    nextPage={nextSkus}
                    previousPage={previousSkus}
                    hasNextPage={hasNextSkus}
                    pageLoaded={skusPageLoaded}
                    hasPreviousPage={hasPreviousSkus}
                  />
                </TableContainer> :
                <SectionLoadingIndicator className={styles.loader} error={!!errorSkus} />
        }
      </div>

      <Suspended open={configureDialogOpen}>
        {
          isAdmin ?
            <ConfigureCategoryProperties
              open={configureDialogOpen}
              onClose={closeConfigureDialog}
              optimizationId={optimizationId}
              clusterId={clusterId}
              category={category}
              readOnly={!optimization.status.isEditable}
            /> :
            <ConfigureSubcategoryProperties
              open={configureDialogOpen}
              onClose={closeConfigureDialog}
              optimizationId={optimizationId}
              clusterId={clusterId}
              category={category}
              readOnly={!optimization.status.isEditable}
            />
        }
      </Suspended>

      <Suspended open={isNewDraftModalOpen}>
        <NewDraft
          open={isNewDraftModalOpen}
          onCreate={handleNewDraftCreated}
          onClose={closeNewDraftModal}
        />
      </Suspended>
    </div>
  )
}
RecommendedAssortment.propTypes = {
  clusterId: PropTypes.number,
  storeId: PropTypes.number,
  isAdmin: PropTypes.bool,
}

export default RecommendedAssortment
