import { styled } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { tooltipClasses } from '@mui/material'
import React from 'react'

const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    height: '100%',
  },
})

export default CustomTooltip
