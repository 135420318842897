import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from '@apollo/client'
import { createAuthLink } from 'aws-appsync-auth-link'
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link'
import configEnv from 'config'

import { config } from './common'

const authorization = {
  type: 'AMAZON_COGNITO_USER_POOLS',
  jwtToken: async() => {
    const token = config.accessToken
    return `Bearer ${token}`
  },
}

const region = configEnv.region
const graphQLUri = configEnv.notificationGraphqlApi

const httpLink = new HttpLink({ uri: graphQLUri })

const apolloLink = ApolloLink.from([
  createAuthLink({ url: graphQLUri, region, auth: authorization }),
  createSubscriptionHandshakeLink({ url: graphQLUri, region, auth: authorization }, httpLink),
])

export const apolloClient = new ApolloClient({
  link: apolloLink,
  cache: new InMemoryCache(),
})
