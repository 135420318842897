import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'
import { ClustersConfigStatus } from 'services/enums'

import * as types from './types'

const initialState = frozen({
  latestClustersConfig: null,
  clustersConfig: null,
  dimensions: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.fetchLatestClustersConfigSuccess]: fetchLatestClustersConfigSuccess,
  [types.fetchClustersSuccess]: fetchClustersSuccess,
  [types.fetchClusterDimensionsSuccess]: fetchClusterDimensionsSuccess,
  [types.fetchClusterStoresSuccess]: fetchClusterStoresSuccess,
  [types.confirmClustersSuccess]: confirmClustersSuccess,
}

function vacate(state) {
  return {
    ...state,
    latestClustersConfig: null,
    clustersConfig: null,
    dimensions: null,
  }
}

function fetchLatestClustersConfigSuccess(state, action) {
  return {
    ...state,
    latestClustersConfig: action.data,
  }
}

function fetchClustersSuccess(state, action) {
  return {
    ...state,
    clustersConfig: action.data,
  }
}

function confirmClustersSuccess(state) {
  return {
    ...state,
    clustersConfig: {
      ...state.clustersConfig,
      status: ClustersConfigStatus.active,
      confirmed: true,
    },
  }
}

function fetchClusterDimensionsSuccess(state, action) {
  return {
    ...state,
    dimensions: action.data,
  }
}

function fetchClusterStoresSuccess(state, action) {
  return {
    ...state,
    clustersConfig: {
      ...state.clustersConfig,
      clusters: state.clustersConfig.clusters
        .map((cluster, index) => cluster.id !== action.clusterId ?
          cluster :
            {
              ...cluster,
              stores: action.data.map(store => ({
                ...store,
                clusterIndex: index,
              })),
            }),
    },
  }
}

export default objectReducer(initialState, actionHandlers)
