import React from 'react'

import NoMatchFound from 'components/ui/NoMatchFound/NoMatchFound'
import noMatchImage from 'assets/no-match-page.png'

import styles from './Unauthorized.module.scss'

function Unauthorized() {
  return (
    <NoMatchFound
      titleText={'Sorry!'}
      descriptionText={'Unauthorized page'}
      image={noMatchImage}
      className={styles.text}
    />
  )
}

Unauthorized.propTypes = {}

export default Unauthorized
