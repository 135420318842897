import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectors } from 'store'

import { ClustersConfigStatus } from 'services/enums'

import styles from './Home.module.scss'

function Home() {
  const navigate = useNavigate()
  const latestClusterConfigStatus = useSelector(state => state.clusters.latestClustersConfig?.status)
  const singleOptimization = useSelector(selectors.optimizations.singleOptimization)
  const hasOptimizations = useSelector(selectors.optimizations.hasOptimizations)

  useEffect(() => {
    if (latestClusterConfigStatus === ClustersConfigStatus.pending ||
      (latestClusterConfigStatus === ClustersConfigStatus.active && !hasOptimizations)) {
      return navigate('/clusters')
    }

    if (singleOptimization) {
      return navigate(`/assortment-optimization/details/${singleOptimization.id}`)
    }

    return navigate('/assortment-optimization/all')
  }, [navigate, latestClusterConfigStatus, singleOptimization, hasOptimizations])

  return (
    <div className={styles.Home}>
    </div>
  )
}

export default Home
