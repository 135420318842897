import React from 'react'
import CloseIcon from '@mui/icons-material/Close'
import PropTypes from 'prop-types'

import { H4 } from 'components/ui/Typography'
import customPropTypes from 'utils/customPropTypes'

import styles from './Dialog.module.scss'
import ModalContainer from './ModalContainer'

const Dialog = ({ open, onClose, title, children, className }) => {
  return (
    <ModalContainer title={title} open={open} onClose={onClose} className={className}>
      <CloseIcon onClick={onClose} className={styles.closeIcon} />
      <H4 className={`${styles.title} title`}>{title}</H4>
      {children}
    </ModalContainer>
  )
}
Dialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: customPropTypes.children,
  className: PropTypes.string,
}

export default Dialog
