import axios from 'axios'
import config from 'config'
import store, { actions } from 'store'
import Qs from 'qs'

import log from 'utils/log'
import { ApiError, ValidationError } from 'services/errors'
import { camelCaseKeysDeep, isEmpty } from 'utils/object'
import { counter } from 'utils/generators'
import { errorToast } from 'components/ui/Toast'

import api from './index'
const axiosInstance = axios.create({
  baseURL: config.apiBaseUrl,
  validateStatus: () => true,
  paramsSerializer: params => Qs.stringify(params, { arrayFormat: 'repeat' }),
})

const requestCounter = counter()

_addResponseInterceptors()

function _addResponseInterceptors() {
  axiosInstance.interceptors.request.use(
    handleRequest,
  )
  axiosInstance.interceptors.response.use(
    handleResponse, printResponse,
  )

  function handleRequest(req) {
    req = {
      ...req,
      _sequence: requestCounter.next().value,
      params: removeUnusedParams(req.params),
    }

    printRequest(req)

    return req
  }

  function handleResponse(res) {
    printResponse(res)

    // If session has changed until response arrived (logout happened)
    if (res?.config?.headers?.Authorization &&
      axiosInstance.defaults?.headers?.common?.Authorization &&
      res?.config?.headers?.Authorization !== axiosInstance.defaults?.headers?.common?.Authorization
    ) {
      log.info(() => ['Ignoring the response for obsolete session.', {
        oldAuthorization: res?.config?.headers?.Authorization,
        newAuthorization: axiosInstance.defaults?.headers?.common?.Authorization,
      }])
      return Promise.reject(new ApiError('Obsolete data.', 500))
    }

    const { data, status, config: conf } = res
    if (is2xx(status)) {
      if (!data?.data) {
        return data
      }
      const resp = camelCaseKeysDeep(data.data)
      if (Array.isArray(resp)) {
        resp._meta = {
          lastId: data.lastEvaluatedKey,
        }
      }
      return resp
    } else if (status === 422) {
      return Promise.reject(new ValidationError(transformValidationError(data)))
    } else if (status === 401) {
      if (res?.config?.headers?.Authorization) {
        return api.auth.refreshAccessToken()
          .then(async(res) => {
            store.dispatch(actions.auth.restoreApiAccessToken(res))
            conf.headers.Authorization = `Bearer ${res}`
            return axiosInstance.request(conf)
          })
      }
      return Promise.reject(new ApiError('User not authenticated.', status))
    } else if (status === 403) {
      return Promise.reject(new ApiError('Request not authorized.', status))
    } else if (status === 404) {
      return Promise.reject(new ApiError('Not found', status))
    } else if (status === 409) {
      return Promise.reject(new ApiError('Conflict.', status, data))
    } else {
      errorToast('Unexpected error occurred. Please contact support.')
      return Promise.reject(new ApiError(data.message, status))
    }
  }

  function printResponse(res) {
    log.debug(() => [':::::::::: API RESPONSE ::::::::::\n', {
      status: res.status,
      endpoint: res.config?.method + ' ' + res.config?.url,
      sequence: res.config._sequence,
      response: subset(res.data),
    }])
  }

  function printRequest(req) {
    log.debug(() => [':::::::::: API REQUEST ::::::::::\n', {
      endpoint: req.method + ' ' + req.url,
      params: req.params,
      headers: req.headers?.common,
      sequence: req._sequence,
      data: subset(req.data),
    }])
  }

  function removeUnusedParams(params) {
    return Object.entries(params || {})
      .reduce((acc, [prop, value]) => {
        if (isEmpty(value)) {
          return acc
        }

        return {
          ...acc,
          [prop]: value,
        }
      }, {})
  }

  function subset(data) {
    const res = {
      ...data,
    }
    if (data?.Data?.length > 3) {
      res.TotalDataLength = data?.Data?.length
      res.Data = data.Data.slice(0, 3)
    }
    return res
  }

  function is2xx(code) {
    return code >= 200 && code < 300
  }

  function transformValidationError(error) {
    return camelCaseKeysDeep(error.data)
  }
}

export default axiosInstance
