import React, { useEffect, useState, memo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'

import 'utils/promise'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import useData from 'hooks/useData'
import EditClusters from 'pages/clusters/Clusters/EditClusters'
import useToast from 'components/ui/Toast/useToast'
import api from 'services/api'
import ConfirmClusters from 'pages/clusters/Clusters/ConfirmClusters'
import OperationInProgressModal from 'components/ui/OperationProgressModal'
import { P } from 'components/ui/Typography'
import useBooleanState from 'hooks/useBooleanState'

import ClusterDimensions from './ClusterDimensions'
import ClusterCharts from './ClusterCharts/ClusterCharts'
import ClusterFilters from './ClusterFilters'
import ClusterMap from './ClusterMap'
import styles from './Clusters.module.scss'

const MapMemo = memo(ClusterMap)

const Clusters = () => {
  const dispatch = useDispatch()
  const { successToast, errorToast } = useToast()

  useEffect(() => {
    dispatch(actions.clusters.fetchLatestClustersConfig())
  }, [dispatch])
  const latestClustersConfigId = useSelector(state => state.clusters.latestClustersConfig?.id)

  const hasOptimizations = useSelector(selectors.optimizations.hasOptimizations)
  const clusterConfirmed = useSelector(state => state.clusters.clustersConfig?.confirmed)

  const { loaded, error } = useData(() => {
    if (!latestClustersConfigId) {
      return Promise.never()
    }
    return Promise.all([
      dispatch(actions.clusters.fetchClustersWithStores(latestClustersConfigId)),
      dispatch(actions.clusters.fetchClusterDimensions(latestClustersConfigId)),
    ])
  }, [dispatch, latestClustersConfigId])

  const allStores = useSelector(selectors.clusters.allStores)

  const [editClusterModalOpen, openEditClusterModal, closeEditClusterModal] = useBooleanState(false)
  const [confirmClusterModalOpen, openConfirmClusterModal, closeConfirmClusterModal] = useBooleanState(false)
  const [filteredStores, setFilteredStores] = useState([])

  useEffect(() => {
    if (loaded && allStores) {
      setFilteredStores(allStores)
    }
  }, [allStores, loaded])

  const handleExport = useCallback(() => {
    closeEditClusterModal()
    api.clusters.downloadClusters(latestClustersConfigId)
  }, [closeEditClusterModal, latestClustersConfigId])

  const handleConfirm = useCallback(() => {
    dispatch(actions.clusters.confirmClusters(latestClustersConfigId))
      .then(closeConfirmClusterModal)
      .catch(e => {
        errorToast(
          `Error while confirming clusters configuration: ${e.message}`,
          { horizontal: 'center', vertical: 'bottom' },
        )
      })
  }, [closeConfirmClusterModal, dispatch, errorToast, latestClustersConfigId])

  const onSelectionChange = ({ cluster, store }) => {
    if (store) {
      setFilteredStores([store])
    } else if (cluster) {
      setFilteredStores(cluster.stores)
    } else {
      setFilteredStores(allStores)
    }
  }

  const onUpload = file => {
    closeEditClusterModal()
    // todo: handle form errors
    api.clusters.updateClusters(latestClustersConfigId, file)
      .then(() => {
        successToast('Clusters configuration successfully updated.')
        return Promise.all([
          dispatch(actions.clusters.fetchClustersWithStores(latestClustersConfigId)),
          dispatch(actions.clusters.fetchClusterDimensions(latestClustersConfigId)),
        ])
      })
      .catch(e => {
        errorToast(
          `Error while updating clusters configuration: ${e.message}`,
          { horizontal: 'center', vertical: 'bottom' },
        )
      })
  }

  return (
    <div className={`flex-col-center ${styles.Clusters}`}>
      {
        loaded ?
          <ClusterFilters
            onExport={handleExport} onEdit={openEditClusterModal} onConfirm={openConfirmClusterModal}
            onSelectionChange={onSelectionChange} className={styles.clusterFilters}
          /> :
          <SectionLoadingIndicator className={styles.clusterFilters} error={!!error} />
      }
      {
        loaded ?
          <MapMemo stores={filteredStores} /> :
          <SectionLoadingIndicator className={styles.mapsLoader} error={!!error} />
      }
      {
        loaded ?
          <ClusterCharts className={styles.charts} /> :
          <SectionLoadingIndicator className={styles.charts} error={!!error} />
      }

      {
        loaded ?
          <ClusterDimensions /> :
          <SectionLoadingIndicator className={styles.dimensionsLoader} error={!!error} />
      }

      {
        loaded &&
          <>
            <EditClusters
              openModal={editClusterModalOpen}
              onClose={closeEditClusterModal}
              onUpload={onUpload}
              onExport={handleExport}
            />
            <ConfirmClusters
              openModal={confirmClusterModalOpen}
              onClose={closeConfirmClusterModal}
              onConfirm={handleConfirm}
            />
          </>
      }

      {
        clusterConfirmed && !hasOptimizations &&
          <OperationInProgressModal
            open={true}
            title="Optimization is in the process"
          >
            <P>
              Optimization is in the process.
            </P>
            <P>
              You will receive a notification when the optimized assortment is ready for your revision.
            </P>
          </OperationInProgressModal>
      }
    </div>
  )
}

export default Clusters
