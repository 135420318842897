import React from 'react'
import { useParams } from 'react-router'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

import useData from 'hooks/useData'
import { H4, P } from 'components/ui/Typography'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'

import styles from './RegistrationVerificationSuccess.module.scss'

const VerificationSuccess = () => {
  const dispatch = useDispatch()
  const { token: verificationToken } = useParams()
  const { loaded, error } = useData(() => {
    dispatch(actions.auth.verifyLink(verificationToken))
  }, [dispatch, verificationToken])

  return (
    <div className={`flex-col-center ${styles.verificationSuccess}`}>
      {
        loaded ?
          <>
            <H4 className={styles.title}> Please wait until your account is set up! </H4>
            <P>Email verification is successful! We will send you email when your account is set up so you can log in.</P></> :
          <SectionLoadingIndicator className={styles.linkVerification} error={!!error} />
      }
    </div>
  )
}
export default VerificationSuccess
