import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'

import useBooleanState from 'hooks/useBooleanState'
import { P } from 'components/ui/Typography'
import { UserStatus } from 'services/enums'
import { LinkIcon } from 'assets/icons'
import { cls } from 'utils/react'
import UserApproval from 'components/ui/UsersTable/UserRow/UserApproval'
import Suspended from 'components/ui/Suspended'

import styles from './UserStatusLabel.module.scss'

const UserStatusLabel = ({ user, onResend, onConfirm }) => {
  const [isConfirmationDialogOpen, openConfirmationDialog, closeConfirmationDialog] = useBooleanState(false)

  const classes = [
    user.status === UserStatus.pendingAccess && styles.pendingAccess,
    user.status === UserStatus.waitingConfirmation && styles.waitingConfirm,
  ]

  const handleResend = useCallback(() => {
    onResend(user.id)
  }, [onResend, user.id])

  return (
    <div className={cls(classes)}>
      <P>{user.status.label}</P>
      {
        user.status === UserStatus.pendingAccess &&
          <IconButton onClick={handleResend}>
            <LinkIcon className="icon normal" />
          </IconButton>
      }
      {
        user.status === UserStatus.waitingConfirmation &&
          <Button onClick={openConfirmationDialog} className={styles.confirmAccess} variant="outlined">
            Confirm access
          </Button>
      }
      <Suspended open={isConfirmationDialogOpen}>
        <UserApproval isOpen={isConfirmationDialogOpen} onClose={closeConfirmationDialog} user={user} onConfirm={onConfirm} />
      </Suspended>
    </div>
  )
}

UserStatusLabel.propTypes = {
  user: PropTypes.object.isRequired,
  onResend: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default UserStatusLabel
