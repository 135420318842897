import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  executionKeyIndicators: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.fetchExecutionPeriodsSuccess]: fetchExecutionPeriodsSuccess,
  [types.fetchExecutionClustersSuccess]: fetchExecutionClustersSuccess,
  [types.fetchExecutionClusterStoresSuccess]: fetchExecutionClusterStoresSuccess,
  [types.fetchExecutionKeyIndicatorsSuccess]: fetchExecutionKeyIndicatorsSuccess,
  [types.fetchExecutionTrackingCategoriesSuccess]: fetchExecutionTrackingCategoriesSuccess,
  [types.fetchExecutionTrackingSubcategoriesSuccess]: fetchExecutionTrackingSubcategoriesSuccess,
  [types.fetchExecutionTrackingSKUsSuccess]: fetchExecutionTrackingSKUsSuccess,
  [types.fetchExecutionTrackingCategoriesTotalSuccess]: fetchExecutionTrackingCategoriesTotalSuccess,
  [types.fetchExecutionTrackingSubcategoriesTotalSuccess]: fetchExecutionTrackingSubcategoriesTotalSuccess,
  [types.fetchExecutionTrackingSKUTotalSuccess]: fetchExecutionTrackingSKUTotalSuccess,
}

function vacate(state) {
  return {
    ...state,
    executionKeyIndicators: null,
  }
}

function fetchExecutionPeriodsSuccess(state, action) {
  return {
    ...state,
    executionPeriods: action.data,
  }
}

function fetchExecutionClustersSuccess(state, action) {
  return {
    ...state,
    executionClusters: action.data,
  }
}

function fetchExecutionClusterStoresSuccess(state, action) {
  return {
    ...state,
    executionClusters: state.executionClusters
      .map((cluster, index) => cluster.id !== action.clusterId ?
        cluster :
          {
            ...cluster,
            stores: action.data.map(store => ({
              ...store,
              clusterIndex: index,
            })),
          }),
  }
}

function fetchExecutionKeyIndicatorsSuccess(state, action) {
  return {
    ...state,
    executionKeyIndicators: action.data,
  }
}

function fetchExecutionTrackingCategoriesSuccess(state, action) {
  return {
    ...state,
    executionCategories: action.data,
  }
}

function fetchExecutionTrackingCategoriesTotalSuccess(state, action) {
  return {
    ...state,
    executionCategoriesTotal: action.data,
  }
}

function fetchExecutionTrackingSubcategoriesSuccess(state, action) {
  return {
    ...state,
    executionSubcategories: action.data,
  }
}

function fetchExecutionTrackingSubcategoriesTotalSuccess(state, action) {
  return {
    ...state,
    executionSubcategoriesTotal: action.data,
  }
}

function fetchExecutionTrackingSKUsSuccess(state, action) {
  return {
    ...state,
    executionSKUs: action.data,
  }
}

function fetchExecutionTrackingSKUTotalSuccess(state, action) {
  return {
    ...state,
    executionSkusTotal: action.data,
  }
}

export default objectReducer(initialState, actionHandlers)
