import React, { useEffect, useState } from 'react'

import clusterColours from 'pages/clusters/clusters.scss'
import Tooltip from 'components/Map/Tooltip'
import Map from 'components/Map'
import customPropTypes from 'utils/customPropTypes'

import styles from './ClusterMap.module.scss'

const numOfColours = Object.keys(clusterColours).length

const ClusterMap = ({ stores }) => {
  const [points, setPoints] = useState([])

  useEffect(() => {
    if (stores) {
      const mapPoints = []
      stores.forEach(store => {
        const clusterClassName = `clusterColor-${store.clusterIndex % numOfColours}`
        const address = `${store.address.line1} ${store.address.line2}, ${store.address.city}`
        mapPoints.push({
          id: store.id,
          lat: store.location.lat,
          lng: store.location.long,
          tooltip: <Tooltip title={store.name} body={address} />,
          className: clusterClassName,
        })
      })
      setPoints(mapPoints)
    }
  }, [stores])

  return (
    <div className={styles.ClusterMap}>
      <Map
        stores={points}
      />
    </div>
  )
}

ClusterMap.propTypes = {
  stores: customPropTypes.stores,
}

export default ClusterMap
