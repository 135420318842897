import api from 'services/api'
import { pass } from 'utils/flow'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const fetchOptimizations = ({
  search,
  dateFrom,
  dateTo,
  status,
  approvedBy,
  includeApprovals,
  pagerState,
}) => dispatch => {
  if (pagerState) {
    return api.optimizations.getOptimizations.restore(pagerState)
      .then(data => pass(data, () => dispatch(fetchOptimizationsSuccess(data))))
  }

  return api.optimizations.getOptimizations({
    search,
    dateFrom,
    dateTo,
    status,
    approvedBy,
    includeApprovals,
  })
    .then(data => pass(data, () => dispatch(fetchOptimizationsSuccess(data))))
}
export const fetchOptimizationsSuccess = (data) =>
  ({ type: types.fetchOptimizationsSuccess, data })

export const fetchOptimization = (optimizationId) => dispatch => {
  return api.optimizations.getOptimization(optimizationId)
    .then(data => pass(data, () => dispatch(fetchOptimizationSuccess(data))))
}
const fetchOptimizationSuccess = (data) =>
  ({ type: types.fetchOptimizationSuccess, data })

export const deleteOptimization = (optimizationId) => dispatch => {
  return api.optimizations.deleteOptimization(optimizationId)
    .then(() => dispatch(deleteOptimizationSuccess(optimizationId)))
}
const deleteOptimizationSuccess = (optimizationId) =>
  ({ type: types.deleteOptimizationSuccess, optimizationId })

export const fetchOptimizationKeyIndicators = (optimizationId, clusterId, storeId) => dispatch => {
  return api.optimizations.getKeyIndicators(optimizationId, clusterId, storeId)
    .then(data => pass(data, () => dispatch(fetchOptimizationKeyIndicatorsSuccess(data))))
}
const fetchOptimizationKeyIndicatorsSuccess = (data) =>
  ({ type: types.fetchOptimizationKeyIndicatorsSuccess, data })

export const fetchGoals = (optimizationId, clusterId) => dispatch => {
  return api.optimizations.getGoals(optimizationId, clusterId)
    .then(data => pass(data, () => dispatch(fetchGoalsSuccess(data))))
}
const fetchGoalsSuccess = (data) =>
  ({ type: types.fetchGoalsSuccess, data })

export const fetchLimits = (optimizationId, clusterId) => dispatch => {
  return api.optimizations.getLimits(optimizationId, clusterId)
    .then(data => pass(data, () => dispatch(fetchLimitsSuccess(data))))
}
const fetchLimitsSuccess = (data) =>
  ({ type: types.fetchLimitsSuccess, data })

export const setGoals = (optimizationId, clusterId, { revenue, margin, baskets }, applyToAllClusters) => dispatch => {
  return api.optimizations.setGoals(optimizationId, clusterId, { revenue, margin, baskets }, applyToAllClusters)
    .then(data => pass(({ data, revenue, margin, baskets }), () => dispatch(setGoalsSuccess({ data, revenue, margin, baskets }))))
}
const setGoalsSuccess = ({ data, revenue, margin, baskets }) =>
  ({ type: types.setGoalsSuccess, data, revenue, margin, baskets })

export const setLimits = (optimizationId, clusterId, { skuNumber, innovationSkuNumber }) => dispatch => {
  return api.optimizations.setLimits(optimizationId, clusterId, { skuNumber, innovationSkuNumber })
    .then(data => pass({ data, skuNumber, innovationSkuNumber }, () => dispatch(setLimitsSuccess({ data, skuNumber, innovationSkuNumber }))))
}
const setLimitsSuccess = ({ data, skuNumber, innovationSkuNumber }) =>
  ({ type: types.setLimitsSuccess, data, skuNumber, innovationSkuNumber })

export const createOptimization = (name, parentOptimizationId) => dispatch => {
  return api.optimizations.createOptimization(name, parentOptimizationId)
    .then(id => pass(id, () => dispatch(createOptimizationSuccess({ id, name }))))
}
const createOptimizationSuccess = ({ id, name }) =>
  ({ type: types.createOptimizationSuccess, id, name })

export const fetchOneOptimization = () => dispatch => {
  return api.optimizations.getOneOptimization({})
    .then(data => pass(data, () => dispatch(fetchOneOptimizationSuccess(data))))
}
export const fetchOneOptimizationSuccess = (data) =>
  ({ type: types.fetchOneOptimizationSuccess, data })

export const fetchProducers = (optimizationId, categoryId, subcategoryId) => dispatch => {
  return api.optimizations.getProducers(optimizationId, categoryId, subcategoryId)
    .then(data => pass(data, () => dispatch(fetchProducersSuccess(data))))
}
const fetchProducersSuccess = (data) =>
  ({ type: types.fetchProducersSuccess, data })

export const fetchBrands = (optimizationId, categoryId, subcategoryId, producerId, search) => dispatch => {
  return api.optimizations.getBrands(optimizationId, categoryId, subcategoryId, producerId, search)
    .then(data => pass(data, () => dispatch(fetchBrandsSuccess(data))))
}
const fetchBrandsSuccess = (data) =>
  ({ type: types.fetchBrandsSuccess, data })

export const resetBrands = () => ({ type: types.resetBrands })

export const fetchCategories = (optimizationId, clusterId, storeId) => dispatch => {
  return api.optimizations.getCategories(optimizationId, clusterId, storeId)
    .then(data => pass(data, () => dispatch(fetchCategoriesSuccess(data))))
}
const fetchCategoriesSuccess = (data) => ({ type: types.fetchCategoriesSuccess, data })

export const fetchSubcategories = (optimizationId, clusterId, storeId, categoryId) => dispatch => {
  return api.optimizations.getSubcategories(optimizationId, clusterId, storeId, categoryId)
    .then(data => pass(data, () => dispatch(fetchSubcategoriesSuccess(data))))
}
const fetchSubcategoriesSuccess = (data) =>
  ({ type: types.fetchSubcategoriesSuccess, data })

export const fetchSkus = (optimizationId, clusterId, storeId, categoryId, subcategoryId, includeRemovedByOptimization, producerId, brandIds, search, pagerState) => dispatch => {
  if (pagerState) {
    return api.optimizations.getSkus.restore(pagerState)
      .then(data => pass(data, () => dispatch(fetchSkusSuccess(data))))
  }

  return api.optimizations.getSkus(optimizationId, clusterId, storeId, categoryId, subcategoryId, includeRemovedByOptimization, producerId, brandIds, search)
    .then(data => pass(data, () => dispatch(fetchSkusSuccess(data))))
}
export const fetchSkusSuccess = (data) => ({ type: types.fetchSkusSuccess, data })

export const setSkuLockFlag = (optimizationId, clusterId, skuId, locked) => dispatch => {
  return api.optimizations.setSkuLockFlag(optimizationId, clusterId, skuId, locked)
    .then(() => dispatch(setSkuLockFlagSuccess(skuId, locked)))
}
const setSkuLockFlagSuccess = (skuId, locked) => ({ type: types.setSkuLockFlagSuccess, skuId, locked })

export const setSkuPresence = (optimizationId, clusterId, skuId, present) => dispatch => {
  return api.optimizations.setSkuPresence(optimizationId, clusterId, skuId, present)
    .then(() => dispatch(setSkuPresenceSuccess(skuId, present)))
}
const setSkuPresenceSuccess = (skuId, present) => ({ type: types.setSkuPresenceSuccess, skuId, present })

export const fetchApprovals = (optimizationId) => dispatch => {
  return api.optimizations.getApprovals(optimizationId)
    .then(data => pass(data, () => dispatch(fetchApprovalsSuccess(data))))
}
const fetchApprovalsSuccess = (data) => ({ type: types.fetchApprovalsSuccess, data })

export const setReadyForCategoryManager = (optimizationId) => dispatch => {
  return api.optimizations.readyForCategoryManagers(optimizationId)
    .then(data => pass(data, () => dispatch(setReadyForCategoryManagerSuccess(optimizationId))))
}
const setReadyForCategoryManagerSuccess = (optimizationId) => ({ type: types.setReadyForCategoryManagerSuccess, optimizationId })
export const fetchCategorySettings = (optimizationId, clusterId, categoryId) => dispatch => {
  return api.optimizations.getCategorySettings(optimizationId, clusterId, categoryId)
    .then(data => pass(data, () => dispatch(fetchCategorySettingsSuccess(data, categoryId))))
}
const fetchCategorySettingsSuccess = (data, categoryId) => ({ type: types.fetchCategorySettingsSuccess, data, categoryId })

export const setCategorySettings = (optimizationId, clusterId, categoryId, values) => dispatch => {
  return api.optimizations.setCategorySettings(optimizationId, clusterId, categoryId, values)
    .then(() => dispatch(setCategorySettingsSuccess(values, categoryId)))
}
const setCategorySettingsSuccess = (data, categoryId) => ({ type: types.setCategorySettingsSuccess, data, categoryId })

export const fetchSubcategorySettings = (optimizationId, clusterId, categoryId) => dispatch => {
  return api.optimizations.getSubcategorySettings(optimizationId, clusterId, categoryId)
    .then(data => pass(data, () => dispatch(fetchSubcategorySettingsSuccess(data, categoryId))))
}
const fetchSubcategorySettingsSuccess = (data, categoryId) => ({ type: types.fetchSubcategorySettingsSuccess, data, categoryId })

export const setSubcategorySettings = (optimizationId, clusterId, categoryId, settings) => dispatch => {
  return api.optimizations.setSubcategorySettings(optimizationId, clusterId, categoryId, settings)
    .then(() => dispatch(setSubcategorySettingsSuccess(settings, categoryId)))
}
const setSubcategorySettingsSuccess = (data, categoryId) => ({ type: types.setSubcategorySettingsSuccess, data, categoryId })

export const runOptimization = (optimizationId) => dispatch => {
  return api.optimizations.runOptimization(optimizationId)
    .then(data => pass(data, () => dispatch(runOptimizationSuccess(optimizationId))))
}
const runOptimizationSuccess = (optimizationId) => ({ type: types.runOptimizationSuccess, optimizationId })

export const finalizeAssortment = (optimizationId) => dispatch => {
  return api.optimizations.finalize(optimizationId)
    .then(() => dispatch(finalizeAssortmentSuccess()))
}
const finalizeAssortmentSuccess = () => ({ type: types.finalizeAssortmentSuccess })

export const fetchApprovalErrors = (optimizationId, clusterId, categoryId, subcategoryId) => dispatch => {
  return api.optimizations.getApprovalErrors(optimizationId, clusterId, categoryId, subcategoryId)
    .then(data => pass(data, () => dispatch(fetchApprovalErrorsSuccess(data))))
}
const fetchApprovalErrorsSuccess = data => ({ type: types.fetchApprovalErrorsSuccess, data })

export const setApproveOptimization = (optimizationId, userId, firstName, lastName) => dispatch => {
  const id = userId
  return api.optimizations.approveOptimization(optimizationId, userId, firstName, lastName)
    .then(data => pass(data, () => dispatch(setApproveOptimizationSuccess(data, firstName, lastName, id))))
}
const setApproveOptimizationSuccess = (data, firstName, lastName, id) => ({ type: types.setApproveOptimizationSuccess, data, firstName, lastName, id })

export const updateOptimization = (optimizationId, { title }) => dispatch => {
  return api.optimizations.updateOptimization(optimizationId, { title })
    .then(() => dispatch(updateOptimizationSuccess(optimizationId, { title })))
}
const updateOptimizationSuccess = (optimizationId, optimization) => ({ type: types.updateOptimizationSuccess, optimizationId, optimization })
