import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Button, MenuItem, Select } from '@mui/material'
import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'

import { P, Strong } from 'components/ui/Typography'
import { DeviationIcon, FilterIcon } from 'assets/icons'
import renderValueWithPercentFactory from 'components/ui/Select/renderValueWithPercentFactory'
import SearchInput from 'components/ui/SearchInput'
import customPropTypes from 'utils/customPropTypes'

import styles from './RecommendedAssortment.module.scss'

const RecommendedAssortmentFilters = ({
  showRemovedSkusChecked,
  setShowRemovedSkusChecked,
  openContextDrawer,
  category,
  setCategory,
  categories,
  subcategory,
  setSubcategory,
  subcategories,
  updateSearch,
}) => {
  const categoryValue = useMemo(() => categories?.find(c => c.id === category?.id) || '', [categories, category])
  const subcategoryValue = useMemo(() => subcategories?.find(c => c.id === subcategory?.id) || '', [subcategories, subcategory])

  const handleShowRemovedSkusChecked = useCallback(({ target: { checked } }) => {
    setShowRemovedSkusChecked(checked)
  }, [setShowRemovedSkusChecked])

  const handleCategorySelect = useCallback(({ target: { value } }) => {
    setCategory(value)
  }, [setCategory])

  const handleSubcategorySelect = useCallback(({ target: { value } }) => {
    setSubcategory(value)
  }, [setSubcategory])

  return (
    <div className={styles.filters}>
      <div className={styles.filterTopContainer}>
        <FormControlLabel
          label={<Strong>Show SKUs removed by optimization</Strong>}
          control={
            <Checkbox
              checked={showRemovedSkusChecked}
              onChange={handleShowRemovedSkusChecked}
            />
          }
        />
        <Button onClick={openContextDrawer} variant="primary" className={styles.filterButton} >
          <FilterIcon className={`icon normal ${styles.icon}`} />
          <Strong>Filter</Strong>
        </Button>
      </div>

      <div className={styles.filterBottom}>
        <div className={styles.dropdown}>
          <P>SKUs grouped by categories:</P>
          <Select
            className={styles.select}
            value={categoryValue}
            size="small"
            onChange={handleCategorySelect}
            renderValue={
              renderValueWithPercentFactory(category => ({
                label: category.name,
                percentage: category.deviation,
                Icon: DeviationIcon,
              }))
            }
          >
            {
              categories?.map(category => (
                <MenuItem key={category.id} value={category}>
                  {category.name}
                </MenuItem>
              ))
            }
          </Select>
        </div>
        <div className={styles.dropdown}>
          <P>Subcategory:</P>
          <Select
            className={styles.select}
            value={subcategoryValue}
            size="small"
            onChange={handleSubcategorySelect}
            renderValue={
              renderValueWithPercentFactory(subcategory => ({
                label: subcategory.name,
                percentage: subcategory.deviation,
                Icon: DeviationIcon,
              }))
            }
          >
            {
              subcategories?.map(subcategory => (
                <MenuItem key={subcategory.id} value={subcategory}>
                  {subcategory.name}
                </MenuItem>
              ))
            }
          </Select>
        </div>
        <SearchInput onChange={updateSearch} placeholder="Search for SKU" />
      </div>
    </div>
  )
}
RecommendedAssortmentFilters.propTypes = {
  showRemovedSkusChecked: PropTypes.bool.isRequired,
  setShowRemovedSkusChecked: PropTypes.func.isRequired,
  openContextDrawer: PropTypes.func.isRequired,
  category: customPropTypes.category,
  setCategory: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(customPropTypes.category),
  subcategory: customPropTypes.category,
  setSubcategory: PropTypes.func.isRequired,
  subcategories: PropTypes.arrayOf(customPropTypes.category),
  updateSearch: PropTypes.func.isRequired,
}

export default RecommendedAssortmentFilters
