import store, { actions } from 'store'

import { ToastSeverity } from 'store/toast/actions'

import cmp from './Toast'

export default cmp

export const errorToast = (message, position) => {
  store.dispatch(actions.toast.queue(message, ToastSeverity.error, position))
}

export const warningToast = (message, position) => {
  store.dispatch(actions.toast.queue(message, ToastSeverity.warning, position))
}

export const infoToast = (message, position) => {
  store.dispatch(actions.toast.queue(message, ToastSeverity.info, position))
}

export const successToast = (message, position) => {
  store.dispatch(actions.toast.queue(message, ToastSeverity.success, position))
}
