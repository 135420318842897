import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'
import { OptimizationStatus, SkuStatus } from 'services/enums'
import { nvl } from 'utils/object'

import * as types from './types'

const initialState = frozen({
  allOptimizations: null,
  optimization: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.fetchOptimizationsSuccess]: fetchOptimizationsSuccess,
  [types.fetchOptimizationSuccess]: fetchOptimizationSuccess,
  [types.deleteOptimizationSuccess]: deleteOptimizationSuccess,
  [types.fetchOptimizationKeyIndicatorsSuccess]: fetchOptimizationKeyIndicatorsSuccess,
  [types.fetchGoalsSuccess]: fetchGoalsSuccess,
  [types.fetchLimitsSuccess]: fetchLimitsSuccess,
  [types.createOptimizationSuccess]: createOptimizationSuccess,
  [types.setGoalsSuccess]: setGoalsSuccess,
  [types.setLimitsSuccess]: setLimitsSuccess,
  [types.fetchOneOptimizationSuccess]: fetchOneOptimizationSuccess,
  [types.fetchProducersSuccess]: fetchProducersSuccess,
  [types.fetchBrandsSuccess]: fetchBrandsSuccess,
  [types.resetBrands]: resetBrands,
  [types.fetchCategoriesSuccess]: fetchCategoriesSuccess,
  [types.fetchSubcategoriesSuccess]: fetchSubcategoriesSuccess,
  [types.fetchSkusSuccess]: fetchSkusSuccess,
  [types.setSkuLockFlagSuccess]: setSkuLockFlagSuccess,
  [types.setSkuPresenceSuccess]: setSkuPresenceSuccess,
  [types.fetchApprovalsSuccess]: fetchApprovalsSuccess,
  [types.setReadyForCategoryManagerSuccess]: setReadyForCategoryManagerSuccess,
  [types.fetchCategorySettingsSuccess]: fetchCategorySettingsSuccess,
  [types.setCategorySettingsSuccess]: setCategorySettingsSuccess,
  [types.fetchSubcategorySettingsSuccess]: fetchSubcategorySettingsSuccess,
  [types.setSubcategorySettingsSuccess]: setSubcategorySettingsSuccess,
  [types.finalizeAssortmentSuccess]: finalizeAssortmentSuccess,
  [types.runOptimizationSuccess]: runOptimizationSuccess,
  [types.fetchApprovalErrorsSuccess]: fetchApprovalErrorsSuccess,
  [types.setApproveOptimizationSuccess]: setApproveOptimizationSuccess,
  [types.updateOptimizationSuccess]: updateOptimizationSuccess,
}

function vacate(state) {
  return {
    ...state,
    allOptimizations: null,
    optimization: null,
  }
}

function fetchOptimizationsSuccess(state, action) {
  return {
    ...state,
    allOptimizations: action.data,
  }
}

function fetchOptimizationSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      ...action.data,
    },
  }
}

function deleteOptimizationSuccess(state, action) {
  const { optimizationId } = action
  return {
    ...state,
    allOptimizations: state.allOptimizations?.filter(o => o.id !== optimizationId),
  }
}

function fetchOptimizationKeyIndicatorsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      keyIndicators: action.data,
    },
  }
}

function fetchGoalsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      goals: action.data,
    },
  }
}

function fetchLimitsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      limits: action.data,
    },
  }
}

function createOptimizationSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,

      createdAt: new Date(),
      readyForCm: false,
      id: action.id,
      title: action.name,
      status: OptimizationStatus.draft,

      // TODO: Replace with session user
      createdBy: {
        id: 1,
        firstName: 'John',
        lastName: 'Doe',
      },
      approvals: [],
      approvalErrors: [],
    },
  }
}

function setGoalsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      goals: {
        revenue: action.revenue,
        margin: action.margin,
        baskets: action.baskets,
      },
    },
  }
}

function setLimitsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      limits: {
        skuNumber: {
          ...state.optimization.limits.skuNumber,
          automatic: nvl(action.skuNumber?.automatic, state.optimization.limits.skuNumber.automatic),
          value: nvl(action.skuNumber?.value, state.optimization.limits.skuNumber.value),
        },
        innovationSkuNumber: {
          ...state.optimization.limits.innovationSkuNumber,
          automatic: nvl(action.innovationSkuNumber?.automatic, state.optimization.limits.innovationSkuNumber.automatic),
          value: nvl(action.innovationSkuNumber?.value, state.optimization.limits.innovationSkuNumber.value),
        },
      },
    },
  }
}

function fetchOneOptimizationSuccess(state, action) {
  return {
    ...state,
    oneOptimization: action.data,
  }
}

function fetchProducersSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      producers: action.data,
    },
  }
}

function fetchBrandsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      brands: action.data,
    },
  }
}

function resetBrands(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      brands: [],
    },
  }
}

function fetchCategoriesSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      categories: action.data,
    },
  }
}

function fetchSubcategoriesSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      subcategories: action.data,
    },
  }
}

function fetchSkusSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      skus: action.data,
    },
  }
}

function setSkuLockFlagSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      skus: state.optimization.skus.map(sku => {
        if (sku.id === action.skuId) {
          return {
            ...sku,
            locked: action.locked,
          }
        }
        return sku
      }),
    },
  }
}

function setSkuPresenceSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      skus: state.optimization.skus.map(sku => {
        if (sku.id === action.skuId) {
          if (action.present) {
            return {
              ...sku,
              statusOverride: sku.optimizationStatus !== SkuStatus.removed ? null : SkuStatus.included,
            }
          }
          return {
            ...sku,
            statusOverride: sku.optimizationStatus === SkuStatus.removed ? null : SkuStatus.removed,
          }
        }
        return sku
      }),
    },
  }
}

function fetchApprovalsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      approvals: action.data || [],
    },
  }
}

function fetchCategorySettingsSuccess(state, action) {
  const { data } = action
  return {
    ...state,
    optimization: {
      ...state.optimization,
      categorySettings: data,
    },
  }
}

function setCategorySettingsSuccess(state, action) {
  const { data } = action
  return {
    ...state,
    optimization: {
      ...state.optimization,
      categorySettings: data,
    },
  }
}

function fetchSubcategorySettingsSuccess(state, action) {
  const { data } = action
  return {
    ...state,
    optimization: {
      ...state.optimization,
      subcategorySettings: data,
    },
  }
}

function setSubcategorySettingsSuccess(state, action) {
  const { data } = action
  return {
    ...state,
    optimization: {
      ...state.optimization,
      subcategorySettings: data,
    },
  }
}

function setReadyForCategoryManagerSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      readyForCm: true,
    },
  }
}

function runOptimizationSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      status: OptimizationStatus.processing,
    },
  }
}

function finalizeAssortmentSuccess(state) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      status: OptimizationStatus.finalized,
    },
  }
}

function fetchApprovalErrorsSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      approvalErrors: action.data,
    },
  }
}

function setApproveOptimizationSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      approvals: [
        ...(state.optimization.approvals || []),
        {
          userId: action.id,
          firstName: action.firstName,
          lastName: action.lastName,
          approvalDate: new Date(),
        },
      ],
    },
  }
}

function updateOptimizationSuccess(state, action) {
  return {
    ...state,
    optimization: {
      ...state.optimization,
      ...action.optimization,
    },
  }
}

export default objectReducer(initialState, actionHandlers)
