import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './LogoutModal.module.scss'

const LogoutModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      title="Log out"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6>Are you sure you want to logout?</H6>
        <div className={`flex-col-center ${styles.buttonContainer}`}>
          <Button
            className={styles.confirmButton}
            onClick={onConfirm}
            variant="contained"
          >Logout</Button>
          <Button
            className={styles.cancelButton}
            onClick={onClose}
            variant="primary"
          >Cancel</Button>
        </div>
      </div>
    </Dialog>
  )
}

LogoutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default LogoutModal
