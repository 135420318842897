export class ApiError extends Error {
  constructor(message, statusCode, data) {
    super(message)
    this.name = this.constructor.name
    this.statusCode = statusCode
    this.error = message
    this.data = data
  }
}

export class ValidationError extends Error {
  constructor(error) {
    super('Validation failed.')
    this.name = this.constructor.name
    this.error = error
  }
}
