import React from 'react'
import PropTypes from 'prop-types'

import { H1, H4 } from 'components/ui/Typography'
import { EditIcon, FinalizedIcon } from 'assets/icons'
import { OptimizationStatus } from 'services/enums'
import { cls } from 'utils/react'

import styles from './OptimizationTitle.module.scss'

const OptimizationTitle = ({ optimization, onEdit, readOnly }) => {
  const isFinalized = optimization.status === OptimizationStatus.finalized
  const isEditable = !readOnly && !isFinalized

  return (
    <div className={styles.titleWrap}>
      <div className={styles.titleEdit}>
        <H1 className={cls([isEditable && styles.editable])}>{optimization?.title}</H1>
        {
          isEditable &&
            <EditIcon onClick={onEdit} className={`icon normal ${styles.edit}`} />
        }
      </div>
      {
        isFinalized &&
          <div className={styles.finalized}>
            <FinalizedIcon className='icon normal' />
            <H4>Finalized</H4>
          </div>
      }
    </div>
  )
}

OptimizationTitle.propTypes = {
  optimization: PropTypes.shape({
    title: PropTypes.string.isRequired,
    status: PropTypes.symbol.isRequired,
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  readOnly: PropTypes.bool.isRequired,
}

export default OptimizationTitle
