/**
 * Returns maximal distance of the values from the mid value.
 */
export const marginMax = (values, mid = 0) => {
  const distances = values
    .map(val => val - mid)
    .map(val => val ? Math.abs(val) : 0)
  return Math.max(...distances)
}

/**
 * Returns minimal distance of the values from the mid value.
 */
export const marginMin = (values, mid = 0) => {
  const distances = values
    .map(val => val - mid)
    .map(val => val ? Math.abs(val) : 0)
  return Math.min(...distances)
}

/**
 * Returns sum of the values.
 */
export const sum = (...values) => {
  return values.reduce((sum, value) => {
    return sum + value
  }, 0)
}

/**
 * Ensures that the value is in [min, max] range.
 * If value is in that range it returns the value.
 * If value is less than min, it returns min.
 * If value is greater than max, it returns max.
 */
export const rangeCap = (value, min, max) => {
  if (value > max) return max
  if (value < min) return min
  return value
}
