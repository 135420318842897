import log from 'utils/log'
import {
  fetchKey,
  removeKey,
  storeKey,
} from 'utils/localStorageService'

/**
 * Creates and subscribes a pure state listener, which invokes handle method
 * only in case of a selected state segment change.
 *
 * @param store Redux store
 * @param {function(state): Object} select Function that accepts the state
 *        and returns a selected portion of the state that this listener monitors
 * @param {function(previous, current): void} handle Handler function that accepts
 *        the previous and current value of the selected state
 * @param enabled Boolean flag indicating if the handler is enabled initially
 * @return An object with store's unsubscribe function that can be used to unsubscribe the listener
 *        and enable and disable methods that enale or disable handler processing.
 */
export const subscribePureListener = (store, select, handle, enabled = true) => {
  let currentValue
  let isEnabled = enabled

  const listener = () => {
    const previousValue = currentValue
    currentValue = select(store.getState())
    if (isEnabled && previousValue !== currentValue) {
      handle(previousValue, currentValue)
    }
  }

  const enable = () => { isEnabled = true }
  const disable = () => { isEnabled = false }

  return {
    unsubscribe: store.subscribe(listener),
    enable,
    disable,
  }
}

/**
 * Creates a handler function for subscribePureListener that stores a value
 * in the key storage under the specified key.
 */
export const createPersister = (key) => (previous, current) => {
  log.debug(() => [`Persisting ${key}: `, current])
  if (current === undefined || current === null) {
    removeKey(key)
  } else {
    storeKey(key, current)
  }
}

/**
 * Restores a value from the local async storage.
 * @param store Redux store
 * @param key Key under which value is stored
 * @param action Function that returns the action that can add the restored value into the state
 * @return Promise that resolves to the dispatched action
 */
export const restoreValue = (store, key, action) => {
  const val = fetchKey(key)
  return store.dispatch(action(val))
}
