import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './RunOptimizationModal.module.scss'

const RunOptimizationModal = ({ open, onClose, onConfirm, quotas }) => {
  const allOptimizationsUsed = quotas?.usedOptimizations === quotas?.allowedOptimizations

  return (
    <Dialog
      title="Run optimization"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6>You are about to run the optimization process. You used {quotas?.usedOptimizations} out of max {quotas?.allowedOptimizations} optimization scenarions to run.</H6>
        <H6>Do you want to proceed?</H6>
        <Button
          className={styles.confirmButton}
          onClick={onConfirm}
          variant="contained"
          disabled={allOptimizationsUsed}
        >Run Optimization</Button>
        <Button
          className={styles.cancelButton}
          onClick={onClose}
          variant="primary"
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

RunOptimizationModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  quotas: PropTypes.object,
}

export default RunOptimizationModal
