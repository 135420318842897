import SuperCluster from 'supercluster'

export const INITIAL_ZOOM = 7
export const NEARBY_STORES_ZOOM = 15

/**
 * transform an array of points on the map to clusters and markers
 * @param {Object} superCluster - an instance of SuperCluster
 * @param {Array} points - points on the map
 * @param {Object} bbox - represents current visible area of Map
 * @param {number} zoom - current zoom level
 * @returns {Array} array of cluster points and regular points
 */
export const getClusterMarkers = (superCluster, points, bbox, zoom) => {
  const { minLatitude, maxLatitude, minLongitude, maxLongitude } = bbox

  // transform points to GeoJSON Feature objects
  const geoPoints = points.map((point) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          point.lat,
          point.lng,
        ],
      },
      properties: { point },
    }
  })

  superCluster.load(geoPoints)

  // getClusters only works with a zoom integer value
  return superCluster.getClusters(
    [
      minLatitude,
      minLongitude,
      maxLatitude,
      maxLongitude,
    ],
    Math.floor(zoom),
  )
}

/**
 * get new zoom level so that the map is focused on the filtered points
 * @param {Array} points - points on the map
 * @returns {number} zoom level to use
 */
export const getZoomLevelForFilteredPoints = (points) => {
  const superClusterTemp = new SuperCluster({ radius: 130 })

  // transform points to GeoJSON Feature objects
  const geoPoints = points.map((point) => {
    return {
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: [
          point.lat,
          point.lng,
        ],
      },
      properties: { point },
    }
  })

  superClusterTemp.load(geoPoints)

  // purposefully create a single cluster so that we can make use of
  // the getClusterExpansionZoom method to find the next zoom level
  const clusters = superClusterTemp.getClusters([-180, -85, 180, 85], 2)
  if (clusters.length) {
    return superClusterTemp.getClusterExpansionZoom(clusters[0].properties.cluster_id) + 1
  }
  return INITIAL_ZOOM
}

export const boundingRegion = (points) => {
  if (!points?.length) { return null }

  let minLat, maxLat, minLng, maxLng;

  (point => {
    minLat = point.lat
    maxLat = point.lat
    minLng = point.lng
    maxLng = point.lng
  })(points[0])

  points.forEach(point => {
    minLat = Math.min(minLat, point.lat)
    maxLat = Math.max(maxLat, point.lat)
    minLng = Math.min(minLng, point.lng)
    maxLng = Math.max(maxLng, point.lng)
  })

  const midLat = (minLat + maxLat) / 2
  const midLng = (minLng + maxLng) / 2

  const deltaLat = (maxLat - minLat)
  const deltaLng = (maxLng - minLng)

  return {
    latitude: midLat,
    longitude: midLng,
    latitudeDelta: deltaLat * 1.2,
    longitudeDelta: deltaLng * 1.2,
    minLatitude: minLat,
    maxLatitude: maxLat,
    minLongitude: minLng,
    maxLongitude: maxLng,
  }
}
