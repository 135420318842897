import React from 'react'

function SplashScreen() {
  return (
    <div className="splash-screen">
      <div className="splash-image">
      </div>
    </div>
  )
}

export default SplashScreen
