import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { P, Strong } from 'components/ui/Typography'

import styles from './ConfirmChoicesDialog.module.scss'

const ConfirmChoicesDialog = ({ open, onClose, onConfirm, approvalErrors }) => {
  return (
    <Dialog
      title="Confirm your categories?"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      {
        approvalErrors?.length ?
          <div className={`flex-col-center ${styles.dialogContainer}`}>
            <P>Some categories have conflicts in number of allowed SKUs, please edit and then confirm your choices.</P>

            {
              approvalErrors.map(approvalError =>
                <Strong key={approvalError.clusterId + approvalError.categoryId + approvalError.subcategoryId} className={styles.error}>{approvalError.clusterName} {approvalError.categoryName} {approvalError.subcategoryName}</Strong>,
              )
            }

            <Button variant="contained" onClick={onClose}>OK</Button>
          </div> :
          <div className={`flex-col-center ${styles.dialogContainer}`}>
            <P>Confirming your categories will send the administrator notification that you are done and they will be able to optimize assortment.</P>
            <P>Do you want to proceed?</P>

            <Button variant="contained" onClick={onConfirm} className={styles.button}>Confirm</Button>
            <Button variant="primary" className={styles.cancelButton} onClick={onClose}>Cancel</Button>
          </div>
      }
    </Dialog>
  )
}

ConfirmChoicesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  approvalErrors: PropTypes.arrayOf(PropTypes.shape({
    categoryId: PropTypes.string,
    categoryName: PropTypes.string,
    clusterId: PropTypes.string,
    clusterName: PropTypes.string,
    maxSkuNumber: PropTypes.number,
    skuNumber: PropTypes.number,
    subcategoryId: PropTypes.string,
    subcategoryName: PropTypes.string,
  })),
}

export default ConfirmChoicesDialog
