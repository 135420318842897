import { decode } from 'base-64'

/**
 * Replaces placeholders of format {0}, {1}, {N} with corresponding argument.
 */
if (!String.prototype.format) {
  // eslint-disable-next-line no-extend-native
  String.prototype.format = function() {
    const args = arguments
    return this.replace(/{(\d+)}/g, function(match, number) {
      return typeof args[number] !== 'undefined' ? args[number] : match
    })
  }
}

/**
 * Replaces placeholders of format {foo}, {bar}, {baz} with corresponding argument.
 */
if (!String.prototype.formatVars) {
  // eslint-disable-next-line no-extend-native
  String.prototype.formatVars = function(variables) {
    return this.replace(/{([a-zA-Z_]\w*)}/g, function(match, variable) {
      return typeof variables[variable] !== 'undefined' ? variables[variable] : match
    })
  }
}

/**
 * Converts base64 encoded string to the HEX encoded string.
 */
export const base64ToHex = base64 => {
  const raw = decode(base64)
  let result = ''
  for (let i = 0; i < raw.length; ++i) {
    const hex = raw.charCodeAt(i).toString(16)
    result += (hex.length === 2 ? hex : '0' + hex)
  }
  return result
}
