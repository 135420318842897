import moment from 'moment'

export function notificationInForm(item) {
  return item && {
    ...item,
    publishedAt: moment(item.publishedAt * 1000).format('MMM D YYYY, h:mma'),
    message: createMessage(item),
    downloadURL: item.payload?.url ?? '',
  }
}

export function dataInForm(item) {
  return item && {
    ...item,
    lastId: item.lastEvaluatedKey,
    notifications: item.notifications.map(notification => notificationInForm(notification)),
  }
}

export function createMessage(item) {
  switch (item.type) {
    case 'new_user_registration': return 'New user is added, please go to users list to confirm access!'
    case 'new_user_confirmed': return `${item.payload.firstName} ${item.payload.lastName} has confirmed their account email address!`
    case 'user_categories_changed': return 'Admin has just changed your categories!'
    case 'assortment_optimization_finished': return `${item.payload.assortmentOptimizationName} optimization has finished!`
    case 'assortment_optimization_ready_for_cms': return `${item.payload.assortmentOptimizationName} optimization is ready for review!`
    case 'assortment_optimization_approved': return `${item.payload.approverFirstName} ${item.payload.approverLastName} has approved ${item.payload.assortmentOptimizationName} optimization!`
    case 'assortment_optimization_finalized':return `${item.payload.assortmentOptimizationName} optimization has been finalized!`
    case 'assortment_optimization_execution_tracking_data_available': return `Execution tracking data for ${item.payload.assortmentOptimizationName} in ${item.payload.executionPeriodName} is now available!`
    case 'assortment_optimization_export_available': return 'Assortment optimization export is now available!'
    case 'execution_tracking_export_available': return 'Execution tracking export is now available!'
    default: return 'Notification'
  }
}
