import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'
import { actions } from 'store'
import { Button } from '@mui/material'

import { isEmailValid } from 'utils/validators'
import Input from 'components/ui/Input'
import { useForm, useRevalidate } from 'hooks/useForm'
import { Errors, H4, P, Label } from 'components/ui/Typography'

import styles from './RegisterForm.module.scss'

const initialValues = {
  name: '',
  surname: '',
  email: '',
  password: '',
  passwordConfirmation: '',
}

const validate = (values) => {
  const errors = {}
  if (!values.email?.trim()) {
    errors.email = { required: true }
  } else if (!isEmailValid(values.email, 8, 50)) {
    errors.email = { policy: true }
  }
  if (!values.password?.trim()) {
    errors.password = { required: true }
  }
  if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = { match: true }
  }
  return errors
}

const RegisterForm = ({ onContinue }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submit = useCallback(values => {
    return dispatch(actions.auth.registerUser(values))
      .then(() => onContinue())
  }, [dispatch, onContinue])

  const revalidate = useRevalidate()
  const { errors, submitForm, processing, values, setValue } =
    useForm('register', validate, submit, initialValues, revalidate)

  return (
    <div className={`flex-col-center ${styles.registrationForm}`}>
      <H4 className={styles.text}> Registration </H4>
      <Input
        name="name"
        label="Name"
        type="text"
        value={values.name || ''}
        onChange={setValue}
        error={!!errors?.name}
        helperText={errors?.name?.[0]}
        fullWidth
      />
      <Input
        name="surname"
        label="Surname"
        type="text"
        value={values.surname || ''}
        onChange={setValue}
        error={!!errors?.surname}
        helperText={errors?.surname?.[0]}
        fullWidth
      />
      <Input
        name="email"
        label="Email*"
        type="email"
        value={values.email || ''}
        onChange={setValue}
        error={!!errors?.email}
        helperText={errors?.email?.[0]}
        fullWidth
      />
      <Input
        name="password"
        label="Password*"
        type="password"
        value={values.password || ''}
        onChange={setValue}
        error={!!errors?.password}
        helperText={errors?.password?.[0]}
        fullWidth
      />
      <Input
        name="passwordConfirmation"
        label="Confirm password*"
        type="password"
        value={values.passwordConfirmation || ''}
        onChange={setValue}
        error={!!errors?.passwordConfirmation}
        helperText={errors?.passwordConfirmation?.[0]}
        fullWidth
      />

      <Label className={styles.helperText}> *Required fields </Label>
      <Errors errors={errors?._} />
      <Button variant='contained' size='small' className={styles.registerButton} disabled={processing} onClick={submitForm}> Registration </Button>
      <P>Already a member?</P>
      <Button variant='text' size='medium' className={styles.loginButton} disabled={processing} onClick={() => navigate('/login')}> Log in </Button>
    </div>
  )
}

RegisterForm.propTypes = {
  onContinue: PropTypes.func.isRequired,
}
export default RegisterForm
