import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  number: 0,
  notifications: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  // [types.loadNotificationsNumberSuccess]: loadNotificationsNumberSuccess,
  [types.loadNotificationsSuccess]: loadNotificationsSuccess,
  [types.newNotificationSuccess]: newNotificationSuccess,
}

function vacate(state) {
  return {
    ...state,
    number: 0,
    notifications: null,
  }
}

function loadNotificationsSuccess(state, action) {
  return {
    ...state,
    number: action.data.count,
    notifications: action.data.notifications,
  }
}

function newNotificationSuccess(state, action) {
  return {
    ...state,
    number: state.number + 1,
    notifications: state.notifications ? [action.data].concat(state.notifications) : [action.data],
  }
}
export default objectReducer(initialState, actionHandlers)
