import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { actions } from 'store'
import { Button } from '@mui/material'

import { H4, P } from 'components/ui/Typography'
import useToast from 'components/ui/Toast/useToast'

import styles from './VerificationLink.module.scss'

const VerificationLink = ({ onCancel }) => {
  const dispatch = useDispatch()
  const { successToast, errorToast } = useToast()
  const registrationId = useSelector(state => state.auth?.registrationId)

  const resendLink = useCallback(() => {
    return dispatch(actions.auth.resendLink(registrationId))
      .then(() => successToast('Verification email is sent again successfully'))
      .catch(() => errorToast('Something went wrong with resending verification email'))
  }, [dispatch, successToast, errorToast, registrationId])

  return (
    <div className={`flex-col-center ${styles.verificationScreen}`}>
      <H4 className={styles.title}> Verification </H4>
      <P> We just sent you confirmation link.
        Please check your email and verify your account! </P>
      <P />
      <P> Didn’t receive our email? </P>
      <Button variant='contained' size='small' className={styles.resendButton} onClick={resendLink}> Send Again </Button>
      <Button
        className={styles.cancelButton}
        onClick={() => onCancel()}
        variant="primary"
      > Cancel </Button>
    </div>
  )
}
VerificationLink.propTypes = {
  onCancel: PropTypes.func.isRequired,
}
export default VerificationLink
