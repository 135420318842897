import { useSelector } from 'react-redux'

import log from 'utils/log'

const useAuth = ({ is, authenticated, anonymous }) => {
  log.assert(!(authenticated && anonymous), 'Cannot require to be anonymous and authenticated at the ame time.')
  log.assert(!(anonymous && is), 'Cannot require role for the anonymous user.')
  log.assert(anonymous || authenticated || !!is, 'At least one of the conditions has to be specified.')

  const profile = useSelector(state => state.users.profile)

  return isAuthorized(authenticated, anonymous, is, !!profile, profile?.role)
}

export default useAuth

function isAuthorized(isAuthenticated, isAnonymous, is, authenticated, role) {
  if (isAnonymous) {
    return !authenticated
  }

  if (is) {
    return is === role
  }

  if (isAuthenticated) {
    return authenticated
  }

  return false
}
