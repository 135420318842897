import React from 'react'
import PropTypes from 'prop-types'

import { P2 } from 'components/ui/Typography'

import styles from './Tooltip.module.scss'

const Tooltip = ({ title, body }) => {
  return (
    <div className={styles.tooltipContainer}>
      <P2 className={styles.tooltipTitle}>{title}</P2>
      <P2 className={styles.tooltipBody}>{body}</P2>
    </div>
  )
}
Tooltip.propTypes = {
  title: PropTypes.string,
  body: PropTypes.string,
}

export default Tooltip
