import { floatInForm } from 'services/api/commonAdapters'

export function storeInForm(item) {
  return item && {
    id: item.id,
    name: item.name,
    location: {
      lat: floatInForm(item.latitude),
      long: floatInForm(item.longitude),
    },
    address: {
      line1: item.address,
      line2: item.municipality,
      city: item.city,
      zip: '',
      state: item.region,
    },
    deviation: item.deviation,
  }
}
