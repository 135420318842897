import { useCallback, useEffect, useState } from 'react'
import { debounce } from 'lodash'

import { formErrors, message } from 'services/messages'
import { ApiError, ValidationError } from 'services/errors'
import { isEmpty } from 'utils/object'
import log from 'utils/log'
import { noop } from 'utils/language'
import usePrevious from 'hooks/usePrevious'
import useIsMounted from 'hooks/useIsMounted'

/**
 * Hook that validates and submits the form, returning useful information about current form state.
 *
 * @param formName Name of the form. This is useful for formatting error message based on ero code,
 *                 i.e. for the same error code, two forms can show different messages.
 * @param validate Validation function that validates the form entries
 *                 and returns validation response (described below).
 *                 If there are no validation issues, this function should return falsy value or an empty object.
 *                 This function will be supplied with one argument equal to the "values" object (returned by this hook)
 *                 and second argument that is config object passed by user to the validateForm function.
 *                 This function can return either value or a promise that resolves to that value.
 * @param submit A form submit function that should return a promise for the submit operation.
 *               Any error returned by this promise is expected to be a server side form validation error and as such
 *               will be processed by the message provider.
 *               This function will be supplied with one argument equal to the "values" object (returned by this hook)
 *               and second argument that is config object passed by user to the submitForm function.
 * @param initialValues An object of field-values that are used to initialize the form.
 *                      Key is form field name and value is value of tha form field.
 * @param onChange A function called when values change.
 *                 It receives aan object with all the return values of this hook.
 *
 * @return validateForm A function that can be used to validate a form. If form is valid it returns a resolved promise
 *                      otherwise a rejected promise is returned. It accepts config object that will be passed to the
 *                      actual validate function.
 * @return errors Object with error messages created based on server or client side validation errors,
 *                processed by the message provider.
 * @return submitForm A function that can be used to submit a form. This function validates the form before submission.
 *                    It accepts config object that will be passed to the actual validate and submit functions.
 * @return processing A flag indicating if form submission/processing is in progress.
 * @return values An object of field-values. Key is form field name and value is value of tha form field.
 * @return reset Function that resets the form values to the initialValues and clears errors.
 * @return clear Function that clears the form values and errors.
 * @return setValue A function that accepts either 2 arguments: "filedName" and "fieldValue"
 *                  and sets the value of the filed in the values object.
 *                  The other forms accepts one argument which is an event of the input (which should have
 *                  e.target.value and e.target.name)
 * @return submitted A boolean flag indicating if the form has been submitted or not.
 *
 * @example <caption>Using hook.</caption>
 * <pre><code>
 *   function validate(values) {
 *     const errors = {}
 *     if (!values.password) {
 *       errors.password = { required: true }
 *     } else if (values.password.length < 8) {
 *       errors.password = { minLen: true }
 *     }
 *
 *    if (values.passwordConfirmation !== values.password) {
 *      errors.passwordConfirmation = { match: true }
 *    }
 *    return errors
 *  }
 *
 *  const { validateForm, clearErrors, errors, submitForm, processing, values, clearValues, resetValues, clear setValue }
 *    = useForm('register', validate, submit, { email: '', password: '' })
 *
 *  <Input
 *    error={!!errors?.password}
 *    helperText={errors?.password?.[0]}
 *    value={values.password || ''}
 *    onChange={e => setValue('password', e.target.value)}
 *  />
 *
 *  OR
 *
 *  <Input
 *    error={!!errors?.password}
 *    helperText={errors?.password?.[0]}
 *    value={values.password || ''}
 *    name="password"
 *    onChange={setValue}
 *  />
 * </code></pre>
 *
 * @example <caption>Use onChange to revalidate form.</caption>
 * <pre><code>
 *   const onChange = useCallback(debounce(({ validateForm, submitted }) => submitted && validateForm().catch(noop), 500), [])
 * </code></pre>
 *
 * @example <caption>Validation response format example</caption>
 * <pre><code>
 * "email": {
 *   "format": {
 *     "message": "Email format is not correct.",
 *   },
 *   "required": true,
 *   "minLength": "Email is too short",
 * },
 * "password": {
 *   "min-length": {
 *       "constraints": {
 *         "min": 8,
 *       },
 *   },
 * }, ...
 * "_": {
 *   "global-err-1-code": {
 *     "message": "Some global error...",
 *   }, ...
 * }
 * </code></pre>
 *
 * If message is provided, it will be used as a default one if locally defined message for the error code is not found.
 *
 * @example <caption>Example of errors returned by this hook</caption>
 * <pre><code>
 * {
 *   "email": [
 *     "Email format is not correct.",
 *     "Email is too long."
 *   ],
 *   "password": [
 *     "Minimal password length is 8 characters."
 *   ],
 *   "_": [
 *     "Some global error...",
 *     "Some other global error..."
 *   ]
 * }
 * </code></pre>
 *
 * In cases where an unknown/unexpected error is caught the errors will be set to the value "true".
 *
 */
export const useForm = (formName, validate, submit, initialValues, onChange) => {
  const [errors, setErrors] = useState(null)
  const [values, setValues] = useState(initialValues || {})
  const [processing, setProcessing] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const previousValues = usePrevious(values)
  const isMounted = useIsMounted()

  useEffect(() => {
    setValues(initialValues || {})
  }, [initialValues])

  const clear = useCallback(() => {
    setSubmitted(false)
    setValues(initialValues || {})
    setErrors({})
  }, [initialValues])

  const reset = useCallback(() => {
    setSubmitted(false)
    setValues(initialValues)
    setErrors(null)
  }, [initialValues])

  const markSubmitted = useCallback(() => {
    setSubmitted(true)
  }, [])

  const validateForm = useCallback((config) => {
    setErrors(null)
    if (validate) {
      return Promise.resolve(validate(values, config))
        .then(validationErrors => {
          setErrors(formErrors(formName, validationErrors))
          return isEmpty(validationErrors) ? Promise.resolve() : Promise.reject(new ValidationError(validationErrors))
        })
    }
    return Promise.resolve()
  }, [formName, validate, values])

  const submitForm = useCallback((config) => {
    setProcessing(true)
    setSubmitted(true)

    return validateForm(config)
      .then(() => submitValues(config))
      .finally(() => {
        setProcessing(false)
      })

    function submitValues(config) {
      return Promise.resolve(submit(values, config))
        .catch(e => {
          if (e instanceof ValidationError) {
            setErrors(formErrors(formName, e.error))
          } else if (e instanceof ApiError) {
            const msg = message(formName, 'api', e.statusCode, e.message)
            setErrors({ _: [msg] })
          } else if (e === undefined) {
            log.info(() => 'Submission rejected with no error.')
          } else {
            log.info(() => ['Unexpected error.', e])
            const msg = message(formName, undefined, 'unknown', e.message)
            setErrors({ _: [msg] })
          }
          throw e
        })
    }
  }, [formName, submit, validateForm, values])

  const setValue = useCallback((field, value) => {
    if (field && value === undefined && field.target?.name) {
      value = field.target.value
      field = field.target.name
    }

    setValues(state => ({
      ...state,
      [field]: value,
    }))
  }, [])

  useEffect(() => {
    onChange && (values !== previousValues) && isMounted &&
    onChange({
      validateForm,
      clear,
      reset,
      errors,
      submitForm,
      processing,
      values,
      setValue,
      submitted,
    })
  }, [clear, errors, isMounted, onChange, previousValues, processing, reset, setValue, submitForm, submitted, validateForm, values])

  return {
    validateForm,
    clear,
    reset,
    errors,
    submitForm,
    processing,
    values,
    setValue,
    submitted,
    markSubmitted,
  }
}

export const useRevalidate = (debounceTime = 500) => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useCallback(
    debounce(({ validateForm, submitted }) => submitted && validateForm().catch(noop), debounceTime), [])
}
