import { useRef, useEffect } from 'react'

export const useOnUpdate = (fn, dependencies) => {
  const isFirst = useRef(true)
  useEffect(() => {
    if (!isFirst.current) {
      fn()
    } else {
      isFirst.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies)
}
