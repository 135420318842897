import { UserStatus, Role } from 'services/enums'
import { booleanInForm } from 'services/api/commonAdapters'

export function userInForm(item) {
  return item && {
    ...item,
    profilePhotoUrl: item.profilePhotoUrl ?? '',
    status: UserStatus.from(item.status),
    role: Role.from(item.role),
    enableEmailNotification: booleanInForm(item.enableEmailNotification),
  }
}
