import { SkuStatus } from 'services/enums'
import { booleanInForm } from 'services/api/commonAdapters'

export function skuInForm(item) {
  return item && {
    id: item.articleId,
    name: item.articleName,
    locked: booleanInForm(item.locked),
    optimizationStatus: SkuStatus.from(item.optimizationStatus),
    statusOverride: SkuStatus.from(item.statusOverride),
  }
}
