export const cached = (fn, name, cache, tokenProvider) => {
  const createKey = (...args) => {
    return JSON.stringify({
      fn: fn.name,
      name,
      token: tokenProvider ? tokenProvider() : null,
      args: JSON.stringify(args),
    })
  }

  const enhanced = async(...args) => {
    const key = createKey(...args)
    let response = cache.get(key)
    if (response === undefined) {
      response = await fn(...args)
      cache.set(key, response)
    }
    return response
  }

  // copy original function's properties
  Object.getOwnPropertyNames(fn)
    .filter(key => key !== 'name' && key !== 'length')
    .forEach(key => {
      enhanced[key] = fn[key]
    })

  enhanced.uncached = async(...args) => {
    const response = await fn(...args)
    const key = createKey(...args)
    cache.set(key, response)
    return response
  }

  enhanced.skipCache = async(...args) => {
    return await fn(...args)
  }

  enhanced.evict = async(...args) => {
    const key = createKey(...args)
    cache.del(key)
  }

  enhanced.clearCache = async() => {
    cache.reset()
  }

  return enhanced
}

export const withCleanCache = (fn, ...caches) => async(...args) => {
  if (caches) {
    caches.forEach(c => c.reset())
  } else if (fn.clearCache) {
    fn.clearCache()
  }

  return await fn(...args)
}
