import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import React from 'react'
import PropTypes from 'prop-types'

import OptimizationSkuTableRow from 'components/ui/OptimizationSkuTableRow/OptimizationSkuTableRow'
import { SkuStatus } from 'services/enums'
import customPropTypes from 'utils/customPropTypes'

import styles from './RecommendedAssortment.module.scss'

const RecommendedAssortmentTable = ({
  subcategory,
  skus,
  readOnly,
  readOnlyLock,
  onLock,
  onRestore,
  skuRowDisabled,
  allClusters,
  isAdmin,
}) => {
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>{subcategory?.name || 'subcategory'}</TableCell>
          { !readOnly && isAdmin && <TableCell align="left">Lock</TableCell> }
          { !readOnly && <TableCell align="left">Remove/Restore</TableCell> }
        </TableRow>
      </TableHead>
      <TableBody>
        {
          skus?.map((sku) => (
            <OptimizationSkuTableRow
              id={sku.id}
              key={sku.id}
              className={skuRowDisabled === sku.id ? styles.rowDisabled : null}
              name={sku.name}
              locked={sku.locked}
              optimizationStatus={sku.optimizationStatus}
              statusOverride={sku.statusOverride}
              readOnly={readOnly}
              readOnlyLock={readOnlyLock}
              onLock={() => onLock(sku.id, true)}
              onUnlock={() => onLock(sku.id, false)}
              onRestore={() => onRestore(sku.id, true)}
              onRemove={() => onRestore(sku.id, false)}
              allClusters={allClusters}
            />
          ))
        }
      </TableBody>
    </Table>
  )
}
RecommendedAssortmentTable.propTypes = {
  subcategory: customPropTypes.category,
  skus: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    locked: PropTypes.bool,
    optimizationStatus: PropTypes.oneOf(SkuStatus.values()),
    statusOverride: PropTypes.oneOf(SkuStatus.values()),
  })),
  readOnly: PropTypes.bool.isRequired,
  readOnlyLock: PropTypes.bool.isRequired,
  onLock: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  skuRowDisabled: PropTypes.number,
  allClusters: PropTypes.bool,
  isAdmin: PropTypes.bool,
}

export default RecommendedAssortmentTable
