import React from 'react'
import PropTypes from 'prop-types'

import { H4 } from 'components/ui/Typography'
import customPropTypes from 'utils/customPropTypes'
import ModalContainer from 'components/ui/Dialog/ModalContainer'
import ProgressIndicator from 'components/ui/ProgressIndicator'

import styles from './OperationInProgress.module.scss'

const OperationInProgressModal = ({ open, title, children, className }) => {
  return (
    <ModalContainer title={title} open={open} >
      <div className={`flex-col-center ${styles.container} ${className || ''}`}>
        <ProgressIndicator />
        {!!title && <H4 className="title"> {title} </H4>}
        <div className={styles.cardBody}>
          {children}
        </div>
      </div>
    </ModalContainer>
  )
}
OperationInProgressModal.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  children: customPropTypes.children.isRequired,
  className: PropTypes.string,
}

export default OperationInProgressModal
