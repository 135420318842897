function frozen(obj) {
  Object.keys(obj).forEach(prop => {
    if (typeof obj[prop] === 'object' && !Object.isFrozen(obj[prop])) {
      frozen(obj[prop])
    }
  })

  return Object.freeze(obj)
}

export default process.env.NODE_ENV === 'development' ? frozen : obj => obj
