import axios from 'services/api/axios.instance'
import { config, withMeta } from 'services/api/common'
import {
  KPIsInForm,
  categoriesInForm,
  clustersInForm,
  periodsInForm,
  subcategoriesTotalInForm, skusInForm, skusTotalInForm,
} from 'services/api/tracking/adapter'
import { storeInForm } from 'services/api/clusters/adapters/storeAdapter'
import { cached } from 'utils/cache'
import { all, paged } from 'services/api/pager'
import cache from 'services/api/clusters/api.cache'

const getExecutionPeriods = async() => {
  return axios.get('/executions')
    .then(data => withMeta(data, data => data.map(item => periodsInForm(item))))
}

const getExecutionClusters = async(executionId) => {
  return axios.get(`/executions/${executionId}/clusters`)
    .then(data => withMeta(data, data => data.map(item => clustersInForm(item))))
}

const getExecutionClusterStores = async(page, executionId, clusterId) => {
  page = page || {}
  return axios.get(`/executions/${executionId}/clusters/${clusterId}/stores`, {
    params: {
      ...page,
    },
  }).then(data => withMeta(data, data => data.map(item => storeInForm(item))))
}

const getExecutionKeyIndicators = async(executionId, clusterId, storeId, compareTo) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += '/kpis'
  return axios.get(url, {
    params: { compareTo },
  }).then(data => withMeta(data, data => KPIsInForm(data, !!compareTo)))
}

const exportExecutionTracking = async(executionId, type) => {
  return axios.get(`/executions/${executionId}`, {
    params: {
      type: type,
      format: 'csv',
    },
  })
}

const getExecutionTrackingCategoriesTotal = async(executionId, clusterId, storeId) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += '/categories'

  return axios.get(url, { params: { total: true } })
    .then(data => withMeta(data, data => categoriesInForm(data)))
}

const TRACKING_QUERY_PARAMS_DEFAULT = { sortBy: 'name', sortOrder: 'asc' }

const getExecutionTrackingCategories = async(executionId, clusterId, storeId, queryParams = TRACKING_QUERY_PARAMS_DEFAULT) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += '/categories'

  return axios.get(url, { params: { ...queryParams, total: false } })
    .then(data => withMeta(data, data => data.map(item => categoriesInForm(item))))
}

const getExecutionTrackingSubcategoriesTotal = async(executionId, clusterId, storeId, categoryId) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += `/categories/${categoryId}/subcategories`

  return axios.get(url, { params: { total: true } })
    .then(data => withMeta(data, data => subcategoriesTotalInForm(data)))
}

const getExecutionTrackingSubcategories = async(page, executionId, clusterId, storeId, categoryId, queryParams = TRACKING_QUERY_PARAMS_DEFAULT) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += `/categories/${categoryId}/subcategories`

  return axios.get(url, { params: { ...page, total: false, ...queryParams } })
    .then(data => withMeta(data, data => data.map(item => categoriesInForm(item))))
}

const getExecutionTrackingSKUTotal = async(executionId, clusterId, storeId, categoryId, subcategoryId) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += `/categories/${categoryId}/subcategories/${subcategoryId}/skus`

  return axios.get(url, { params: { total: true } })
    .then(data => withMeta(data, data => skusTotalInForm(data)))
}

const getExecutionTrackingSKUs = async(page, executionId, clusterId, storeId, categoryId, subcategoryId, queryParams = TRACKING_QUERY_PARAMS_DEFAULT) => {
  let url = `/executions/${executionId}`
  if (clusterId) {
    url += `/clusters/${clusterId}`

    if (storeId) {
      url += `/stores/${storeId}`
    }
  }
  url += `/categories/${categoryId}/subcategories/${subcategoryId}/skus`

  return axios.get(url, { params: { ...page, total: false, ...queryParams } })
    .then(data => withMeta(data, data => data.map(item => skusInForm(item))))
}

const api = {
  getExecutionPeriods,
  getExecutionKeyIndicators,
  getExecutionClusters,
  getExecutionClusterStores: cached(all(getExecutionClusterStores), 'getExecutionClusterStores', cache.default, () => config.accessToken),
  exportExecutionTracking,
  getExecutionTrackingCategories,
  getExecutionTrackingCategoriesTotal,
  getExecutionTrackingSubcategories: all(getExecutionTrackingSubcategories),
  getExecutionTrackingSubcategoriesTotal,
  getExecutionTrackingSKUs: paged(getExecutionTrackingSKUs, 10),
  getExecutionTrackingSKUTotal,
}

export default api
