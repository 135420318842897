// noinspection DuplicatedCode

import log from 'utils/log'

const MAX_IPP = Number.MAX_SAFE_INTEGER

export const all = (fn) => async(...args) => {
  log.assert(typeof fn === 'function', 'Argument fn is not a function!')

  const result = []

  const page = { ipp: MAX_IPP }
  let hasMoreData
  do {
    try {
      const resp = await fn(page, ...args)

      if (Array.isArray(resp)) {
        result.push(...resp)
        page.pa = resp._meta.lastId
        hasMoreData = !!resp.length && !!page.pa
      } else {
        return result.length ? result : resp
      }
    } catch (err) {
      if (err.statusCode === 404) {
        hasMoreData = false
      } else {
        throw err
      }
    }
  } while (hasMoreData)

  return result
}

export const paged = (fn, ipp) => {
  const pagedFn = async(...args) => {
    log.assert(typeof fn === 'function', 'Argument fn is not a function!')

    let resp, hasNext, eol
    try {
      resp = await fn({ ipp }, ...args)
      eol = !(Array.isArray(resp) && resp.length)
      hasNext = Array.isArray(resp) && resp.length && resp?._meta?.lastId
    } catch (err) {
      if (err.statusCode === 404) {
        resp = []
      } else {
        throw err
      }
    }

    const history = [resp._meta.lastId]
    const state = { ipp, history, args }

    return _responseWithPager(resp, hasNext, false, eol, true, history, fn, ipp, state, ...args)
  }

  pagedFn.restore = state => _restore(fn, state)

  return pagedFn
}

/**
 * A function that restores previously saved state of the pager.
 * Useful when we need to jump to the "saved" state (e.g. when returning to the paginated view from a detail child view)
 */
async function _restore(fn, { ipp, history, args }) {
  let resp, hasNext, hasPrevious, eol
  try {
    history.pop()
    resp = await fn({ ipp, pa: history.slice(-1).pop() }, ...args)

    const hasData = Array.isArray(resp) && resp.length
    eol = !hasData
    history = hasData ? [...history, resp._meta.lastId] : history
    hasNext = hasData && resp?._meta?.lastId
    hasPrevious = history.length > 1
  } catch (err) {
    if (err.statusCode === 404) {
      resp = []
      eol = true
      hasPrevious = history.length > 1
    } else {
      throw err
    }
  }

  const state = { ipp, history, args }

  return _responseWithPager(resp, hasNext, hasPrevious, eol, false, history, fn, ipp, state, ...args)
}

async function _nextPage(fn, ipp, history, ...args) {
  let resp, hasNext, hasPrevious, eol
  try {
    resp = await fn({ ipp, pa: history.slice(-1).pop() }, ...args)

    const hasData = Array.isArray(resp) && resp.length
    eol = !hasData
    history = hasData ? [...history, resp._meta.lastId] : history
    hasNext = hasData && resp?._meta?.lastId
    hasPrevious = history.length > 1
  } catch (err) {
    if (err.statusCode === 404) {
      resp = []
      eol = true
      hasPrevious = history.length > 1
    } else {
      throw err
    }
  }

  const state = { ipp, history, args }

  return _responseWithPager(resp, hasNext, hasPrevious, eol, false, history, fn, ipp, state, ...args)
}

async function _previousPage(fn, ipp, history, ...args) {
  let resp, hasNext, hasPrevious, eol
  try {
    const historyHead = history.slice(0, -2)
    const previousPa = history.slice(-3, -2).pop()

    resp = await fn({ ipp, pa: previousPa }, ...args)

    const isArray = Array.isArray(resp)
    eol = !(isArray && resp.length)
    history = isArray ? [...historyHead, resp._meta.lastId] : history
    hasNext = isArray && resp.length && resp?._meta?.lastId
    hasPrevious = history.length > 1
  } catch (err) {
    if (err.statusCode === 404) {
      resp = []
      eol = true
      hasPrevious = history.length > 1
    } else {
      throw err
    }
  }

  const state = { ipp, history, args }

  return _responseWithPager(resp, hasNext, hasPrevious, eol, false, history, fn, ipp, state, ...args)
}

function _responseWithPager(resp, hasNext, hasPrevious, eol, initial, history, fn, ipp, state, ...args) {
  resp._meta = resp._meta || {}
  resp._meta.pager = {
    ...(resp._meta.pager || {}),
    next: hasNext ? () => _nextPage(fn, ipp, history, ...args) : null,
    previous: hasPrevious ? () => _previousPage(fn, ipp, history, ...args) : null,
    eol,
    initial,
    state,
  }

  return resp
}

export const hasNext = data => data?._meta?.pager?.next
export const hasPrevious = data => data?._meta?.pager?.previous
export const isEol = data => data?._meta?.pager?.eol
export const isInitial = data => data?._meta?.pager?.initial

export const pagedState = (state, data) => {
  if (!isInitial(data) && isEol(data)) {
    return {
      ...state,
      _meta: data._meta,
    }
  } else {
    return data
  }
}

const pager = {
  all,
  paged,
  hasNext,
  hasPrevious,
}

export default pager
