import React, { useCallback, useState } from 'react'

import RegisterForm from './RegisterForm/RegisterForm'
import VerificationLink from './VerificationLink/VerificationLink'
import { REGISTER_FORM, REGISTER_VERIFICATION } from './registerConstants'

const RegisterFlow = () => {
  const [activeStep, setActiveStep] = useState(REGISTER_FORM)

  const handlerRegisterFormOnContinue = useCallback(() => setActiveStep(REGISTER_VERIFICATION), [])
  const handlerVerificationLinkOnCancel = useCallback(() => setActiveStep(REGISTER_FORM), [])

  return (
    <div>
      {(activeStep === REGISTER_FORM) && <RegisterForm onContinue={handlerRegisterFormOnContinue} />}
      {(activeStep === REGISTER_VERIFICATION) && <VerificationLink onCancel={handlerVerificationLinkOnCancel} />}
    </div>
  )
}
export default RegisterFlow
