import { useCallback, useMemo } from 'react'

import log from 'utils/log'
import { rangeCap } from 'utils/math'

/**
 *
 * @param mid Middle value in the heatmap scale
 * @param max Maximum value in the heatmap scale.
 *            Entire scale is [-max, ..., mid, ..., max]
 * @param classes An array of classes for each segment in the heatmap. It should have odd number of elements.
 * @return function A function "getHeatmapClass" that accepts numerical value and returns class name for that value.
 */
const useHeatmap = (mid, max, classes) => {
  log.assert(classes.length % 2 === 1, 'Odd number of classes is required.')

  const { midIndex, segmentWidth, segmentsNum } = useMemo(() => {
    const segmentsNum = Math.floor(classes.length / 2)
    const midIndex = segmentsNum
    const segmentWidth = (max - mid) / segmentsNum
    return {
      midIndex,
      segmentWidth,
      segmentsNum,
    }
  }, [classes, max, mid])

  return useCallback(value => {
    if (value === mid) {
      return classes[midIndex]
    }

    if (value > mid) {
      const index = rangeCap(Math.floor((value - mid) / segmentWidth + 0.5), 0, segmentsNum)
      return classes[midIndex + index]
    }

    if (value < mid) {
      const index = rangeCap(Math.floor((mid - value) / segmentWidth + 0.5), 0, segmentsNum)
      return classes[midIndex - index]
    }
  }, [classes, mid, midIndex, segmentWidth, segmentsNum])
}

export default useHeatmap
