import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { actions } from 'store'
import { Button } from '@mui/material'

import { isEmailValid } from 'utils/validators'
import Input from 'components/ui/Input'
import { useForm, useRevalidate } from 'hooks/useForm'
import { H4, P } from 'components/ui/Typography'

import styles from './ResetPasswordEmailInput.module.scss'

const initialValues = {
  email: '',
}

function validate(values, flag) {
  const errors = {}
  if (!values.email?.trim()) {
    errors.email = { required: true }
  } else if (!isEmailValid(values.email, 8, 50)) {
    errors.email = { policy: true }
  }
  return errors
}

const ResetPasswordEmailInput = ({ onContinue }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const submit = useCallback(values => {
    return dispatch(actions.auth.resetPasswordForEmail(values))
      .then(onContinue)
  }, [dispatch, onContinue])

  const revalidate = useRevalidate()
  const { errors, submitForm, values, setValue } =
    useForm('emailInput', validate, submit, initialValues, revalidate)

  return (
    <div className={'flex-col-center'}>
      <H4 className={styles.title}> Reset password </H4>
      <P className={styles.text}> Forgot your password? Don’t worry,
        we will send you a reset password link! </P>
      <Input
        name="email"
        label="Email"
        type="email"
        value={values.email || ''}
        onChange={setValue}
        error={!!errors?.email}
        helperText={errors?.email?.[0]}
        fullWidth
      />
      <Button
        className={styles.resetButton}
        onClick={submitForm}
        variant='contained'
      > Reset password </Button>

      <Button
        className={styles.cancelButton}
        onClick={() => navigate('/login')}
        variant="primary"
      > Cancel </Button>
    </div >
  )
}

ResetPasswordEmailInput.propTypes = {
  onContinue: PropTypes.func.isRequired,
}
export default ResetPasswordEmailInput
