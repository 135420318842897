import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './ReadyForCategoryManagerModal.module.scss'

const ReadyForCategoryManagerModal = ({ open, onClose, onConfirm }) => {
  return (
    <Dialog
      title="Ready for Category manager review"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6>You are about to notify all category managers that assortment is ready for their review. </H6>
        <H6>Do you want to proceed?</H6>
        <Button
          className={styles.confirmButton}
          onClick={onConfirm}
          variant="contained"
        >Notify CMs</Button>
        <Button
          className={styles.cancelButton}
          onClick={onClose}
          variant="primary"
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

ReadyForCategoryManagerModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ReadyForCategoryManagerModal
