import React, { useState, useCallback, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { actions } from 'store'
import PropTypes from 'prop-types'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Button } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import { useNavigate } from 'react-router'

import useData from 'hooks/useData'
import { useForm, useRevalidate } from 'hooks/useForm'
import Dialog from 'components/ui/Dialog'
import { P, Errors } from 'components/ui/Typography'
import SliderEditor from 'components/ui/SliderEditor'
import AutoTextField from 'components/ui/AutoTextField'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import { InfoIcon } from 'assets/icons'
import NewDraft from 'pages/NewDraft'
import { isDefined, nvl } from 'utils/object'
import { sum } from 'utils/math'
import { OptimizationStatus } from 'services/enums'
import { noop } from 'utils/language'
import Suspended from 'components/ui/Suspended'
import useBooleanState from 'hooks/useBooleanState'
import useToast from 'components/ui/Toast/useToast'

import styles from './ConfigureProperties.module.scss'

function validateGoals(values) {
  const errors = {}
  if (sum(values.revenue, values.margin || 0, values.baskets) !== 100) {
    errors.goals = { sum: true }
  }
  return errors
}

function validateLimits(values, config) {
  const errors = {}
  const result = Math.round((values.skuNumberDefaultValue * 20) / 100)
  if (values.innovationSkuNumber.value >= result) {
    errors.innovationSkuNumber = { requestedNumber: true }
  }

  if (values.skuNumber.value > config?.limits.skuNumber.defaultValue) {
    errors.skuNumber = { max: true }
  }

  return errors
}

const ConfigureProperties = ({ open, onClose, clusterId }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { errorToast } = useToast()

  const { limits, goals, id: optimizationId, status } = useSelector(state => state.optimizations.optimization || {})
  const draft = status === OptimizationStatus.draft

  const { loaded, error } = useData(() => {
    return Promise.all([
      dispatch(actions.optimizations.fetchGoals(optimizationId, clusterId)),
      dispatch(actions.optimizations.fetchLimits(optimizationId, clusterId)),
    ])
  }, [dispatch, optimizationId, clusterId])

  const isMarginDisabled = goals && !isDefined(goals.margin)
  const isInnovationSkuNumberDisable = useMemo(() => !limits?.innovationSkuNumber?.editable, [limits])
  const isSkuNumberDisable = useMemo(() => !limits?.skuNumber?.editable, [limits])

  const [allClustersChecked, setAllClustersChecked] = useState(!clusterId)
  const [isNewDraftModalOpen, openNewDraftModal, closeNewDraftModal] = useBooleanState(false)

  const initialValuesGoals = useMemo(() => {
    const revenue = nvl(goals?.revenue, 0)
    const margin = nvl(goals?.margin, 0)
    const baskets = nvl(goals?.baskets, 0)

    return { revenue, margin, baskets }
  }, [goals])

  const initialValuesLimits = useMemo(() => {
    const innovationSkuNumber = {
      automatic: nvl(limits?.innovationSkuNumber?.automatic, false),
      value: nvl(limits?.innovationSkuNumber?.value, 0),
    }

    const skuNumberDefaultValue = nvl(limits?.skuNumber?.defaultValue, 0)
    const skuNumber = {
      automatic: nvl(limits?.skuNumber?.automatic, false),
      value: nvl(limits?.skuNumber?.value, 0),
    }
    return { innovationSkuNumber, skuNumber, skuNumberDefaultValue }
  }, [limits])

  const handleAllClustersChecked = useCallback(({ target: { checked } }) => {
    setAllClustersChecked(checked)
  }, [])

  const submitGoals = useCallback((values, { optimizationId }) => {
    const margin = isMarginDisabled ? null : values.margin
    return dispatch(actions.optimizations.setGoals(
      optimizationId,
      clusterId,
      { revenue: values.revenue, margin, baskets: values.baskets },
      allClustersChecked),
    )
  }, [allClustersChecked, clusterId, dispatch, isMarginDisabled])

  const submitLimits = useCallback((values, { optimizationId }) => {
    if (isSkuNumberDisable && isInnovationSkuNumberDisable) return null
    else {
      return dispatch(actions.optimizations.setLimits(
        optimizationId,
        clusterId,
        { skuNumber: isSkuNumberDisable ? undefined : values.skuNumber, innovationSkuNumber: isInnovationSkuNumberDisable ? undefined : values.innovationSkuNumber },
      ))
    }
  }, [clusterId, isSkuNumberDisable, isInnovationSkuNumberDisable, dispatch])

  const revalidateGoals = useRevalidate()
  const {
    errors: errorsGoals,
    submitForm: submitFormGoals,
    validateForm: validateFormGoals,
    processing: processingGoals,
    values: valuesGoals,
    setValue: setValueGoals,
    clear: clearGoals,
    markSubmitted: markSubmittedGoals,
  } = useForm('optimizationConfigGoals', validateGoals, submitGoals, initialValuesGoals, revalidateGoals)

  const revalidateLimits = useRevalidate()
  const {
    errors: errorsLimits,
    submitForm: submitFormLimits,
    validateForm: validateFormLimits,
    processing: processingLimits,
    values: valuesLimits,
    setValue: setValueLimits,
    clear: clearLimits,
    markSubmitted: markSubmittedLimits,
  } = useForm('optimizationConfigLimits', validateLimits, submitLimits, initialValuesLimits, revalidateLimits)

  const handleClose = useCallback(() => {
    onClose()
    clearGoals()
    clearLimits()
  }, [onClose, clearGoals, clearLimits])

  const handleConfirm = useCallback(() => {
    if (draft) {
      return Promise.all([
        submitFormGoals({ optimizationId }),
        submitFormLimits({ optimizationId, limits }),
      ]).then(handleClose)
        .catch(noop)
    } else {
      markSubmittedGoals()
      markSubmittedLimits()
      validateFormGoals({ optimizationId })
        .then(() => validateFormLimits({ optimizationId, limits }))
        .then(() => openNewDraftModal())
        .catch(noop)
    }
  }, [draft, submitFormGoals, optimizationId, handleClose, submitFormLimits, limits, markSubmittedGoals, markSubmittedLimits, validateFormGoals, validateFormLimits, openNewDraftModal])

  const handleNewDraftCreated = useCallback((newOptimizationId) => {
    return Promise.all([
      submitFormGoals({ optimizationId: newOptimizationId }),
      submitFormLimits({ optimizationId: newOptimizationId, limits }),
    ])
      .then(() => navigate(`/assortment-optimization/details/${newOptimizationId}`))
      .catch((err) => {
        onClose()
        navigate(`/assortment-optimization/details/${newOptimizationId}`)
        errorToast(`Update was rejected by server. Please try again: ${err.message}`)
      })
  }, [errorToast, limits, navigate, onClose, submitFormGoals, submitFormLimits])

  return (
    <Dialog
      title="Configure properties"
      open={open}
      onClose={handleClose}
      className={styles.dialog}
    >
      {
        loaded ?
          <div className={`flex-col-center ${styles.configContainer}`}>
            <P className={styles.subtitle}>Reconfiguring the goal function will require you to run the optimization again</P>

            <div className={`flex-col-center ${styles.container}`}>
              <Errors errors={errorsGoals?._} />
              <Errors errors={errorsLimits?._} />
              <div className={`flex-col-center ${styles.sliderContainer}`}>
                <SliderEditor
                  label='Revenue'
                  value={valuesGoals.revenue}
                  onChange={value => setValueGoals('revenue', value)}
                  className={styles.revenueSliderEditor}
                />
                <SliderEditor
                  label='Gross margin'
                  disabled={isMarginDisabled}
                  value={valuesGoals.margin}
                  onChange={value => setValueGoals('margin', value)}
                  className={styles.marginSliderEditor}
                />
                <SliderEditor
                  label='Penetration in baskets'
                  value={valuesGoals.baskets}
                  onChange={value => setValueGoals('baskets', value)}
                  className={styles.basketsSliderEditor}
                />
              </div>
              {!!errorsGoals?.goals && <P className={styles.error}>{errorsGoals?.goals?.[0]}</P>}

              <div className={'flex-row-center'}>
                <FormControlLabel
                  label="Apply this configuration of goal function to all clusters"
                  control={
                    <Checkbox
                      checked={allClustersChecked}
                      className="checkbox"
                      disabled={!clusterId}
                      onChange={handleAllClustersChecked}
                    />
                  }
                />
              </div>
            </div>

            <div className={styles.line} />

            <div className={`flex-col-center ${styles.container}`}>
              <div>
                <div className='flex-row-center'>
                  <P>Maximum number of SKUs allowed in assortment:</P>

                  <Tooltip title="Final assortment depth will go near requested number to maximize effects.">
                    <InfoIcon className="icon small" />
                  </Tooltip>
                </div>
                <AutoTextField
                  automatic={valuesLimits.skuNumber.automatic}
                  disabled={isSkuNumberDisable}
                  value={valuesLimits.skuNumber.value}
                  automaticValue={limits.skuNumber.defaultValue}
                  onChange={(checked, value) => setValueLimits('skuNumber', { automatic: checked, value })}
                />
                {!!errorsLimits?.skuNumber && <P className={styles.error}>{errorsLimits?.skuNumber?.[0]}</P>}
              </div>

              <AutoTextField
                title='Number of innovation and seasonal of SKUs allowed in assortment:'
                disabled={isInnovationSkuNumberDisable}
                automatic={valuesLimits.innovationSkuNumber.automatic}
                value={valuesLimits.innovationSkuNumber.value}
                automaticValue={limits.innovationSkuNumber.defaultValue}
                onChange={(checked, value) => setValueLimits('innovationSkuNumber', { automatic: checked, value })}
              />
              {!!errorsLimits?.innovationSkuNumber && <P className={styles.error}>{errorsLimits?.innovationSkuNumber?.[0]}</P>}

              <P>*Number of SKUs in assortment have to be configured for each cluster separately</P>
            </div>

            <div className={`flex-col-center ${styles.buttonsContainer}`}>
              <Button
                className={styles.containedButton}
                onClick={handleConfirm}
                variant="contained"
                size="medium"
                disabled={processingGoals || processingLimits}
              >
                Confirm
              </Button>
              <Button
                className={styles.button}
                onClick={handleClose}
                variant="primary"
              >
                Cancel
              </Button>
            </div>
          </div> :
          <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }

      <Suspended open={isNewDraftModalOpen}>
        <NewDraft
          open={isNewDraftModalOpen}
          onCreate={handleNewDraftCreated}
          onClose={closeNewDraftModal}
        />
      </Suspended>
    </Dialog>
  )
}

ConfigureProperties.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  clusterId: PropTypes.number,
}

export default ConfigureProperties
