import React from 'react'
import PropTypes from 'prop-types'
import { selectors } from 'store'
import { useSelector } from 'react-redux'

import PieChart from 'components/ui/PieChart'

import styles from './ClusterCharts.module.scss'

function ClusterCharts({ className }) {
  const pieChartData = useSelector(selectors.clusters.pieChartData)

  return (
    !!pieChartData && <div className={`flex-row-center ${styles.ClusterCharts} ${className || ''}`}>
      {
        pieChartData.map(pieChart =>
          <PieChart key={pieChart.title} title={pieChart.title} values={pieChart.values} className={styles.pieChart} />,
        )
      }
    </div>
  )
}

ClusterCharts.propTypes = {
  className: PropTypes.string,
}

export default ClusterCharts
