import React from 'react'
import PropTypes from 'prop-types'

import styles from './ClusterMarker.module.scss'

const ClusterMarker = ({
  className,
  onClick,
  text,
}) => {
  return (
    <div
      className={`${styles.circle} ${className}`}
      onClick={onClick}
    >
      <span className={styles.circleText}>
        {text}
      </span>
    </div>
  )
}
ClusterMarker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default ClusterMarker
