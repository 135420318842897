import React, { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { actions } from 'store'

import LogoutModal from 'components/ui/LogoutModal'

function Logout() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const handleClose = () => {
    navigate(-1)
  }

  const handleLogout = useCallback(() => {
    dispatch(actions.users.loadUserProfileSuccess(null))
    dispatch(actions.tracking.fetchExecutionTrackingCategoriesSuccess(null))
    dispatch(actions.auth.logout())
  }, [dispatch])

  return (
    <LogoutModal onClose={handleClose} onConfirm={handleLogout} open={true} />
  )
}

export default Logout
