import moment from 'moment'

const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December',
]

const getTitle = (createdAt, numberOfMonths) => {
  const optimizationDate = moment(createdAt, 'YYYY-MM-D H:m:s').toDate()
  const month = optimizationDate.getMonth()
  const year = optimizationDate.getFullYear()
  switch (numberOfMonths) {
    case 1:
      return `${monthNames[month]}, ${year} `
    case 2:
      if (month <= 1) return `January - February, ${year}`
      if (month <= 3) return `March - April, ${year}`
      if (month <= 5) return `May - June, ${year}`
      if (month <= 7) return `July - August, ${year}`
      if (month <= 9) return `September - October, ${year}`
      return `November - December, ${year}`
    case 4:
      if (month <= 3) return `Trimestar 1, ${year}`
      if (month <= 7) return `Trimestar 2, ${year}`
      return `Trimestar 3, ${year}`
    case 6:
      if (month <= 5) return `Semestar 1, ${year}`
      return `Semestar 2, ${year}`
    case 12:
      return `${year}`
    case 3:
    default:
      if (month <= 2) return `Quartal 1, ${year}`
      if (month <= 5) return `Quartal 2, ${year}`
      if (month <= 8) return `Quartal 3, ${year}`
      return `Quartal 4, ${year}`
  }
}

export default getTitle
