/**
 * Returns a promise that never resolves nor rejects.
 */
if (!Promise.never) {
  // eslint-disable-next-line no-extend-native
  Promise.never = function() {
    return new Promise(() => {})
  }
}

/**
 * Returns a resolved promise with specified value and specified delay.
 */
if (!Promise.resolveDelayed) {
  // eslint-disable-next-line no-extend-native
  Promise.resolveDelayed = function(value, tout) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(value)
      }, tout)
    })
  }
}

/**
 * Returns a rejected promise with specified value and specified delay.
 */
if (!Promise.rejectDelayed) {
  // eslint-disable-next-line no-extend-native
  Promise.rejectDelayed = function(value, tout) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        reject(value)
      }, tout)
    })
  }
}

/**
 * Old good deferred object.
 */
export const deferred = () => {
  let resolve, reject
  const promise = new Promise(function() {
    resolve = arguments[0]
    reject = arguments[1]
  })
  return {
    resolve: resolve,
    reject: reject,
    promise: promise,
  }
}
