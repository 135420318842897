export const storeKey = (key, value) => {
  if (value === null || value === undefined) {
    return removeKey(key)
  } else {
    return localStorage.setItem(key, JSON.stringify(value))
  }
}

export const fetchKey = (key) => {
  return JSON.parse(localStorage.getItem(key))
}

export const removeKey = (key) => {
  return localStorage.removeItem(key)
}

const keysObject = {
  fetchKey,
  storeKey,
  removeKey,
}

export default keysObject
