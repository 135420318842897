import api from 'services/api'
import { pass } from 'utils/flow'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const loadUserProfile = () => dispatch => {
  return api.users.getProfile()
    .then(profile => pass(profile, () => dispatch(loadUserProfileSuccess(profile))))
}
export const loadUserProfileSuccess = profile => ({ type: types.loadUserProfileSuccess, profile })

export const fetchUsers = (search) => dispatch => {
  return api.users.getUsers(search)
    .then(data => pass(data, () => dispatch(fetchUsersSuccess(data))))
}
export const fetchUsersSuccess = (data) =>
  ({ type: types.fetchUsersSuccess, data })

export const fetchUser = (userId) => dispatch => {
  return api.users.getUser(userId)
    .then(data => pass(data, () => dispatch(fetchUserSuccess(data))))
}
export const fetchUserSuccess = (data) =>
  ({ type: types.fetchUserSuccess, data })

export const deleteUser = (userId) => dispatch => {
  return api.users.deleteUser(userId)
    .then(data => pass(data, () => dispatch(deleteUserSuccess(userId))))
}
const deleteUserSuccess = (userId) => ({ type: types.deleteUserSuccess, userId })

export const suspendUser = (userId) => dispatch => {
  return api.users.suspendUser(userId)
    .then(data => pass(data, () => dispatch(suspendUserSuccess(userId))))
}
const suspendUserSuccess = (userId) => ({ type: types.suspendUserSuccess, userId })

export const restoreUser = (userId) => dispatch => {
  return api.users.restoreUser(userId)
    .then(data => pass(data, () => dispatch(restoreUserSuccess(userId))))
}
const restoreUserSuccess = (userId) => ({ type: types.restoreUserSuccess, userId })

export const resendUserConfirmationLink = (userId) => dispatch => {
  return api.users.resendUserConfirmationLink(userId)
    .then(() => dispatch(resendUserConfirmationLinkSuccess(userId)))
}

const resendUserConfirmationLinkSuccess = (userId) => ({ type: types.resendUserConfirmationLinkSuccess, userId })

export const updateUser = ({ id, firstName, lastName, password, categories }) => dispatch => {
  return api.users.updateUser({ id, firstName, lastName, password, categories })
    .then(data => pass({ data, id, firstName, lastName, categories }, () => dispatch(updateUserSuccess({ data, id, firstName, lastName, categories }))))
}
const updateUserSuccess = ({ data, firstName, lastName, categories }) =>
  ({ type: types.updateUserSuccess, data, firstName, lastName, categories })

export const resetPassword = ({ id, password }) => () => {
  return api.users.updateUser({ id, password })
}

export const approveCategoryManager = (id, categories) => dispatch => {
  return api.users.approveCategoryManager(id, categories)
    .then(data => pass(data, () => dispatch(approveCategoryManagerSuccess(id, categories))))
}
const approveCategoryManagerSuccess = (id, categories) =>
  ({ type: types.approveCategoryManagerSuccess, id, categories })

export const updateProfilePhoto = (id, photo) => dispatch => {
  return api.users.updateProfilePhoto(id, photo)
    .then(data => pass(data, () => dispatch(updateProfilePhotoSuccess(photo))))
}
const updateProfilePhotoSuccess = data =>
  ({ type: types.updateProfilePhotoSuccess, data })

export const enableEmailNotifications = ({ id, enableNotifications }) => dispatch => {
  return api.users.updateUserSettings(id, enableNotifications)
    .then(() => dispatch(enableEmailNotificationsSuccess(enableNotifications)))
}

const enableEmailNotificationsSuccess = data =>
  ({ type: types.enableEmailNotificationsSuccess, data })
