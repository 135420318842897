import axiosBase from 'axios'

import axios from 'services/api/axios.instance'

import { withMeta } from '../common'

import { dataInForm } from './adapters/notificatonsAdapter'

const getUserNotifications = search => {
  return axios.get('/notifications', { params: { search } })
    .then(data => {
      return withMeta(data, data => dataInForm(data))
    })
}

const dismissNotification = id => {
  return axios.post(`/notifications/${id}/dismiss-commands`)
}

const dismissAllNotifications = () => {
  return axios.post('/notifications/dismiss-all-commands')
}

const checkURLValidity = url => {
  return axiosBase.create({
    baseURL: url,
  }).get('')
}

const downloadExport = url => {
  const link = document.createElement('a')
  link.href = url
  document.body.appendChild(link)
  return link.click()
}

const api = {
  getUserNotifications,
  dismissNotification,
  dismissAllNotifications,
  checkURLValidity,
  downloadExport,
}

export default api
