import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { P } from 'components/ui/Typography'

import styles from './ConfirmClusters.module.scss'

const ConfirmClusters = ({ openModal, onClose, onConfirm }) => {
  return (
    <Dialog
      className={styles.dialog}
      title="Confirm clusters"
      open={openModal}
      onClose={onClose}
    >
      <div className={`flex-col-center ${styles.body}`}>
        <div className={`flex-col-center ${styles.description}`}>
          <P>
            All future assortment optimizations will be based on these clusters. This action cannot be undone.
          </P>
          <P>
            Do you want to proceed?
          </P>
        </div>

        <div className={`flex-col-center ${styles.buttonContainer}`}>
          <Button className={styles.confirmButton} onClick={onConfirm} variant="contained" size="small">
            Confirm
          </Button>
          <Button className={styles.cancelButton} onClick={onClose} variant="primary" size="small">
            Cancel
          </Button>
        </div>
      </div>
    </Dialog>
  )
}

ConfirmClusters.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default ConfirmClusters
