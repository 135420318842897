import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  navigate: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.navigate]: navigate,
  [types.navigated]: navigated,
}

function vacate() {
  return initialState
}

function navigate(state, { to, replace, state: navState }) {
  return {
    ...state,
    navigate: {
      to,
      replace,
      state: navState,
    },
  }
}

function navigated(state) {
  return {
    ...state,
    navigate: null,
  }
}

export default objectReducer(initialState, actionHandlers)
