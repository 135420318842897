import { createSelector } from 'reselect'

export const hydratedUsers = createSelector(
  state => state.users.users,
  state => state.organizations.categories,
  (users, categories) => users?.map(user => {
    return {
      ...user,
      categories: user.categories
        ?.map(category => categories?.find(c => c.id === category))
        ?.filter(c => !!c),
    }
  }),
)

export const userWithCategories = createSelector(
  state => state.users.user,
  state => state.organizations.categories,
  (user, categories) => {
    return {
      ...user,
      categories: user?.categories
        ?.map(category => categories?.find(c => c.id === category))
        ?.filter(c => !!c),
    }
  },
)
