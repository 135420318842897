import axios from 'services/api/axios.instance'
import { cached } from 'utils/cache'
import { all } from 'services/api/pager'
import { config } from 'services/api/common'

import cache from './api.cache'

const getQuotas = async() => {
  return axios.get('/quotas')
}

const getConfigs = async() => {
  return axios.get('/configs')
}

const getCategories = async(page, unassigned, search) => {
  page = page || {}
  return axios.get('/categories', {
    params: {
      unassigned,
      search,
      ...page,
    },
  })
}

const api = {
  getQuotas,
  getConfigs: cached(getConfigs, 'getConfigs', cache.default, () => config.accessToken),
  getCategories: all(getCategories),
}

export default api
