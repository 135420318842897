import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

import { focusEnd } from 'utils/mui'
import { Errors } from 'components/ui/Typography'
import { useForm, useRevalidate } from 'hooks/useForm'
import Dialog from 'components/ui/Dialog'

import styles from './EditTitleModal.module.scss'

function validate(values) {
  const errors = {}
  if (!values.title?.trim()) {
    errors.title = { required: true }
  } else if (values.title?.length >= 50) {
    errors.title = { maxLength: true }
  }

  return errors
}

const EditTitleModal = ({ open, onClose, optimization }) => {
  const dispatch = useDispatch()
  const { id, title } = optimization
  const initialValues = useMemo(() => ({ title: title || '' }), [title])

  const submit = useCallback(({ title }) => {
    return dispatch(actions.optimizations.updateOptimization(id, { title }))
      .then(onClose)
  }, [dispatch, id, onClose])

  const revalidate = useRevalidate()
  const { errors, submitForm, processing, values, setValue, clear } =
    useForm('editOptimizationTitle', validate, submit, initialValues, revalidate)

  const handleClose = useCallback(() => {
    onClose()
    clear()
  }, [clear, onClose])

  return (
    <Dialog
      title="Edit optimization name"
      open={open}
      onClose={handleClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <Errors errors={errors?._} />
        <TextField
          name="title"
          multiline
          rows={4}
          value={values.title}
          onChange={setValue}
          error={!!errors?.title}
          helperText={errors?.title?.[0]}
          size="small"
          {...focusEnd}
        />
        <Button
          className={styles.saveButton}
          onClick={submitForm}
          variant="contained"
          disabled={processing}
        >Save Changes</Button>
        <Button
          className={styles.cancelButton}
          onClick={handleClose}
          variant="primary"
        >Cancel</Button>
      </div>
    </Dialog>
  )
}

EditTitleModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  optimization: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
  }),
}

export default EditTitleModal
