import React from 'react'
import PropTypes from 'prop-types'

import { EmptyPageIcon } from 'assets/icons'
import { H2 } from 'components/ui/Typography'

import styles from './NoResults.module.scss'

function NoResults({ title }) {
  return (
    <div className={`flex-col-center ${styles.emptyPage}`} >
      <EmptyPageIcon className={styles.emptyPageIcon} />
      <H2>{title}</H2>
    </div>
  )
}

NoResults.propTypes = {
  title: PropTypes.string.isRequired,
}

export default NoResults
