import React from 'react'

import { DeviationTightIcon } from 'assets/icons'

import styles from './renderValueWithPercentFactory.module.scss'

export default function renderValueWithPercentFactory(valueExtractor) {
  // eslint-disable-next-line react/display-name
  return item => {
    const { label, percentage, Icon = DeviationTightIcon, className, iconClassName } = valueExtractor(item)
    return (
      <div className={`flex-row-center ${styles.container} ${className || ''}`}>
        {label}
        {
          !!percentage &&
            <div className="flex-row-center">
              {!!Icon && <Icon className={`icon ${styles.icon} ${iconClassName || ''}`} />}
              {!!percentage && percentage}%
            </div>
        }
      </div>
    )
  }
}
