import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import ClusterDimension from 'pages/clusters/Clusters/ClusterDimension'

import styles from './ClusterDimensions.module.scss'

function ClusterDimensions({ className }) {
  const { clusters } = useSelector(state => state.clusters.clustersConfig || {})
  const { dimensions } = useSelector(state => state.clusters.dimensions || {})

  const columns = useMemo(() => clusters.map(cluster => ({
    id: cluster.id,
    label: cluster.name,
  })), [clusters])

  return (
    <div className={`flex-col-center ${styles.ClusterDimensions} ${className || ''}`}>
      {dimensions?.map((dimension, index) => <ClusterDimension dimension={dimension} index={index} columns={columns} key={dimension.name} />)}
    </div>
  )
}

ClusterDimensions.propTypes = {
  className: PropTypes.string,
}

export default ClusterDimensions
