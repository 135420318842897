import React, { Fragment, useState, useEffect, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { actions } from 'store'
import { useDispatch } from 'react-redux'

import getTitle from 'components/ui/OptimizationsTable/optimizationTitle'
import { H2 } from 'components/ui/Typography'
import { DeleteIcon, ExportIcon, FinalizedIcon } from 'assets/icons'
import { OptimizationStatus, Role } from 'services/enums'
import TablePaginationButtons from 'components/ui/TablePaginationButtons'
import ExportStartModal from 'components/ui/ExportStartModal'
import Suspended from 'components/ui/Suspended'
import customPropTypes from 'utils/customPropTypes'
import api from 'services/api'
import useBooleanState from 'hooks/useBooleanState'

import styles from './OptimizationsTable.module.scss'

const OptimizationsTable = ({
  optimizationRecords,
  profile,
  onNextPage,
  onPreviousPage,
  numberOfMonths,
  hasNextPage,
  hasPreviousPage,
  pageLoaded,
  pagerState,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [exportStartOpen, openExportStart, closeExportStart] = useBooleanState(false)

  const handleExport = (optimizationId) => {
    return api.optimizations.downloadOptimization(optimizationId)
      .then(() => openExportStart())
  }

  const handleDelete = (optimizationId) => {
    dispatch(actions.optimizations.deleteOptimization(optimizationId))
  }

  useEffect(() => {
    const tableData = []
    optimizationRecords.map(optimization => {
      const title = getTitle(optimization.createdAt, numberOfMonths)
      const periodDataIndex = tableData.findIndex(d => d.title === title)
      if (periodDataIndex >= 0) {
        return tableData[periodDataIndex].rows.push(optimization)
      } else {
        return tableData.push({
          title: title,
          rows: [optimization],
        })
      }
    })
    setData(tableData)
  }, [optimizationRecords, numberOfMonths])

  const renderStatus = useCallback(optimization => {
    if (profile.role === Role.admin || optimization.status !== OptimizationStatus.draft) {
      return optimization.status.label
    }

    return optimization.approvedByMe ? 'Reviewed by me' : optimization.status.label
  }, [profile])

  const handleDetails = (optimizationId) => {
    navigate(`/assortment-optimization/details/${optimizationId}`, { state: pagerState })
  }
  return (
    <TableContainer className={styles.tableContainer} component={Paper}>
      {
        data.map((periodData) => (
          <Fragment key={periodData.title}>
            <H2 className={styles.title}> Assortment optimization {periodData.title} </H2>
            <Table className={styles.table}>
              <TableHead>
                <TableRow>
                  <TableCell className={styles.automatizationVersionCell}>Automatization version</TableCell>
                  <TableCell className={styles.createdAtCell} align="left">Created at</TableCell>
                  <TableCell className={styles.statusCell} align="left">Status</TableCell>
                  <TableCell className={styles.createdByCell} align="left">Created by</TableCell>
                  <TableCell className={styles.trackButtonCell} align="center" />
                  <TableCell className={styles.exportCell} align="right">Export</TableCell>
                  <TableCell className={styles.deleteCell} align="right">Remove</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  periodData.rows.map((optimization) => (
                    <TableRow key={optimization.id}>
                      <TableCell className={styles.titleRow} align="left" component="th" scope="row" onClick={() => handleDetails(optimization.id)}>
                        {
                          (optimization.status === OptimizationStatus.finalized ||
                            optimization.status === OptimizationStatus.tracking) &&
                              <FinalizedIcon align="center" className={`${styles.finalizedIcon} icon small`} />
                        }
                        {optimization.title}
                      </TableCell>
                      <TableCell align="left">{moment(optimization.createdAt).format('L')}</TableCell>
                      <TableCell className={styles.status} align="left">{renderStatus(optimization)}</TableCell>
                      <TableCell align="left">
                        {
                          optimization.createdBy ?
                            `${optimization.createdBy.firstName} ${optimization.createdBy.lastName}` :
                            'Automatic'
                        }
                      </TableCell>
                      <TableCell align="center">
                        {
                          optimization.status === OptimizationStatus.tracking &&
                            <Button onClick={() => navigate('/execution-tracking')} variant="contained" className={styles.trackButton}>
                              Track Execution
                            </Button>
                        }
                      </TableCell>
                      <TableCell size='small' align="right">
                        <IconButton onClick={() => handleExport(optimization.id)} aria-label="export" size="small">
                          <ExportIcon className="icon small" />
                        </IconButton>
                      </TableCell>
                      <TableCell size='small' className={styles.delete} align="right">
                        <IconButton onClick={() => handleDelete(optimization.id)} aria-label="delete" size="large">
                          <DeleteIcon className="icon small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                }
              </TableBody>
            </Table>
          </Fragment>
        ))
      }
      <TablePaginationButtons
        nextPage={onNextPage}
        previousPage={onPreviousPage}
        hasNextPage={hasNextPage}
        hasPreviousPage={hasPreviousPage}
        pageLoaded={pageLoaded}
      />
      <Suspended open={exportStartOpen}>
        <ExportStartModal
          open={exportStartOpen}
          onClose={closeExportStart}
        />
      </Suspended>
    </TableContainer>
  )
}

OptimizationsTable.propTypes = {
  optimizationRecords: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    approvedByMe: PropTypes.bool,
    clusterConfigId: PropTypes.number,
    createdAt: PropTypes.object,
    createdBy: PropTypes.object,
    readyForCm: PropTypes.bool,
    status: PropTypes.oneOf(OptimizationStatus.values()),
  })),
  profile: customPropTypes.profile,
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  numberOfMonths: PropTypes.number,
  hasNextPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasPreviousPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageLoaded: PropTypes.bool,
  pagerState: PropTypes.object,
}

export default OptimizationsTable
