import api from 'services/api'
import log from 'utils/log'
import { pass } from 'utils/flow'
import { actions } from 'store/index'

import * as types from './types'

export const postAuthData = (dispatch) => Promise.all([
  dispatch(actions.users.loadUserProfile()),
  dispatch(actions.clusters.fetchLatestClustersConfig()),
  dispatch(actions.optimizations.fetchOneOptimization()),
  dispatch(actions.organizations.fetchConfig()),
])

export const vacate = () => ({ type: types.vacate })

export const login = (values) => dispatch => {
  return api.auth.login(values)
    .then(data => pass(data, () => dispatch(loginSuccess(data))))
    .then(() => postAuthData(dispatch))
}
const loginSuccess = data => ({ type: types.loginSuccess, data })

export const logout = () => dispatch => {
  api.auth.logout()
    .then(() => log.info(() => 'Logout performed by API.'))
    .catch(() => log.warn(() => 'Logout by API failed.'))
  dispatch(logoutSuccess())
  dispatch(actions.router.navigate('/login'))
}
const logoutSuccess = () => ({ type: types.logoutSuccess })

export const resetPasswordForEmail = ({ email }) => () => {
  return api.auth.resetPasswordForEmail(email)
}

export const setNewPassword = ({ email, password, token }) => () => {
  return api.auth.setNewPassword(email, password, token)
}

export const registerUser = (values) => dispatch => {
  return api.auth.register(values)
    .then(data => pass(data, () => dispatch(registerUserSuccess(data))))
}

const registerUserSuccess = (data) => ({ type: types.registerUserSuccess, data })

export const verifyLink = (link) => () => {
  return api.auth.verifyLink(link)
}

export const resendLink = (email) => () => {
  return api.auth.resendLink(email)
}

export const restoreApiAccessToken = token => ({ type: types.restoreApiAccessToken, token })

export const restoreApiRefreshToken = token => ({ type: types.restoreApiRefreshToken, token })

export const restoreClientId = clientId => ({ type: types.restoreClientId, clientId })
