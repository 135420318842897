export const vacate = 'vacate'
export const fetchExecutionPeriodsSuccess = 'fetch_execution_periods_success'
export const fetchExecutionClusterStoresSuccess = 'fetch_execution_stores_success'
export const fetchExecutionClustersSuccess = 'fetch_execution_clusters_success'
export const fetchExecutionKeyIndicatorsSuccess = 'fetch_execution_key_indicators_success'
export const fetchExecutionTrackingCategoriesSuccess = 'fetch_execution_tracking_categories_success'
export const fetchExecutionTrackingSubcategoriesSuccess = 'fetch_execution_tracking_subcategories_success'
export const fetchExecutionTrackingSKUsSuccess = 'fetch_execution_tracking_skus_success'
export const fetchExecutionTrackingCategoriesTotalSuccess = 'fetch_execution_tracking_categories_total_success'
export const fetchExecutionTrackingSubcategoriesTotalSuccess = 'fetch_execution_tracking_subcategories_total_success'
export const fetchExecutionTrackingSKUTotalSuccess = 'fetch_execution_tracking_skus_total_success'
