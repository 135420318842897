import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import { H1, Strong } from 'components/ui/Typography'

import styles from './UserAvatar.module.scss'

const UserAvatar = ({ user }) => {
  return (
    <Link
      className={styles.userNameRow}
      to={user.status.value === 'active' || user.status.value === 'suspended' ? `../users/${user.id}` : ''}
    >
      {
        !user.profilePhotoUrl ?
          <div className={styles.user}>
            <H1 className={styles.initials}>{`${user.firstName?.charAt(0) || ''}${user.lastName?.charAt(0) || ''}`}</H1>
          </div> :
          <img className={styles.user} src={user.profilePhotoUrl} alt='' />
      }
      <Strong>{`${user.firstName || ''} ${user.lastName || ''}`}</Strong>
    </Link>
  )
}

UserAvatar.propTypes = {
  user: PropTypes.object.isRequired,
}

export default UserAvatar
