import { combineReducers, createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'

import authReducer from 'store/auth/reducer'
import toastReducer from 'store/toast/reducer'
import routerReducer from 'store/router/reducer'
import clustersReducer from 'store/clusters/reducer'
import optimizationsReducer from 'store/optimizations/reducer'
import usersReducer from 'store/users/reducer'
import organizationsReducer from 'store/organizations/reducer'
import trackingReducer from 'store/tracking/reducer'
import notificationsReducer from 'store/notifications/reducer'
import * as authActions from 'store/auth/actions'
import * as toastActions from 'store/toast/actions'
import * as routerActions from 'store/router/actions'
import * as clustersActions from 'store/clusters/actions'
import * as optimizationsActions from 'store/optimizations/actions'
import * as usersActions from 'store/users/actions'
import * as organizationsActions from 'store/organizations/actions'
import * as trackingActions from 'store/tracking/actions'
import * as notificationsActions from 'store/notifications/actions'
import * as clustersSelectors from 'store/clusters/selectors'
import * as optimizationsSelectors from 'store/optimizations/selectors'
import * as usersSelectors from 'store/users/selectors'
import * as trackingSelectors from 'store/tracking/selectors'

export const reducer = combineReducers({
  auth: authReducer,
  toast: toastReducer,
  router: routerReducer,
  clusters: clustersReducer,
  optimizations: optimizationsReducer,
  users: usersReducer,
  organizations: organizationsReducer,
  tracking: trackingReducer,
  notifications: notificationsReducer,
})

export const actions = {
  auth: authActions,
  toast: toastActions,
  router: routerActions,
  clusters: clustersActions,
  optimizations: optimizationsActions,
  users: usersActions,
  organizations: organizationsActions,
  tracking: trackingActions,
  notifications: notificationsActions,
}

export const selectors = {
  clusters: clustersSelectors,
  optimizations: optimizationsSelectors,
  users: usersSelectors,
  tracking: trackingSelectors,
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

export default createStore(reducer, {}, composeEnhancers(
  applyMiddleware(thunk),
))
