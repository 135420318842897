import React, { useState, useEffect, useCallback, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { actions, selectors } from 'store'
import _ from 'lodash'

import useBooleanState from 'hooks/useBooleanState'
import api from 'services/api'
import { Role } from 'services/enums'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import useAuth from 'hooks/useAuth'
import useData from 'hooks/useData'
import usePager from 'hooks/usePager'
import AssortmentLengthIcon from 'assets/executionTracking/assortmentLength.png'
import BasketsIcon from 'assets/executionTracking/baskets.png'
import QuantityIcon from 'assets/executionTracking/quantity.png'
import RevenueIcon from 'assets/executionTracking/revenue.png'
import MarginIcon from 'assets/executionTracking/margin.png'
import SpecialSkusIcon from 'assets/executionTracking/specialSkus.png'
import KPICard from 'components/ui/KPICard/KPICard'
import Suspended from 'components/ui/Suspended/Suspended'
import VerticalHeatmapLegend from 'components/ui/VerticalHeatmapLegend'
import NoResults from 'components/ui/NoResults'
import { H1, H5 } from 'components/ui/Typography'
import ExportStartModal from 'components/ui/ExportStartModal'

import styles from './ExecutionTracking.module.scss'
import ExecutionTrackingInSubcategories from './ExecutionTrackingInSubcategories'
import SubcategoriesTable from './ExecutionTrackingTable/SubcategoriesTable'
import CategoriesTable from './ExecutionTrackingTable/CategoriesTable'
import ExecutionTrackingFilters from './ExecutionTrackingFilters'
import TrackingMap from './TrackingMap'
import ExportModal from './ExportModal'
const MapMemo = memo(TrackingMap)

const INITIAL_TABLE_FILTERS = { search: '', category: null, subcategory: null }
const TRACKING_QUERY_PARAMS_DEFAULT = { search: '', sortBy: 'revenue', sortOrder: 'desc' }

const ExecutionTracking = () => {
  const dispatch = useDispatch()

  const [compare, setCompare] = useState(false)
  const [selectedPeriod, setSelectedPeriod] = useState('')
  const [executionId, setExecutionId] = useState(null)
  const [exportOpen, openExport, closeExport] = useBooleanState(false)
  const [exportStartOpen, openExportStart, closeExportStart] = useBooleanState(false)
  const [cluster, setCluster] = useState(null)
  const [filteredStores, setFilteredStores] = useState([])
  const [tableFilters, setTableFilters] = useState(INITIAL_TABLE_FILTERS)
  const [categoryTableSort, setCategoryTableSort] = useState(TRACKING_QUERY_PARAMS_DEFAULT)
  const [subcategoryTableSort, setSubcategoryTableSort] = useState(TRACKING_QUERY_PARAMS_DEFAULT)
  const isAdmin = useAuth({ is: Role.admin })
  const optimization = useSelector(state => state.optimizations.oneOptimization)

  const {
    executionPeriods,
    executionKeyIndicators,
    executionCategories,
    executionSubcategories,
    executionSKUs,
    hasTrackingOptimization,
    executionCategoriesTotal,
    executionSubcategoriesTotal,
    executionSkusTotal,
  } = useSelector(state => state.tracking)

  const selectedStoreId = useMemo(() => filteredStores?.length === 1 ? filteredStores[0]?.id : null, [filteredStores])
  const selectedCategoryId = useMemo(() => {
    if (executionCategories && executionCategories.length) {
      return tableFilters.category ? tableFilters.category.id : executionCategories[0].id
    }
  }, [executionCategories, tableFilters.category])

  useEffect(() => {
    if (executionId) {
      dispatch(actions.tracking.fetchExecutionTrackingCategories(
        executionId,
        cluster?.id,
        selectedStoreId,
        {
          search: categoryTableSort.search,
          sortBy: categoryTableSort.sortBy,
          sortOrder: categoryTableSort.sortOrder,
        },
      )).then(
        dispatch(actions.tracking.fetchExecutionTrackingCategoriesTotal(
          executionId,
          cluster?.id,
          selectedStoreId,
        ))).catch(err => err)
    }
  }, [dispatch, cluster, executionId, selectedStoreId, categoryTableSort])

  const allStores = useSelector(selectors.tracking.allStores)

  useEffect(() => {
    dispatch(actions.tracking.fetchExecutionPeriods())
  }, [dispatch])

  const { loaded } = useData(() => {
    if (executionId && selectedCategoryId) {
      if (tableFilters.subcategory) {
        dispatch(actions.tracking.fetchExecutionTrackingSKUsTotal(
          executionId,
          cluster?.id,
          selectedStoreId,
          selectedCategoryId,
          tableFilters.subcategory?.id,
        ))
        return dispatch(actions.tracking.fetchExecutionTrackingSKUs(
          executionId,
          cluster?.id,
          selectedStoreId,
          selectedCategoryId,
          tableFilters.subcategory?.id,
          {
            search: tableFilters.search,
            sortBy: subcategoryTableSort.sortBy,
            sortOrder: subcategoryTableSort.sortOrder,
          },
        ))
      } else {
        dispatch(actions.tracking.fetchExecutionTrackingSubcategoriesTotal(
          executionId,
          cluster?.id,
          selectedStoreId,
          selectedCategoryId,
        ))
        return dispatch(actions.tracking.fetchExecutionTrackingSubcategories(
          executionId,
          cluster?.id,
          selectedStoreId,
          selectedCategoryId,
          {
            search: tableFilters.search,
            sortBy: subcategoryTableSort.sortBy,
            sortOrder: subcategoryTableSort.sortOrder,
          },
        ))
      }
    }
    // do not change dependencies to be whole tableFilters object
  }, [
    dispatch,
    executionId,
    cluster?.id,
    selectedStoreId,
    selectedCategoryId,
    tableFilters.subcategory,
    tableFilters.search,
    subcategoryTableSort,
  ])

  useEffect(() => {
    setExecutionId(executionPeriods?.[0]?.id)
  }, [executionPeriods])

  const { loaded: loadedClusters, error: errorClusters } = useData(() => {
    if (!executionId) {
      return Promise.never()
    }
    return dispatch(actions.tracking.fetchExecutionClustersWithStores(executionId))
  }, [dispatch, executionId])

  // todo add compare period in api call
  const { loaded: loadedKPIs, error: errorKPIs } = useData(() => {
    if (!(executionId) || !isAdmin) {
      return Promise.never()
    }
    return dispatch(actions.tracking.fetchExecutionKeyIndicators(
      executionId,
      cluster?.id,
      selectedStoreId,
      compare && selectedPeriod ? selectedPeriod.id : null,
    ))
  }, [cluster, compare, dispatch, executionId, selectedPeriod, selectedStoreId, isAdmin])

  useEffect(() => {
    if (loadedClusters && allStores) {
      setFilteredStores(allStores)
    }
  }, [allStores, loadedClusters])

  const handleExportData = useCallback(exportType => {
    return api.tracking.exportExecutionTracking(executionId, exportType)
      .then(() => openExportStart())
  }, [executionId, openExportStart])

  const onSelectionChange = ({ cluster, store }) => {
    setCluster(cluster)
    if (store) {
      setFilteredStores([store])
    } else if (cluster) {
      setFilteredStores(cluster.stores)
    } else {
      setFilteredStores(allStores)
    }
    // reset table filters
    setTableFilters(INITIAL_TABLE_FILTERS)
  }

  const handlePeriodChange = useCallback((period) => {
    setCompare(!!period)
    setSelectedPeriod(period)
  }, [])

  const handleSetFilters = useCallback((newFilters) => {
    if (!_.isEqual(tableFilters, newFilters)) {
      setTableFilters(newFilters)
    }
  }, [tableFilters])

  const {
    previous: previousSKUs,
    next: nextSKUs,
    hasNext: hasNextSKUs,
    hasPrevious: hasPreviousSKUs,
    loaded: loadedSKUs,
  } = usePager(executionSKUs, actions.tracking.fetchExecutionTrackingSKUsSuccess)

  const {
    previous: previousSubs,
    next: nextSubs,
    hasNext: hasNextSubs,
    hasPrevious: hasPreviousSubs,
    loaded: subsPageLoaded,
  } = usePager(executionSubcategories, actions.tracking.fetchExecutionTrackingSubcategoriesSuccess)

  const KPIs = useMemo(() => {
    return loadedKPIs && [
      {
        title: 'Assortment length',
        icon: AssortmentLengthIcon,
        values: [
          { value: executionKeyIndicators.assortment.length, label: 'current length' },
          { value: executionKeyIndicators.assortment.recommendedLength, label: 'recommended length' },
          { value: executionKeyIndicators.assortment.deviation, label: 'deviation', suffix: '%' },
        ],
        info: 'Total number of different products (SKUs) offered.',
      },
      {
        title: 'Revenue',
        icon: RevenueIcon,
        values: [
          { value: executionKeyIndicators.revenue.amount, label: 'revenue' },
          { value: executionKeyIndicators.revenue.share, label: 'in total sales', suffix: '%' },
        ],
        info: 'The total sales generated from the sale of goods in the specified period.',
      },
      {
        title: 'Margin',
        icon: MarginIcon,
        values: [
          { value: executionKeyIndicators.margin.amount, label: '' },
          { value: executionKeyIndicators.margin.shareInSales, label: 'share in total sales', suffix: '%' },
          { value: executionKeyIndicators.margin.shareInMargin, label: 'share in total margin', suffix: '%' },
        ],
        info: 'The difference between the cost and selling price of a product in the specified period.',
      },
      {
        title: 'Quantity',
        icon: QuantityIcon,
        values: [
          { value: executionKeyIndicators.quantity.units, label: 'quantity units' },
          { value: executionKeyIndicators.quantity.share, label: 'quantity share', suffix: '%' },
        ],
        info: 'Amount of goods (items) sold in the specified period.',
      },
      {
        title: 'Baskets',
        icon: BasketsIcon,
        values: [
          { value: executionKeyIndicators.baskets.basketNumber, label: 'baskets' },
          { value: executionKeyIndicators.baskets.averageRevenue, label: 'avg. basket value' },
          { value: executionKeyIndicators.baskets.averageUnits, label: 'avg.units per basket', suffix: '%' },
        ],
        info: 'A set of products purchased together and on the same receipt (transaction).',
      },
      {
        title: 'Special SKUs',
        icon: SpecialSkusIcon,
        values: [
          { value: executionKeyIndicators.specialSkus.share, label: 'share', suffix: '%' },
          { value: executionKeyIndicators.specialSkus.newSkus, label: 'Number of New SKUs' },
          { value: executionKeyIndicators.specialSkus.delistedSkus, label: 'Number of Delisted SKUs' },
        ],
        compare: true,
        info: 'A unique, seasonal, limited edition or high-demand items (SKUs) in an assortment that require special attention. Not included in the assortment optimization.',
      },
    ].filter(kpi => compare ? true : !kpi.compare)
  }, [executionKeyIndicators, loadedKPIs, compare])

  return (
    hasTrackingOptimization !== false ?
      <div className={`flex-col-center ${styles.executionTracking}`}>
        {
          loadedClusters ?
            <ExecutionTrackingFilters
              onExport={openExport}
              onSelectionChange={onSelectionChange}
              onPeriodChange={handlePeriodChange}
              executionPeriods={executionPeriods.slice(1)}
              period={selectedPeriod === '' ? null : selectedPeriod}
              comparePeriods={compare}
            /> :
            <SectionLoadingIndicator className={styles.executionTrackingFilters} error={!!errorClusters} />
        }
        {
          loadedClusters ?
            <div style={{ position: 'relative' }}>
              <MapMemo stores={filteredStores} allStores={allStores} />
              <VerticalHeatmapLegend className={styles.heatmap} />
            </div> :
            <SectionLoadingIndicator className={styles.mapLoader} error={!!errorClusters} />
        }
        <H1 className={styles.executionTrackingTitle}>{optimization?.[0].name}</H1>
        {
          isAdmin && <div>
            {
              loadedKPIs ?
                <div>
                  <H5 className={styles.executionTrackingKPITitle}>Key assortment indicators</H5>
                  <div className={styles.keyIndicators}>
                    {
                      KPIs.map((data) => (
                        <KPICard
                          key={data.title}
                          data={data}
                          compare={compare}
                        />
                      ))
                    }
                  </div>
                </div> :
                <SectionLoadingIndicator className={styles.loaderKPIs} error={!!errorKPIs} />
            }
          </div>
        }
        <Suspended open={exportOpen}>
          <ExportModal
            open={exportOpen}
            onClose={closeExport}
            exportData={handleExportData}
          />
        </Suspended>
        <Suspended open={exportStartOpen}>
          <ExportStartModal
            open={exportStartOpen}
            onClose={closeExportStart}
          />
        </Suspended>
        {
          executionCategories && executionCategoriesTotal &&
            <CategoriesTable
              tableData={executionCategories}
              total={executionCategoriesTotal}
              compare={compare}
              sort={categoryTableSort}
              onSortChange={setCategoryTableSort}
            />
        }
        {
          !loaded && !executionSubcategories ?
            <SectionLoadingIndicator className={styles.executionTrackingFilters} /> :
            executionCategories && executionSubcategories &&
              <ExecutionTrackingInSubcategories
                setFilters={handleSetFilters}
                categories={executionCategories}
                subcategories={executionSubcategories}
              />
        }
        {
          ((tableFilters.subcategory && (!executionSKUs || !executionSkusTotal)) || (!tableFilters.subcategory && (!executionSubcategories || !executionSubcategoriesTotal))) ?
            <SectionLoadingIndicator className={styles.executionTrackingSubcategoriesTable} /> :
              (tableFilters.subcategory ?
                  (executionSKUs && executionSkusTotal) :
                  (executionSubcategories && executionSubcategoriesTotal)) &&
                    <SubcategoriesTable
                      onNextPage={tableFilters.subcategory ? nextSKUs : nextSubs}
                      onPreviousPage={tableFilters.subcategory ? previousSKUs : previousSubs}
                      hasNextPage={tableFilters.subcategory ? hasNextSKUs : hasNextSubs}
                      hasPreviousPage={tableFilters.subcategory ? hasPreviousSKUs : hasPreviousSubs}
                      pageLoaded={tableFilters.subcategory ? loadedSKUs : subsPageLoaded}
                      compare={compare}
                      tableData={tableFilters.subcategory ? executionSKUs : executionSubcategories}
                      tableFilter={tableFilters}
                      total={tableFilters.subcategory ? executionSkusTotal : executionSubcategoriesTotal}
                      sort={subcategoryTableSort}
                      onSortChange={setSubcategoryTableSort}
                      loadedData={loaded}
                    />
        }
      </div> :
      <NoResults
        title={'This page is currently empty'}
      />
  )
}

export default ExecutionTracking
