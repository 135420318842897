import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'
import { actions, selectors } from 'store'

import { Role } from 'services/enums'
import useData from 'hooks/useData'
import Dialog from 'components/ui/Dialog'
import useToast from 'components/ui/Toast/useToast'
import { P, Strong } from 'components/ui/Typography'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'

import styles from './FinalizeAssortment.module.scss'

const FinalizeAssortment = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const { successToast } = useToast()
  const { id: optimizationId, approvals } = useSelector(state => state.optimizations.optimization || {})
  const role = useSelector(state => state.users?.profile?.role)
  const users = useSelector(selectors.users.hydratedUsers)
  const [categoriesConfirmed, setCategoriesConfirmed] = useState(false)

  const { loaded, error } = useData(() => {
    const promises = [
      dispatch(actions.users.fetchUsers()),
      dispatch(actions.organizations.fetchCategories()),
    ]
    if (role === Role.admin) {
      promises.unshift(dispatch(actions.optimizations.fetchApprovals(optimizationId)))
    }
    return Promise.all(promises)
      .then(([optimizationApprovals]) => {
        return setCategoriesConfirmed(!optimizationApprovals.find(cm => !cm.approvalDate))
      })
  }, [dispatch, optimizationId, role])

  const onFinalize = useCallback(() => {
    return dispatch(actions.optimizations.finalizeAssortment(optimizationId))
      .then(() => {
        successToast('Assortment has been finalized')
        return onClose()
      })
  }, [dispatch, onClose, optimizationId, successToast])

  return (
    <Dialog
      title="Finalize the Assortment"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      {
        loaded ?
          <div className={`flex-col-center ${styles.bodyContainer}`}>
            {
              !categoriesConfirmed &&
                <>
                  <P>You are about to finalize assortment for All clusters.</P>
                  <P>Some category managers haven’t confirmed their choices:</P>
                  <div>
                    {
                      approvals.filter(cm => !cm.approvalDate).map(cm => {
                        const { userId, firstName, lastName } = cm
                        const { categories } = users?.find(u => u.id === userId) || {}
                        return (
                          <div className={`flex-row-center ${styles.cmRow}`} key={cm.userId}>
                            <Strong>{firstName} {lastName}</Strong>
                            <div className={styles.categories}>
                              <P className={styles.category}>
                                {
                                  categories?.map(category => category.name)?.join(', ')
                                }
                              </P>
                            </div>
                          </div>
                        )
                      })
                    }
                  </div>
                </>
            }
            <P>
              {categoriesConfirmed && 'You are about to finalize assortment for All clusters.'}
              The finalized assortment will be used to track execution, because of that this action <b>can not be undone</b>.
            </P>
            <P>Do you want to proceed?</P>
            <Button
              className={styles.containedButton}
              onClick={onFinalize}
              variant="contained"
              size="medium"
              disabled={false}
            >
              Confirm
            </Button>
            <Button
              className={styles.button}
              onClick={onClose}
              variant="primary"
            >
              Cancel
            </Button>
          </div> :
          <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }
    </Dialog>
  )
}

FinalizeAssortment.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default FinalizeAssortment
