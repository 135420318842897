import React from 'react'

import NoMatchFound from 'components/ui/NoMatchFound/NoMatchFound'
import noMatchImage from 'assets/no-match-page.png'

function NoMatch() {
  return (
    <NoMatchFound
      titleText={'Sorry!'}
      descriptionText={'No match found...'}
      image={noMatchImage}
    />
  )
}

export default NoMatch
