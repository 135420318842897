import React from 'react'
import PropTypes from 'prop-types'

import customPropTypes from 'utils/customPropTypes'
import ProtectedContent from 'components/router/ProtectedContent'
import useAuth from 'hooks/useAuth'

function AppProtectedContent({ children, is, authenticated, anonymous, redirectPath = '/unauthorized' }) {
  const authorized = useAuth({ is, authenticated, anonymous })

  return (
    <ProtectedContent authorized={authorized} redirectPath={redirectPath}>
      {!!children && children}
    </ProtectedContent>
  )
}

AppProtectedContent.propTypes = {
  children: customPropTypes.children,
  authenticated: PropTypes.bool,
  anonymous: PropTypes.bool,
  is: PropTypes.symbol,
  redirectPath: PropTypes.string,
}

export default AppProtectedContent
