import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from '@mui/material'
import TextField from '@mui/material/TextField'
import { actions } from 'store'

import { useForm, useRevalidate } from 'hooks/useForm'
import Dialog from 'components/ui/Dialog'
import { P, Errors } from 'components/ui/Typography'
import { pass } from 'utils/flow'

import styles from './NewDraft.module.scss'

function validate(values) {
  const errors = {}
  if (!values.name?.trim()) {
    errors.name = { required: true }
  } else if (values.name?.length >= 50) {
    errors.name = { maxLength: true }
  }

  return errors
}

const NewDraft = ({ open, onClose, onCreate }) => {
  const dispatch = useDispatch()

  const { id, title } = useSelector(state => state.optimizations.optimization || {})

  const initialValues = useMemo(() => ({ name: title || '' }), [title])

  const submit = useCallback(({ name }) => {
    return dispatch(actions.optimizations.createOptimization(name, id))
      .then(data => pass(data, onClose))
      .then(onCreate)
  }, [dispatch, id, onClose, onCreate])

  const revalidate = useRevalidate()
  const { errors, submitForm, processing, values, setValue, clear } =
    useForm('newDraft', validate, submit, initialValues, revalidate)

  const handleClose = useCallback(() => {
    onClose()
    clear()
  }, [clear, onClose])

  return (
    <Dialog
      title="New draft"
      open={open}
      onClose={handleClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <P className={styles.text}>We will create new draft for you so you can continue working on the assortment! You can always open previous version in All optimizations list.</P>
        <div className={styles.inputContainer}>
          <P className={styles.name}>New assortment version name: </P>
          <Errors errors={errors?._} />
          <TextField
            name="name"
            className={styles.input}
            fullWidth
            value={values.name}
            onChange={setValue}
            error={!!errors?.name}
            helperText={errors?.name?.[0]}
            size="small"
            variant="outlined"
          />
        </div>
        <Button
          className={styles.containedButton}
          onClick={submitForm}
          variant="contained"
          size="medium"
          disabled={processing}
        >
          Save new version
        </Button>
        <Button
          className={styles.button}
          onClick={handleClose}
          variant="primary"
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  )
}

NewDraft.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreate: PropTypes.func.isRequired,
}

export default NewDraft
