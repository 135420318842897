import React from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'

import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import styles from './PasswordResetSuccess.module.scss'

const PasswordResetSuccess = ({ open, onClose }) => {
  return (
    <Dialog
      title="Successful password reset"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <H6 className={styles.text}>Your password is now reset, next time you can log in with your new password!</H6>
        <Button className={styles.button} onClick={onClose} variant="contained" size="medium">OK</Button>
      </div>
    </Dialog>
  )
}

PasswordResetSuccess.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default PasswordResetSuccess
