import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

import { NextIcon, PreviousIcon } from 'assets/icons'

import styles from './TablePaginationButtons.module.scss'

const TablePaginationButtons = ({
  previousPage,
  hasPreviousPage,
  pageLoaded,
  nextPage,
  hasNextPage,
}) => {
  return (
    <div className={styles.buttonsWrap}>
      <Button
        onClick={previousPage}
        className={styles.paginationButton}
        variant="outlined"
        disabled={!hasPreviousPage || !pageLoaded}
      >
        <PreviousIcon className={`${(!hasPreviousPage || !pageLoaded) ? styles.disabledPaginationIcon : styles.paginationIcon} icon small`} />
      </Button>
      <Button
        onClick={nextPage}
        className={styles.paginationButton}
        variant="outlined"
        disabled={!hasNextPage || !pageLoaded}
      >
        <NextIcon className={`${(!hasNextPage || !pageLoaded) ? styles.disabledPaginationIcon : styles.paginationIcon} icon small`} />
      </Button>
    </div>
  )
}
TablePaginationButtons.propTypes = {
  previousPage: PropTypes.func.isRequired,
  hasPreviousPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  nextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageLoaded: PropTypes.bool,
}

export default TablePaginationButtons
