import React from 'react'
import PropTypes from 'prop-types'

import { assortmentLengthNumberFormat } from 'utils/numberFormat'
import BaseAssortmentCard from 'components/ui/AssortmentCards/BaseAssortmentCard'
import { H6 } from 'components/ui/Typography'

import styles from '../AssortmentCards.module.scss'

const AssortmentLengthCard = ({ data, className }) => {
  const { code, title, info, assortmentLength } = data

  return (
    <BaseAssortmentCard title={title} info={info} className={`${className || ''} ${styles.card}`}>
      <div className={`flex-row-center ${styles.assortmentLengthContainer}`}>
        <div className={`${styles.bigNumber} ${code}`}>{assortmentLengthNumberFormat(assortmentLength)}</div>
        <H6>SKUs</H6>
      </div>
    </BaseAssortmentCard>
  )
}

AssortmentLengthCard.propTypes = {
  data: PropTypes.shape({
    code: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    info: PropTypes.string.isRequired,
    assortmentLength: PropTypes.number,
  }).isRequired,
  className: PropTypes.string,
}

export default AssortmentLengthCard
