import React from 'react'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Fade from '@mui/material/Fade'
import PropTypes from 'prop-types'
import { Dialog } from '@mui/material'

import customPropTypes from 'utils/customPropTypes'

import styles from './Dialog.module.scss'

/*
  todo @anya: this modal https://zpl.io/5EkqEPn doesn't fit the rest, but it can use this container
  instead of the Dialog component
*/
const ModalContainer = ({ open, onClose, children, className }) => {
  return (
    <div>
      <Dialog
        className={styles.modalContainer}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        scroll="body"
        BackdropProps={
          {
            timeout: 500,
          }
        }
      >
        <Fade in={open}>
          <Box className={`flex-col-center ${styles.dialog} ${className || ''}`}>
            {children}
          </Box>
        </Fade>
      </Dialog>
    </div>
  )
}
ModalContainer.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  children: customPropTypes.children,
  className: PropTypes.string,
}

export default ModalContainer
