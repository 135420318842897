import React from 'react'
import PropTypes from 'prop-types'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'

import { Error, P } from 'components/ui/Typography'

import styles from './AutoTextField.module.scss'

const AutoTextField = ({ className, disabled, title, error, automatic, value, automaticValue, onChange, helperText }) => {
  return (
    <div className={`${styles.container} ${className || ''}`}>
      <P className="title">{title}</P>
      <div className={`flex-row-center ${styles.body} body`}>
        <TextField
          disabled={disabled || automatic}
          error={error}
          className={`${styles.input} input`}
          value={automatic ? automaticValue : value}
          variant='outlined'
          size='small'
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          onChange={({ target: { value: v } }) => onChange(automatic, isNaN(v) ? value : +v)}
        />
        <FormControlLabel
          label="Automatic"
          className="automaticLabel"
          control={
            <Checkbox
              checked={automatic}
              disabled={disabled}
              className="checkbox"
              onChange={e => onChange(e.target.checked, automaticValue)}
            />
          }
        />
      </div>
      {!!helperText && <Error align="left">{helperText}</Error>}
    </div>
  )
}

AutoTextField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  error: PropTypes.bool,
  automatic: PropTypes.bool.isRequired,
  value: PropTypes.number.isRequired,
  automaticValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  helperText: PropTypes.string,
}

export default AutoTextField
