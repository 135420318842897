import React from 'react'
import PropTypes from 'prop-types'
import Paper from '@mui/material/Paper'
import CloudOffOutlinedIcon from '@mui/icons-material/CloudOffOutlined'

import ProgressIndicator from 'components/ui/ProgressIndicator'

import styles from './SectionLoadingIndicator.module.scss'

function SectionLoadingIndicator({ className, error }) {
  return (
    <div className={`${styles.container} ${className || ''}`}>
      <Paper variant="outlined" className={`${styles.box} box`}>
        { error ? <CloudOffOutlinedIcon className={styles.errorIcon} /> : <ProgressIndicator />}
      </Paper>
    </div>
  )
}

SectionLoadingIndicator.propTypes = {
  className: PropTypes.string,
  error: PropTypes.bool,
}

export default SectionLoadingIndicator
