import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { VictoryPie } from 'victory-pie'
import { VictoryLabel } from 'victory-core'

import { pluralize } from 'utils/language'
import { H3 } from 'components/ui/Typography'

import styles from './PieChart.module.scss'

function PieChart({ title, values, className }) {
  const pieChartArray = useMemo(() => {
    const sum = values.reduce((sum, val) => sum + val.clusterValue, 0)

    return values
      .map(value => ({
        ...value,
        clusterValue: Math.round((value.clusterValue * 100) / sum),
      }))
      .map(value => ({
        x: value.clusterName,
        y: value.clusterValue,
        fill: value.clusterColor,
        size: value.clusterSize,
      }))
  }, [values])

  return (
    <div className={`flex-col-center ${className || ''}`}>
      <H3 className={`${styles.title} title`}>{title}</H3>

      <div className={`${styles.chartContainer} chartContainer`}>
        <VictoryPie
          data={pieChartArray}
          labels={({ datum }) => [datum.x, datum.size + pluralize(datum.size, ' Store', ' Stores'), datum.y + '%']}
          labelComponent={<VictoryLabel />}
          style={
            {
              data: {
                fill: ({ datum }) => datum.fill,
                fillOpacity: 0.9,
                stroke: '#ffffff',
                strokeWidth: 1,
              },
              labels: {
                fontSize: 15,
                textAlight: 'center',
              },
            }
          }
        />
      </div>
    </div>
  )
}

PieChart.propTypes = {
  title: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(PropTypes.shape({
    clusterName: PropTypes.string,
    clusterSize: PropTypes.number,
    clusterValue: PropTypes.number,
    clusterColor: PropTypes.string,
  })).isRequired,
  className: PropTypes.string,
}

export default PieChart
