import { cached, withCleanCache } from 'utils/cache'
import axios from 'services/api/axios.instance'
import { config, withMeta } from 'services/api/common'
import { all } from 'services/api/pager'

import { storeInForm } from './adapters/storeAdapter'
import { clustersConfigInForm } from './adapters/clustersConfigAdapter'
import cache from './api.cache'

const getLatestClustersConfig = async() => {
  return axios.get('/cluster-configs', {
    params: {
      ipp: 1,
    },
  })
    .then(clustersConfigs => clustersConfigs?.[0])
    .then(clustersConfigInForm)
}

const getClusters = async(clustersConfigId) => {
  return axios.get(`/cluster-configs/${clustersConfigId}`)
    .then(clustersConfigInForm)
}

const getClusterStores = async(page, clustersConfigId, clusterId) => {
  page = page || {}
  return axios.get(`/cluster-configs/${clustersConfigId}/clusters/${clusterId}/stores`, {
    params: {
      ...page,
    },
  })
    .then(data => withMeta(data, data => data.map(storeInForm)))
}

const downloadClusters = async(clustersConfigId) => {
  return axios.get(`/cluster-configs/${clustersConfigId}`, {
    responseType: 'blob',
    params: {
      format: 'csv',
    },
  }).then((response) => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `export_clusters_${clustersConfigId}.csv`)
    document.body.appendChild(link)
    return link.click()
  })
}

const getDimensions = async(clustersConfigId) => {
  return axios.get(`/cluster-configs/${clustersConfigId}/dimensions`)
}

const updateClusters = async(clustersConfigId, file) => {
  const data = new FormData()
  data.append('File', file)

  return axios.put(`/cluster-configs/${clustersConfigId}`, data)
}

const confirmClusters = async(clustersConfigId) => {
  return axios.post(`/cluster-configs/${clustersConfigId}/confirmations`, {})
}

const api = {
  getLatestClustersConfig: cached(getLatestClustersConfig, 'getLatestClustersConfig', cache.default, () => config.accessToken),
  getClusters: cached(getClusters, 'getClusters', cache.default, () => config.accessToken),
  downloadClusters: downloadClusters,
  getDimensions: cached(getDimensions, 'getDimensions', cache.default, () => config.accessToken),
  updateClusters: withCleanCache(updateClusters, cache.default),
  confirmClusters: withCleanCache(confirmClusters, cache.default),
  getClusterStores: cached(all(getClusterStores), 'getClusterStores', cache.default, () => config.accessToken),
}

export default api
