import messageProvider from 'utils/messageProvider'

const messages = {
  email: {
    policy: 'This is not valid format of email',
  },

  password: {
    policy: 'Password should contain digits, upper and lower case letters and special characters.',
  },

  passwordConfirmation: {
    match: 'Passwords need to match',
  },

  newDraft: {
    name: {
      maxLength: 'Value must be less than 50 characters',
    },
  },

  optimizationConfigGoals: {
    sum: 'Sum of all values should be 100',
  },

  optimizationConfigLimits: {
    skuNumber: {
      max: 'Number of SKUs cannot be higher than the default value',
    },
    innovationSkuNumber: {
      requestedNumber: 'Please note that requested number of innovation & seasonal SKUs is much higher then your previous practice',
    },
  },

  configureCategoryProperties: {
    category: {
      total: 'Number of innovation and seasonal of SKUs cannot be higher than maximum number of SKUs',
    },
  },
  configureSubcategoryProperties: {
    subCategory: {
      skuTotal: 'Sum of all SKUs for subcategories can not be higher than the allowed number of SKUs for',
      subCatTotal: 'Sum of all SKUs for this subcategory can not be higher than maximum of SKUs that are available in this',
    },
  },

  userInfo: {
    firstName: {
      maxLength: 'Value must be less than 20 characters',
    },

    lastName: {
      maxLength: 'Value must be less than 30 characters',
    },
  },

  api: {
    403: 'You are not authorized to perform this operation',
    404: 'Record could not be found',
  },

  editOptimizationTitle: {
    title: {
      required: 'Title is required',
      maxLength: 'Title must be less than 50 characters',
    },
  },

  required: 'This value is required',

  unknown: 'Unexpected error occurred',
}

export const message = (context, area, code, defaultMessage, constraints) =>
  messageProvider.message(messages, context, area, code, defaultMessage, constraints)

export const formErrors = (formName, errors) =>
  messageProvider.formErrors(messages, formName, errors)
