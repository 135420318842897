import React, { useCallback, useEffect, useRef, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import SuperCluster from 'supercluster'
import _ from 'lodash'

import {
  boundingRegion,
  getClusterMarkers,
  getZoomLevelForFilteredPoints,
  INITIAL_ZOOM,
  NEARBY_STORES_ZOOM,
} from 'utils/clustering'
import Marker from 'components/Map/Marker'
import usePrevious from 'hooks/usePrevious'
import ClusterMarker from 'components/Map/ClusterMarker'

const superCluster = new SuperCluster({ radius: 60 })

const Map = ({ stores }) => {
  const mapRef = useRef(null)
  const [bounds, setBounds] = useState(null)
  const [markers, setMarkers] = useState([])
  const [zoom, setZoom] = useState(INITIAL_ZOOM)
  const [initialPosition, setInitialPosition] = useState(null)

  const storesPrev = usePrevious(stores)
  useEffect(() => {
    if (stores && stores.length && !_.isEqual(stores, storesPrev)) {
      let newZoom
      const bbox = boundingRegion(stores)
      setMarkers(getClusterMarkers(superCluster, stores, bbox, zoom))
      if (stores.length === 1) {
        newZoom = NEARBY_STORES_ZOOM
      } else {
        newZoom = getZoomLevelForFilteredPoints(stores)
      }
      setZoom(newZoom)

      // first render - set initial position of map
      if (!initialPosition) {
        setInitialPosition({
          center: {
            lat: bbox.latitude,
            lng: bbox.longitude,
          },
          zoom: newZoom,
        })
      } else {
        mapRef.current.setZoom(newZoom)
        mapRef.current.panTo({ lat: bbox.latitude, lng: bbox.longitude })
      }
    } else if (!stores.length) {
      // don't stay zoomed in if there are no stores that fit the search criteria
      setZoom(INITIAL_ZOOM)
    }
  }, [initialPosition, stores, storesPrev, zoom])

  useEffect(() => {
    if (stores && zoom && bounds) {
      const bbox = {
        minLatitude: bounds[1],
        maxLatitude: bounds[3],
        minLongitude: bounds[0],
        maxLongitude: bounds[2],
      }
      setMarkers(getClusterMarkers(superCluster, stores, bbox, zoom))
    }
  }, [zoom, bounds, stores])

  const handleClusterMarkerClick = useCallback((point) => {
    const { properties: { cluster_id: clusterId }, geometry: { coordinates } } = point
    const clusterZoom = superCluster.getClusterExpansionZoom(clusterId)
    mapRef.current.setZoom(clusterZoom)
    mapRef.current.panTo({ lat: coordinates[0], lng: coordinates[1] })
  }, [])

  return initialPosition ?
      (
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAP_API_KEY }}
          defaultCenter={initialPosition.center}
          defaultZoom={initialPosition.zoom}
          onGoogleApiLoaded={({ map }) => { mapRef.current = map }}
          yesIWantToUseGoogleMapApiInternals={true}
          options={
            {
              fullscreenControl: false,
            }
          }
          onChange={
            ({ zoom, bounds }) => {
              setZoom(zoom)
              setBounds([
                bounds.nw.lng,
                bounds.se.lat,
                bounds.se.lng,
                bounds.nw.lat,
              ])
            }
          }
        >
          {
            markers.map(point => {
              if (point.properties?.cluster) {
                const { properties: { cluster_id: clusterId, point_count: pointCount }, geometry: { coordinates } } = point
                return (
                  <ClusterMarker
                    key={`${clusterId}`}
                    lat={coordinates[0]}
                    lng={coordinates[1]}
                    onClick={() => handleClusterMarkerClick(point)}
                    text={`${pointCount}`}
                  />
                )
              }

              const store = stores.find(s => s.id === point.properties.point.id)
              if (!store) {
                return null
              }
              return (
                <Marker
                  key={store.id}
                  lat={store.lat}
                  lng={store.lng}
                  tooltip={store.tooltip}
                  className={store.className}
                />
              )
            })
          }
        </GoogleMapReact>
      ) :
    null
}

Map.propTypes = {
  stores: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    lat: PropTypes.number,
    lng: PropTypes.number,
    tooltip: PropTypes.node,
    className: PropTypes.string,
  })),
}

export default Map
