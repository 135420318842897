/* eslint-disable no-console */
import config from 'config'

const LogLevels = {
  off: 0,
  error: 1,
  warning: 2,
  info: 3,
  assert: 4,
  debug: 5,
}

const level = LogLevels[config.logLevel] || LogLevels.error

export class AssertError extends Error {
  constructor(message) {
    super(message)
    this.name = this.constructor.name
    this.error = message
  }
}

const stringify = (o, debug) => {
  if (o instanceof Error) {
    return `${o.name}: ${o.message}` + (debug ? `\n${o.stack}\n` : '')
  } else {
    return JSON.stringify(o, null, ' ')
  }
}
const isString = o => typeof o === 'string'

const prepare = (supplier, debug = false) => {
  const items = supplier()
  let [message, ...rest] = Array.isArray(items) ? items : [items]
  message = isString(message) ? message : stringify(message, debug)
  rest = rest.map(o => stringify(o, debug))
  return [message, ...rest]
}

const devDebug = (supplier) => {
  console.log(...prepare(supplier, true))
}
const devInfo = (supplier) => {
  console.log(...prepare(supplier))
}
const devWarn = (supplier) => {
  console.warn(...prepare(supplier))
}
const devError = (supplier) => {
  console.error(...prepare(supplier))
}
const devAssert = (assertion, message) => {
  if (!assertion) {
    throw new AssertError(message)
  }
}

export const debug = (level >= LogLevels.debug) ? devDebug : () => {}
export const info = (level >= LogLevels.info) ? devInfo : () => {}
export const warn = (level >= LogLevels.warning) ? devWarn : () => {}
export const error = (level >= LogLevels.error) ? devError : () => {}
export const assert = (level >= LogLevels.assert) ? devAssert : () => {}

/**
 * Use log, warn and error methods to console.log, console.warn and console.error respectively.
 * The functions accept a function that should return items that should be logged.
 * Example:
 *
 * info(() => ['Message', computeExpensiveArg()])
 * info(() => 'Message 2')
 */
const log = {
  debug,
  info,
  warn,
  error,
  assert,
}

export default log
