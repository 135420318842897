import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import { actions } from 'store'
import { Button } from '@mui/material'

import Input from 'components/ui/Input'
import { useForm, useRevalidate } from 'hooks/useForm'
import { H4 } from 'components/ui/Typography'
import { validateResetPassword } from 'services/validation/resetPassword'

import styles from './ResetPasswordNewPassInput.module.scss'

const initialValues = {
  password: '',
  passwordConfirmation: '',
}

const ResetPasswordNewPassInput = ({ onContinue }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { email, token } = useParams()

  const submit = useCallback(values => {
    values.email = email
    values.token = token
    return dispatch(actions.auth.setNewPassword(values))
      .then(onContinue)
  }, [dispatch, onContinue, email, token])

  const revalidate = useRevalidate()
  const { errors, submitForm, values, setValue } =
    useForm('passwordInput', validateResetPassword, submit, initialValues, revalidate)

  return (
    <div className={`flex-col-center ${styles.passwordInput}`}>
      <H4 className={styles.text}> New password </H4>
      <Input
        name="password"
        label="New password"
        type="password"
        value={values.password || ''}
        onChange={setValue}
        error={!!errors?.password}
        helperText={errors?.password?.[0]}
        fullWidth
      />
      <Input
        name="passwordConfirmation"
        label="Confirm new password"
        type="password"
        value={values.passwordConfirmation || ''}
        onChange={setValue}
        error={!!errors?.passwordConfirmation}
        helperText={errors?.passwordConfirmation?.[0]}
        fullWidth
      />
      <Button
        className={styles.resetButton}
        onClick={submitForm}
        variant='contained'
      > Reset password </Button>

      <Button
        className={styles.cancelButton}
        onClick={() => { navigate('/login') }}
        variant="primary"
      > Cancel </Button>
    </div>
  )
}

ResetPasswordNewPassInput.propTypes = {
  onContinue: PropTypes.func.isRequired,
}
export default ResetPasswordNewPassInput
