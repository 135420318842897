import * as React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSubscription } from '@apollo/client'
import { styled } from '@mui/material/styles'
import Menu from '@mui/material/Menu'
import Divider from '@mui/material/Divider'
import { Badge } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import { mapKeys, camelCase } from 'lodash'
import { actions } from 'store'

import { NotificationsIcon } from 'assets/icons'
import { P2 } from 'components/ui/Typography'
import { errorToast } from 'components/ui/Toast'
import { GraphqlAPI } from 'services/api/graphqlAPI'
import api from 'services/api'
import { notificationInForm } from 'services/api/notifications/adapters/notificatonsAdapter'

import NotificationHeadCard from '../NotificationHeadCard'
import NotificationCard from '../NotificationCard'

import styles from './NotificationsMenu.module.scss'

const StyledMenu = styled((props) => (
  <Menu
    elevation={6}
    marginThreshold={0}
    anchorOrigin={
      {
        vertical: 'top',
        horizontal: 'right',
      }
    }
    transformOrigin={
      {
        vertical: 'bottom',
        horizontal: 'right',
      }
    }
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
    paddingRight: '20px',
    paddingLeft: '21px',
    maxHeight: '401px',
    minHeight: '147px',
    height: 'auto',
    overflow: 'auto',
    width: '359px',
    color: 'rgb(55, 65, 81)',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    border: 'solid 1px #979797',
  },
  '& .MuiList-root': {
    maxHeight: '375px',
  },
}))

export default function NotificationsMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const notificationsNumber = useSelector(state => state.notifications?.number)
  const notifications = useSelector(state => state.notifications?.notifications)
  const profile = useSelector(state => state.users.profile)
  const open = Boolean(anchorEl)
  const dispatch = useDispatch()

  const { data } = useSubscription(GraphqlAPI.NOTIFICATIONS.subscription(), {
    variables: GraphqlAPI.NOTIFICATIONS.subscriptionVariables(profile?.id.toString()),
  })

  useEffect(() => {
    dispatch(actions.notifications.loadNotifications())
  }, [dispatch])

  useEffect(() => {
    if (data) {
      dispatch(actions.notifications.newNotificationSuccess(notificationInForm({
        id: data.onCreate.id,
        publishedAt: data.onCreate.published_at,
        payload: mapKeys(JSON.parse(data.onCreate.payload), (v, k) => camelCase(k)),
        type: data.onCreate.type,
      })))
    }
  }, [dispatch, data])

  const handleClick = () => {
    setAnchorEl(document.getElementById('icon-wrapper'))
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDownload = url => {
    api.notifications.checkURLValidity(url)
      .then(() => api.notifications.downloadExport(url))
      .catch(() => errorToast('The export has expired!'))
  }

  const handleDismiss = id => {
    return dispatch(actions.notifications.dismissNotification(id))
      .then(() => {
        const index = notifications.findIndex(n => n.id === id)
        const newNotifications = [...notifications]
        newNotifications.splice(index, 1)
        return dispatch(actions.notifications.loadNotificationsSuccess({
          notifications: newNotifications,
          count: notificationsNumber - 1,
        }))
      })
  }

  const handleDismissAll = () => {
    return dispatch(actions.notifications.dismissAllNotifications())
      .then(() => {
        return dispatch(actions.notifications.loadNotificationsSuccess({
          notifications: [],
          count: 0,
        }))
      })
  }

  return (
    <div>
      <div className={styles.iconWrapper} id='icon-wrapper'>
        <IconButton onClick={handleClick}>
          <Badge className="notifications" badgeContent={notificationsNumber} showZero color='primary'>
            <NotificationsIcon className='icon normal' />
          </Badge>
        </IconButton>
      </div>
      <StyledMenu
        id='notifications'
        anchorEl={anchorEl}
        MenuListProps={
          {
            'aria-labelledby': 'notifications-list',
          }
        }
        open={open}
        onClose={handleClose}
      >
        <NotificationHeadCard notificationsNumber={notificationsNumber} onDismiss={handleDismissAll} />
        <Divider />
        {
          notifications?.length > 0 && notifications.map(n => (
            <NotificationCard
              key={n.id}
              id={n.id}
              type={n.type}
              time={n.publishedAt}
              onDismiss={handleDismiss}
              message={n.message}
              downloadURL={n.downloadURL}
              handleDownload={handleDownload}
            />
          ))
        }
        {
          (!notifications || !notifications.length) && <div className={styles.noNotificationsMessage}>
            <P2>There are no new notifications! You are up to date!</P2>
          </div>
        }
      </StyledMenu>
    </div>
  )
}
