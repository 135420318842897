import React from 'react'
import PropTypes from 'prop-types'

import { P, Strong } from 'components/ui/Typography'

import styles from './TableCell.module.scss'

function TableCell({ value, compare, suffix, name }) {
  const shownValue = suffix === '%' || name === 'revenue' ?
    value.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) :
    value.toLocaleString('en-US')
  return (
    <div className={compare && value !== 0 ? (value > 0 ? styles.tableCellPositive : styles.tableCellNegative) : styles.tableCell}>
      {compare ? <Strong>{Math.sign(value) === 1 ? '+' : ''}{shownValue}{suffix}</Strong> : <P>{shownValue}{suffix}</P> }
    </div>
  )
}

TableCell.propTypes = {
  value: PropTypes.number.isRequired,
  compare: PropTypes.bool.isRequired,
  suffix: PropTypes.string,
  name: PropTypes.string,
}

export default TableCell
