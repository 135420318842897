/**
 * Conditionally executes action based on the condition and returns the condition.
 * This is useful in promise handling when we want to do something conditionally and
 * pass the condition to the chain.
 *
 * @param condition Truthy value
 * @param action Action to execute if condition is truth
 * @return condition
 */
export const passGuard = async(condition, action) => {
  if (condition) {
    await action()
  }
  return condition
}

/**
 * Executes action and returns the value.
 * This is useful in promise handling when we want to do something and
 * pass the value to the chain.
 *
 * @param value Value to be returned
 * @param action Action to execute
 * @return value
 */
export const pass = async(value, action) => {
  await action()
  return value
}
