import React, { useCallback } from 'react'
import CancelIcon from '@mui/icons-material/Cancel'
import PropTypes from 'prop-types'
import {
  InputLabel,
  FormControl,
  Select,
  OutlinedInput,
  MenuItem,
  Grid,
  Chip,
  Checkbox,
}
  from '@mui/material'

import { useOnUpdate } from 'hooks/useOnUpdate'

import styles from './MultipleChipSelect.module.scss'

export const MultipleChipSelect = ({ itemsList, onSelection, selectedValues }) => {
  useOnUpdate(() => {
    onSelection(selectedValues)
  }, [selectedValues, onSelection])

  const handleChange = useCallback(value => {
    onSelection(prevValues =>
      prevValues.includes(value) ?
        prevValues.filter(selectOption => selectOption !== value) :
          [...prevValues, value],
    )
  }, [onSelection])

  const handleChipDelete = useCallback((event, value) => {
    event.preventDefault()
    onSelection(prevValues => prevValues.filter(selectOption => selectOption !== value))
  }, [onSelection])

  return (
    <FormControl fullWidth>
      <InputLabel id='chip-label'>Choose categories</InputLabel>
      <Select
        labelId='chip-label'
        multiple
        className={styles.select}
        value={selectedValues}
        input={<OutlinedInput label='Choose categories' />}
        renderValue={
          (selected) => (
            <Grid className={styles.selectedItems}>
              {
                selected.map((value) => (
                  <Chip
                    className={styles.selectedChip}
                    variant='outlined'
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                    onDelete={(e) => handleChipDelete(e, value)}
                    key={value.id}
                    label={value.name}
                  />
                ))
              }
            </Grid>
          )
        }
      >
        {
          itemsList.map((listItem) => (
            <MenuItem key={listItem.id} value={listItem.id} onClick={() => { handleChange(listItem) }}>
              <Checkbox key={listItem.id} checked={selectedValues.some(value => value.id === listItem.id)} />
              {listItem.name}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

MultipleChipSelect.propTypes = {
  itemsList: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  selectedValues: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onSelection: PropTypes.func.isRequired,
}
export default MultipleChipSelect
