import React from 'react'
import PropTypes from 'prop-types'

import { Label } from 'components/ui/Typography'

import styles from './HorizontalHeatmapLegend.module.scss'

export const heatmapClasses = [
  styles.colorMin5, styles.colorMin4, styles.colorMin3, styles.colorMin2, styles.colorMin1,
  styles.colorMid,
  styles.colorMax1, styles.colorMax2, styles.colorMax3, styles.colorMax4, styles.colorMax5,
]

const minScaleClasses = heatmapClasses.slice(0, 5)
const maxScaleClasses = heatmapClasses.slice(6, 11)

function HorizontalHeatmapLegend({ className }) {
  function renderLegendItems(classes) {
    return (
      <div className={styles.scale}>
        { classes.map(squareClass => <div key={squareClass} className={`${styles.square} ${squareClass}`} />) }
      </div>
    )
  }

  return (
    <div className={`flex-col-center ${className || ''}`}>
      <div className={styles.labels}>
        <Label className={styles.label}>Bellow</Label>
        <Label className={styles.label}>Average</Label>
        <Label className={styles.label}>Above</Label>
      </div>
      <div className={styles.legend}>
        { renderLegendItems(minScaleClasses) }
        <div className={styles.mid} />
        { renderLegendItems(maxScaleClasses) }
      </div>
    </div>
  )
}

HorizontalHeatmapLegend.propTypes = {
  className: PropTypes.string,
}

export default HorizontalHeatmapLegend
