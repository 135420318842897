import React from 'react'
import PropTypes from 'prop-types'

import customPropTypes from 'utils/customPropTypes'
import useSettledState from 'hooks/useSettledState'
import { useOnUpdate } from 'hooks/useOnUpdate'

/**
 * Renders children based on the value of the "open" prop.
 * When children are removed from DOM (opne is false), that is done with a delay of "timeout" milliseconds
 * in order to give chance for exit animations to complete.
 */
function Suspended({ children, open, timeout = 500 }) {
  const [active, setActive] = useSettledState(open, timeout)

  useOnUpdate(() => {
    setActive(open)
  }, [open, setActive])

  return (
    (active.value || !active.settled) &&
      <>
        { children }
      </>
  )
}

Suspended.propTypes = {
  timeout: PropTypes.number,
  open: PropTypes.bool,
  children: customPropTypes.children.isRequired,
}

export default Suspended
