import React, { useCallback } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import IconButton from '@mui/material/IconButton'
import PropTypes from 'prop-types'

import { DeleteIcon, LockIcon, UnlockIcon } from 'assets/icons'
import { UserStatus, Role } from 'services/enums'

import UserStatusLabel from './UserStatusLabel/UserStatusLabel'
import UserAvatar from './UserAvatar/UserAvatar'
import styles from './UserRow.module.scss'
import UserCategories from './UserCategories/UserCategories'

const UserRow = ({ user, onRemove, onSuspend, onRestore, onResend, onConfirm }) => {
  const handleRemove = useCallback(() => onRemove(user.id), [onRemove, user.id])
  const handleRestore = useCallback(() => onRestore(user.id), [onRestore, user.id])
  const handleSuspend = useCallback(() => onSuspend(user.id), [onSuspend, user.id])

  return (
    <TableRow >
      <TableCell align="left" >
        <UserAvatar user={user} />
      </TableCell>
      <TableCell align="left" className={styles.emailWrap}>
        {user.email}
      </TableCell>
      <TableCell className={styles.statusWrap} align="left" >
        <UserStatusLabel
          user={user}
          onResend={onResend}
          onConfirm={onConfirm}
        />
      </TableCell>
      <TableCell align="left">
        {user.role.label}
      </TableCell>
      <TableCell className={styles.categories} align="left">
        <UserCategories user={user} />
      </TableCell>
      <TableCell align="center">
        {
          user.role === Role.categoryManager &&
            (
              user.status === UserStatus.suspended ?
                <IconButton onClick={handleRestore} size='large'>
                  <LockIcon className={`${styles.suspended} icon small`} />
                </IconButton> :
                <IconButton onClick={handleSuspend} size="large">
                  <UnlockIcon className='icon small' />
                </IconButton>
            )
        }
      </TableCell>
      <TableCell size='small' className={styles.delete} align="right">
        {
          user.role === Role.categoryManager &&
            <IconButton onClick={handleRemove} aria-label="delete" size="large">
              <DeleteIcon className="icon small" />
            </IconButton>
        }
      </TableCell>
    </TableRow>
  )
}

UserRow.propTypes = {
  user: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSuspend: PropTypes.func.isRequired,
  onRestore: PropTypes.func.isRequired,
  onResend: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
}

export default UserRow
