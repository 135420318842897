import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import ReportGmailerrorredOutlined from '@mui/icons-material/ReportGmailerrorredOutlined'
import InfoOutlined from '@mui/icons-material/InfoOutlined'
import WarningAmberOutlined from '@mui/icons-material/WarningAmberOutlined'
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined'
import { actions } from 'store'

import { P } from 'components/ui/Typography'
import { ToastSeverity } from 'store/toast/actions'

import styles from './Toast.module.scss'

function Toast({ className }) {
  const dispatch = useDispatch()

  const messages = useSelector(state => state.toast.messages)
  const activeMessage = useSelector(state => state.toast.activeMessage)
  const open = useSelector(state => state.toast.open)

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    dispatch(actions.toast.close())
  }, [dispatch])

  const handleExited = useCallback(() => {
    dispatch(actions.toast.dismiss())
  }, [dispatch])

  useEffect(() => {
    if (messages.length && !activeMessage) {
      // set a new snack when we don't have an active one
      dispatch(actions.toast.showNext())
    } else if (messages.length && activeMessage && open) {
      // close an active snack when a new one is added
      dispatch(actions.toast.close())
    }
  }, [messages, activeMessage, open, dispatch])

  return (
    <Snackbar
      className={`${styles.snack} ${className || ''}`}
      key={activeMessage?.key}
      open={open}
      autoHideDuration={5000}
      onClose={handleClose}
      TransitionProps={{ onExited: handleExited }}
      anchorOrigin={activeMessage?.position}
      message={<ToastMessage message={activeMessage?.message} severity={activeMessage?.severity} />}
      action={
        <IconButton
          aria-label="close"
          color="inherit"
          className={styles.closeIcon}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      }
    />
  )
}

Toast.propTypes = {
  className: PropTypes.string,
}

function getIcon(severity) {
  switch (severity) {
    case ToastSeverity.info:
      return InfoOutlined
    case ToastSeverity.error:
      return ReportGmailerrorredOutlined
    case ToastSeverity.warning:
      return WarningAmberOutlined
    case ToastSeverity.success:
      return CheckCircleOutlined
    default:
      return InfoOutlined
  }
}

function ToastMessage({ message, severity }) {
  const [Icon, setIcon] = useState(getIcon(severity))

  useEffect(() => {
    setIcon(getIcon(severity))
  }, [severity])

  return (
    <div className={styles.messageContainer}>
      <Icon color={severity.value} />
      <P>{message}</P>
    </div>
  )
}

ToastMessage.propTypes = {
  message: PropTypes.string,
  severity: PropTypes.oneOf([ToastSeverity.error, ToastSeverity.warning, ToastSeverity.info, ToastSeverity.success]),
}

export default Toast
