import React from 'react'

import { H4, H6 } from 'components/ui/Typography'

import styles from './NotVerified.module.scss'

const NotVerified = () => {
  return (
    <div className={`auth-page ${styles.notVerfied}`}>
      <H4 className={styles.title}> Your account is not confirmed by admin! </H4>
      <H6 className={styles.text} > We will send you email when your account is set up so you can log in. </H6>
    </div>
  )
}

export default NotVerified
