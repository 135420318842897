import React from 'react'
import PropTypes from 'prop-types'

import { H4, H6 } from 'components/ui/Typography'
import { ReviewStatus } from 'services/enums'

import styles from './CategoryReviewStatus.module.scss'

function CategoryReviewStatus({ manager, status }) {
  return (
    <div className={styles.reviewStatus}>
      <div className={styles.categoryManager}>
        <H6>Category manager:</H6>
        <H4>{manager}</H4>
      </div>
      <div className={`${styles.status} flex-row-center`}>
        <div className={`${styles.circle} ${status === ReviewStatus.reviewed && styles.reviewed}`} />
        <H6>Status:</H6>
        <H4>{status.label}</H4>
      </div>
    </div>
  )
}

CategoryReviewStatus.propTypes = {
  manager: PropTypes.string,
  status: PropTypes.oneOf(ReviewStatus.values()),
}

export default CategoryReviewStatus
