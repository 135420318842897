/**
 * Creates a string suitable for className prop.
 * It accepts either an array of class names or a single class name.
 * When processing the array, it ignores empty strings, null or undefined elements.
 *
 * @param {string|Array} classes
 */
export const cls = (classes) => {
  if (Array.isArray(classes)) {
    return classes
      .filter(className => className)
      .join(' ')
  }

  if (typeof classes === 'string') {
    return classes
  }

  return ''
}
