import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  categories: null,
  config: {
    monthsInPeriod: 3,
  },
  quotas: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.fetchCategoriesSuccess]: fetchCategoriesSuccess,
  [types.fetchUnassignedCategoriesSuccess]: fetchUnassignedCategoriesSuccess,
  [types.fetchConfigSuccess]: fetchConfigSuccess,
  [types.fetchQuotasSuccess]: fetchQuotasSuccess,

}

function vacate(state) {
  return {
    ...state,
    categories: null,
    config: {
      monthsInPeriod: 3,
    },
    quotas: null,
  }
}

function fetchCategoriesSuccess(state, action) {
  return {
    ...state,
    categories: action.data,
  }
}

function fetchUnassignedCategoriesSuccess(state, action) {
  return {
    ...state,
    unassignedCategories: action.data,
  }
}

function fetchConfigSuccess(state, action) {
  return {
    ...state,
    config: {
      ...state.config,
      ...action.data,
    },
  }
}

function fetchQuotasSuccess(state, action) {
  return {
    ...state,
    quotas: action.data,
  }
}

export default objectReducer(initialState, actionHandlers)
