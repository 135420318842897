import React from 'react'
import PropTypes from 'prop-types'
import Tooltip from '@mui/material/Tooltip'

import { DeviationIcon } from 'assets/icons'

import styles from './SkuDeviationTableCell.module.scss'

function SkuDeviationTableCell({ deviation }) {
  return (
    <div className={styles.skuDeviationTableCell}>
      {
        deviation ?
          <Tooltip title="Added by user not reccommended by optimization.">
            <DeviationIcon className='icon small' />
          </Tooltip> :
          '—'
      }
    </div>
  )
}

SkuDeviationTableCell.propTypes = {
  deviation: PropTypes.bool.isRequired,
}

export default SkuDeviationTableCell
