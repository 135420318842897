import frozen from 'utils/frozen'
import { objectReducer } from 'utils/objectReducer'

import * as types from './types'

const initialState = frozen({
  profile: null,
  apiAccessToken: null,
  apiRefreshToken: null,
  clientId: null,
  registrationId: null,
})

const actionHandlers = {
  [types.vacate]: vacate,
  [types.loginSuccess]: loginSuccess,
  [types.logoutSuccess]: logoutSuccess,
  [types.registerUserSuccess]: registerUserSuccess,
  [types.restoreApiAccessToken]: restoreApiAccessToken,
  [types.restoreApiRefreshToken]: restoreApiRefreshToken,
  [types.restoreClientId]: restoreClientId,
}

function vacate(state) {
  return {
    ...state,
    profile: null,
    apiAccessToken: null,
    apiRefreshToken: null,
    clientId: null,
    registrationId: null,
  }
}

function loginSuccess(state, action) {
  return {
    ...state,
    apiAccessToken: action.data.accessToken,
    apiRefreshToken: action.data.refreshToken,
    clientId: action.data.clientId,
  }
}

function logoutSuccess(state) {
  return {
    ...state,
    profile: null,
    apiAccessToken: null,
    apiRefreshToken: null,
    clientId: null,
  }
}

function registerUserSuccess(state, action) {
  return {
    ...state,
    registrationId: action.data.userId,
  }
}

function restoreApiAccessToken(state, action) {
  return {
    ...state,
    apiAccessToken: action.token || false,
  }
}

function restoreApiRefreshToken(state, action) {
  return {
    ...state,
    apiRefreshToken: action.token || false,
  }
}

function restoreClientId(state, action) {
  return {
    ...state,
    clientId: action.clientId || false,
  }
}

export default objectReducer(initialState, actionHandlers)
