import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useDropzone } from 'react-dropzone'
import AvatarEditor from 'react-avatar-editor'
import { Button } from '@mui/material'
import Slider from '@mui/material/Slider'

import Dialog from 'components/ui/Dialog'
import { P } from 'components/ui/Typography'

import styles from './UploadProfilePhotoStart.module.scss'

const EDITOR_SIZE = 200

const UploadProfilePhotoStart = ({ openModal, onClose, onUpload }) => {
  const [chosenFile, setChosenFile] = useState(null)
  const [zoom, setZoom] = useState(1)
  const [editorRef, setEditorRef] = useState('')

  const handleOnDrop = useCallback(acceptedFiles => {
    setChosenFile(acceptedFiles.map(file => Object.assign(file, {
      preview: URL.createObjectURL(file),
    })))
  }, [])

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleOnDrop,
    accept: 'image/jpeg,image/png',
  })

  useEffect(() => {
    setChosenFile(acceptedFiles?.[0])
  }, [acceptedFiles])

  const handleSaveFile = useCallback(() => {
    if (editorRef) {
      const canvasScaled = editorRef.getImageScaledToCanvas()
      const croppedImg = canvasScaled.toDataURL()

      onUpload(croppedImg)
      setChosenFile(null)
      onClose()
    }
  }, [editorRef, onClose, onUpload])

  const handleCancel = useCallback(() => {
    setChosenFile(null)
    onClose()
  }, [onClose])

  const handleZoomChange = useCallback(({ target: { value } }) => {
    setZoom(value)
  }, [])

  return (
    <Dialog
      title='Add profile photo'
      open={openModal}
      onClose={onClose}
      className={styles.dialog}
    >
      <div className={`flex-col-center ${styles.chooseFileContainers}`}>
        <div {...getRootProps({ className: 'dropzone' })}>
          <input {...getInputProps()} />
          {
            !chosenFile ?
              <div className={`flex-col-center ${styles.fileContainer}`}>
                <P>Upload photo</P>
                <Button
                  className={styles.chooseFileButton}
                  onClick={open}
                  variant="contained"
                  size="small"
                >
                  Choose file
                </Button>
              </div> :
              <div className={`flex-col-center ${styles.savePhotoContainer}`}>
                <P>Resize and save!</P>
                <div>
                  <AvatarEditor
                    ref={ed => setEditorRef(ed)}
                    image={chosenFile?.preview}
                    width={EDITOR_SIZE}
                    height={EDITOR_SIZE}
                    border={0}
                    borderRadius={EDITOR_SIZE / 2}
                    scale={zoom}
                  />
                  <Slider
                    size="small"
                    className={styles.input}
                    min={1}
                    max={2}
                    step={0.01}
                    value={zoom}
                    onChange={handleZoomChange}
                  />
                </div>
                <Button
                  className={styles.savePhotoButton}
                  onClick={handleSaveFile}
                  variant="contained"
                  size="small"
                >
                  Save photo
                </Button>
              </div>
          }
        </div>
        <Button
          className={styles.cancelButton}
          onClick={handleCancel}
          variant="primary"
          size="small"
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  )
}

UploadProfilePhotoStart.propTypes = {
  openModal: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpload: PropTypes.func.isRequired,
}

export default UploadProfilePhotoStart
