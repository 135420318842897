import React, { useState } from 'react'
import { actions, selectors } from 'store'
import { useDispatch, useSelector } from 'react-redux'

import NoResults from 'components/ui/NoResults'
import UsersTable from 'components/ui/UsersTable/UsersTable'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import useData from 'hooks/useData'
import usePager from 'hooks/usePager'
import SearchInput from 'components/ui/SearchInput/SearchInput'
import useDebounceCallback from 'hooks/useDebounceCallback'

import styles from './Users.module.scss'

const Users = () => {
  const dispatch = useDispatch()
  const users = useSelector(selectors.users.hydratedUsers)
  const [search, setSearch] = useState(null)

  const { data, loaded, error } = useData(() => {
    return dispatch(actions.users.fetchUsers(search))
  }, [dispatch, search])

  const { loaded: loadedCategories } = useData(() => {
    return dispatch(actions.organizations.fetchCategories())
  }, [dispatch])

  const {
    previous: previousUsers,
    next: nextUsers,
    hasNext: hasNextUsers,
    hasPrevious: hasPreviousUsers,
    loaded: usersPageLoaded,
  } = usePager(data, actions.users.fetchUsersSuccess)

  const updateSearch = useDebounceCallback(({ target: { value } }) => {
    setSearch(value)
  }, [])

  return (
    <div className={styles.users}>
      <SearchInput className={styles.searchBox} placeholder="Search" onChange={updateSearch} />
      {
        loadedCategories && data ?
          data.length ?
            <div className={loaded ? '' : styles.usersTable}>
              <UsersTable
                userRecords={users}
                onNextPage={nextUsers}
                onPreviousPage={previousUsers}
                hasNextPage={hasNextUsers}
                hasPreviousPage={hasPreviousUsers}
                pageLoaded={usersPageLoaded}
              />
            </div> :
            <NoResults
              title={'No results found'}
            /> :
            <SectionLoadingIndicator className={styles.loader} error={!!error} />
      }
    </div>
  )
}

export default Users
