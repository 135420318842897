/**
 * Creates an bject from element of the map. For each array element, map key is determined by
 * using keyExtractor() function.
 * Additionally, optional enhancer() function can be used to create additional properties added to the new items
 *
 * @param array Array to transform to map
 * @param keyExtractor Function that accepts (item, index) and returns the map key for the item
 * @param enhancer Function that accepts (item, index) and returns the object with additional properties
 *                 that will be used to enhance the original items
 * @return object Map object
 */
export const arrayToMap = (array, keyExtractor, enhancer) => {
  function enhance(item, index, enhancer) {
    return !enhancer ?
      item :
        {
          ...item,
          ...enhancer(item, index),
        }
  }

  return array?.reduce((acc, item, index) => {
    return {
      ...acc,
      [keyExtractor(item, index)]: enhance(item, index, enhancer),
    }
  }, {})
}
