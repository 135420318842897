import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { actions, selectors } from 'store'
import { Button } from '@mui/material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import { useParams } from 'react-router-dom'
import _ from 'lodash'

import useData from 'hooks/useData'
import Input from 'components/ui/Input'
import { P, Errors } from 'components/ui/Typography'
import SectionLoadingIndicator from 'components/ui/SectionLoadingIndicator'
import MultipleChipSelect from 'components/ui/MultipleChipSelect'
import Suspended from 'components/ui/Suspended'
import { errorToast } from 'components/ui/Toast'
import useToast from 'components/ui/Toast/useToast'
import { Role } from 'services/enums'
import useBooleanState from 'hooks/useBooleanState'
import useAuth from 'hooks/useAuth'
import { useForm, useRevalidate } from 'hooks/useForm'

import UploadProfilePhotoStart from './UploadProfilePhotoStart'
import ProfilePhoto from './ProfilePhoto'
import Categories from './Categories'
import styles from './Profile.module.scss'

function validate(values) {
  const errors = {}
  if (!values.firstName?.trim()) {
    errors.firstName = { required: true }
  } else if (values.firstName?.length >= 20) {
    errors.firstName = { maxLength: true }
  }

  if (!values.lastName?.trim()) {
    errors.lastName = { required: true }
  } else if (values.lastName?.length >= 30) {
    errors.lastName = { maxLength: true }
  }

  return errors
}

const Profile = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { successToast } = useToast()
  const { id: userIdFromParams } = useParams()
  const userId = useSelector(state => state.users.profile.id)
  const user = useSelector(selectors.users.userWithCategories)
  const isAdmin = useAuth({ is: Role.admin })
  const unassignedCategories = useSelector(state => state.organizations?.unassignedCategories)

  const [selectedValues, setSelectedValues] = useState([])
  const [completeCategories, setCompleteCategories] = useState([])
  const initialValues = useMemo(() => ({ firstName: user?.firstName || '', lastName: user?.lastName || '' }), [user])

  useEffect(() => {
    if (unassignedCategories && user.categories) {
      setSelectedValues(user.categories)
      setCompleteCategories(_.uniqBy([...unassignedCategories, ...user.categories], c => c.id)
        .sort((a, b) => a.name.localeCompare(b.name)))
    }
  }, [user, unassignedCategories])

  useEffect(() => {
    dispatch(actions.organizations.fetchUnassignedCategories())
  }, [dispatch, user.categories])

  const submit = useCallback(({ firstName, lastName }) => {
    const updateUser = { id: userIdFromParams ?? userId, firstName, lastName }
    if (isAdmin && selectedValues) {
      updateUser.categories = selectedValues.map(value => value.id)
    }
    return dispatch(actions.users.updateUser(updateUser))
      .then(() => {
        setSelectedValues(updateUser.categories)
        return successToast('Category manager successfully updated')
      })
      .catch(() => {
        return errorToast('Failed updating user')
      })
  }, [dispatch, userIdFromParams, userId, isAdmin, selectedValues, successToast])

  const revalidate = useRevalidate()
  const { errors, submitForm, values, setValue, clear } =
    useForm('userInfo', validate, submit, initialValues, revalidate)

  const handleClose = useCallback(() => {
    clear()
    setSelectedValues(user?.categories)
  }, [clear, user])

  const [uploadPhotoModalOpen, openUploadPhotoModal, closeUploadPhotoModal] = useBooleanState(false)

  const { loaded, error } = useData(() => {
    return Promise.all([
      dispatch(actions.users.fetchUser(userIdFromParams || userId)),
      dispatch(actions.organizations.fetchCategories()),
      dispatch(actions.organizations.fetchUnassignedCategories()),
    ])
  }, [dispatch, userId, userIdFromParams])

  const handlePhotoUpload = profilePhoto => {
    dispatch(actions.users.updateProfilePhoto(userId, profilePhoto))
  }

  if (!loaded) {
    return (
      <SectionLoadingIndicator className={styles.loader} error={!!error} />
    )
  }

  return (
    <>
      {isAdmin && <Button className={styles.backButton} startIcon={<ArrowBackIosIcon />} onClick={() => navigate(-1)}> Back to All users </Button>}
      <div className={styles.profilePage}>
        <div className={styles.container}>
          <div className={`flex-col-center ${styles.mainInfoContainer}`}>
            <ProfilePhoto profilePhotoUrl={user?.profilePhotoUrl} onClick={(!isAdmin || userId === +userIdFromParams) ? openUploadPhotoModal : undefined} />
            <div className={'flex-col-center'}>
              <P className={styles.userName}>{user.firstName} {user.lastName}</P>
              {
                user.role === Role.admin ?
                  <P>Admin</P> :
                  <P>Category manager</P>
              }
            </div>
          </div>

          <div className={`flex-col-center ${styles.userDetailsContainer}`}>
            <Errors errors={errors?._} />
            <Input
              name='firstName'
              className={styles.input}
              label='Name'
              value={values.firstName || ''}
              onChange={setValue}
              error={!!errors?.firstName}
              helperText={errors?.firstName?.[0]}
              fullWidth
            />
            <Input
              name='lastName'
              className={styles.input}
              label='Surname'
              value={values.lastName || ''}
              onChange={setValue}
              error={!!errors?.lastName}
              helperText={errors?.lastName?.[0]}
              fullWidth
            />
            {
              user.role.value !== 'admin' && (isAdmin && user.status.value !== 'suspended' ?
                <MultipleChipSelect itemsList={completeCategories} onSelection={setSelectedValues} selectedValues={selectedValues} /> :
                <Categories categories={user?.categories} />)
            }

            <div className={styles.buttonsContainer}>
              <Button onClick={handleClose} variant="outlined" className={styles.cancelButton}>
                Cancel
              </Button>
              <Button onClick={submitForm} variant="contained" className={styles.saveButton}>
                Save Changes
              </Button>
            </div>
          </div>
        </div>
        <Suspended open={uploadPhotoModalOpen}>
          <UploadProfilePhotoStart
            openModal={uploadPhotoModalOpen}
            onClose={closeUploadPhotoModal}
            onUpload={handlePhotoUpload}
          />
        </Suspended>
      </div>
    </>
  )
}

export default Profile
