import React from 'react'
import PropTypes from 'prop-types'

import styles from './ProgressIndicator.module.scss'

function ProgressIndicator({ className }) {
  return (
    <div className={`${styles.circles} ${className}`}>
      <div className={`${styles.circle} circle`} />
      <div className={`${styles.circle} circle`} />
      <div className={`${styles.circle} circle`} />
    </div>
  )
}

ProgressIndicator.propTypes = {
  className: PropTypes.string,
}

export default ProgressIndicator
