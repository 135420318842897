import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { actions } from 'store'

import customPropTypes from 'utils/customPropTypes'

function ConnectedRouter({ children }) {
  const dispatch = useDispatch()
  const { to, replace, state } = useSelector(state => state.router.navigate || {})
  const navigate = useNavigate()

  useEffect(() => {
    if (to) {
      navigate(to, { replace, state })
      dispatch(actions.router.navigated())
    }
  }, [dispatch, navigate, replace, state, to])

  return (
    <>
      { children }
    </>
  )
}

ConnectedRouter.propTypes = {
  children: customPropTypes.children,
}

export default ConnectedRouter
