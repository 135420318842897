import { enumValue, from, values } from 'utils/enum'

import * as types from './types'

export const ToastSeverity = {
  error: enumValue('error'),
  warning: enumValue('warning'),
  info: enumValue('info'),
  success: enumValue('success'),
  from,
  values,
}

const defaultPosition = {
  vertical: 'top',
  horizontal: 'center',
}

export const vacate = () => ({ type: types.vacate })

export const queue = (message, severity = ToastSeverity.info, position = defaultPosition) =>
  ({ type: types.queue, message, severity, position })

export const showNext = () => ({ type: types.showNext })

export const dismiss = () => ({ type: types.dismiss })

export const open = () => ({ type: types.open })

export const close = () => ({ type: types.close })
