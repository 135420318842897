import React, { useState, useCallback } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { actions } from 'store'

import useBooleanState from 'hooks/useBooleanState'
import useToast from 'components/ui/Toast/useToast'
import Dialog from 'components/ui/Dialog'
import { H6 } from 'components/ui/Typography'

import TablePaginationButtons from '../TablePaginationButtons'

import UserRow from './UserRow/UserRow'
import styles from './UsersTable.module.scss'
import SuspendUserModal from './SuspendUserModal'
import RemoveUserModal from './RemoveUserModal'

function UsersTable({
  userRecords,
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
  pageLoaded,
}) {
  const dispatch = useDispatch()
  const { successToast, errorToast } = useToast()
  const [suspendConfirmationOpen, openSuspendConfirmation, closeSuspendConfirmation] = useBooleanState(false)
  const [removeConfirmationOpen, openRemoveConfirmation, closeRemoveConfirmation] = useBooleanState(false)
  const [isSuccessfulDialogOpen, openSuccessDialog, closeSuccessDialog] = useBooleanState(false)
  const [selectedUser, setSelectedUser] = useState('')

  const handleRemove = useCallback(id => {
    openRemoveConfirmation()
    setSelectedUser(id)
  }, [openRemoveConfirmation])

  const handeRemoveConfirmation = useCallback(() => {
    dispatch(actions.users.deleteUser(selectedUser))
    closeRemoveConfirmation()
  }, [dispatch, selectedUser, closeRemoveConfirmation])

  const handleSuspend = useCallback(id => {
    openSuspendConfirmation()
    setSelectedUser(id)
  }, [openSuspendConfirmation])

  const handleSuspendConfirmation = useCallback(() => {
    dispatch(actions.users.suspendUser(selectedUser))
    dispatch(actions.organizations.fetchUnassignedCategories())
    closeSuspendConfirmation()
  }, [dispatch, selectedUser, closeSuspendConfirmation])

  const handleRestore = useCallback(id => {
    dispatch(actions.users.restoreUser(id))
    dispatch(actions.organizations.fetchUnassignedCategories())
  }, [dispatch])

  const handleResend = useCallback(id => {
    return dispatch(actions.users.resendUserConfirmationLink(id))
      .then(() => successToast('Email sent successfully'))
      .catch(() => errorToast('Error while resending email'))
  }, [dispatch, successToast, errorToast])

  const handleConfirm = useCallback((id, categories) => {
    dispatch(actions.users.approveCategoryManager(id, categories.map(category => category.id)))
      .then(openSuccessDialog)
      .catch(() => errorToast('Confirming category manager failed!'))
  }, [dispatch, errorToast, openSuccessDialog])

  return (
    <>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table className={styles.table} >
          <TableHead >
            <TableRow >
              <TableCell align="left">Name</TableCell>
              <TableCell align="left">E-mail</TableCell>
              <TableCell align="left">Status</TableCell>
              <TableCell align="left">User type</TableCell>
              <TableCell align="left">Categories</TableCell>
              <TableCell align="center">Revoke Access</TableCell>
              <TableCell className={styles.deleteCell} align="right">Remove</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              userRecords.map((user) => (
                <UserRow
                  key={user.id}
                  user={user}
                  onSuspend={handleSuspend}
                  onRemove={handleRemove}
                  onRestore={handleRestore}
                  onResend={handleResend}
                  onConfirm={handleConfirm}
                />
              ))
            }
          </TableBody>
        </Table>
        <TablePaginationButtons
          nextPage={onNextPage}
          previousPage={onPreviousPage}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          pageLoaded={pageLoaded}
        />
      </TableContainer>

      <SuspendUserModal
        open={suspendConfirmationOpen}
        onClose={closeSuspendConfirmation}
        onConfirm={handleSuspendConfirmation}
      />
      <RemoveUserModal
        open={removeConfirmationOpen}
        onClose={closeRemoveConfirmation}
        onConfirm={handeRemoveConfirmation}
      />
      <Dialog
        title='Successful confirmation'
        open={isSuccessfulDialogOpen}
        onClose={closeSuccessDialog}
        className={`flex-col-center ${styles.successDialog}`}
      >
        <H6 className={styles.text}> New team member is added to your team! They will receive an activation email and log into their account. </H6>
        <Button variant='contained' size='small' className={styles.okButton} onClick={closeSuccessDialog}> OK </Button>
      </Dialog>
    </>
  )
}

UsersTable.propTypes = {
  userRecords: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    email: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profilePhotoUrl: PropTypes.string,
    status: PropTypes.symbol,
    role: PropTypes.symbol,
    categories: PropTypes.array,
  })),
  onPreviousPage: PropTypes.func,
  onNextPage: PropTypes.func,
  hasNextPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  hasPreviousPage: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
  pageLoaded: PropTypes.bool,
}

export default UsersTable
