import React from 'react'
import PropTypes from 'prop-types'

import { H4 } from 'components/ui/Typography'
import CustomTooltip from 'components/ui/CustomTooltip'
import customPropTypes from 'utils/customPropTypes'
import { InfoIcon } from 'assets/icons'

import styles from './BaseAssortmentCard.module.scss'

function BaseAssortmentCard({ title, info, children, className }) {
  return (
    <div className={`${styles.cardContainer} ${className || ''}`}>
      <div className={`flex-row-center ${styles.cardHeader}`}>
        <H4>{title}</H4>

        <CustomTooltip title={info} >
          <InfoIcon className="icon small" />
        </CustomTooltip>
      </div>

      <div className={styles.cardBody}>
        {children}
      </div>
    </div>
  )
}

BaseAssortmentCard.propTypes = {
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  children: customPropTypes.children.isRequired,
  className: PropTypes.string,
}

export default BaseAssortmentCard
