import React from 'react'

import notFoundImage from 'assets/page-not-found.png'
import NoMatchFound from 'components/ui/NoMatchFound/NoMatchFound'

import styles from './NotFound.module.scss'
function NotFound() {
  return (
    <NoMatchFound
      titleText={'Oops!'}
      descriptionText={'Something went wrong...'}
      image={notFoundImage}
      className={styles.text}
    />
  )
}

export default NotFound
