import React from 'react'
import { Outlet } from 'react-router-dom'

import { LogoAndText } from 'assets/icons'

import styles from './AuthContainer.module.scss'

const AuthContainer = () => {
  return (
    <div className={styles.AuthContainer}>
      <div className={`flex-col-center ${styles.bodyContainer}`}>
        <LogoAndText className={styles.spectra} />
        <Outlet />
      </div>
    </div>
  )
}

export default AuthContainer
