import { createSelector } from 'reselect'

import { ReviewStatus } from 'services/enums'
import { nvl } from 'utils/object'

export const categoryManagers = createSelector(
  state => state.optimizations.optimization?.approvals,
  (approvals) => {
    if (approvals) {
      const catManagers = {}
      approvals.forEach(manager => {
        manager.categories?.forEach(category => {
          catManagers[category.categoryId] = {
            manager: `${manager.firstName} ${manager.lastName}`,
            status: manager.approvalDate ? ReviewStatus.reviewed : ReviewStatus.pending,
          }
        })
      })

      return catManagers
    }
    return {}
  },
)

/**
 * Boolean values indicating if there is at least one optimization in the system.
 */
export const hasOptimizations = createSelector(
  state => state.optimizations.oneOptimization,
  oneOptimization => !!oneOptimization?.length,
)

/**
 * This is optimization if there is one and only one optimization in the system.
 * If there are ore than one optimization or zero optimizations in the system, this will be null.
 */
export const singleOptimization = createSelector(
  state => state.optimizations.oneOptimization,
  oneOptimization => oneOptimization?._meta?.lastId ? null : oneOptimization?.[0],
)

/**
 * Here we are mapping subcategory metadata from categories list with their values from their settings
 */
export const subCategoryDetails = createSelector(
  state => state.optimizations.optimization?.subcategorySettings,
  state => state.optimizations.optimization?.subcategories,
  (subcategorySettings, subcategories) => {
    if (subcategorySettings && subcategories) {
      const subCategoriesWithMeta = {}
      subcategories.forEach(subCat => {
        const settings = subcategorySettings[subCat.id] || {}
        subCategoriesWithMeta[subCat.id] = {
          ...subCat,
          ...settings,
          value: nvl(settings.value, settings.defaultValue),
          automatic: nvl(settings.automatic, false),
        }
      })
      return subCategoriesWithMeta
    }
  },
)
