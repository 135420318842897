import log from 'utils/log'
import axios from 'services/api/axios.instance'
import axiosCognito from 'services/api/axiosCognito.instance'
import { config, setAccessToken, setClientId, setRefreshToken } from 'services/api/common'
import { ApiError } from 'services/errors'

import { profileInForm } from './adapters/authAdapter'

const getTenant = () => {
  const host = window.location.host
  return host.startsWith('localhost:') ? 'alpha.spectraopt.com' : host
}

const register = async(values) => {
  const body = {
    email: values.email,
    tenant: getTenant(),
    password: values.password,
    firstName: values.name,
    lastName: values.surname,
  }

  return axios.post('/registrations', body)
}

const login = async({ email, password }) => {
  const userPool = await axios.post('/auth/login_config', {
    tenant: getTenant(),
    username: email,
  })

  setClientId(userPool.cognitoAppClientId)
  const body = {
    AuthFlow: 'USER_PASSWORD_AUTH',
    ClientId: userPool.cognitoAppClientId,
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
    ClientMetadata: {},
  }

  const cognitoData = await axiosCognito.post('/', body, {
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
  })

  if (!cognitoData.data.AuthenticationResult) {
    throw new ApiError(cognitoData.data.message, cognitoData.status)
  }

  setAccessToken(cognitoData.data.AuthenticationResult.IdToken)
  setRefreshToken(cognitoData.data.AuthenticationResult.RefreshToken)

  const profileData = await getProfile()

  return {
    accessToken: cognitoData.data.AuthenticationResult.IdToken,
    refreshToken: cognitoData.data.AuthenticationResult.RefreshToken,
    clientId: userPool.cognitoAppClientId,
    profileData: profileData,
  }
}

let inflightRefreshAccessToken = null
const refreshAccessToken = async() => {
  if (inflightRefreshAccessToken) return inflightRefreshAccessToken

  const body = {
    AuthFlow: 'REFRESH_TOKEN_AUTH',
    ClientId: config.clientId,
    AuthParameters: {
      REFRESH_TOKEN: config.refreshToken,
    },
    ClientMetadata: {},
  }

  inflightRefreshAccessToken = axiosCognito.post('/', body, {
    headers: {
      'X-Amz-Target': 'AWSCognitoIdentityProviderService.InitiateAuth',
      'Content-Type': 'application/x-amz-json-1.1',
    },
  })
    .then((cognitoData) => {
      if (!cognitoData.data.AuthenticationResult) {
        throw new ApiError(cognitoData.data.message, cognitoData.status)
      }

      setAccessToken(cognitoData.data.AuthenticationResult.IdToken)

      return cognitoData.data.AuthenticationResult.IdToken
    })
    .finally(() => {
      inflightRefreshAccessToken = null
    })
  return inflightRefreshAccessToken
}

const logout = async() => {
  setAccessToken()
  setRefreshToken()
  setClientId()
  if (!config.accessToken) {
    log.info(() => 'Logout not invoked since user is already logged out.')
    return false
  }
}

const getProfile = async() => {
  return axios.get('/me', {})
    .then(profile => profileInForm(profile))
}

const resetPasswordForEmail = async(email) => {
  return axios.post('/password-resets', { email: email, tenant: getTenant() })
}

const setNewPassword = async(email, password, token) => {
  return axios.put(`/password-resets/${token}`, { email: email, tenant: getTenant(), password: password })
}

const verifyLink = async(token) => {
  return axios.post(`/email-confirmations/${token}`)
}

const resendLink = async(email) => {
  return axios.post(`/registrations/${email}/resend-confirmation-email-commands`)
}

const api = {
  register,
  login,
  refreshAccessToken,
  logout,
  getProfile,
  resetPasswordForEmail,
  setNewPassword,
  verifyLink,
  resendLink,
}

export default api
