import React from 'react'
import PropTypes from 'prop-types'

import { P, Strong } from 'components/ui/Typography'

import styles from './Categories.module.scss'

const Categories = ({ categories }) => {
  return (
    <>
      <Strong>Categories:</Strong>
      <div className={styles.categoriesContainer}>
        {
          categories.map(category => (
            <P key={category.id} className={styles.allCategories} noWrap={true}>{category.name}</P>
          ))
        }
      </div>
    </>
  )
}

Categories.propTypes = {
  categories: PropTypes.array.isRequired,
}

export default Categories
