import React from 'react'
import { useLocation, useNavigate, useParams } from 'react-router'
import { styled, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import useMediaQuery from '@mui/material/useMediaQuery'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import IconButton from '@mui/material/IconButton'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Outlet } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { UsersIcon, SettingsIcon, ExecutionTrackingIcon, AssortmentOptimizationIcon, ClustersIcon, Logo, LogoText, CollpaseLeftIcon, LogoAndText, LogoutIcon } from 'assets/icons'
import useBooleanState from 'hooks/useBooleanState'
import AppLayoutProvider from 'app/navigation/AppLayoutProvider'
import NotificationsMenu from 'components/ui/Notifications/NotificationsMenu'
import DrawerFilter from 'components/DrawerFilter'
import { H1 } from 'components/ui/Typography'
import { Role } from 'services/enums'

import './MainNavigation.scss'
import { getPageTitle } from './getPageTitle'

const navigationItems = [
  {
    Icon: ClustersIcon,
    label: 'Smart clusters',
    pathname: '/clusters',
    role: [Role.admin, Role.categoryManager],
  }, {
    Icon: AssortmentOptimizationIcon,
    label: 'Assortment optimization',
    pathname: '/assortment-optimization/all',
    pathMatch: '/assortment-optimization',
    role: [Role.admin, Role.categoryManager],
  }, {
    Icon: ExecutionTrackingIcon,
    label: 'Execution tracking',
    pathname: '/execution-tracking',
    role: [Role.admin, Role.categoryManager],
  }, {
    Icon: UsersIcon,
    label: 'Users',
    pathname: '/users',
    role: [Role.admin],
  }, {
    Icon: UsersIcon,
    label: 'My profile',
    pathname: '/profile',
    role: [Role.categoryManager],
  }, {
    Icon: SettingsIcon,
    label: 'Settings',
    pathname: '/settings',
    role: [Role.admin, Role.categoryManager],
  }, {
    Icon: LogoutIcon,
    label: 'Logout',
    pathname: '/logout',
    role: [Role.admin, Role.categoryManager],
  },
]

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}))

const MainNavigation = () => {
  const location = useLocation()
  const { id } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const isWideScreen = useMediaQuery(theme.breakpoints.up('wideScreen'), { noSsr: true })
  const role = useSelector(state => state.users?.profile?.role)
  const [drawerOpen, , closeDrawer, toggleDrawer] = useBooleanState(!!isWideScreen)

  const navItems = navigationItems.filter(item => item.role.includes(role))

  const drawer = (
    <>
      <DrawerHeader className={`drawer-header ${!drawerOpen ? 'closed' : null}`}>
        {
          drawerOpen &&
            <>
              <LogoAndText className='logo-and-text' />
              <IconButton onClick={toggleDrawer} className="drawer-header-icon-container">
                <CollpaseLeftIcon className="drawer-header-icon icon normal" />
              </IconButton>
            </>
        }
        {
          !drawerOpen &&
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              edge="start"
              className="drawer-header-icon"
            >
              <Logo className="logo-menu" />
            </IconButton>
        }
      </DrawerHeader>
      <List className="drawer-list">
        {
          navItems.map(item => {
            const Icon = item.Icon
            const active = location?.pathname.startsWith(item.pathMatch || item.pathname)
            return (
              <ListItem
                button
                key={item.label}
                className={`list-item ${active ? 'active' : null}`}
                onClick={
                  () => {
                    navigate(item.pathname)
                    if (!isWideScreen) {
                      closeDrawer()
                    }
                  }
                }
              >
                { active && <div className="active-bar" /> }
                <ListItemIcon className="drawer-icon-container">
                  <Icon className={`drawer-icon ${active ? 'active' : null}`} />
                </ListItemIcon>
                <ListItemText primary={item.label} className="drawer-item-text" />
              </ListItem>
            )
          })
        }
      </List>
    </>
  )

  return (
    <AppLayoutProvider>
      <Box className="navigation-container">
        <AppBar
          className={`navigation-header-container ${(drawerOpen) ? 'open' : 'closed'}`}
          position="fixed" open={drawerOpen}
        >
          <Toolbar className="navigation-header" >
            <div className="header-main">
              { !drawerOpen && <LogoText className="navigation-header-text" /> }
              <H1>
                { getPageTitle(location?.pathname, id) }
              </H1>
            </div>
            <NotificationsMenu />
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          open={isWideScreen ? drawerOpen : false}
          className={
`
            permanent-drawer
            ${(isWideScreen && drawerOpen) ? 'wide-screen' : 'mobile'}
            ${drawerOpen ? 'open' : null}
          `
          }
        >
          {drawer}
        </Drawer>
        {
          !isWideScreen && drawerOpen &&
            <Drawer
              variant="temporary"
              open={true}
              onClose={toggleDrawer}
              ModalProps={
                {
                  keepMounted: true, // Better open performance on mobile.
                }
              }
            >
              {drawer}
            </Drawer>
        }
        <Box className="main-container">
          <div className="main">
            <DrawerFilter />
            <Outlet />
          </div>
        </Box>
      </Box>
    </AppLayoutProvider>
  )
}
MainNavigation.propTypes = {}

export default MainNavigation
