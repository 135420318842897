import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button } from '@mui/material'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'

import { ExportExecution } from 'services/enums'
import Dialog from 'components/ui/Dialog'
import { P } from 'components/ui/Typography'

import styles from './ExportModal.module.scss'

const ExportModal = ({ open, onClose, exportData }) => {
  const [value, setValue] = useState(ExportExecution.all.value)

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    exportData(value)
    onClose()
  }

  return (
    <Dialog
      title="Export executing tracking data"
      open={open}
      onClose={onClose}
      className={styles.dialog}
    >
      <form onSubmit={handleSubmit}>
        <FormControl>
          <div className={`flex-col-center ${styles.bodyContainer}`}>
            <P>You are about to export execution tracking data for filtered options. Please select export options:</P>
            <RadioGroup
              value={value}
              onChange={handleChange}
            >
              <FormControlLabel value={ExportExecution.all.value} control={<Radio />} label="All data from this page" />
              <FormControlLabel value={ExportExecution.kpiAndSubcategories.value} control={<Radio />} label="KPIs and subcategories" />
            </RadioGroup>
            <Button
              className={styles.confirmButton}
              type='submit'
              variant="contained"
            >Export</Button>
            <Button
              className={styles.cancelButton}
              onClick={onClose}
              variant="primary"
            >Cancel</Button>
          </div>
        </FormControl>
      </form>
    </Dialog>
  )
}

ExportModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  exportData: PropTypes.func,
}

export default ExportModal
