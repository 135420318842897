import api from 'services/api'
import { pass } from 'utils/flow'
import { commonErrorHandler } from 'services/api/common'

import * as types from './types'

export const vacate = () => ({ type: types.vacate })

export const fetchLatestClustersConfig = () => dispatch => {
  return api.clusters.getLatestClustersConfig()
    .then(data => pass(data, () => dispatch(fetchLatestClustersConfigSuccess(data))))
}
const fetchLatestClustersConfigSuccess = data => ({ type: types.fetchLatestClustersConfigSuccess, data })

export const fetchClusters = (clustersConfigId) => dispatch => {
  return api.clusters.getClusters(clustersConfigId)
    .then(data => pass(data, () => dispatch(fetchClustersSuccess(data))))
}
const fetchClustersSuccess = data => ({ type: types.fetchClustersSuccess, data })

export const fetchClusterDimensions = clustersConfigId => dispatch => {
  return api.clusters.getDimensions(clustersConfigId)
    .then(data => pass(data, () => dispatch(fetchClusterDimensionsSuccess(data))))
}
const fetchClusterDimensionsSuccess = data => ({ type: types.fetchClusterDimensionsSuccess, data })

export const fetchClusterStores = (clustersConfigId, clusterId) => dispatch => {
  return api.clusters.getClusterStores(clustersConfigId, clusterId)
    .then(data => pass(data, () => dispatch(fetchClusterStoresSuccess(clusterId, data))))
}
const fetchClusterStoresSuccess = (clusterId, data) => ({ type: types.fetchClusterStoresSuccess, clusterId, data })

export const fetchClustersWithStores = (clustersConfigId) => (dispatch, getState) => {
  return dispatch(fetchClusters(clustersConfigId))
    .then(data => Promise.all(data.clusters.map(cluster => dispatch(fetchClusterStores(clustersConfigId, cluster.id)))))
    .then(() => getState().clusters.clustersConfig)
}

export const confirmClusters = (clustersConfigId) => dispatch => {
  return api.clusters.confirmClusters(clustersConfigId)
    .then(data => pass(data, () => dispatch(confirmClustersSuccess(clustersConfigId))))
    .catch(commonErrorHandler)
}
const confirmClustersSuccess = (clustersConfigId) => ({ type: types.confirmClustersSuccess, clustersConfigId })
